import { useMutation } from "@tanstack/react-query";
import { CreatePayoutRequestPayload } from "models/payoutRequests";

import { useAuthenticatedRequestCreator } from "./useRequests";

const useCreatePayoutRequest = () => {
    const createPayout = useAuthenticatedRequestCreator<
        void,
        CreatePayoutRequestPayload
    >((data) => ({
        url: "/payout-requests/create",
        method: "POST",
        data,
    }), [400]);

    return useMutation(["createPayout"], createPayout);
};

export default useCreatePayoutRequest;
