import { Box, Text } from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Button, Col, List, Radio, RadioChangeEvent, Row, Select, Table } from "antd";
import { DefaultOptionType } from "antd/lib/select";
import { ColumnsType } from "antd/lib/table";
import { FilterValue } from "antd/lib/table/interface";
import MultiValueDisplay from "components/MultiValueDisplay";
import { useAuthenticatedRequest, useAuthenticatedRequestCreator } from "hooks/useRequests";
import { ApplicationUser } from "models/applicationUsers";
import { CardFolder, CardFolderAssignment, CardFolderExtra, FolderFilter } from "models/cardIngestion";
import { InitialPageRequest, PageRequest, PageSizeOptions, PagedResultWithExtra } from "models/common";
import { useState } from "react";
import { localizedDate, pageRequestToUrlSearchParams } from "util/helpers";

const NoTyper = 'N/T';

const useCardFolders = (pageRequest: PageRequest) => {
    const options = pageRequestToUrlSearchParams(pageRequest);

    const get = useAuthenticatedRequest<PagedResultWithExtra<CardFolder, CardFolderExtra>>(({
        method: "get",
        url: `/card-titling/folders?${options}`
    }));

    return useQuery(["card-folders", pageRequest], get);
}

const useActiveTypers = () => {
    const get = useAuthenticatedRequest<ApplicationUser[]>(({
        method: "get",
        url: "/card-titling/available-typers"
    }));

    return useQuery(["available-typers"], get);
}

const useCardFolderAssignment = () => {
    const queryClient = useQueryClient();

    const update = useAuthenticatedRequestCreator<void, CardFolderAssignment>(
        assign => ({
            method: "post",
            url: "/card-titling/assign-typer",
            data: assign
        })
    );

    const { mutateAsync: assignCardFolder, isLoading: isSaving } = useMutation(update, {
        onSuccess: () => {
            queryClient.invalidateQueries(["card-folders"])
        }
    });

    return { assignCardFolder, isSaving };
}

const sumCards = (data: CardFolder[]) => data.map(x => x.cardCount).reduce((a, c) => a += c, 0);

const CustomerDisplay = (folder: CardFolder) =>
    !folder.friendlyPackageId
        ? ''
        : <MultiValueDisplay
            title={`${folder.customerFirstName} ${folder.customerLastName}`}
            subTitle={folder.customerEmail}
        />;

const SelectionDisplay = (folder: CardFolder) =>
    !folder.friendlyPackageId
        ? {
            title: <Text fontFamily='body'>{folder.name}</Text>,
            description: 'This folder contains multiple packages'
        }
        : {
            title: <Text fontFamily='body'>#{folder.friendlyPackageId} - {folder.name}</Text>,
            description: `${folder.customerFirstName} ${folder.customerLastName} (${folder.customerEmail})`
        }

const getFilterBy = (filter: FolderFilter): Record<string, string[]> | undefined => {
    switch (filter) {
        case FolderFilter.Unassigned:
            return { Unassigned: [''] };
        case FolderFilter.InProgress:
            return { InProgress: [''] };
        case FolderFilter.Completed:
            return { ShowCompleted: [''] };
        default:
            return undefined;
    }
}

const TyperAssignment = () => {
    const [{ page, filterBy, withinDays, sort, pageSize }, setPageRequest] =
        useState<PageRequest>({
            ...InitialPageRequest,
            filterBy: getFilterBy(FolderFilter.Unassigned)
        });
    const { isLoading, data } = useCardFolders({
        page,
        filterBy,
        withinDays,
        sort,
        pageSize
    });
    const { data: typers } = useActiveTypers();
    const { assignCardFolder, isSaving } = useCardFolderAssignment();
    const [list, setList] = useState<CardFolder[]>([]);
    const [selectedTyper, setSelectedTyper] = useState<DefaultOptionType | null>(null);

    const { data: folders, totalRecords, extra } = data ?? {};
    const selectedCount = sumCards(list);

    const working = isLoading || isSaving;

    const columns: ColumnsType<CardFolder> = [
        {
            title: "Package ID",
            dataIndex: "friendlyPackageId",
            sorter: false,
        },
        {
            title: "Name",
            dataIndex: "name",
            sorter: false,
        },
        {
            title: "Customer",
            dataIndex: "customerUserName",
            sorter: false,
            render: (_, rec) => CustomerDisplay(rec),
        },
        {
            title: "Count",
            dataIndex: "cardCount",
            sorter: false,
        },
        {
            title: "Progress",
            key: "progress",
            render: (_, rec) => `${((rec.completedCount / rec.cardCount) * 100).toFixed(2)}%`
        },
        {
            title: "Rec. Date",
            dataIndex: "packageReceivedDate",
            sorter: false,
            render: localizedDate
        },
        {
            title: "Est. Upload Date",
            dataIndex: "packageEstUploadDate",
            sorter: false,
            render: localizedDate
        },
        {
            title: "Typer",
            dataIndex: "assignedTyperId",
            sorter: false,
            filters: (typers ?? []).map(t => ({ text: `${t.firstName} ${t.lastName}`, value: t.id })),
            onFilter: (v, rec) => rec.assignedTyperId === (v === '' ? null : v),
            filterMultiple: false,
            render: (_, rec) => rec.assignedTyperUserName
        }
    ];

    const tableChanged = (
        // pagination
        { current: newPage, pageSize: newPageSize }: any,
        // filtering
        filters: Record<string, FilterValue | null>,
        // sorting
        { column, field, order }: any
    ) => {
        setPageRequest({
            page: newPageSize !== pageSize ? 1 : newPage,
            pageSize: newPageSize,
            filterBy: filters,
            withinDays,
            sort: column ? [field, order === "ascend"] : null,
        });
    };

    const applyFilter = (e: RadioChangeEvent) => {
        const filterBy = getFilterBy(e.target.value);
        setPageRequest({ page: 1, filterBy, sort, pageSize, withinDays });
    }

    const assign = async () => {
        const typerId = selectedTyper!.value === NoTyper
            ? null
            : selectedTyper!.value!.toString();

        await assignCardFolder({
            typerId,
            cardFolderIds: list.map(f => f.id)
        });
        setList([]);
    }

    return <Box p={50} w='100%'>
        <Row gutter={[32, 8]}>
            <Col span={16}>
                <Box justifyContent='space-between' display='flex' style={{ width: '100%' }}>
                    <Box fontSize={36} pb={10}>Typer Assignment</Box>
                    <Radio.Group
                        defaultValue={FolderFilter.Unassigned}
                        size='large'
                        buttonStyle='solid'
                        onChange={applyFilter}
                        style={{ alignSelf: 'end' }}
                        disabled={working}
                    >
                        <Radio.Button value={FolderFilter.All}>All</Radio.Button>
                        <Radio.Button value={FolderFilter.Unassigned}>Unassigned</Radio.Button>
                        <Radio.Button value={FolderFilter.InProgress}>In Progress</Radio.Button>
                        <Radio.Button value={FolderFilter.Completed}>Completed</Radio.Button>
                    </Radio.Group>
                </Box>
            </Col>
            <Col span={8}></Col>

            <Col span={16}>
                <Table
                    loading={working}
                    columns={columns}
                    dataSource={folders}
                    rowKey={p => p.id}
                    rowSelection={{
                        selectedRowKeys: list.map(f => f.id),
                        onChange: (_, rows) => setList([...rows]),
                        selections: [
                            Table.SELECTION_ALL,
                            Table.SELECTION_INVERT,
                            Table.SELECTION_NONE
                        ]
                    }}
                    pagination={{
                        total: totalRecords,
                        current: page,
                        pageSize,
                        pageSizeOptions: PageSizeOptions,
                        showSizeChanger: true,
                        showQuickJumper: true,
                    }}
                    onChange={tableChanged}
                    footer={() => <Box>
                        Total Cards: {extra?.totalCards}
                    </Box>}
                />
            </Col>
            <Col span={8}>
                <List
                    dataSource={list}
                    loading={working}
                    itemLayout='horizontal'
                    header={<Box display='flex' justifyContent='space-between' w='100%'>
                        <Box fontSize={24}> {selectedCount} Cards Selected</Box>
                        <Box>
                            <Select
                                placeholder='Assign Folder(s) To'
                                options={
                                    [
                                        { label: 'Unassign', value: NoTyper },
                                        ...(typers ?? []).map(t => ({ label: `${t.firstName} ${t.lastName}`, value: t.id }))
                                    ]}
                                onChange={(_, o) => setSelectedTyper(o as DefaultOptionType)}
                                style={{ minWidth: '200px' }}
                                disabled={working}
                            />
                            <Button type="primary" disabled={list.length === 0 || selectedTyper === null || working} onClick={assign}>Assign</Button>
                        </Box>
                    </Box>}
                    renderItem={item => (
                        <List.Item>
                            <List.Item.Meta {...SelectionDisplay(item)} />
                            <Box>{item.cardCount} Cards</Box>
                        </List.Item>
                    )}
                />
            </Col>
        </Row>
    </Box>
};

export default TyperAssignment;