import { Button, ButtonProps, forwardRef, Text } from "@chakra-ui/react";
import { PublicRoutes } from "appRoutePaths";
import { useNavigate } from "react-router-dom";

type NavigateButtonProps = { text?: string; to: string } & ButtonProps;

export const NavigateButton = forwardRef<NavigateButtonProps, typeof Button>(
    ({ text, to, ...props }, ref) => {
        const navigate = useNavigate();
        return (
            <Button
                fontSize={"sm"}
                fontWeight={"semibold"}
                onClick={() => navigate(to)}
                variant="primary"
                ref={ref}
                py={2}
                px={4}
                height="auto"
                {...props}
            >
                <Text>{text}</Text>
            </Button>
        );
    }
);

const LoginButton = forwardRef<Omit<NavigateButtonProps, "to">, typeof Button>(
    ({ text, ...props }, ref) => (
        <NavigateButton
            text={text ?? "Login"}
            to={PublicRoutes.login.url}
            ref={ref}
            style={{ fontSize: "1.5rem" }}
            {...props}
        />
    )
);

export default LoginButton;
