import { ExternalLinkIcon } from "@chakra-ui/icons";
import { forwardRef, HStack, StackProps } from "@chakra-ui/react";
import useZendeskSSO from "hooks/useZendeskSSO";
import { ZENDESK_BASE_URL } from "util/config";

const OpenExternalSupport = forwardRef<StackProps, typeof HStack>(
    (props, ref) => {
        const makeZendeskLink = useZendeskSSO();

        const openZendeskLink = async () => {
            const zendeskLink = await makeZendeskLink(
                `${ZENDESK_BASE_URL}/hc/en-us/requests`
            );
            window.open(zendeskLink, "_blank");
        };

        return (
            <HStack
                ref={ref}
                as="a"
                onClick={openZendeskLink}
                fontSize={20}
                fontWeight="semibold"
                color="lightBlue"
                spacing={2}
                lineHeight="1em"
                {...props}
            >
                <span>Visit dcsports87's Support Center</span>
                <ExternalLinkIcon />
            </HStack>
        );
    }
);

export default OpenExternalSupport;
