import { Button, Modal, Table } from "antd"
import { ColumnsType } from "antd/lib/table";
import useShipmentBatchSummary from "hooks/useShipmentBatchSummary";
import {
    ShipmentBatch,
    ShipmentBatchSummary,
    ShipmentServiceLevelNames,
    ShipmentTemplates
} from "models/shipping";
import { useState } from "react";

const columns: ColumnsType<ShipmentBatchSummary> = [
    {
        title: 'ServiceLevel',
        dataIndex: 'serviceLevel',
        render: (sl) => ShipmentServiceLevelNames[sl]
    },
    {
        title: 'Dimensions',
        dataIndex: 'dimensions',
        render: (dim) => ShipmentTemplates[dim] || dim
    },
    {
        title: 'Count',
        dataIndex: 'count'
    },
];

const ShipmentBatchSummaryModel = (props: { shipmentBatch: ShipmentBatch }) => {
    const [visible, setVisible] = useState<boolean>(false);
    const [shipmentBatchId, setShipmentBatchId] = useState<string | undefined>();

    // to ensure instances of this modal aren't sending requests when the 
    // parent has mounted (N+1 server calls where N is the amount of modals on the page), 
    // set the shipment batch id when the modal opens. The hook is only enabled when
    // the id is not null or undefined.
    const { isLoading, data } = useShipmentBatchSummary(shipmentBatchId);

    const onOpen = () => {
        setVisible(true);
        setShipmentBatchId(props.shipmentBatch.id);
    }

    return <>
        <Button type="link" onClick={onOpen}>
            {props.shipmentBatch.orders}
        </Button>
        <Modal
            title={props.shipmentBatch.name}
            closable={false}
            keyboard={true}
            maskClosable={true}
            destroyOnClose={true}
            open={visible}
            footer={[
                <Button key='ok' type="primary" onClick={() => setVisible(false)}>OK</Button>
            ]}
        >
            <Table
                rowKey={r => `${r.serviceLevel}-${r.dimensions}`}
                loading={isLoading}
                size="small"
                columns={columns}
                dataSource={data}
                pagination={{
                    hideOnSinglePage: true
                }}
            />
        </Modal>
    </>
}

export default ShipmentBatchSummaryModel;