import TextTableFilter from "components/TextTableFilter";
import { ClearTableFilterRequest } from "models/common";
import {
    ExtendedFilterDropdownProps,
} from "util/table";

const TransactionNameSearch = ({
    prefixCls,
    visible,
    confirm,
    selectedKeys,
    setSelectedKeys,
    clearFilters,
    onFilter,
}: ExtendedFilterDropdownProps) => {
    return <TextTableFilter
        prefixCls={prefixCls}
        visible={visible}
        confirm={confirm}
        selectedKeys={selectedKeys}
        setSelectedKeys={setSelectedKeys}
        clearFilters={clearFilters}
        onFilter={onFilter}
        clearTableFilterRequest={ClearTableFilterRequest.Name}
        placeholder='Desc. contains...'
    />;
};

export default TransactionNameSearch;