import { useQuery } from "@tanstack/react-query";
import { PagedResult, PageRequest } from "models/common";
import { FinancialSummaryData } from "models/financialSummary";
import { pageRequestToUrlSearchParams } from "util/helpers";
import { useAuthenticatedRequest } from "./useRequests";

export const useFinancialSummary = (pageRequest: PageRequest) => {
    const options = pageRequestToUrlSearchParams(pageRequest);

    const getFinancialSummary = useAuthenticatedRequest<PagedResult<FinancialSummaryData>>({
        method: "get",
        url: `/finance/summary?${options}`
    });

    return useQuery(["finance", "summary", pageRequest], getFinancialSummary);
}

export const useFinancialSummaryJobStatus = () => {
    const getStatus = useAuthenticatedRequest<{ status: string }>({
        method: "get",
        url: "/finance/summary/job-status"
    });

    return useQuery(["finance", "summary", "job-status"], getStatus);
};