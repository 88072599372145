import { useMutation } from "@tanstack/react-query";
import { ApplicationUser } from "models/applicationUsers";
import { useAuthenticatedRequestCreator } from "./useRequests";

export interface ManualAdjustment {
    customer: ApplicationUser;
    description: string;
    amountInCents: number;
}

const useCreateTransaction = () => {
    const createManualAdjustmentCreator = useAuthenticatedRequestCreator<
        boolean,
        ManualAdjustment
    >(adjustment => ({
        method: "post",
        url: "/transactions/manual-adjustment",
        data: {
            customerId: adjustment.customer.id,
            description: adjustment.description,
            amountInCents: adjustment.amountInCents
        }
    }));

    const { mutate: createManualAdjustment } = useMutation(
        (transaction: ManualAdjustment) =>
            createManualAdjustmentCreator(transaction)
    );

    return { createManualAdjustment };
};

export default useCreateTransaction;
