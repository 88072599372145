import { CartItem, CartStockCheck } from "models/store";
import { useAuthenticatedRequest, useAuthenticatedRequestCreator } from "./useRequests";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useIdentity } from "context/auth";
import { getOrderPreviewKey } from "./useOrderPreview";

export interface AddToCartRequest {
    productId: string;
    variationId: string;
    quantity: number;
}

export interface UpdateCartRequest {
    cartItemId: string;
    quantity: number;
}

const useInvalidateCartCache = (userId: string) => {
    const queryClient = useQueryClient();
    const orderPreviewKey = getOrderPreviewKey(userId);

    // ensuring we invalidate the order preview when the cart changes
    return { clearCartCache: () => queryClient.invalidateQueries(orderPreviewKey) };
}

const useAddToCart = () => {
    const { id } = useIdentity();
    const { clearCartCache } = useInvalidateCartCache(id!);

    const addToCartRequest = useAuthenticatedRequestCreator<void, AddToCartRequest>(
        (requestBody) => ({
            method: 'post',
            url: '/cart/add',
            data: requestBody,
        })
    );

    const addToCart = async (requestBody: AddToCartRequest): Promise<void> => {
        await addToCartRequest(requestBody);
        clearCartCache();
    };

    return addToCart;
};

const useUpdateCart = () => {
    const { id } = useIdentity();
    const { clearCartCache } = useInvalidateCartCache(id!);

    const updateCartRequest = useAuthenticatedRequestCreator<void, UpdateCartRequest>(
        (requestBody) => ({
            method: 'post',
            url: '/cart/update',
            data: requestBody,
        })
    );



    const updateCart = async (requestBody: UpdateCartRequest): Promise<void> => {
        await updateCartRequest(requestBody);
        clearCartCache();
    };

    return updateCart;
};

const useCartItems = () => {
    const { id } = useIdentity();

    const getData = useAuthenticatedRequest<CartItem[]>({
        url: "/cart",
        method: "GET",
    });

    return useQuery(["customer", id, "cart"], getData);
};

const useStockCheck = () => {
    const { id } = useIdentity();
    const stockCheckKey = ["customer", id!, "stock-check"];

    const check = useAuthenticatedRequest<CartStockCheck[]>({
        url: "/cart/stock-check",
        method: "GET"
    });

    return useQuery(stockCheckKey, check, { enabled: false, cacheTime: 0 });
}

const useShoppingCart = () => {

    const fetchCart = useCartItems();
    const addToCart = useAddToCart();
    const updateCart = useUpdateCart();
    // only want to run this just-in-time, so the
    // query is disabled, and using refetch as the
    // invocation
    const { refetch } = useStockCheck();

    return {
        fetchCart,
        addToCart,
        updateCart,
        stockCheck: refetch
    }
};

export default useShoppingCart;