import { LoadingOutlined } from "@ant-design/icons";
import { Flex, Text, VStack } from "@chakra-ui/react";
import EnableMFA from "components/customer/Profile/EnableMFA";
import { useIdentity } from "context/auth";
import useProfile from "hooks/useProfile";

const MandatoryMFA = () => {
    const { data: profile, isLoading } = useProfile();
    const { logout } = useIdentity();

    if (isLoading || !profile) {
        return <LoadingOutlined spin style={{ color: "var(--dcs-blue)", fontSize: '40px' }} />;
    }

    return <Flex alignItems="center" justifyContent="center" m={70}>
        <Flex direction='column'>
            <Text fontSize="4xl" mb={5}>Mandatory Two-Factor Authentication</Text>
            <Text fontSize="2xl" mb={5}>As a dcsports87 administrator, you are required to set up two-factor authentication for your account.</Text>

            <VStack
                bg="lightGray"
                rounded={5}
                p={4}
                alignItems='center'
            >
                <EnableMFA onEnabled={logout} emailConfirmed={true} />
            </VStack>

            <Text fontSize="2xl" mt={5}>Once two-factor has been setup, you will have to login again.</Text>
        </Flex>
    </Flex>;
}

export default MandatoryMFA;