import { Button, Modal } from "antd";
import { StoreOrder } from "models/store";
import { FC, useState } from "react";
import OrderDetail from "./OrderDetail";
import { Box } from "@chakra-ui/react";

const OrderDetailModal: FC<{ order: StoreOrder }> = ({ order }) => {
    const [visible, setVisible] = useState<boolean>(false);

    return <>
        <Button type="link" onClick={() => setVisible(true)}>
            #{order.friendlyId}
        </Button>
        <Modal
            width={600}
            style={{ top: 30 }}
            bodyStyle={{ marginTop: 0, paddingTop: 0 }}
            closable={false}
            keyboard
            maskClosable
            destroyOnClose
            open={visible}
            onCancel={() => setVisible(false)}
            footer={[
                <Button block size="large" key='ok' type="primary" onClick={() => setVisible(false)}>OK</Button>
            ]}>
            <Box w="100%">
                <OrderDetail order={order} />
            </Box>
        </Modal>
    </>;
};

export default OrderDetailModal;