import { StaffRoutes, TyperRoutes } from "appRoutePaths";
import { Roles } from "context/auth";
import { ZENDESK_BASE_URL } from "util/config";

export interface SideMenuEntry {
    name: string;
    url: string;
    icon: any;
    external?: boolean;
    children?: SubMenuEntry[];
    isActive?: boolean;
    count?: number;
}

export interface SubMenuEntry {
    name: string;
    url: string;
}

export const StaffMenuItems = (roles: Roles[]): SideMenuEntry[] => {
    if (roles.includes(Roles.Admin)) {
        return [
            {
                name: "Admin Dashboard",
                url: StaffRoutes.dashboard.url,
                icon: require("images/icons/icon_dashboard.svg"),
            },
            {
                name: "Packages",
                url: StaffRoutes.packages.url,
                icon: require("images/icons/icon_packages.svg"),
            },
            {
                name: "Payouts",
                url: StaffRoutes.payouts.url,
                icon: require("images/icons/icon_payout.svg"),
            },
            {
                name: "Cards",
                url: StaffRoutes.cards.url,
                icon: require("images/icons/icon_cards.svg"),
            },
            {
                name: "Users",
                url: StaffRoutes.users.url,
                icon: require("images/icons/icon_support.svg"),
            },
            {
                name: "Website Updates",
                url: StaffRoutes.websiteUpdates.url,
                icon: require("images/icons/icon_support.svg"),
            },
            {
                name: "Support Tickets",
                url: ZENDESK_BASE_URL,
                external: true,
                icon: require("images/icons/icon_support.svg"),
            },
            {
                name: "Transactions",
                url: StaffRoutes.transactions.url,
                icon: require("images/icons/icon_transactions.svg"),
            },
            {
                name: "Financial Summary",
                url: StaffRoutes.financialSummary.url,
                icon: require("images/icons/icon_support.svg"),
            },
            {
                name: "Shipment Batches",
                url: StaffRoutes.shipmentBatches.url,
                icon: require("images/icons/icon_packages.svg")
            },
            {
                name: "Unsold Card Batches",
                url: StaffRoutes.unsoldCardBatches.url,
                icon: require("images/icons/icon_cards.svg")
            },
            {
                name: "Order Fulfillment",
                url: StaffRoutes.orders.url,
                icon: require("images/icons/icon_start_selling.svg")
            }
        ];
    }
    else if (roles.includes(Roles.Staff)) {
        const staffItems = [
            {
                name: "Staff Dashboard",
                url: StaffRoutes.dashboard.url,
                icon: require("images/icons/icon_dashboard.svg"),
            },
            {
                name: "Packages",
                url: StaffRoutes.packages.url,
                icon: require("images/icons/icon_packages.svg"),
            },
            {
                name: "Shipment Batches",
                url: StaffRoutes.shipmentBatches.url,
                icon: require("images/icons/icon_packages.svg")
            },
            {
                name: "Unsold Card Batches",
                url: StaffRoutes.unsoldCardBatches.url,
                icon: require("images/icons/icon_cards.svg")
            },
            {
                name: "Order Fulfillment",
                url: StaffRoutes.orders.url,
                icon: require("images/icons/icon_start_selling.svg")
            },
            {
                name: "Package Types/Fees",
                url: StaffRoutes.packageTypes.url,
                icon: require("images/icons/icon_packages.svg")
            }];

        if (roles.includes(Roles.Typer)) {
            staffItems.push({
                name: "Card Titling",
                url: TyperRoutes.dashboard.url,
                icon: require("images/icons/icon_cards.svg")
            });
        }

        return staffItems;
    }
    return [];  // TODO add pages for users w/ staff role
}
