import { PrinterOutlined } from "@ant-design/icons";
import { Box, HStack, Text, VStack } from "@chakra-ui/react";
import { Button, Divider } from "antd";
import { StoreOrderShipment } from "models/store";
import { FC } from "react";

const ShipmentDetails: FC<StoreOrderShipment> = ({
    trackingNumber,
    labelUrl,
    dimensions,
    weightInOz
}) => {
    return <Box w='100%'>
        <Divider style={{ margin: 0 }}>Shipment Details</Divider>
        <HStack mt='10px' display='flex' justifyContent='center'>
            <Button
                style={{ marginRight: '20px' }}
                size="large"
                type="link"
                icon={<PrinterOutlined style={{ fontSize: '46px' }} />}
                target="_blank"
                href={labelUrl!} />
            <VStack alignItems='start'>
                <Text>{trackingNumber}</Text>
                <Text>
                    {dimensions}, {weightInOz} oz.
                </Text>
            </VStack>
        </HStack>
    </Box>;
}

export default ShipmentDetails;