import { CheckCircleFilled, SnippetsOutlined } from "@ant-design/icons";
import { HStack, Text } from "@chakra-ui/react";
import { Button, Col, DatePicker, Form, Input, Modal, Row, Select, Tooltip } from "antd";
import { RuleObject } from "antd/lib/form";
import BatchSelector from "components/BatchSelector";
import CustomerSelector from "components/CustomerSelector";
import useSingleCardUpdate from "hooks/useSingleCardUpdate";
import { CardStatus, StaffSportsCard } from "models/cards";
import moment from "moment";
import { FC, useState } from "react";
import { centsToDollars, dollarsToCents } from "util/helpers";

export type CardUpdateCallback = (cardId: string) => void;

const CardIdHelper: FC<{ card: StaffSportsCard }> = ({ card }) => {
    const [copied, setCopied] = useState<boolean>(false);
    const { cardId, sku } = card;

    const copy = () => {
        navigator.clipboard.writeText(cardId);
        setCopied(true);
    }

    return <HStack justifyContent='space-between'>
        <Text>Edit Card</Text>

        {!sku && !copied && <Tooltip placement='left' title="Click to copy card id to clipboard">
            <Button icon={<SnippetsOutlined />} onClick={copy} type="link" />
        </Tooltip>}

        {!sku && copied && <Tooltip placement='left' title="Copied card id to clipboard!">
            <Button icon={<CheckCircleFilled />} onClick={copy} type="link" />
        </Tooltip>}

        {sku && <Text fontSize={16}>SKU: {sku}</Text>}
    </HStack>;
}

const EditCardModal = (props: {
    open: boolean;
    onClose: () => void;
    card: StaffSportsCard;
    onUpdate: CardUpdateCallback;
}) => {
    const { update } = useSingleCardUpdate();
    const [working, setWorking] = useState<boolean>(false);
    const [newCardStatus, setCardStatus] = useState<string>(props.card.status);
    const [form] = Form.useForm();

    const { open, card, onUpdate, onClose } = props;

    const lineItem = card.order?.lineItems
        ? card.order.lineItems[0]
        : undefined;

    const salePriceDisabled = card.status === CardStatus.Active;

    const defaultPlaceholder = !card.sku ? "LEGACY" : undefined;
    const initialValues = {
        ...card,
        salePrice: centsToDollars(card.salePriceInCents, false),
        listingDate: moment(card.listingDate || new Date()),
        saleDate: moment(card.order?.creationDate || new Date()),
        paymentDate: moment(card.order?.paymentDate || new Date()),
        shippedDate: moment(lineItem?.shippedDate || new Date()),
        ebayItemId: lineItem?.legacyId ? lineItem.legacyId : defaultPlaceholder,
        ebayOrderId: card.order?.id ? card.order.id : defaultPlaceholder,
        ebayOrderLineItemId: lineItem?.id ? lineItem.id : defaultPlaceholder,
        buyerName: card.order?.buyerName
            ? card.order.buyerName
            : defaultPlaceholder,
        buyerEmail: card.order?.buyerEmail
            ? card.order.buyerEmail
            : defaultPlaceholder,
        buyerAddress1: card.order?.address1
            ? card.order.address1
            : defaultPlaceholder,
        buyerAddress2: card.order?.address2
            ? card.order.address2
            : defaultPlaceholder,
        buyerCity: card.order?.city ? card.order.city : defaultPlaceholder,
        buyerState: card.order?.state ? card.order.state : defaultPlaceholder,
        buyerZipCode: card.order?.zipCode
            ? card.order.zipCode
            : defaultPlaceholder,
    };
    const batch = {
        id: card.batchId,
        number: card.batchNumber,
        createdDate: card.batchCreatedDate,
    };
    const customer = {
        id: card.customerId,
        email: card.customerEmail,
        userName: "",
        firstName: card.customerFirstName,
        lastName: card.customerLastName,
    };

    const salePriceValidator = async (
        _: RuleObject,
        price: number
    ): Promise<void> => {
        if (isNaN(price)) {
            return Promise.reject(new Error("Not a valid price!"));
        }
        form.setFieldsValue({ salePriceInCents: price });
        return Promise.resolve();
    };

    const setEntity = (field: string, entity: { id: string } | undefined) => {
        form.setFieldsValue({ [field]: entity?.id });
    };
    const clearEntity = (field: string) => {
        form.setFieldsValue({ [field]: null });
    };

    const getNextStatuses = (currentStatus: CardStatus): CardStatus[] => {
        switch (currentStatus) {
            case CardStatus.Active:
                return [
                    CardStatus.AwaitingPayment,
                    CardStatus.Cancelled,
                    CardStatus.Paid,
                    CardStatus.Unsold,
                ];
            case CardStatus.Unsold:
                return [
                    CardStatus.Active,
                    CardStatus.AwaitingPayment,
                    CardStatus.Cancelled,
                    CardStatus.Paid,
                ];
            case CardStatus.AwaitingPayment:
                return [CardStatus.Cancelled, CardStatus.Paid];
            case CardStatus.Cancelled:
                return [CardStatus.Active, CardStatus.Unsold];
        }
        return [];
    };

    const isStatusChangedToActive =
        newCardStatus !== card.status && newCardStatus === CardStatus.Active;
    const isStatusChangedToAwaitingPayment =
        newCardStatus !== card.status &&
        newCardStatus === CardStatus.AwaitingPayment;
    const isStatusChangedToPaid =
        newCardStatus !== card.status && newCardStatus === CardStatus.Paid;

    const submitForm = () => {
        form.validateFields().then(async (form) => {
            const cardUpdate = {
                cardId: card.cardId,
                batchId: form.batchId,
                customerId: form.customerId,
                salePriceInCents: dollarsToCents(form.salePrice),
                title: form.title,
                status: form.status,
                listingDate: form.listingDate?.toDate(),
                saleDate: form.saleDate?.toDate(),
                order: isStatusChangedToPaid
                    ? {
                        id: form.ebayOrderId,
                        creationDate: form.saleDate?.toDate(),
                        paymentDate: form.paymentDate?.toDate(),
                        buyerName: form.buyerName,
                        buyerEmail: form.buyerEmail,
                        buyerUsername: 'legacy_ebay_user',
                        address1: form.buyerAddress1,
                        address2: form.buyerAddress2,
                        city: form.buyerCity,
                        state: form.buyerState,
                        zipCode: form.buyerZipCode,
                        lineItems: [
                            {
                                id: form.ebayOrderLineItemId,
                                legacyId: form.ebayItemId,
                                rawSKU: card.sku ? card.sku : "",
                                title: form.title,
                                priceInCents: dollarsToCents(form.salePrice),
                                orderCreationDate: form.saleDate?.toDate(),
                                shippedDate: form.shippedDate?.toDate(),
                                shipped: true
                            },
                        ],
                    }
                    : undefined,
            };

            setWorking(true);

            try {
                await update(cardUpdate);
                onUpdate(card.cardId);
                onClose();
            } finally {
                setWorking(false);
            }
        });
    };

    const resetForm = () => {
        form.resetFields();
        setCardStatus(card.status);
        onClose();
    };

    return (
        <Modal
            className="card-edit-modal"
            title={<CardIdHelper card={card} />}
            open={open}
            closable={false}
            keyboard={false}
            maskClosable={false}
            confirmLoading={working}
            cancelButtonProps={{ disabled: working }}
            okText={working ? "Saving..." : "Update"}
            onOk={submitForm}
            onCancel={resetForm}
            destroyOnClose
        >
            <Form
                form={form}
                disabled={working}
                layout="vertical"
                initialValues={initialValues}
            >
                <Row gutter={[16, 0]}>
                    <Col span={12}>
                        <Form.Item
                            label="Batch"
                            name="batchId"
                            rules={[
                                {
                                    required: true,
                                    message: "You must select a batch!",
                                },
                            ]}
                        >
                            <BatchSelector
                                onSelected={(batch) =>
                                    setEntity("batchId", batch)
                                }
                                onClear={() => clearEntity("batchId")}
                                initialEntity={batch}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Sale Price"
                            name="salePrice"
                            rules={[
                                {
                                    required: true,
                                    validator: salePriceValidator,
                                    transform: dollarsToCents,
                                },
                            ]}
                        >
                            <Input
                                prefix="$"
                                suffix="USD"
                                disabled={salePriceDisabled}
                            />
                        </Form.Item>
                        <Form.Item
                            name="salePriceInCents"
                            className="has-hidden-input"
                        >
                            <Input type="hidden"></Input>
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item
                    label="Customer"
                    name="customerId"
                    rules={[
                        {
                            required: true,
                            message: "You must select a customer!",
                        },
                    ]}
                >
                    <CustomerSelector
                        onSelected={(customer) =>
                            setEntity("customerId", customer)
                        }
                        onClear={() => clearEntity("customerId")}
                        initialEntity={customer}
                    />
                </Form.Item>
                <Form.Item
                    label="Card Title"
                    name="title"
                    rules={[
                        {
                            required: true,
                            message: "You must specify a card title!",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Card Status" name="status">
                    <Select
                        options={[card.status]
                            .concat(getNextStatuses(card.status))
                            .map((s) => ({
                                value: s,
                                label: s,
                            }))}
                        onChange={(newStatus) => setCardStatus(newStatus)}
                    />
                </Form.Item>
                <Form.Item
                    label="Listing Date"
                    name="listingDate"
                    rules={[
                        {
                            required: isStatusChangedToActive,
                            message: "You must specify a listing date!",
                        },
                    ]}
                    hidden={!isStatusChangedToActive}
                >
                    <DatePicker format="MM/DD/YYYY" />
                </Form.Item>
                <Form.Item
                    label="Sale Date"
                    name="saleDate"
                    rules={[
                        {
                            required:
                                isStatusChangedToAwaitingPayment ||
                                isStatusChangedToPaid,
                            message: "You must specify a sale date!",
                        },
                    ]}
                    hidden={
                        !(
                            isStatusChangedToAwaitingPayment ||
                            isStatusChangedToPaid
                        )
                    }
                >
                    <DatePicker format="MM/DD/YYYY" />
                </Form.Item>
                <Form.Item
                    label="Payment Date"
                    name="paymentDate"
                    rules={[
                        {
                            required: isStatusChangedToPaid,
                            message: "You must specify an eBay item ID!",
                        },
                    ]}
                    hidden={!isStatusChangedToPaid}
                >
                    <DatePicker format="MM/DD/YYYY" />
                </Form.Item>
                <Form.Item
                    name="shippedDate"
                    hidden={true}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="eBay Item ID"
                    name="ebayItemId"
                    rules={[
                        {
                            required: isStatusChangedToPaid,
                            message: "You must specify an eBay item ID!",
                        },
                    ]}
                    hidden={!isStatusChangedToPaid}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="eBay Order Number"
                    name="ebayOrderId"
                    rules={[
                        {
                            required: isStatusChangedToPaid,
                            message:
                                "You must specify an eBay order number!",
                        },
                    ]}
                    hidden={!isStatusChangedToPaid}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="eBay Order Line Item ID"
                    name="ebayOrderLineItemId"
                    rules={[
                        {
                            required: isStatusChangedToPaid,
                            message:
                                "You must specify an eBay order line item ID!",
                        },
                    ]}
                    hidden={!isStatusChangedToPaid}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Buyer Name"
                    name="buyerName"
                    rules={[
                        {
                            required: isStatusChangedToPaid,
                            message: "You must specify a buyer name!",
                        },
                    ]}
                    hidden={!isStatusChangedToPaid}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Buyer Email"
                    name="buyerEmail"
                    rules={[
                        {
                            required: isStatusChangedToPaid,
                            message:
                                "You must specify a buyer email address!",
                        },
                    ]}
                    hidden={!isStatusChangedToPaid}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Buyer Address 1"
                    name="buyerAddress1"
                    rules={[
                        {
                            required: isStatusChangedToPaid,
                            message: "You must specify an address!",
                        },
                    ]}
                    hidden={!isStatusChangedToPaid}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Buyer Address 2"
                    name="buyerAddress2"
                    hidden={!isStatusChangedToPaid}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Buyer City"
                    name="buyerCity"
                    rules={[
                        {
                            required: isStatusChangedToPaid,
                            message: "You must specify a city!",
                        },
                    ]}
                    hidden={!isStatusChangedToPaid}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Buyer State"
                    name="buyerState"
                    rules={[
                        {
                            required: isStatusChangedToPaid,
                            message: "You must specify a state!",
                        },
                    ]}
                    hidden={!isStatusChangedToPaid}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Buyer ZIP Code"
                    name="buyerZipCode"
                    rules={[
                        {
                            required: isStatusChangedToPaid,
                            message: "You must specify a ZIP code!",
                        },
                    ]}
                    hidden={!isStatusChangedToPaid}
                >
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default EditCardModal;
