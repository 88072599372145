/**
 * A transaction line item
 */
export type Transaction = {
    transactionId: string;
    accountId: string;
    cardId: string | null;
    transactionType: number;
    description: string;
    grossAmountInCents: number;
    feesInCents: number;
    netAmountInCents: number;
    transactionDate: string;
};

/**
 * A transaction for a customer that is being viewed by staff
 */
export type StaffTransaction = {
    transactionId: string;
    customerId: string;
    customerFirstName: string | null;
    customerLastName: string | null;
    customerEmail: string;
    accountId: string;
    cardId: string | null;
    transactionType: TransactionType;
    description: string;
    grossAmountInCents: number;
    feesInCents: number;
    netAmountInCents: number;
    transactionDate: string;
    createdByUserEmail: string | null;
};

export const enum TransactionType {
    Unknown = 0,
    CardSale = 1,
    Payout = 2,
    ManualAdjustment = 3,
    OrderPayment = 4,
    UserTransfer = 5,
    ListingFee = 6
}

export const TransactionTypeNames: Record<TransactionType, string> = {
    [TransactionType.Unknown]: "Unknown",
    [TransactionType.CardSale]: "Card Sale",
    [TransactionType.Payout]: "Payout",
    [TransactionType.ManualAdjustment]: "Man. Adj.",
    [TransactionType.OrderPayment]: "Payment",
    [TransactionType.UserTransfer]: "User Transfer",
    [TransactionType.ListingFee]: "Listing Fee",
};