import { Dropdown, Menu } from "antd";
import { CardStatus, StaffSportsCard } from "models/cards";
import { useState } from "react";
import EditCardModal, { CardUpdateCallback } from "./EditCardModal";
import ReturnCardModal from "./ReturnCardModal";
import ShippedToCustomerModal from "./ShippedToCustomerModal";

const CardActions = (props: {
    card: StaffSportsCard,
    onUpdate: CardUpdateCallback
}) => {
    const [editOpen, setEditOpen] = useState(false);
    const [returnOpen, setReturnOpen] = useState(false);
    const [shippedOpen, setShippedOpen] = useState(false);

    const returned = props.card.status === CardStatus.Returned;
    const shippedBack = props.card.status === CardStatus.ShippedToCustomer;

    return <>
        <Dropdown.Button
            onClick={() => setEditOpen(true)}
            overlay={(() => (
                <Menu items={[
                    {
                        key: "return",
                        label: "Mark as Returned",
                        disabled: returned || shippedBack,
                        onClick: () => setReturnOpen(true)
                    },
                    {
                        key: "shipped",
                        label: "Shipped to Customer",
                        disabled: shippedBack,
                        onClick: () => setShippedOpen(true)
                    }
                ]} />
            ))}
        >
            Edit
        </Dropdown.Button>
        <EditCardModal
            open={editOpen}
            onClose={() => setEditOpen(false)}
            card={props.card}
            onUpdate={props.onUpdate}
        />
        <ReturnCardModal
            open={returnOpen}
            onClose={() => setReturnOpen(false)}
            card={props.card}
            onSuccess={props.onUpdate}
        />
        <ShippedToCustomerModal
            open={shippedOpen}
            onClose={() => setShippedOpen(false)}
            card={props.card}
            onSuccess={props.onUpdate}
        />
    </>;
}

export default CardActions;