import { Box, Text, VStack } from "@chakra-ui/react";
import { Button, Divider } from "antd";
import { CustomerRoutes } from "appRoutePaths";
import LoadingSpinnerOverlay from "components/LoadingSpinnerOverlay";
import OrderDetail from "components/customer/Orders/OrderDetail";
import useCustomerOrder from "hooks/useCustomerOrder";
import { useParams } from "react-router-dom";

const OrderConfirmation = () => {
    const { orderId } = useParams();
    const { data, isLoading } = useCustomerOrder(orderId);

    if (isLoading) {
        return <LoadingSpinnerOverlay isLoading={true} />;
    }

    return <Box>
        <VStack>
            <VStack alignItems='start' w='100%'>
                <Box fontSize={36}>Thank you for your order!</Box>
                <Text>We'll send you a notification when your order has shipped!</Text>
                <Button style={{ padding: 0 }} type="link" href={CustomerRoutes.orders.url}>View all orders</Button>
            </VStack>
            <Divider />
            <Box alignSelf='start'>
                <OrderDetail order={data!} />
            </Box>
        </VStack>
    </Box>;
}

export default OrderConfirmation;