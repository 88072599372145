import { useQuery } from "@tanstack/react-query";
import { UserProfile } from "models/profile";

import { useIdentity } from "context/auth";
import { useAuthenticatedRequest } from "./useRequests";

type ProfileState = {
    addressPresent: boolean;
    validEmail: boolean;
};

export const useProfileState = () => {
    const requestProfileState = useAuthenticatedRequest<ProfileState>({
        url: "/profile/state",
        method: "get",
    });

    return useQuery(["profileState"], requestProfileState, { cacheTime: 0 });
};

const useProfile = ({ suspense = false } = {}) => {
    const { twoFactorTokenRequired } = useIdentity();

    const requestProfile = useAuthenticatedRequest<UserProfile>({
        url: "/profile/me",
        method: "get",
    });

    return useQuery(["profile"], requestProfile, {
        cacheTime: 0,
        suspense,
        enabled: !twoFactorTokenRequired
    });
};

export default useProfile;
