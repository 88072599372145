import React from "react";
import { useEffect, useRef } from "react";
import type { NavigateProps } from "react-router-dom";
import { useNavigate } from "react-router-dom";

// There is a bug in react-router that causes an infinite redirect loop in tests,
// this component is a fix from the issue on GitHub.
// https://github.com/remix-run/react-router/issues/8733

export const Navigate: React.FC<NavigateProps> = ({ to, replace, state }) => {
    const navigate = useNavigate();
    const navigateRef = useRef(navigate);

    useEffect(() => {
        navigateRef.current = navigate;
    }, [navigate]);

    useEffect(() => {
        navigateRef.current(to, { replace, state });
    }, [to, replace, state]);

    return null;
};
