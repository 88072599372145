import { DefaultDays } from "components/TableActions";
import { FilterBy, PageRequest } from "models/common";

export const DefaultPageRequest: PageRequest = {
    page: 1,
    filter: undefined,
    withinDays: DefaultDays,
    sort: null,
    filterBy: undefined,
};

// Intended to be paired with a link created by pageRequestToUrlSearchParams in helpers.ts
const getDefaultPageRequest = (): PageRequest => {
    // Get the default page request from the URL
    const urlParams = new URLSearchParams(window.location.search);
    const page = urlParams.get("page");
    const filter = urlParams.get("filter");
    const withinDays = urlParams.get("withinDays");
    const sortKey = urlParams.get("sortKey");
    const sortDirection = urlParams.get("sortAsc") === "asc" ? true : false;
    const filterBy = Array.from(urlParams)
        .filter(([key]) => key.startsWith("filterBy"))
        .reduce((acc, [key, value]) => {
            const filterKey = key.replace("filterBy[", "").replace("]", "");
            acc[filterKey] = [value];
            return acc;
        }, {} as FilterBy);

    // If the URL has a page request, use it
    const pageRequest: PageRequest = {
        page: page ? parseInt(page) : DefaultPageRequest.page,
        filter: filter ? filter : DefaultPageRequest.filter,
        withinDays: withinDays
            ? parseInt(withinDays)
            : DefaultPageRequest.withinDays,
        sort: sortKey ? [sortKey, sortDirection] : DefaultPageRequest.sort,
        filterBy,
    };
    return pageRequest;
};

export default getDefaultPageRequest;
