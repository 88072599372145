import { FC } from "react";
import FAQ from "./FAQ";

// We have a specific version of the FAQ page that acts as our Start Selling page
const SELL_WITH_US_CATEGORY_ID = 8706588307725;

const SellWithUsFAQPage: FC = () => {
    return <FAQ soloCategoryId={SELL_WITH_US_CATEGORY_ID} />;
};

export default SellWithUsFAQPage;
