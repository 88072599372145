import { VStack } from "@chakra-ui/react";
import AllPackages from "components/staff/Packages/AllPackages";
import InProgressPackages from "components/staff/Packages/InProgressPackages";

const StaffPackages = () => {
    return (
        <VStack>
            <InProgressPackages />
            <AllPackages />
        </VStack>
    );
};

export default StaffPackages;
