import { useQuery } from "@tanstack/react-query";
import { useAuthenticatedRequest } from "./useRequests";
import { CatalogItem } from "models/store";


const useShopCatalog = () => {
    const getData = useAuthenticatedRequest<CatalogItem[]>({
        url: "/store/catalog",
        method: "GET",
    });


    return useQuery(["catalog"], getData);
};

export default useShopCatalog;

