import { Box, BoxProps, forwardRef } from "@chakra-ui/react";
import { Select, SelectProps } from "antd";
import useCountryOptions from "hooks/useCountryOptions";
import FormInput from "./FormInput";

const CountrySelect = forwardRef<SelectProps & BoxProps, typeof Box>(
    ({ ...props }, ref) => {
        const countries = useCountryOptions();
        return (
            <FormInput
                label="Country"
                name="country"
                maxWidth="15rem"
                autoComplete="country"
            >
                <Box
                    ref={ref}
                    as={Select}
                    {...props}
                    placeholder="Select a country"
                    dropdownStyle={{ zIndex: 2000 }}
                    minW="15rem"
                    options={countries}
                />
            </FormInput>
        );
    }
);

export default CountrySelect;
