import { Box, VStack, useBreakpointValue } from "@chakra-ui/react";
import CategoryButtons from "components/FAQ/CategoryButtons";
import ContactUsSection from "components/FAQ/ContactUsSection";
import FAQHero from "components/FAQ/FAQHero";
import HelpArticles from "components/FAQ/HelpArticles";
import { WaitTimesBanner } from "components/WaitTimes";
import useFAQ from "hooks/useFAQ";
import { FC, useState } from "react";

const FAQ: FC<{ soloCategoryId?: number }> = ({ soloCategoryId }) => {
    const isMobileLayout = useBreakpointValue({ base: true, md: false });
    const { data: faqData } = useFAQ();

    const [selectedCategoryId, setSelectedCategoryId] =
        useState(soloCategoryId);

    const selectedArticles = faqData?.articles.filter((a) =>
        selectedCategoryId ? a.categoryId === selectedCategoryId : true
    );

    return (
        <VStack spacing={6} w="100vw" backgroundColor="var(--dcs-black)">
            <Box w="100%" bg="var(--dcs-dark-blue)">
                <WaitTimesBanner />
                <FAQHero />
            </Box>
            <Box w={isMobileLayout ? "90vw" : "80vw"}>
                {!soloCategoryId && (
                    <CategoryButtons
                        categories={faqData?.categories ?? []}
                        selectedId={selectedCategoryId}
                        onSelect={setSelectedCategoryId}
                    />
                )}
                <HelpArticles articles={selectedArticles ?? []} />
            </Box>
            <ContactUsSection />
        </VStack>
    );
};

export default FAQ;
