import { FileExclamationTwoTone, FileTextOutlined } from "@ant-design/icons";
import { Box, HStack, VStack } from "@chakra-ui/react";
import { Input, Select, Typography } from "antd";
import { PropsWithChildren } from "react";
import { Link } from "react-router-dom";

const { Text } = Typography;
const { Option } = Select;
const { Search } = Input;

export const DefaultDays = 0;

interface TableActionsProps {
    onDaysChanged: (value: number) => void;
    onFilterChanged?: (value: string) => void;
    isLoading: boolean;
    filterPlaceholder?: string;
    showFilter?: boolean;
    onExportCSV?: () => void;
    allowExport?: boolean;
}

const TableActions = ({
    onDaysChanged,
    onFilterChanged = (_) => { },
    isLoading,
    filterPlaceholder = "Search",
    showFilter = true,
    onExportCSV,
    allowExport = true,
    children
}: TableActionsProps & PropsWithChildren) => {
    return (
        <VStack align="right">
            <HStack justifyContent="flex-end">
                {children}
                {allowExport ? (
                    <Link to="#" onClick={onExportCSV}>
                        <FileTextOutlined /> Export to CSV
                    </Link>
                ) : (
                    <Box textColor="var(--dcs-gray)">
                        <FileExclamationTwoTone twoToneColor="#eb2f96" /> Export to CSV
                    </Box>
                )}
            </HStack>
            <HStack justifyContent="end">
                <Text style={{ color: "var(--dcs-white)" }}>Date Range:</Text>
                <Select
                    placeholder="Date Range"
                    defaultValue={DefaultDays}
                    onChange={onDaysChanged}
                >
                    <Option value={0}>No Date Limit</Option>
                    <Option value={90}>Last 90 Days</Option>
                    <Option value={60}>Last 60 Days</Option>
                    <Option value={30}>Last 30 Days</Option>
                </Select>
                {showFilter === true && (
                    <Search
                        loading={isLoading}
                        placeholder={filterPlaceholder}
                        onSearch={onFilterChanged}
                        allowClear
                    />
                )}
            </HStack>
        </VStack>
    );
};

export default TableActions;