import { Box, VStack } from "@chakra-ui/react";
import { FC } from "react";
import { Link } from "react-router-dom";

type AdminDashboardProps = {
    title: string;
    subtitle?: string;
    value?: string;
    linkText: string;
    // If the link is a function, it's really an onClick handler
    linkTo: string | Function;
    external?: boolean;
    showLink?: boolean;
};
// TODO: This is actually an almost identical component to CustomerInfoBox.tsx
//  We should probably combine them into one component
const AdminDashboardCard: FC<AdminDashboardProps> = ({
    title,
    subtitle,
    value,
    linkText,
    linkTo,
    external,
    showLink = true
}) => {
    const linkComponent = external ? "a" : Link;
    const linkProps = external ? { href: linkTo } : { to: linkTo };

    return (
        <VStack
            fontFamily="body"
            color="darkGray"
            bg="white"
            rounded={3}
            p={3}
            align="left"
            spacing={2}
        >
            <Box lineHeight="1.2rem">
                <Box fontSize={24}>{title}</Box>
                <Box fontSize={14}>{subtitle}</Box>
            </Box>
            <Box fontSize={36} fontWeight="bold">
                {value}
            </Box>
            <Box w="100%" h={1} bg="lightGreen" />
            {typeof linkTo === "string" ? (
                <Box
                    color="blue"
                    fontWeight="semibold"
                    textDecoration="underline"
                    target={external ? "_blank" : undefined}
                    as={linkComponent}
                    {...linkProps}
                    visibility={showLink ? 'visible' : 'hidden'}
                >
                    {linkText}
                </Box>
            ) : (
                <Box
                    color="blue"
                    fontWeight="semibold"
                    textDecoration="underline"
                    onClick={linkTo as any}
                    visibility={showLink ? 'visible' : 'hidden'}
                >
                    {linkText}
                </Box>
            )}
        </VStack>
    );
};

export default AdminDashboardCard;
