import { Portal } from "./portal";
import { Button } from "./button";
import { Drawer } from "./drawer";
import { Th } from "./table";

import { StepsStyleConfig as Steps } from "chakra-ui-steps";

export const components = {
    Button,
    Drawer,
    Portal,
    Th,
    // Ads the 3rd party component Steps from chakra-ui-steps
    Steps,
};

export default components;
