import { Box, Select } from "@chakra-ui/react";
import { CustomerRoutes } from "appRoutePaths";
import { useIdentity } from "context/auth";
import useTreasuryAccounts from "hooks/useTreasuryAccounts";
import { ApplicationUser } from "models/applicationUsers";
import { FC } from "react";
import { Link } from "react-router-dom";
import FormInput from "./Inputs/FormInput";

const ACHPayoutDetails: FC<{ customer?: ApplicationUser }> = ({ customer }) => {
    const { isAdmin } = useIdentity();
    const { data: accounts, isLoading } = useTreasuryAccounts(customer?.id);

    const noAccounts = accounts && accounts.length === 0;

    const placeholder = isAdmin && !customer?.id
        ? "Select a customer to load their ACH accounts"
        : "Loading Accounts...";

    return <>
        <FormInput
            label="Account"
            name="externalAccountId"
            maxWidth="29.5rem"
            autoComplete="account"
        >
            <Select
                minWidth="15rem"
                size="sm"
                placeholder={isLoading ? placeholder : "Select an Account"}
                borderColor="#d9d9d9"
                borderRadius={2}
                outline="none"
                disabled={isLoading || noAccounts}
            >
                {(accounts?.map(a => (
                    <option key={a.accountId} value={a.accountId}>{a.organization} - {a.accountNumber}</option>
                )))}
            </Select>
        </FormInput>

        {noAccounts && <Box fontSize="sm">
            You can configure bank accounts on your <Link to={CustomerRoutes.profile.url} type="link" style={{ color: 'var(--dcs-blue)' }}>profile page</Link>.
            Your accounts will be securely stored with J.P. Morgan Chase, and will be available here to use for ACH payouts.
        </Box>}
    </>;
}

export default ACHPayoutDetails;
