import KeyValueTableFilter from "components/KeyValueTableFilter";
import { ClearTableFilterRequest } from "models/common";
import { ExtendedFilterDropdownProps } from "util/table";

const SaleType = "Sale";
const ManAdjType = "ManualAdjustment";
const PayoutType = "Payout";
const PaymentType = "OrderPayment";

const TypeFilter = ({
    prefixCls,
    visible,
    confirm,
    selectedKeys,
    setSelectedKeys,
    clearFilters,
    onFilter,
}: ExtendedFilterDropdownProps) => {
    return (
        <KeyValueTableFilter
            prefixCls={prefixCls}
            visible={visible}
            confirm={confirm}
            selectedKeys={selectedKeys}
            setSelectedKeys={setSelectedKeys}
            clearFilters={clearFilters}
            onFilter={onFilter}
            clearTableFilterRequest={ClearTableFilterRequest.Status}
            keyValuePairs={{
                "Card Sale": SaleType,
                Payout: PayoutType,
                "Manual Adjustment": ManAdjType,
                "Order Payment": PaymentType
            }}
        />
    );
};

export default TypeFilter;
