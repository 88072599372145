import { Box, Text } from "@chakra-ui/react";
import { Button, Form } from "antd";
import { SignUpFormItem } from "components/SignUp/SignUpFormItem";
import { useTwoFactorToken } from "hooks/useLogin";
import { FC } from "react";

export const TwoFactorForm: FC = () => {
    const { verifyToken, isLoading, isError } = useTwoFactorToken();

    const onFinish = async (values: any) => {
        await verifyToken(values.token);
    };

    return (
        <Box
            as={Form}
            name="authenticate"
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
            w="100%"
        >
            <SignUpFormItem
                title="Authenticator Code"
                placeholder="6-Digit Code -OR- Recovery Code"
                name="token"
            />

            <Form.Item>
                {isError && <Text color="red">Invalid Code</Text>}
                <Button
                    id="btn-login"
                    className="primary-button"
                    type="primary"
                    htmlType="submit"
                    loading={isLoading}
                    style={{ marginTop: '20px' }}
                >
                    {isLoading ? "Authenticating..." : "Sign In"}
                </Button>
            </Form.Item>
        </Box>
    );
};
