import {
    Box,
    Button,
    Flex,
    HStack,
    Image,
    Text,
    VStack,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { Form } from "antd";
import { PublicRoutes } from "appRoutePaths";
import LoadingSpinnerOverlay from "components/LoadingSpinnerOverlay";
import { SignUpFormItem } from "components/SignUp/SignUpFormItem";
import { usePublicRequestCreator } from "hooks/useRequests";
import { FC } from "react";
import { Link } from "react-router-dom";

const LoginImage = require("images/login-page.png");

const Header: FC = () => (
    <Flex flexDir="column" pb="2rem">
        <Box fontSize="3xl">Forgot Password</Box>
        <HStack spacing="2">
            <Text as={Link} to={PublicRoutes.login.url} color="blue">
                Back to Login
            </Text>
        </HStack>
    </Flex>
);

const useForgotPassword = () => {
    const requestPasswordReset = usePublicRequestCreator<
        void,
        { email: string }
    >((data) => ({
        url: "/account/forgot-password",
        method: "POST",
        data,
    }));

    return useMutation(requestPasswordReset);
};

const ForgotPasswordForm: FC<{ onFinish: (data: any) => void }> = ({
    onFinish,
}) => {
    return (
        <VStack
            as={Form}
            name="authenticate"
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
            w="100%"
            spacing={6}
            align="end"
        >
            <SignUpFormItem
                title="Email"
                name="email"
                autoComplete="email"
                width="100%"
            />
            <Box pr={4}>
                <Button variant="primary" type="submit">
                    Request Reset
                </Button>
            </Box>
        </VStack>
    );
};

const ForgotPasswordPage: FC = () => {
    const {
        mutateAsync: requestPasswordReset,
        isLoading,
        isSuccess,
    } = useForgotPassword();

    return (
        <>
            <LoadingSpinnerOverlay isLoading={isLoading} />
            <Flex
                w="100%"
                color="#22272c"
                pos="relative"
                h="100%"
                minH="85vh"
                flex={1}
            >
                <Flex
                    flex={1}
                    justifyContent="center"
                    maxW={{ base: "unset", md: "66%" }}
                >
                    <VStack
                        align="start"
                        minWidth="30vw"
                        pt="7vh"
                        px="2rem"
                        maxWidth="30rem"
                        spacing={8}
                    >
                        <Header />
                        {isSuccess ? (
                            <VStack fontSize="lg">
                                <Box fontSize="2xl" fontWeight="semibold">
                                    Success!
                                </Box>
                                <Box>
                                    Check your email for a password reset link.
                                </Box>
                            </VStack>
                        ) : (
                            <ForgotPasswordForm
                                onFinish={requestPasswordReset}
                            />
                        )}
                    </VStack>
                </Flex>
                <Image
                    pos="absolute"
                    right="0"
                    top="0"
                    bottom="0"
                    src={LoginImage}
                    maxWidth={{ base: 0, md: "30%" }}
                    flexBasis="30%"
                    h="100%"
                    objectFit="cover"
                />
            </Flex>
        </>
    );
};

export default ForgotPasswordPage;
