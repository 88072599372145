import "styles/staff/staff_cards.css";

import {
    CaretDownFilled,
    CheckSquareFilled,
    FilterFilled,
} from "@ant-design/icons";
import { Box } from "@chakra-ui/react";
import { Button, Col, Dropdown, Menu, Row, Space } from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import { FilterValue } from "antd/lib/table/interface";
import Currency from "components/Currency";
import { BatchSearch, CustomerSearch } from "components/EntityTableSearch";
import MultiValueDisplay from "components/MultiValueDisplay";
import TableActions from "components/TableActions";
import {
    BulkEditFilterModal,
    BulkEditSelectionModal,
} from "components/staff/Cards/BulkEditModals";
import CardActions from "components/staff/Cards/CardActions";
import CardTitleSearch from "components/staff/Cards/CardTitleSearch";
import StaffCardsFilter, {
    StaffCardFilterDetail,
} from "components/staff/Cards/StaffCardsFilter";
import StatusFilter from "components/staff/Cards/StatusFilter";
import FriendlyIdFilter from "components/staff/Packages/FriendlyIdFilter";
import useStaffCards, { useStaffCardsCSV } from "hooks/useStaffCards";
import { StaffSportsCard } from "models/cards";
import { InitialPageRequest, PageRequest, PageSizeOptions } from "models/common";
import React, { useState } from "react";
import downloadAsCSV from "util/downloadAsCSV";
import { localizedDate } from "util/helpers";
import { createCustomFilter } from "util/table";

const Cards = () => {
    const [{ page, filterBy, withinDays, sort, pageSize }, setPageRequest] =
        useState<PageRequest>(InitialPageRequest);
    const [{ isLoading, data }, forceRefresh] = useStaffCards({
        page,
        filterBy,
        withinDays,
        sort,
        pageSize
    });
    const [selectedCardIds, setSelectedCardIds] = useState<React.Key[]>([]);
    const [filterDetail, setFilterDetail] = useState<StaffCardFilterDetail>({});
    const [recentUpdates, setRecentUpdates] = useState<Set<string>>(new Set());
    const [bulkSelectionModalVisible, setBulkSelectionModalVisible] =
        useState<boolean>(false);
    const [bulkFilterModalVisible, setBulkFilterModalVisible] =
        useState<boolean>(false);

    const { data: records, totalRecords } = data || {};

    const setTablePage = (pageRequest: PageRequest) => {
        setPageRequest(pageRequest);
        setRecentUpdates(new Set());
    };

    const daysChanged = (value: number) => {
        setTablePage({ page: 1, filterBy, withinDays: value, sort, pageSize });
    };

    const tableChanged = (
        // pagination
        { current: newPage, pageSize: newPageSize }: any,
        // filtering
        filters: Record<string, FilterValue | null>,
        // sorting
        { column, field, order }: any
    ) => {
        setTablePage({
            page: newPageSize !== pageSize ? 1 : newPage,
            pageSize: newPageSize,
            filterBy: filters,
            withinDays,
            sort: column ? [field, order === "ascend"] : null,
        });
    };

    const highlightAndRefresh = (updatedCardIds: string[]) => {
        updatedCardIds.forEach((cardId) => recentUpdates.add(cardId));
        forceRefresh();
    };

    const onFilterUpdate = (key: string) => (value: any) => {
        setFilterDetail((prev) => ({ ...prev, [key]: value }));
    };

    const isFiltering = (): boolean => {
        return (
            records !== undefined &&
            records.length > 0 &&
            (filterDetail.batch !== undefined ||
                filterDetail.customer !== undefined ||
                filterDetail.status !== undefined ||
                filterDetail.friendlyPackageId !== undefined ||
                filterDetail.title !== undefined)
        );
    };

    const bulkEditMenu = (
        <Menu
            items={[
                {
                    label: "All Selected",
                    key: "by-selection",
                    icon: <CheckSquareFilled />,
                    disabled: selectedCardIds.length < 2,
                    onClick: () => setBulkSelectionModalVisible(true),
                },
                {
                    label: "All Filtered",
                    key: "by-filtered",
                    icon: <FilterFilled />,
                    disabled: !isFiltering(),
                    onClick: () => setBulkFilterModalVisible(true),
                },
            ]}
        />
    );

    const columns: ColumnsType<StaffSportsCard> = [
        {
            title: "Batch",
            dataIndex: "batchNumber",
            key: "batchId",
            sorter: true,
            ...createCustomFilter(
                BatchSearch,
                onFilterUpdate("batch"),
                filterDetail.batch?.id
            ),
            render: (bn, rec) => (
                <MultiValueDisplay
                    title={bn}
                    subTitle={localizedDate(rec.batchCreatedDate)}
                />
            ),
        },
        {
            title: "Package",
            dataIndex: "friendlyPackageId",
            key: "friendlyPackageId",
            sorter: false,
            ...createCustomFilter(
                FriendlyIdFilter,
                onFilterUpdate("friendlyPackageId"),
                filterDetail.friendlyPackageId
            ),
            render: (id) => (id === 0 ? "N/A" : id),
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            ...createCustomFilter(
                StatusFilter,
                onFilterUpdate("status"),
                filterDetail.status
            ),
        },
        {
            title: <MultiValueDisplay title="Card Title" subTitle="eBay ID" />,
            dataIndex: "title",
            key: "title",
            sorter: true,
            ...createCustomFilter(
                CardTitleSearch,
                onFilterUpdate("title"),
                filterDetail.title
            ),
            render: (n, rec) => (
                <MultiValueDisplay
                    title={n}
                    subTitle={rec.ebayItemId}
                />
            ),
        },
        {
            title: "Customer",
            dataIndex: "customerId",
            key: "customerId",
            sorter: true,
            ...createCustomFilter(
                CustomerSearch,
                onFilterUpdate("customer"),
                filterDetail.customer?.id
            ),
            render: (_, rec) => (
                <MultiValueDisplay
                    title={`${rec.customerFirstName} ${rec.customerLastName}`}
                    subTitle={rec.customerEmail}
                />
            ),
        },
        {
            title: "Listing Date",
            dataIndex: "listingDate",
            key: "listingDate",
            sorter: true,
            render: localizedDate,
        },
        {
            title: "Sale Price",
            dataIndex: "salePriceInCents",
            key: "salePrice",
            sorter: true,
            align: "right",
            render: (a) => <Currency cents={a} />,
        },
        {
            title: "",
            key: "edit",
            render: (_, rec) => <CardActions
                card={rec}
                onUpdate={(cardId) => highlightAndRefresh([cardId])}
            />,
        },
    ];

    const fetchTransactionsCSV = useStaffCardsCSV({
        filterBy,
        withinDays,
        sort,
    });

    const handleCSVExport = async () => {
        const csvData = await fetchTransactionsCSV();
        const date = new Date().toISOString().split("T")[0];
        const filename = `all-cards-${date}.csv`;
        downloadAsCSV(csvData, filename);
    };

    return (
        <Box p={50}>
            <Row align="bottom">
                <Col span={6}>
                    <Box fontSize={36}>All Cards</Box>
                </Col>
                <Col span={10} offset={8} className="actions-container">
                    <TableActions
                        onDaysChanged={daysChanged}
                        isLoading={isLoading}
                        showFilter={false}
                        onExportCSV={handleCSVExport}
                    />
                </Col>
            </Row>
            <Row id="actions-and-filter">
                <Col span={3}>
                    <Dropdown overlay={bulkEditMenu}>
                        <Button id="bulk-edit-button">
                            <Space>
                                Bulk Edit
                                <CaretDownFilled />
                            </Space>
                        </Button>
                    </Dropdown>
                </Col>
                <Col span={21}>
                    <StaffCardsFilter detail={filterDetail} />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Table
                        id="staff-card-table"
                        rowClassName={(rec, _) =>
                            recentUpdates.has(rec.cardId)
                                ? "recently-updated"
                                : ""
                        }
                        rowSelection={{
                            onChange: (keys) => setSelectedCardIds(keys),
                        }}
                        columns={columns}
                        dataSource={records}
                        rowKey={(r) => r.cardId}
                        onChange={tableChanged}
                        pagination={{
                            total: totalRecords,
                            current: page,
                            pageSize,
                            pageSizeOptions: PageSizeOptions,
                            showSizeChanger: true,
                            showQuickJumper: true,
                        }}
                        showSorterTooltip={false}
                        loading={isLoading}
                    />
                </Col>
            </Row>
            <BulkEditSelectionModal
                cardIds={selectedCardIds}
                affectedCount={selectedCardIds.length}
                visible={bulkSelectionModalVisible}
                onSuccess={() =>
                    highlightAndRefresh(selectedCardIds as string[])
                }
                onClose={() => setBulkSelectionModalVisible(false)}
            />
            <BulkEditFilterModal
                pageRequest={{ page, filterBy, withinDays, sort }}
                affectedCount={totalRecords || 0}
                visible={bulkFilterModalVisible}
                onSuccess={forceRefresh}
                onClose={() => setBulkFilterModalVisible(false)}
            />
        </Box>
    );
};

export default Cards;
