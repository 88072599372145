import { Box, Flex, HStack } from "@chakra-ui/react";
import { Button, Descriptions } from "antd";
import { StaffRoutes } from "appRoutePaths";
import { StaffPackage, PackageAmount } from "models/packages";
import { Link } from "react-router-dom";
import { localizedDate } from "util/helpers";

const CreatedPackageDetail = (props: {
    package: StaffPackage;
    onAddNext: () => void;
}) => {
    const { package: pkg } = props;

    return (
        <Flex flexDirection="column">
            <Flex alignSelf="flex-end" py={3}>
                ID:{" "}
                <Box fontWeight="semibold" pl={2}>
                    {pkg.friendlyId}
                </Box>
            </Flex>
            <Descriptions layout="horizontal" column={1} bordered>
                <Descriptions.Item
                    label="Customer Name:"
                    style={{ fontSize: 18 }}
                >
                    {pkg.customerFirstName} {pkg.customerLastName}
                </Descriptions.Item>
                <Descriptions.Item
                    label="Package Type:"
                    style={{ fontSize: 18 }}
                >
                    {pkg.packageTypeDisplayName}
                </Descriptions.Item>
                <Descriptions.Item label="Amount:" style={{ fontSize: 18 }}>
                    {PackageAmount[pkg.packageAmount]}
                </Descriptions.Item>
                <Descriptions.Item
                    label="Date Received:"
                    style={{ fontSize: 18 }}
                >
                    {localizedDate(pkg.receivedDate)}
                </Descriptions.Item>
                <Descriptions.Item
                    label="Est. Upload Date:"
                    style={{ fontSize: 18 }}
                >
                    {localizedDate(pkg.estimatedUploadDate)}
                </Descriptions.Item>
            </Descriptions>
            <HStack pt={4} spacing={6} alignSelf="flex-end">
                <Button size="large">
                    <Link to={StaffRoutes.packages.url}>Close</Link>
                </Button>
                <Button size="large" type="primary" onClick={props.onAddNext}>
                    Add Next
                </Button>
            </HStack>
        </Flex>
    );
};

export default CreatedPackageDetail;
