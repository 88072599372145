import { Box, Flex, Image, Text, VStack } from "@chakra-ui/react";
import ErrorBoundary from "antd/lib/alert/ErrorBoundary";
import { CustomerRoutes } from "appRoutePaths";
import { CreateAccountForm } from "components/SignUp/CreateAccountForm";
import { ExistingUser } from "components/SignUp/ExistingUserBlurb";
import { OrDivider } from "components/SignUp/OrDivider";
import { useIdentity } from "context/auth";
import { FC } from "react";
import { useNavigate } from "react-router";

const SignUpImage = require("images/sign-up-page.png");

export const SignUp: FC = () => {
    const navigate = useNavigate();
    const { isLoggedIn } = useIdentity();
    if (isLoggedIn) {
        navigate(CustomerRoutes.dashboard.url);
    }

    return (
        <Flex
            minHeight="85vh"
            height="100%"
            w="100%"
            color="darkGray"
            m={0}
            p={0}
        >
            <Flex flexDir="column" w="100%" py="12" px="5" align="center">
                <VStack
                    spacing={6}
                    align="baseline"
                    w="100%"
                    flexBasis="50%"
                    maxWidth="40rem"
                >
                    <ExistingUser />
                    <OrDivider />
                    <VStack spacing={1} align="baseline" w="100%">
                        <Text fontSize="3xl">
                            Create an account here, then send in your cards along with a note with your full name and email or use
                            {" "}<Box as="a" href={CustomerRoutes.submissionForm.url} target="_blank" rel="noreferrer" color="blue">this form</Box>
                        </Text>
                        <ErrorBoundary message="An error has occured">
                            <CreateAccountForm />
                        </ErrorBoundary>
                    </VStack>
                </VStack>
            </Flex>
            <Image
                src={SignUpImage}
                maxWidth={{ base: 0, md: "100%" }}
                flexBasis="30%"
                h="auto"
                objectFit="cover"
            />
        </Flex>
    );
};
