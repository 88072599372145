import { useQuery } from "@tanstack/react-query";
import { SportsCard } from "models/cards";
import { PagedResult, PageRequest } from "models/common";
import { pageRequestToUrlSearchParams } from "util/helpers";
import { useAuthenticatedRequest } from "./useRequests";

const makeAxiosRequest = (accountId: string, pageRequest: PageRequest) => {
    const options = pageRequestToUrlSearchParams(pageRequest);

    return {
        method: "get",
        url: `/cards/${accountId}?${options}`,
    };
};

export const useCardsCSV = (
    accountId: string,
    pageRequest: Omit<PageRequest, "page">
) => {
    const pageRequestWithCSV = {
        ...pageRequest,
        page: 1, // ignored
        format: "csv",
    } as const;
    const axiosRequest = makeAxiosRequest(accountId, pageRequestWithCSV);

    return useAuthenticatedRequest<string>(axiosRequest);
};

const useCards = (accountId: string, pageRequest: PageRequest) => {
    const getCards = useAuthenticatedRequest<PagedResult<SportsCard>>(
        makeAxiosRequest(accountId, pageRequest)
    );

    return useQuery([accountId, "cards", pageRequest], getCards);
};

export default useCards;
