export const MaxTitleLength = 80;
export const GraderRegEx = /(PSA|BCCG|BVG|BGS|CSG|CGC|SGC|KSA|GMA|HGA|ISA|GSG|PGS|MNT|TAG|Rare|RCG|CGA|TCG|AGS|DSG|Arena\sClub)\s((\d+(\.\d)?)|A.*?)(\s|\/)?/i;
export const LotRegEx = /^\(\d+\)\sLot\s/i;
export const NewStatus = '1000';
export const UsedStatus = '3000';
export const GradedStatus = '2750';
export const UngradedStatus = '4000';
export const SportCategory = 'Sport';
export const OtherCategory = 'Other';
export const SportExcellentCondition = '400011';

export const EmptyAuctionCategory = {
    id: '',
    type: '',
    subTypes: [],
    itemConditions: [],
    graders: [],
    ungradedConditions: [],
    isLot: false
};

export const DefaultCardTitleValues = {
    category: SportCategory,
    statusId: UngradedStatus,
    conditionId: SportExcellentCondition
};

export interface IdNamePair {
    id: string;
    name: string;
}

export interface AuctionCategory {
    id: string;
    type: string;
    subTypes: string[];
    isLot: boolean;
    itemConditions: ItemCondition[];
    graders: Grader[];
    ungradedConditions: UngradedCondition[];
}

export interface ItemCondition extends IdNamePair { }

export interface Grader extends IdNamePair {
    grades: Grade[];
}

export interface Grade extends IdNamePair { }

export interface UngradedCondition extends IdNamePair { }

export interface TyperForm {
    id: string;
    cardTitle: string;
    isLot: boolean;
    category: string;
    subCategory: string;
    subCategoryText: string;
    statusId: string;
    conditionId: string | null;
    grader: string | null;
    grade: string | null;
    notes: string | null;
    packageId: string | null;
    packageFriendlyId: number | null;
}

export const CardCategories = [
    { value: 'Sport' },
    { value: 'Franchise' },
    { value: 'Game' }
];

export interface CardImages {
    front: string;
    back: string;
    additional?: string[]
}

export interface CardFolder {
    id: string;
    name: string;
    friendlyPackageId: number;
    customerEmail: string;
    customerFirstName: string;
    customerLastName: string;
    cardCount: number;
    completedCount: number;
    packageReceivedDate: string;
    packageEstUploadDate: string;
    assignedTyperId: string | null;
    assignedTyperUserName: string | null;
}

export interface CardFolderExtra {
    totalCards: number;
}

export interface CardFolderAssignment {
    typerId: string | null;
    cardFolderIds: string[];
}

export enum FolderFilter {
    Unknown = 0,
    All,
    Unassigned,
    InProgress,
    Completed
}

export enum CardPhotoType {
    Unknown = 0,
    Front = 1,
    Back = 2,
    Extra = 3
}

export interface CardTitle {
    id: string;
    title: string;
    category: string;
    subCategory: string;
    conditionId: string | null;
    conditionName: string | null;
    gradeId: string | null;
    gradeName: string | null;
    gradingCompanyId: string | null;
    gradingCompanyName: string | null;
    statusId: string;
    statusName: string;
    notes: string | null;
    isLot: boolean;
    photos: CardTitlePhotos[];
    doNotUpload: boolean;
    doNotUploadReason: string | null;
    cardFolderId: string;
    folderName: string;
    folderCompleted: number;
    folderTotal: number;
    multiPackageFolder: boolean;
    packageId: string | null;
    packageFriendlyId: number | null;
    completed: boolean;
}

export interface CardTitlePhotos {
    id: string;
    type: CardPhotoType;
    imageUrl: string;
    ordinal: number;
}

export interface CompletedCardTitle {
    id: string;
    title: string;
    photos: CardTitlePhotos[];
}

export interface CompletedCardTitlesByDateRange {
    startDate: string;
    endDate: string;
    count: number;
}