import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAuthenticatedRequestCreator } from "./useRequests";
import { StaffCardsQueryKey } from "./useStaffCards";

const useReturnCard = () => {
    const queryClient = useQueryClient();

    const post = useAuthenticatedRequestCreator<void, string>(
        (cardId: string) => ({
            method: "put",
            url: "/cards/returned",
            data: { cardId }
        })
    );

    const { mutateAsync: returnCard } = useMutation(post, {
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [StaffCardsQueryKey] });
        }
    });

    return { returnCard };
};

export default useReturnCard;