import { Button, DatePicker, Form, Upload, UploadFile } from "antd";
import useWebsiteSettings from "hooks/useWebsiteSettings";
import { DefaultTimeZone, DisplayDateFormat, ServerDateFormat } from "models/websiteSettings";
import moment from "moment";
import { useState } from "react";
import SettingsHeader from "./SettingsHeader";
import Paragraph from "antd/lib/typography/Paragraph";
import { Box, HStack, Image } from "@chakra-ui/react";
import { CheckOutlined, UploadOutlined } from "@ant-design/icons";

const { RangePicker } = DatePicker;

const PromotionalSection = () => {
    const { isLoading, settings, updatePromotion } = useWebsiteSettings();
    const { promoStartDate, promoEndDate, promoImageKey } = settings || {};
    const [form] = Form.useForm();
    const [result, setResult] = useState<boolean>(false);

    if (isLoading) {
        return <></>;
    }

    const hasImage = promoImageKey?.trim() !== '';

    const update = async (values: { range: [moment.Moment, moment.Moment], image: UploadFile }) => {
        const { range, image } = values;

        if (!hasImage && (!image || image.status === 'removed')) {
            form.setFields([{ name: 'image', errors: ['You must select an image to upload.'] }]);
            return;
        }

        const file = (image as unknown) as File;
        setResult(
            await updatePromotion({
                startDate: range[0].format(ServerDateFormat),
                endDate: range[1].format(ServerDateFormat),
                image: file
            })
        );

        form.resetFields(['image']);
    }

    const validateUpload = (file: File) => {
        if (!file.type.startsWith('image/')) {
            form.setFields([{ name: 'image', errors: [`${file.name} is not a valid image.`] }]);
            return Upload.LIST_IGNORE;
        }

        form.setFields([{ name: 'image', errors: [] }]);
        // returning false stops the control from trying to upload
        // the image as soon as we select it.
        return false;
    }

    const start = moment(promoStartDate).tz(DefaultTimeZone);
    const end = moment(promoEndDate).tz(DefaultTimeZone);

    return <>
        <SettingsHeader title="Promotional Display" />
        <Paragraph>Replace the featured cards carousel with a promotional image for a specified date range</Paragraph>
        <HStack>
            <Form
                form={form}
                initialValues={{
                    range: [start, end],
                    image: undefined
                }}
                className="website-update"
                onFinish={update}>
                <Form.Item name="range" rules={[
                    {
                        required: true,
                        message: 'Start and end dates are required'
                    }
                ]}>
                    <RangePicker format={DisplayDateFormat} />
                </Form.Item>
                <Form.Item name="image" getValueFromEvent={e => e.file}>
                    <Upload
                        beforeUpload={validateUpload}
                        onPreview={() => false /* do nothing if the preview image is clicked */}
                        listType="picture"
                        maxCount={1}
                    >
                        <Button icon={<UploadOutlined />}>Upload Image</Button>
                    </Upload>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Update Promotional Details
                    </Button>
                    {result && <CheckOutlined className="result-success" />}
                </Form.Item>
            </Form>
            {hasImage && <Box
                as="a"
                href={`/api/images/${promoImageKey}`}
                target='_blank'
                style={{ marginLeft: '100px' }} >
                <Image src={`/api/images/${promoImageKey}`} maxH={200} alt='promotional preview' />
            </Box>}
        </HStack>
    </>;
}

export default PromotionalSection;