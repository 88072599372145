import { extendTheme } from "@chakra-ui/react";
import colors from "./colors";
import components from "./components";
import config from "./config";
import styles from "./styles";
import fonts from "./fonts";

const theme = extendTheme({
    colors,
    config,
    components,
    styles,
    fonts
});

export default theme;
