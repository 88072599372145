import { useQuery } from "@tanstack/react-query";
import { useAuthenticatedRequest } from "hooks/useRequests";
import { AuctionCategory } from "models/cardIngestion";

const useAuctionCategories = () => {
    const get = useAuthenticatedRequest<AuctionCategory[]>({
        method: "get",
        url: '/card-titling/item-categories'
    });

    return useQuery(["card-titling", "item-categories"], get, {
        suspense: true
    });
};

export default useAuctionCategories;