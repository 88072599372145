import { useQueryClient } from "@tanstack/react-query";
import { useAuthenticatedRequestCreator } from "./useRequests";
import { useAuthContext } from "context/auth";
import { UnreadTicketsKeyPrefix } from "./useSupportTickets";

const useMarkTicketAsRead = () => {
    const { isImpersonating } = useAuthContext();
    const queryClient = useQueryClient();

    const invalidateUnreadQueries = () => queryClient.invalidateQueries(UnreadTicketsKeyPrefix);

    const markTicket = useAuthenticatedRequestCreator<boolean, number>(
        (id: number) => ({
            method: 'post',
            url: `/support/tickets/read/${id}`
        })
    );

    const markAll = useAuthenticatedRequestCreator<boolean, void>(
        () => ({
            method: 'post',
            url: '/support/tickets/read/all'
        })
    );

    const markAsRead = async (id: number) => {
        if (!isImpersonating()) {
            await markTicket(id);
            invalidateUnreadQueries();
        }
        return true;
    }

    const markAllAsRead = async () => {
        if (!isImpersonating()) {
            await markAll();
            invalidateUnreadQueries();
        }
        return true;
    }

    return { markAsRead, markAllAsRead };
}

export default useMarkTicketAsRead;