import { FormInstance, RuleObject } from "antd/lib/form";

export enum Kind {
    LeadTime = 0,
    OneTimeEvent = 1,
    WeeklyEvent = 2
}

export const toNullableNumber = (value: string): number | null => {
    if (value.trim() === '') {
        return null;
    }

    return Number(value);
}

export const bannerOrdinalValidator = async (
    form: FormInstance,
    _: RuleObject,
    ordinal: number | null
): Promise<void> => {
    if (ordinal !== null && isNaN(ordinal)) {
        ordinal = null;
    }

    form.setFieldsValue({ bannerOrdinal: ordinal });

    return Promise.resolve();
}

export const feeValidator = async (
    form: FormInstance,
    rule: any,
    price: number
): Promise<void> => {
    if (form.getFieldValue(rule.field).trim() === '' || !isNaN(price)) {
        form.setFieldsValue({ [`${rule.field}InCents`]: Math.max(price || 0, 0) });
        return Promise.resolve();
    }

    return Promise.reject(new Error("Not a valid fee!"));
};

export const minimumSalePrice = async (
    _: any,
    price: number
): Promise<void> => {
    if (Number(price) < 0.99 || isNaN(Number(price))) {
        return Promise.reject(new Error("Price should be $0.99 or greater!"));
    }

    return Promise.resolve();
}