import { PaperClipOutlined } from "@ant-design/icons";
import { Box, HStack, VStack } from "@chakra-ui/react";
import { Button, Switch } from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import MultiValueDisplay from "components/MultiValueDisplay";
import useAllPackageTypes from "hooks/useAllPackageTypes";
import { CssVariables, InitialPageRequest, PageRequest, PageSizeOptions } from "models/common";
import { FeeTier, FeeTierNames, FeeTierRange, PackageType } from "models/packages";
import { DisplayDateFormat } from "models/websiteSettings";
import moment from "moment-timezone";
import { FC, useState } from "react";
import { centsToDollars } from "util/helpers";
import DryRunModal from "./PackageTypes/DryRunModal";
import EditModal from "./PackageTypes/EditModal";
import NewModal from "./PackageTypes/NewModal";
import TierFeeDisplay from "./PackageTypes/TierFeeDisplay";
import TogglePackageType from "./PackageTypes/TogglePackageType";
import SettingsHeader from "./SettingsHeader";

const EnabledFilter = { Enabled: [''] };

const SupportedCustomTiers = [
    FeeTier.Unsold,
    FeeTier.Tier1,
    FeeTier.Tier2,
    FeeTier.Tier3,
    FeeTier.Tier4,
    FeeTier.Tier5,
    FeeTier.Tier6
] as const;

const LeadTime: FC<{ packageType: PackageType }> = ({ packageType }) => {
    const { leadTimeInDays, estimatedUploadDate, eventDayOfWeek } = packageType;

    if (leadTimeInDays !== null) {
        return <>
            {leadTimeInDays} Day{leadTimeInDays === 1 ? '' : 's'}
        </>
    } else if (estimatedUploadDate !== null) {
        const typedDate = moment(estimatedUploadDate);
        const inThePast = typedDate.isBefore(moment());

        return <Box textColor={inThePast ? CssVariables.errorRed : 'default'}>
            {typedDate.format(DisplayDateFormat)}
        </Box>
    } else if (eventDayOfWeek !== null) {
        const nextOccurance = moment().isoWeekday() < eventDayOfWeek
            ? moment().day(eventDayOfWeek)
            : moment().day(7 + eventDayOfWeek);

        return <MultiValueDisplay
            title={`${nextOccurance.format('dddd')}s`}
            subTitle={`Next: ${nextOccurance.format(DisplayDateFormat)}`}
        />
    }

    return null;
}

const PackageTypesSection: FC<{ readOnly: boolean }> = ({ readOnly }) => {
    const [pageRequest, setPageRequest] =
        useState<PageRequest>({ ...InitialPageRequest, filterBy: EnabledFilter });
    const { isLoading, data, refetch } = useAllPackageTypes(pageRequest);
    const { data: records, totalRecords } = data || {};

    const tierFeeProps = (packageType: PackageType, tier: FeeTier) => ({
        packageType,
        feeTier: tier,
        onSuccess: refetch,
        readOnly
    });

    const columns: ColumnsType<PackageType> = [
        {
            title: <NewModal onSuccess={refetch} />,
            align: 'center',
            dataIndex: "#",
            render: (_, rec) => <>
                <Button type="link" icon={<PaperClipOutlined />} href={`/staff/audit-log/?t=PackageType&id=${rec.id}`} target="_blank" title="Audit Log" />
                <EditModal packageType={rec} onSuccess={refetch} />
            </>
        },
        {
            title: "Enabled",
            dataIndex: "enabled",
            align: 'center',
            render: (_, rec) => <TogglePackageType packageType={rec} onSuccess={refetch} readOnly={readOnly} />
        },
        {
            title: <MultiValueDisplay title="Internal Name" subTitle="Display Name" />,
            dataIndex: "internalName",
            sorter: (a, b) => a.internalName.localeCompare(b.internalName),
            render: (_, rec) => rec.internalName === rec.displayName
                ? rec.internalName
                : <MultiValueDisplay title={rec.internalName} subTitle={rec.displayName} />
        },
        {
            title: "Banner #",
            dataIndex: "bannerOrdinal",
            align: 'center',
            width: 90,
            sorter: (a, b) => (a.bannerOrdinal || 1000) - (b.bannerOrdinal || 1000)
        },
        {
            title: <MultiValueDisplay title="Lead Time" subTitle="or Est. Upload Date" />,
            dataIndex: "eta",
            key: "eta",
            render: (_, rec) => <LeadTime packageType={rec} />
        },
        {
            title: <span>Processing<br />Fee</span>,
            dataIndex: "processingFeeInCents",
            render: v => v === 0 ? "" : centsToDollars(v)
        },
        ...SupportedCustomTiers.map(tier => ({
            title: <MultiValueDisplay title={FeeTierNames[tier]} subTitle={FeeTierRange[tier]} />,
            dataIndex: `feeTier${Number(tier)}`,
            key: `feeTier${Number(tier)}`,
            render: (_: any, rec: PackageType) => <TierFeeDisplay {...tierFeeProps(rec, tier)} />
        }))
    ];

    const toggleFilter = (checked: boolean) => {
        setPageRequest({ ...pageRequest, page: 1, filterBy: checked ? EnabledFilter : undefined });
    }

    const tableChanged = (
        // pagination
        { current: newPage, pageSize: newPageSize }: any,
        // filtering
        _: any,
        // sorting
        { column, field, order }: any
    ) => {
        setPageRequest({
            ...pageRequest,
            page: newPageSize !== pageRequest.pageSize ? 1 : newPage,
            pageSize: newPageSize,
            sort: column ? [field, order === "ascend"] : null,
        });
    };

    const finalColumns = readOnly
        ? columns.slice(1)
        : columns

    return <>
        <SettingsHeader title="Package Types & Fees" />
        <VStack alignItems='stretch' w='98%'>
            <HStack justifyContent='space-between'>
                <Switch
                    defaultChecked
                    onChange={toggleFilter}
                    checkedChildren="Showing Enabled Types"
                    unCheckedChildren="Showing All" />
                <DryRunModal />
            </HStack>
            <Table
                size="small"
                columns={finalColumns}
                dataSource={records}
                rowKey={(r) => r.id}
                showSorterTooltip={false}
                loading={isLoading}
                onChange={tableChanged}
                pagination={{
                    total: totalRecords,
                    current: pageRequest.page,
                    pageSize: pageRequest.pageSize,
                    pageSizeOptions: PageSizeOptions,
                    showSizeChanger: true,
                    showQuickJumper: true,
                }}
            />
        </VStack>
    </>
};

export default PackageTypesSection;