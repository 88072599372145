import { DefaultOptionType } from "antd/lib/select";
import { Country, countries } from "countries-list";
import { useMemo } from "react";

const unitedStates = countries.US;
const countriesList = Object.values(countries).filter(
    ({ name }) => name !== unitedStates.name
);
const typedCountries = countries as Record<string, Country>;

export const getCountryCode = (name: string) => {
    return Object.keys(countries).find(
        k => typedCountries[k].name === name
    );
}

const useCountryOptions = (): DefaultOptionType[] => {
    return useMemo(() =>
        [
            {
                value: unitedStates.name,
                label: unitedStates.name
            },
            {
                value: "dummy",
                label: '--------------------------------------',
                disabled: true
            },
            ...countriesList.map(({ name }) => ({
                value: name,
                label: name
            }))
        ], []);
};

export default useCountryOptions;