import FilterTag from "components/FilterTag";
import { ApplicationUser } from "models/applicationUsers";
import { CardBatch } from "models/cards";
import { ClearTableFilterRequest } from "models/common";
import { localizedDate, toDisplayName } from "util/helpers";

export interface StaffCardFilterDetail {
    batch?: CardBatch | undefined;
    status?: string | undefined;
    title?: string | undefined;
    customer?: ApplicationUser | undefined;
    friendlyPackageId?: string | undefined;
}

const StaffCardsFilter = (props: { detail: StaffCardFilterDetail }) => {
    const { batch, status, title, customer, friendlyPackageId } = props.detail;

    const displayName = toDisplayName(customer);

    return (
        <div id="staff-card-active-filters">
            {batch && (
                <FilterTag
                    filter={ClearTableFilterRequest.Batch}
                    text={`Batch: ${batch.number} (${localizedDate(
                        batch.createdDate
                    )})`}
                />
            )}
            {friendlyPackageId && (
                <FilterTag
                    filter={ClearTableFilterRequest.FriendlyId}
                    text={`Package: ${friendlyPackageId}`}
                />
            )}
            {status && (
                <FilterTag
                    filter={ClearTableFilterRequest.Status}
                    text={`Status: ${status}`}
                />
            )}
            {title && (
                <FilterTag
                    filter={ClearTableFilterRequest.Title}
                    text={`Card title contains '${title}'`}
                />
            )}
            {customer && (
                <FilterTag
                    filter={ClearTableFilterRequest.Customer}
                    text={`Customer: ${displayName}`}
                />
            )}
        </div>
    );
};

export default StaffCardsFilter;
