import {
    useQuery,
} from "@tanstack/react-query";
import { PagedResult, PageRequest } from "models/common";
import { ShipmentBatch } from "models/shipping";
import { pageRequestToUrlSearchParams } from "util/helpers";
import { useAuthenticatedRequest } from "./useRequests";

const makeAxiosRequest = (pageRequest: PageRequest) => {
    const options = pageRequestToUrlSearchParams(pageRequest);

    return {
        method: "get",
        url: `/shipping/batches?${options}`
    };
};

const useShipmentBatches = (pageRequest: PageRequest) => {
    const getShipmentBatches = useAuthenticatedRequest<PagedResult<ShipmentBatch>>(
        makeAxiosRequest(pageRequest)
    );

    return useQuery(["shipment-batches", pageRequest], getShipmentBatches)
};

export default useShipmentBatches;