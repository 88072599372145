import { forwardRef } from "@chakra-ui/react";
import { Input } from "antd";

import FormInput, { FormInputProps } from "./FormInput";

const TextInput = forwardRef<Omit<FormInputProps, "children">, "input">(
    ({ autoComplete, ...props }, ref) => {
        return (
            <FormInput
                ref={ref}
                validateTrigger="onBlur"
                {...props}
            >
                <Input autoComplete={autoComplete} />
            </FormInput>
        );
    }
);

export default TextInput;
