import { useAuthenticatedRequest } from "./useRequests";
import { useQuery } from "@tanstack/react-query";

export type PackageOwner = {
    id: string;
    email: string;
    userName: string;
    firstName?: string;
    lastName?: string;
    address: OwnerAddress | null;
};

export type OwnerAddress = {
    addressLine1: string;
    addressLine2: string | null;
    city: string;
    state: string;
    zipCode: string;
    country: string;
};

const usePackageOwner = (id: string | undefined) => {
    const getOwner = useAuthenticatedRequest<PackageOwner>({
        method: "get",
        url: `/packages/owner/${id}`,
    });

    return useQuery(["package", "owner", id], getOwner, { enabled: !!id });
};

export default usePackageOwner;
