import { useQuery } from "@tanstack/react-query";
import { useAuthenticatedRequest } from "hooks/useRequests";
import { CardTitle } from "models/cardIngestion";

const useCardTitle = (cardId: string | null, enabled: boolean) => {
    const get = useAuthenticatedRequest<CardTitle>({
        method: "get",
        url: `/card-titling/cards/${cardId}`
    });

    return useQuery(["card-titling", "cards", cardId], get, {
        suspense: true,
        enabled: cardId !== null && enabled,
    });
}

export default useCardTitle;