import { Button } from "antd";
import { useAuthContext, useIdentity } from "context/auth";

export const ImpersonatingBanner = () => {
    const { isImpersonating, stopImpersonating } = useAuthContext();
    const { userName, email } = useIdentity();

    if (isImpersonating()) {
        return (
            <div id="impersonation-banner">
                <div className="viewing-as">
                    You are viewing as customer: {userName} ({email})
                </div>
                <Button className="primary-button" onClick={stopImpersonating}>
                    Stop Impersonating
                </Button>
            </div>
        );
    }
    return <></>;
};
