import { useMutation, useQuery } from "@tanstack/react-query";
import { Shipment, ShipmentCarrier, ShipmentLabel, ShipmentTemplates, ShippingRate } from "models/shipping";
import { Dimensions } from "pages/staff/ShipmentLabelReplacement";
import { useAuthenticatedRequest, useAuthenticatedRequestCreator } from "./useRequests";

interface LabelRequest {
    id: number,
    option: NewShipmentSelectOption,
    length?: number;
    width?: number;
    height?: number;
    weight?: number;
    rateId?: string;
}

export interface NewShipmentSelectOption {
    value: number,
    custom: boolean,
    label: string,
}

export const NewShipmentSelectOptions: NewShipmentSelectOption[] = [
    { value: 7, custom: true, label: 'Carrier/Rate Selection' },
    { value: 2, custom: false, label: 'USPS Priority - Flat Rate Padded Envelope' },
    { value: 3, custom: false, label: 'USPS Priority - Small Flat Rate Box' },
    { value: 4, custom: false, label: 'USPS Priority - Medium Flat Rate Box' },
    { value: 5, custom: false, label: 'USPS Priority - Large Flat Rate Box' },
];

export const DefaultShipmentSelectOption = NewShipmentSelectOptions.find(o => o.value === 7);

type RateRequest = Dimensions & {
    shipmentFriendlyId: number;
    requireSignature: boolean;
};

const requestPayload = (req: LabelRequest) => {
    const payload = {
        shipmentFriendlyId: req.id,
        newCarrier: ShipmentCarrier.USPS,
        serviceLevel: "usps_priority"
    };

    switch (req.option.value) {
        case 2:
            return { ...payload, template: "USPS_FlatRatePaddedEnvelope" };
        case 3:
            return { ...payload, template: "USPS_SmallFlatRateBox" };
        case 4:
            return { ...payload, template: "USPS_MediumFlatRateBox1" };
        case 5:
            return { ...payload, template: "USPS_LargeFlatRateBox" };
        case 7: {
            // shipment rates also contain the rates for flat-fee USPS shipping options.
            // if the rate id matches a shipment template, then send a template label request
            if (req.rateId && ShipmentTemplates[req.rateId]) {
                return { ...payload, template: req.rateId }
            }
            return { shipmentFriendlyId: req.id, rateId: req.rateId, serviceLevel: '' }
        }
        default:
            throw Error('invalid shipment option');
    }
}

const useShipmentByFriendlyId = (id: number | undefined) => {
    const find = useAuthenticatedRequest<Shipment>({
        method: "get",
        url: `/shipping/shipments/${id}`,
    }, [404]);

    return useQuery(["shipment", "label", id], find, { enabled: !!id, retry: false });
}

const useNewShipmentLabel = () => {
    const post = useAuthenticatedRequestCreator<ShipmentLabel, LabelRequest>(
        (req: LabelRequest) => ({
            method: "post",
            url: "/shipping/labels/new",
            data: requestPayload(req)
        })
    );

    const { mutateAsync: requestNewLabel, isLoading: requesting } = useMutation(post);

    return { requestNewLabel, requesting };
}

const useCustomShipmentRates = () => {
    const post = useAuthenticatedRequestCreator<ShippingRate[], RateRequest>(
        (req: RateRequest) => ({
            method: 'post',
            url: '/shipping/rates',
            data: req
        })
    );

    const { mutateAsync: requestRates, isLoading } = useMutation(post);

    return { requestRates, isLoading };
}

export { useCustomShipmentRates, useNewShipmentLabel, useShipmentByFriendlyId };

