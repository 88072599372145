import { CardBatch } from "models/cards";
import { useAuthenticatedRequestCreator } from "./useRequests";

const useBatchSearch = () => {
    const searchBatches = useAuthenticatedRequestCreator<CardBatch[], string>(
        number => ({
            method: "get",
            url: `/cards/batch-search?number=${number}`
        })
    );

    const search = async (number: string) => {
        if (!number || number.trim() === "") {
            return [];
        }

        return await searchBatches(number);
    };

    return { search };
};

export default useBatchSearch;
