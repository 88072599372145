import { HStack } from "@chakra-ui/react";
import { Button, Divider, Image } from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import { CompletedCardTitle } from "models/cardIngestion";
import { InitialPageRequest } from "models/common";
import { FC, useState } from "react";
import useCompletedCardTitles from "./useCompletedCardTitles";

const CardTitleHistory: FC<{
    cardFolderId: string,
    onSelectedCard: (card: CompletedCardTitle) => void,
    onReturnToCurrentCard: () => void,
}> = ({ cardFolderId, onSelectedCard, onReturnToCurrentCard }) => {
    const [visible, setVisible] = useState<boolean>(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
    const [page, setPage] = useState<number>(1);
    const { isLoading, data } = useCompletedCardTitles(cardFolderId, { ...InitialPageRequest, pageSize: 5, page });
    const { data: history = [] } = data || {};

    const selectionLink = (card: CompletedCardTitle, display: string) =>
        <Button type="link" size="small" onClick={() => {
            setSelectedRowKeys([card.id]);
            onSelectedCard(card);
        }}>{display}</Button>;

    const columns: ColumnsType<CompletedCardTitle> = [
        {
            title: "Images",
            sorter: false,
            width: '150px',
            render: (_, rec) => <HStack>
                <Image src={rec.photos[0].imageUrl} width={50} />
                <Image src={rec.photos[1].imageUrl} width={50} />
            </HStack>
        },
        {
            title: "Card Title",
            sorter: false,
            dataIndex: "title",
            render: (title, rec) => selectionLink(rec, title)
        }
    ];

    if (!visible) {
        return <Button onClick={() => setVisible(true)}>Show Completed Card Titles</Button>
    }

    const tableChanged = (
        { current: newPage }: any,
        _: any,
        __: any
    ) => setPage(newPage);

    return <Table
        loading={isLoading}
        size="small"
        columns={columns}
        dataSource={history}
        rowKey={(c) => c.id}
        onChange={tableChanged}
        pagination={{
            total: data?.totalRecords,
            pageSize: 5,
            current: page,
            showSizeChanger: false,
            showQuickJumper: true,
        }}
        rowSelection={{
            selectedRowKeys,
            type: 'radio',
            renderCell: () => null,
        }}
        title={() => <HStack>
            <Button type="link" onClick={() => setVisible(false)}>Hide Card History Table</Button>
            <Divider type='vertical' />
            <Button type="link"
                disabled={selectedRowKeys.length === 0}
                onClick={() => {
                    setSelectedRowKeys([]);
                    onReturnToCurrentCard();
                    setPage(1);
                    setVisible(false);
                }}>Return to Current Card</Button>
        </HStack>}
    />
}

export default CardTitleHistory;