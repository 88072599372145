import { StoreOrder } from "models/store";
import { useAuthenticatedRequest } from "./useRequests";
import { useQuery } from "@tanstack/react-query";
import { useIdentity } from "context/auth";

export const getOrderPreviewKey = (userId: string) => ["customer", userId, "order-preview"];

const useOrderPreview = () => {
    const { id } = useIdentity();
    const key = getOrderPreviewKey(id!);

    const get = useAuthenticatedRequest<StoreOrder>(({
        method: "get",
        url: "/cart/order-preview"
    }));

    return useQuery(key, get);
}

export default useOrderPreview;