import { useQuery } from "@tanstack/react-query";
import { PageRequest, PagedResult } from "models/common";
import { UnsoldCardBatch } from "models/unsoldCards";
import { pageRequestToUrlSearchParams } from "util/helpers";
import { useAuthenticatedRequest } from "./useRequests";

const makeAxiosRequest = (pageRequest: PageRequest) => {
    const options = pageRequestToUrlSearchParams(pageRequest);

    return {
        method: 'get',
        url: `/unsold-cards/batches?${options}`
    };
};

const useUnsoldCardBatches = (pageRequest: PageRequest) => {
    const getUnsoldCardBatches = useAuthenticatedRequest<PagedResult<UnsoldCardBatch>>(
        makeAxiosRequest(pageRequest)
    );

    return useQuery(["unsold-card-batches", pageRequest], getUnsoldCardBatches);
};

export default useUnsoldCardBatches;