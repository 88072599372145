import { RollbackOutlined } from "@ant-design/icons";
import { Box, HStack, Text, VStack } from "@chakra-ui/react";
import { Button, Checkbox, Col, Divider, Form, Input, InputRef, Modal, Progress, Row, Select, Tooltip } from "antd";
import { RuleObject } from "antd/lib/form";
import { BaseOptionType } from "antd/lib/select";
import { TyperRoutes } from "appRoutePaths";
import { CardCategories, IdNamePair, MaxTitleLength, OtherCategory, TyperForm } from "models/cardIngestion";
import { CssVariables } from "models/common";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CardPreview } from "./CardPreview";
import { useCardTitleContext } from "./CardTitleContext";
import CardTitleHistory from "./CardTitleHistory";
import DoNotUploadModal from "./DoNotUploadModal";
import PackageSelector from "./PackageSelector";

const toSelectOptions = (values: IdNamePair[] | undefined, withEmptyOption?: boolean): BaseOptionType[] => {
    const result = values?.map(x => ({
        value: x.id,
        label: x.name
    })) ?? [];

    if (withEmptyOption) {
        return [{ value: '' }, ...result];
    }

    return result;
}

const CardTitleAssignment = () => {
    const { cardFolderId, cardId } = useParams();
    const {
        id,
        cardTitle,
        cardFolderName,
        folderCompleted,
        folderTotal,
        photos,
        shouldNotUpload,
        shouldNotUploadReason,
        isLot,
        category,
        subCategory,
        grader,
        grade,
        statusId,
        conditionId,
        auctionCategory,
        gradingCompany,
        isGraded,
        notes,
        previousCardTitle,
        working,
        stashedCardId,
        multiPackageFolder,
        packageId,
        packageFriendlyId,
        completed,
        previousPackageId,
        loadCardTitle,
        saveCardTitle,
        doNotUploadCard,
        allowCardUpload,
        setIsLot,
        setCategory,
        setCardTitle,
        setCondition,
        setSubcategory,
        setStatus,
        setGradingCompany,
        setNotes,
        setPackageId,
        stashState,
        popState,
    } = useCardTitleContext();
    const [showFreeformSubcategory, setShowFreeformSubcategory] = useState(false);
    const ref = useRef<InputRef>(null);
    const [form] = Form.useForm<TyperForm>();
    const navigate = useNavigate();
    const saveActionText = `Save & ${folderCompleted === (folderTotal - 1) ? 'Complete Folder' : 'Go to Next Card'}`;

    // syncing the state of the context with the form instance; its either this or write
    // a custom validation layer and i'd rather just have ant design do the work
    useEffect(() => {
        const subCategoryIsInList = auctionCategory.subTypes.includes(subCategory) || subCategory === '';

        form.setFieldsValue({
            id,
            cardTitle,
            isLot,
            category,
            subCategory: subCategoryIsInList ? subCategory : OtherCategory,
            subCategoryText: subCategoryIsInList ? '' : subCategory,
            statusId,
            conditionId,
            grader,
            grade,
            notes,
            packageId,
            packageFriendlyId
        });

        setShowFreeformSubcategory(!subCategoryIsInList);
    }, [
        form,
        id,
        cardTitle,
        isLot,
        category,
        subCategory,
        grader,
        grade,
        statusId,
        conditionId,
        notes,
        packageId,
        packageFriendlyId,
        auctionCategory
    ]);

    const focusCardTitleInput = () => {
        const timeoutId = setTimeout(() =>
            ref.current!.focus(), 10
        );

        return () => clearTimeout(timeoutId);
    }

    useEffect(() => {
        // the card id in the URL does not match the id in the context,
        // load the card based on the id in the URL
        if (id !== cardId) {
            loadCardTitle(cardId!);
        }
    }, [id, cardId, loadCardTitle]);

    // workaround for setting the card title to auto-focus
    useEffect(() => {
        return focusCardTitleInput();
    }, [id]);

    const ensureSupportedGrade = async (_: RuleObject, id: string) => {
        if (!gradingCompany) {
            throw new Error("Please select a grader first")
        } else if (!gradingCompany!.grades.find(g => g.id === id)) {
            throw new Error(`Invalid grade for ${gradingCompany!.name}`);
        }
    }

    const onSubcategoryChange = (newSubcategory: string) => {
        if (newSubcategory !== OtherCategory) {
            setSubcategory(newSubcategory);
        }
        setShowFreeformSubcategory(newSubcategory === OtherCategory);
    }

    const catchAutoCompletePrevTitle = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.code === "Tab" && previousCardTitle && cardTitle.trim().length === 0) {
            e.preventDefault();
            setCardTitle(previousCardTitle);
        }
    }

    const catchCtrlEnter = async (e: React.KeyboardEvent<HTMLFormElement>) => {
        if (e.code === "Enter" && e.ctrlKey) {
            e.preventDefault();
            await submitForm();
        }
    };

    const flagAsDoNotUpload = async (reason: string) => {
        const nextCardId = await doNotUploadCard(reason);

        nextCardOrCompleteFolder(nextCardId);
    }

    const removeDoNotUploadFlag = async () => {
        await allowCardUpload();

        focusCardTitleInput();
    };

    const submitForm = async () => {
        await form.validateFields();

        const nextCardId = await saveCardTitle();

        nextCardOrCompleteFolder(nextCardId);
    };

    const nextCardOrCompleteFolder = (nextCardId: string | null) => {
        if (nextCardId) {
            navigate(TyperRoutes.cardTitling.url(cardFolderId!, nextCardId));
        } else {
            Modal.success({
                title: `${cardFolderName} Complete`,
                content: 'You have completed all card titles in this folder.',
                onOk: () => navigate(TyperRoutes.dashboard.url)
            });
        }
    };

    return <Box paddingTop='20px'>
        <Row gutter={32}>
            <Col span={8}>
                <VStack paddingTop={5} spacing={5}>
                    <Text fontWeight={'bold'} fontSize={28}>{cardFolderName}</Text>
                    {photos.length > 0 && <CardPreview
                        key={id}
                        front={photos[0].imageUrl}
                        back={photos[1].imageUrl}
                        additional={photos.slice(2).map(p => p.imageUrl)}
                    />}
                </VStack>
            </Col>

            <Col span={16} style={{ paddingTop: '52px' }}>
                {shouldNotUpload && <>
                    <Row gutter={32}>
                        <Col span={24}>
                            <HStack>
                                <Button icon={<RollbackOutlined />} size='large' title="Remove Do Not Upload Flag" onClick={removeDoNotUploadFlag} />
                                <Text fontSize={30} color={CssVariables.errorRed}>
                                    Do Not Upload: {shouldNotUploadReason}
                                </Text>
                            </HStack>
                        </Col>
                    </Row>
                    <Divider />
                </>}

                <Form
                    form={form}
                    preserve={false}
                    layout="vertical"
                    disabled={working || shouldNotUpload}
                    onKeyDown={catchCtrlEnter}
                >
                    <Form.Item name="id" hidden>
                        <Input />
                    </Form.Item>

                    <PackageSelector
                        key={id}
                        completedTitle={completed}
                        packageFriendlyId={packageFriendlyId}
                        previousPackageId={previousPackageId}
                        visible={multiPackageFolder}
                        setPackageId={setPackageId}
                    />

                    <Row gutter={32}>
                        <Col span={16}>
                            <Form.Item
                                label="Card Title"
                                name="cardTitle"
                                rules={[
                                    { required: true, message: 'Card title is required' },
                                    { max: MaxTitleLength, message: `Card title has to be ${MaxTitleLength} characters or less` }
                                ]}
                                tooltip={previousCardTitle ? "Tab to auto-complete previous title" : undefined}>
                                <Input
                                    placeholder={previousCardTitle ?? ''}
                                    showCount={{ formatter: (args) => `${args.count} / ${MaxTitleLength}` }}
                                    onChange={e => setCardTitle(e.target.value)}
                                    onKeyDown={catchAutoCompletePrevTitle}
                                    ref={ref}
                                    spellCheck
                                />
                            </Form.Item>
                        </Col>

                        <Col className="gutter-row" span={8}>
                            <Form.Item
                                label=" "
                                valuePropName="checked"
                                name="isLot"
                            >
                                <Checkbox onChange={e => setIsLot(e.target.checked)}>Is Lot?</Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={32}>
                        <Col span={8}>
                            <Form.Item
                                label="Category"
                                name="category"
                                rules={[
                                    { required: true }
                                ]}
                            >
                                <Select options={CardCategories} onChange={setCategory} />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="Sub Category"
                                name="subCategory"
                                rules={[
                                    { required: true, message: 'Sub category is required' }
                                ]}
                            >
                                <Select options={[
                                    { value: '' },
                                    ...auctionCategory.subTypes.map(x => ({ value: x })),
                                    { value: OtherCategory }
                                ]} onChange={onSubcategoryChange} />
                            </Form.Item>
                        </Col>

                        <Col span={8} hidden={!showFreeformSubcategory}>
                            <Form.Item
                                label="Specify Other Category"
                                name="subCategoryText"
                                rules={[
                                    { required: showFreeformSubcategory, message: 'You have to enter a sub category' }
                                ]}
                            >
                                <Input onChange={e => setSubcategory(e.target.value)} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={32}>
                        <Col span={8}>
                            <Form.Item
                                label="Status"
                                name="statusId"
                                rules={[
                                    { required: true }
                                ]}
                            >
                                <Select
                                    options={toSelectOptions(auctionCategory.itemConditions)}
                                    onChange={v => setStatus(v)}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={8} hidden={!isGraded}>
                            <Form.Item
                                label="Grader"
                                name="grader"
                                rules={[
                                    { required: isGraded, message: 'You have to select a grader' }
                                ]}
                            >
                                <Select
                                    options={toSelectOptions(auctionCategory.graders, true)}
                                    onChange={v => setGradingCompany(v)}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={8} hidden={!isGraded}>
                            <Form.Item
                                label="Grade"
                                name="grade"
                                rules={[
                                    { required: isGraded, message: 'You have to select a grade' },
                                    { validator: isGraded ? ensureSupportedGrade : () => Promise.resolve() }
                                ]}
                            >
                                <Select disabled={!gradingCompany}
                                    options={toSelectOptions(gradingCompany?.grades, true)}
                                    onChange={v => setGradingCompany(grader, v)}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={8} hidden={isGraded || isLot}>
                            <Form.Item
                                label="Condition"
                                name="conditionId"
                                rules={[
                                    { required: !isGraded && !isLot }
                                ]}
                            >
                                <Select
                                    options={toSelectOptions(auctionCategory.ungradedConditions)}
                                    onChange={setCondition}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={32}>
                        <Col span={16}>
                            <Form.Item
                                label="Help / Notes"
                                name="notes"
                            >
                                <Input onChange={e => setNotes(e.target.value)} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={32}>
                        <Col span={24}>
                            <Box>{`Completed: ${folderCompleted} / ${folderTotal}`}</Box>
                            <Progress
                                percent={(folderCompleted / folderTotal) * 100}
                                showInfo={false}
                                status='active'
                                strokeColor={{
                                    '0%': CssVariables.lightGreen,
                                    '100%': CssVariables.darkGreen,
                                }}
                                trailColor={CssVariables.white}
                            />
                        </Col>
                    </Row>
                </Form>

                <Row gutter={32}>
                    <Col span={24} style={{ paddingTop: '20px' }}>
                        <Tooltip title={`Ctrl+Enter will also ${saveActionText}`} mouseEnterDelay={1} placement="bottom">
                            <Button onClick={submitForm} type="primary" style={{ marginRight: '10px' }}>
                                {saveActionText}
                            </Button>
                        </Tooltip>

                        <DoNotUploadModal
                            key={id}
                            onConfirm={flagAsDoNotUpload}
                        />

                        <CardTitleHistory
                            key={`card-history-${cardFolderId}-${folderCompleted}`}

                            cardFolderId={cardFolderId!}

                            onSelectedCard={card => {
                                if (!stashedCardId && !completed) {
                                    stashState();
                                }
                                loadCardTitle(card.id);
                                navigate(TyperRoutes.cardTitling.url(cardFolderId!, card.id), { replace: true });
                            }}

                            onReturnToCurrentCard={() => {
                                popState();
                                navigate(TyperRoutes.cardTitling.url(cardFolderId!, stashedCardId!), { replace: true });
                            }}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    </Box>;
};

export default CardTitleAssignment;