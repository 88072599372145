import AnnouncementSection from "components/staff/WebsiteUpdates/AnnouncementSection";
import CustomerPayoutSection from "components/staff/WebsiteUpdates/CustomerPayoutSection";
import EbayFunctionsSection from "components/staff/WebsiteUpdates/EbayFunctionsSection";
import FeaturedVideoSection from "components/staff/WebsiteUpdates/FeaturedVideoSection";
import OutOfOfficeSection from "components/staff/WebsiteUpdates/OutOfOfficeSection";
import PackageTypesSection from "components/staff/WebsiteUpdates/PackageTypesSection";
import PayoutConfigSection from "components/staff/WebsiteUpdates/PayoutConfigSection";
import PromotionalSection from "components/staff/WebsiteUpdates/PromotionalSection";
import TestimonialSection from "components/staff/WebsiteUpdates/TestimonialSection";
import { useIdentity } from "context/auth";
import { useEffect } from "react";

import "styles/staff/website_updates.css";

const WebsiteUpdates = () => {
    const { isAdmin } = useIdentity();
    // for whatever reason, the editable Paragraph and Text components
    // in TestimonialPreview take focus on page load, scrolling the content
    // to the bottom. I've spent too much time trying to find out why, so
    // here's a band-aid.
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <PackageTypesSection readOnly={!isAdmin} />
            <CustomerPayoutSection />
            <PayoutConfigSection />
            <AnnouncementSection />
            <OutOfOfficeSection />
            <PromotionalSection />
            <FeaturedVideoSection />
            <EbayFunctionsSection />
            <TestimonialSection />
        </>
    );
};

export default WebsiteUpdates;
