import { Button, Divider, Select, Input, Alert } from "antd";
import { ChangeEvent, FC, useState } from "react";
import { Box, VStack, HStack, Text } from "@chakra-ui/react";
import {
    CustomDimensions,
    OrderFulfillmentShippingOptions,
    OrderShipmentSelectOption
} from "hooks/useOrderFulfillment";

const DefaultDimensions = { lengthInInches: 0, widthInInches: 0, heightInInches: 0, weightInOz: 0 };

interface ShippingOptionsProps {
    isLoading: boolean,
    errors: string[],
    onSubmit: (option: OrderShipmentSelectOption, dimensions: CustomDimensions) => void
}

const ShippingOptions: FC<ShippingOptionsProps> = ({
    isLoading,
    errors,
    onSubmit
}) => {
    const [option, setOption] = useState<OrderShipmentSelectOption | undefined>();
    const [dimensions, setDimensions] = useState<CustomDimensions>(DefaultDimensions);
    const [validForm, setValidForm] = useState<boolean>(false);

    const formCheck = (opt: OrderShipmentSelectOption | undefined, dim: CustomDimensions): boolean => {
        if (opt && opt.value !== 5) {
            return true;
        }

        if (opt && opt.value === 5) {
            return dim.lengthInInches > 0 &&
                dim.widthInInches > 0 &&
                dim.heightInInches > 0 &&
                dim.weightInOz > 0;
        }

        return false;
    }

    const onSelectChange = (_: number, option: OrderShipmentSelectOption | OrderShipmentSelectOption[]) => {
        setOption(_ => {
            const newOpt = option as OrderShipmentSelectOption;
            const result = formCheck(newOpt, dimensions);

            setValidForm(result);
            return newOpt;
        });
    }

    const onDimensionChange = (e: ChangeEvent<HTMLInputElement>) => {
        setDimensions(dim => {
            const newDim = { ...dim, [e.target.name]: Number(e.target.value) };
            const result = formCheck(option, newDim);

            setValidForm(result);
            return newDim;
        });
    }

    const submit = () => {
        onSubmit(option!, dimensions);
    }

    const inputProps = option && option.value === 5
        ? { disabled: false, style: { opacity: 1 }, type: 'number', onChange: onDimensionChange }
        : { disabled: true, style: { opacity: 0.5 }, type: 'number', onChange: onDimensionChange }

    return <Box w='100%'>
        <Divider style={{ margin: 0 }}>Shipping Options</Divider>
        <VStack w='100%' pt={5}>
            <Select
                disabled={isLoading}
                style={{ width: '100%' }}
                placeholder="Select a Shipping Option"
                options={OrderFulfillmentShippingOptions}
                onChange={onSelectChange}
            />
            <Input.Group>
                <HStack>
                    <Input name="lengthInInches"
                        addonBefore="Length"
                        suffix="in."
                        value={dimensions.lengthInInches}
                        {...inputProps} />
                    <Input name="widthInInches"
                        addonBefore="Width"
                        suffix="in."
                        value={dimensions.widthInInches}
                        {...inputProps} />
                    <Input name="heightInInches"
                        addonBefore="Height"
                        suffix="in."
                        value={dimensions.heightInInches}
                        {...inputProps} />
                    <Input name="weightInOz"
                        addonBefore="Weight"
                        suffix="oz."
                        value={dimensions.weightInOz}
                        {...inputProps} />
                </HStack>
            </Input.Group>
            <Divider />
            {errors.length > 0 && <Alert
                style={{ width: "100%" }}
                type="error"
                message="Fulfillment Error"
                description={<Box>
                    {errors.map((e, idx) => (<Text as="p" key={idx}>{e}</Text>))}
                </Box>}
                showIcon
            />}
            <Button block type="primary" disabled={!validForm} onClick={submit} loading={isLoading}>
                Generate Shipping Label
            </Button>
        </VStack>
    </Box>;
}

export default ShippingOptions;