import { PackageAmount } from "models/packages";

const packageAmountOptions = [
    {
        label: "1/4",
        value: PackageAmount.QuarterRow,
    },
    {
        label: "1/2",
        value: PackageAmount.HalfRow,
    },
    {
        label: "1",
        value: PackageAmount.One,
    },
    {
        label: "2",
        value: PackageAmount.Two,
    },
    {
        label: "3",
        value: PackageAmount.Three,
    },
    {
        label: "4",
        value: PackageAmount.Four,
    },
    {
        label: "5",
        value: PackageAmount.Five,
    },
    {
        label: "6",
        value: PackageAmount.Six,
    },
    {
        label: "7",
        value: PackageAmount.Seven,
    },
    {
        label: "8",
        value: PackageAmount.Eight,
    },
    {
        label: "9",
        value: PackageAmount.Nine,
    },
    {
        label: "10",
        value: PackageAmount.Ten,
    },
    {
        label: "11",
        value: PackageAmount.Eleven,
    },
    {
        label: "12",
        value: PackageAmount.Twelve,
    },
    {
        label: "13",
        value: PackageAmount.Thirteen,
    },
    {
        label: "14",
        value: PackageAmount.Fourteen,
    },
    {
        label: "15",
        value: PackageAmount.Fifteen,
    },
    {
        label: "16",
        value: PackageAmount.Sixteen,
    },
    {
        label: "17",
        value: PackageAmount.Seventeen,
    },
    {
        label: "18",
        value: PackageAmount.Eighteen,
    },
    {
        label: "19",
        value: PackageAmount.Nineteen,
    },
    {
        label: "20",
        value: PackageAmount.Twenty,
    },
    {
        label: "Over 20",
        value: PackageAmount.OverTwenty,
    },
    {
        label: "Not Applicable",
        value: PackageAmount.NotApplicable,
    },
];

export default packageAmountOptions;
