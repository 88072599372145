import { Accordion, Box, Flex } from "@chakra-ui/react";
import { SupportTicket } from "models/support";
import { FC } from "react";
import SupportTicketRow from "./SupportTicket";

// The actual list of Support Tickets
// NOTE: Because of the Suspense, this component should only ever be mounted if the data is loaded
const SupportTicketsList: FC<{ tickets: SupportTicket[], unreadIds: number[] | undefined }> = ({ tickets, unreadIds }) => {
    const unread = unreadIds || [];

    return (
        <Box w="100%" rounded="lg" bg="lightGray" p={6}>
            <Flex pl="10px" pr="2rem" fontSize="18">
                <Box w="5.5rem">Status</Box>
                <Box flex="1">Subject</Box>
                <Box flex="1">Description</Box>
                <Flex w="10rem" justifyContent="end">
                    Last Updated
                </Flex>
            </Flex>
            {tickets.length ? (
                <Accordion allowToggle w="100%">
                    {tickets?.map((ticket) => (
                        <SupportTicketRow key={ticket.id} ticket={ticket} unread={unread.includes(ticket.id)} />
                    ))}
                </Accordion>
            ) : (
                <Flex
                    w="100%"
                    minH="15rem"
                    p={4}
                    align="center"
                    justify="center"
                >
                    No tickets to show
                </Flex>
            )}
        </Box>
    );
};

export default SupportTicketsList;
