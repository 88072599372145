import { Box, Flex, Stack, VStack } from "@chakra-ui/react";
import { CustomerRoutes } from "appRoutePaths";
import React from "react";

// How far up and down the odd/even items are offset
const VERTICAL_OFFSET = "2.5rem";

const HowDoesThisWorkStep = (props: {
    number: string;
    name: string;
    details: string | React.ReactNode;
}) => {
    const intNumber = Number.parseInt(props.number, 10);
    const isEven = intNumber % 2 === 0;
    const sign = isEven ? "" : "-";

    return (
        <VStack
            pos="relative"
            fontFamily="heading"
            h="100%"
            maxW={{
                base: "18rem",
                md: "15rem",
            }}
            transform={{
                base: "unset",
                md: `translateY(${sign}${VERTICAL_OFFSET})`,
            }}
            textAlign={{
                base: "center",
                md: "left",
            }}
            align={{
                base: "center",
                md: "baseline",
            }}
            py={{
                base: 0,
                // Make sure we're the correct height
                md: VERTICAL_OFFSET,
            }}
        >
            <Box
                bg="var(--dcs-light-blue)"
                fontSize="56"
                px=".5rem"
                lineHeight="70px"
                mb="1rem"
            >
                <Box pos="relative" transform="translate(0, 1.05rem)">
                    {props.number}
                </Box>
            </Box>
            <Box fontSize="3xl" color="var(--dcs-white)">
                {props.name}
            </Box>
            <Box fontFamily="body" color="var(--dcs-white)">
                {props.details}
            </Box>
        </VStack>
    );
};

export const HowDoesThisWork = () => {
    return (
        <Flex justify="center">
            <VStack
                align="center"
                w="100%"
                p="8"
                spacing="6"
                maxW={{
                    base: "80%",
                }}
            >
                <Stack
                    spacing="8"
                    direction={{
                        base: "column",
                        md: "row",
                    }}
                    w="100%"
                    align="center"
                    justifyContent="space-between"
                >
                    <HowDoesThisWorkStep
                        number="01"
                        name="Sign up and send your cards to us."
                        details={
                            <span>
                                Create an account on this website, print out and
                                send a{" "}
                                <Box
                                    as="a"
                                    href={CustomerRoutes.submissionForm.url}
                                    target="_blank"
                                    rel="noreferrer"
                                    color="blue"
                                >
                                    submission form
                                </Box>{" "}
                                with your name and email along with your cards
                                and you are good to go!
                            </span>
                        }
                    />
                    <HowDoesThisWorkStep
                        number="02"
                        name="We prepare your cards for eBay."
                        details="We handle everything! Scans, titles, shipping, and all the other tasks that come with selling on eBay. You can just sit back, relax, and watch our process work for you!"
                    />
                    <HowDoesThisWorkStep
                        number="03"
                        name="We provide detailed sales reports!"
                        details="We provide you with a link to all of your active auctions. Click your link and watch the bids come in! Once your cards have sold, your balance and dashboard will update within minutes!"
                    />
                    <HowDoesThisWorkStep
                        number="04"
                        name="CASH OUT! GET PAID!"
                        details="We process all payouts within ONE BUSINESS DAY! We offer ACH Bank Transfer (most popular), Paypal (Goods &amp; Services), check, and bank wire ($10k minimum USA, no minimum for INTL customers). Once you are ready to cash out select one of these options and get paid fast!"
                    />
                </Stack>
            </VStack>
        </Flex>
    );
};
