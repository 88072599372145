import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Flex,
} from "@chakra-ui/react";
import { FAQArticle } from "hooks/useFAQ";
import parse from "html-react-parser";
import { FC } from "react";

const HelpArticles: FC<{ articles: FAQArticle[] }> = ({ articles }) => {
    return (
        <Accordion allowToggle={true} mb={5}>
            {articles?.map((article) => (
                <AccordionItem border="none">
                    {({ isExpanded }) => (
                        <Box
                            p={15}
                            borderBottom="1px solid"
                            borderColor={
                                isExpanded
                                    ? "transparent"
                                    : "var(--dcs-light-blue)"
                            }
                            color="white"
                        >
                            <AccordionButton _hover={{ bg: "none" }}>
                                <Flex
                                    textAlign="start"
                                    fontSize={22}
                                    fontWeight="semibold"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    w="100%"
                                    cursor="pointer"
                                >
                                    <Box>{article.title}</Box>
                                    <AccordionIcon />
                                </Flex>
                            </AccordionButton>
                            <AccordionPanel>
                                <Box className="zendesk-html">
                                    {parse(article.body)}
                                </Box>
                            </AccordionPanel>
                        </Box>
                    )}
                </AccordionItem>
            ))}
        </Accordion>
    );
};

export default HelpArticles;
