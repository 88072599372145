import { Button, Radio, RadioChangeEvent, Space } from "antd";
import { CardStatus } from "models/cards";
import { ClearTableFilterRequest } from "models/common";
import { useCallback, useEffect, useState } from "react";
import {
    ExtendedFilterDropdownProps,
    handleClearFilterRequest,
} from "util/table";

const StatusFilter = ({
    confirm,
    selectedKeys,
    setSelectedKeys,
    clearFilters,
    onFilter,
}: ExtendedFilterDropdownProps) => {
    const [selected, setSelected] = useState<string | undefined>();

    const setFilter = () => {
        confirm({ closeDropdown: true });
        onFilter(selected);
    };

    const reset = () => {
        setSelectedKeys([]);
        setSelected(undefined);
    };

    const onClear = useCallback(() => {
        clearFilters && clearFilters();
        confirm({ closeDropdown: false });
        onFilter(undefined);
    }, [clearFilters, confirm, onFilter]);

    const onChange = (e: RadioChangeEvent) => {
        const status = e.target.value;
        setSelectedKeys(status ? [status] : []);
        setSelected(status);
    };

    useEffect(() => {
        handleClearFilterRequest(ClearTableFilterRequest.Status, onClear);
    }, [onClear]);

    return (
        <div className="custom-filter">
            <div className="selector-wrapper">
                <Radio.Group onChange={onChange} value={selectedKeys[0]}>
                    <Space direction="vertical">
                        <Radio value={CardStatus.Active}>Active</Radio>
                        <Radio value={CardStatus.AwaitingPayment}>
                            Awaiting Payment
                        </Radio>
                        <Radio value={CardStatus.Cancelled}>
                            Listing Ended
                        </Radio>
                        <Radio value={CardStatus.Paid}>Paid</Radio>
                        <Radio value={CardStatus.Unsold}>Unsold</Radio>
                        <Radio value={CardStatus.Returned}>Returned</Radio>
                        <Radio value={CardStatus.ShippedToCustomer}>Shipped to Customer</Radio>
                    </Space>
                </Radio.Group>
            </div>
            <div className="table-filter-actions-multiple ant-table-filter-dropdown-btns">
                <Button
                    size="small"
                    type="link"
                    onClick={reset}
                    disabled={selectedKeys[0] === undefined}
                >
                    Reset
                </Button>
                <Button size="small" type="primary" onClick={setFilter}>
                    OK
                </Button>
            </div>
        </div>
    );
};

export default StatusFilter;
