import { Box } from "@chakra-ui/react";
import { Button, Form, Input, Modal } from "antd";
import { useRegistration } from "hooks/useRegistration";
import { useState } from "react";

interface AddUserForm {
    firstName: string;
    lastName: string;
    username: string;
    email: string;
}

const AddUserModal = (props: { onSuccess?: () => void }) => {
    const [isOpen, setOpen] = useState<boolean>(false);
    const [form] = Form.useForm<AddUserForm>();
    const [isWorking, setWorking] = useState<boolean>(false);
    const { registerUser } = useRegistration();

    const close = async () => {
        setOpen(false);
        form.resetFields();
    };

    const submitForm = async () => {
        const formData = await form.validateFields();
        setWorking(true);
        try {
            await registerUser({
                firstName: formData.firstName,
                lastName: formData.lastName,
                username: formData.username,
                email: formData.email,
                password: undefined,
            });

            if (props.onSuccess) {
                props.onSuccess();
            }
            close();
        } finally {
            setWorking(false);
        }
    };

    return (
        <>
            <Box marginBottom="8px">
                <Button type="primary" onClick={() => setOpen(true)}>
                    Add New User
                </Button>
            </Box>
            <Modal
                open={isOpen}
                title="Add New User"
                closable={false}
                keyboard={false}
                maskClosable={false}
                destroyOnClose={true}
                cancelButtonProps={{ disabled: isWorking }}
                onCancel={() => close()}
                okText={isWorking ? "Saving..." : "Add User"}
                onOk={submitForm}
            >
                <Form form={form} disabled={isWorking} layout="vertical">
                    <Form.Item
                        name="firstName"
                        label="First Name"
                        rules={[
                            {
                                required: true,
                                message: "First name is required",
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="lastName"
                        label="Last Name"
                        rules={[
                            {
                                required: true,
                                message: "Last name is required",
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="username"
                        label="Username"
                        rules={[
                            {
                                required: true,
                                message: "Username is required",
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="email"
                        label="Email Address"
                        rules={[
                            {
                                required: true,
                                message: "Email address is required",
                            },
                            {
                                type: "email",
                                message: "Email address is invalid",
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default AddUserModal;
