import { useQuery, useQueryClient } from "@tanstack/react-query";
import { DefaultDays } from "components/TableActions";
import { useIdentity } from "context/auth";
import { PagedResult, PageRequest } from "models/common";
import {
    PayoutRequest,
    PayoutRequestStatus,
    PayoutRequestStatusLabels,
} from "models/payoutRequests";
import { pageRequestToUrlSearchParams } from "util/helpers";

import { useAuthenticatedRequest } from "./useRequests";

const QUERY_KEY = "payout-requests";

export const useInvalidatePayoutRequests = () => {
    const queryClient = useQueryClient();
    return () => queryClient.invalidateQueries([QUERY_KEY]);
};

export const usePendingFilteredPayouts = () => {
    return usePayoutRequests({
        page: 1,
        withinDays: DefaultDays,
        sort: null,
        filterBy: {
            status: [PayoutRequestStatus.Pending],
        },
    });
};

const makeAxiosRequest = (
    pageRequest: PageRequest,
    isAdmin: boolean | undefined,
    adminStatusFilter?:
        | PayoutRequestStatus.Pending
        | PayoutRequestStatus.Complete
) => {
    const options = pageRequestToUrlSearchParams(pageRequest);

    const status =
        PayoutRequestStatusLabels[
        adminStatusFilter ?? PayoutRequestStatus.Pending
        ];

    const url = isAdmin
        ? `/payout-requests/${status}?${options}`
        : `/payout-requests?${options}`;

    return {
        method: "get",
        url,
    };
};

export const usePayoutRequestsCSV = (
    pageRequest: Omit<PageRequest, "page">,
    adminStatusFilter?:
        | PayoutRequestStatus.Pending
        | PayoutRequestStatus.Complete
) => {
    const { isAdmin } = useIdentity();
    const pageRequestWithCSV = {
        ...pageRequest,
        page: 1, // ignored
        format: "csv",
    } as const;
    const axiosRequest = makeAxiosRequest(pageRequestWithCSV, isAdmin, adminStatusFilter);

    return useAuthenticatedRequest<string>(axiosRequest);
};

const usePayoutRequests = (
    pageRequest: PageRequest,
    adminStatusFilter?:
        | PayoutRequestStatus.Pending
        | PayoutRequestStatus.Complete
) => {
    const { isAdmin } = useIdentity();

    const fetchPayoutRequests = useAuthenticatedRequest<
        PagedResult<PayoutRequest>
    >(makeAxiosRequest(pageRequest, isAdmin, adminStatusFilter));

    return useQuery(
        [QUERY_KEY, pageRequest, adminStatusFilter],
        fetchPayoutRequests,
        {}
    );
};

export default usePayoutRequests;
