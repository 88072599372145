export interface PaymentCalculation {
    balance: number;
    orderTotal: number;
    canUseBalance: boolean;
    willUseBalance: boolean;
    balanceCanCoverOrder: boolean;
    balancePaymentAmount: number;
    otherPaymentAmount: number;
}

const usePaymentCalculation = (
    orderTotalInCents: number,
    accountBalanceInCents: number,
    useBalance: boolean): PaymentCalculation => {

    const balancePaymentAmount = useBalance
        ? Math.min(accountBalanceInCents, orderTotalInCents)
        : 0;
    const otherPaymentAmount = orderTotalInCents - balancePaymentAmount;

    return {
        balance: accountBalanceInCents,
        orderTotal: orderTotalInCents,
        canUseBalance: accountBalanceInCents > 0,
        willUseBalance: useBalance,
        balanceCanCoverOrder: accountBalanceInCents >= orderTotalInCents,
        balancePaymentAmount,
        otherPaymentAmount
    };
};

export default usePaymentCalculation;