import "styles/staff/staff_transactions.css";

import { Box } from "@chakra-ui/react";
import { Col, Row, Table } from "antd";
import { ColumnsType, FilterValue } from "antd/lib/table/interface";
import Currency from "components/Currency";
import { CustomerSearch } from "components/EntityTableSearch";
import MultiValueDisplay from "components/MultiValueDisplay";
import TableActions from "components/TableActions";
import ManualAdjustmentModal from "components/staff/Transactions/ManualAdjustmentModal";
import StaffTransactionFilter, {
    StaffTransactionFilterDetail,
} from "components/staff/Transactions/StaffTransactionFilter";
import TransactionNameSearch from "components/staff/Transactions/TransactionNameSearch";
import TransactionType from "components/staff/Transactions/TransactionType";
import TypeFilter from "components/staff/Transactions/TypeFilter";
import useStaffTransactions, {
    useStaffTransactionsCSV,
} from "hooks/useStaffTransactions";
import { InitialPageRequest, PageRequest, PageSizeOptions } from "models/common";
import { StaffTransaction } from "models/transactions";
import { useState } from "react";
import downloadAsCSV from "util/downloadAsCSV";
import { localizedDate } from "util/helpers";
import { createCustomFilter } from "util/table";

const Transactions = () => {
    const [{ page, filterBy, withinDays, sort, pageSize }, setPageRequest] =
        useState<PageRequest>(InitialPageRequest);
    const [{ isLoading, data }, forceRefresh] = useStaffTransactions({
        page,
        filterBy,
        withinDays,
        sort,
        pageSize
    });
    const [filterDetail, setFilterDetail] =
        useState<StaffTransactionFilterDetail>({});
    const { data: records, totalRecords } = data || {};

    const daysChanged = (value: number) => {
        setPageRequest({ page: 1, filterBy, withinDays: value, sort, pageSize });
    };

    const tableChanged = (
        // pagination
        { current: newPage, pageSize: newPageSize }: any,
        // filtering
        filters: Record<string, FilterValue | null>,
        // sorting
        { column, field, order }: any
    ) => {
        setPageRequest({
            page: newPageSize !== pageSize ? 1 : newPage,
            pageSize: newPageSize,
            filterBy: filters,
            withinDays,
            sort: column ? [field, order === "ascend"] : null,
        });
    };

    const onFilterUpdate = (key: string) => (value: any) => {
        setFilterDetail((prev) => ({ ...prev, [key]: value }));
    };

    const columns: ColumnsType<StaffTransaction> = [
        {
            title: "Date",
            dataIndex: "transactionDate",
            key: "date",
            sorter: true,
            render: localizedDate,
        },
        {
            title: "Customer",
            dataIndex: "customerId",
            key: "customerId",
            sorter: true,
            ...createCustomFilter(
                CustomerSearch,
                onFilterUpdate("customer"),
                filterDetail.customer?.id
            ),
            render: (_, rec) => (
                <MultiValueDisplay
                    title={`${rec.customerFirstName} ${rec.customerLastName}`}
                    subTitle={rec.customerEmail}
                />
            ),
        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description",
            sorter: true,
            ...createCustomFilter(
                TransactionNameSearch,
                onFilterUpdate("description"),
                filterDetail.description
            ),
            render: (n) => <span className="bold-column">{n}</span>,
        },
        {
            title: "Type",
            dataIndex: "transactionType",
            key: "transactionType",
            sorter: false,
            ...createCustomFilter(
                TypeFilter,
                onFilterUpdate("type"),
                filterDetail.type
            ),
            render: (t) => <TransactionType type={t} />,
        },
        {
            title: "Gross Amount",
            dataIndex: "grossAmountInCents",
            key: "grossAmountInCents",
            sorter: true,
            align: "right",
            render: (a) => <Currency cents={a} />,
        },
        {
            title: "Fees",
            dataIndex: "feesInCents",
            key: "feesInCents",
            sorter: true,
            align: "right",
            render: (a) => <Currency cents={-a} />,
        },
        {
            title: "Net Amount",
            dataIndex: "netAmountInCents",
            key: "netAmountInCents",
            sorter: true,
            align: "right",
            render: (a) => <Currency cents={a} />,
        },
    ];

    const fetchCSV = useStaffTransactionsCSV({
        withinDays,
        sort,
    });

    const handleCSVExport = async () => {
        const csvData = await fetchCSV();
        const date = new Date().toISOString().split("T")[0];
        const filename = `all-transactions-${date}.csv`;
        downloadAsCSV(csvData, filename);
    };

    return (
        <Box p={50}>
            <Row align="bottom">
                <Col span={6}>
                    <Box fontSize={36}>Transactions</Box>
                </Col>
                <Col span={10} offset={8} className="actions-container">
                    <TableActions
                        onDaysChanged={daysChanged}
                        isLoading={isLoading}
                        showFilter={false}
                        onExportCSV={handleCSVExport}
                    />
                </Col>
            </Row>
            <Row id="actions-and-filter">
                <Col span={4}>
                    <ManualAdjustmentModal onSuccess={forceRefresh} />
                </Col>
                <Col span={20}>
                    <StaffTransactionFilter detail={filterDetail} />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Table
                        id="staff-transaction-table"
                        columns={columns}
                        dataSource={records}
                        rowKey={(r) => r.transactionId}
                        onChange={tableChanged}
                        pagination={{
                            total: totalRecords,
                            current: page,
                            pageSize,
                            pageSizeOptions: PageSizeOptions,
                            showSizeChanger: true,
                            showQuickJumper: true,
                        }}
                        expandable={{
                            rowExpandable: (r) => r.createdByUserEmail !== null,
                            expandedRowRender: (r) => (
                                <span className="bold-font transaction-creator">
                                    Transaction initiated by:{" "}
                                    {r.createdByUserEmail}
                                </span>
                            ),
                        }}
                        showSorterTooltip={false}
                        loading={isLoading}
                    />
                </Col>
            </Row>
        </Box>
    );
};

export default Transactions;
