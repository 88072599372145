import { Box, Flex, forwardRef, Select, SelectProps } from "@chakra-ui/react";
import {
    PayoutRequestMethod,
    ValidPaymentMethods,
} from "models/payoutRequests";
import { SyntheticEvent, useState } from "react";

import FormInput from "./PayoutMethodDetailForms/Inputs/FormInput";
import TransferFee from "./TransferFee";

type PayoutMethodSelectorProps = {
    value: ValidPaymentMethods | undefined;
    onSelect: (method: ValidPaymentMethods | undefined) => void;
    selectedMethodExists: boolean | undefined;
};

const PayoutMethodSelector = forwardRef<
    Omit<SelectProps, "onSelect"> & PayoutMethodSelectorProps,
    typeof Select
>(({ value, onSelect, selectedMethodExists, ...props }, ref) => {
    const [selection, setSelection] = useState<ValidPaymentMethods | undefined>();

    const onSelected = (event: SyntheticEvent<HTMLSelectElement>) => {
        const value = event.currentTarget.value;
        const num = value && Number.parseInt(value, 10);
        const method = num as ValidPaymentMethods | undefined;
        setSelection(method)
        onSelect(method);
    };

    return (
        <Flex flexDir="column">
            <FormInput
                label="Payout Method"
                name="payoutMethod"
                required={false}
            >
                <Select
                    minWidth="15rem"
                    ref={ref}
                    size="sm"
                    placeholder="Select a payment method"
                    borderColor="#d9d9d9"
                    borderRadius={2}
                    outline="none"
                    onChange={onSelected}
                    value={value}
                    {...props}
                >
                    <option value={PayoutRequestMethod.ACH}>ACH</option>
                    <option value={PayoutRequestMethod.Check}>Check</option>
                    <option value={PayoutRequestMethod.Paypal}>Paypal</option>
                    {/* 
                    <option value={PayoutRequestMethod.Wire}>
                        Wire Transfer
                    </option>
                    */}
                    <option value={PayoutRequestMethod.InternationalWire}>
                        International Wire Transfer
                    </option>
                </Select>
            </FormInput>
            <Box>
                {selectedMethodExists && value && (
                    <Box color="red" fontSize="sm" fontWeight="semibold">
                        You already have a pending payout request for this
                        method
                    </Box>
                )}
            </Box>
            <TransferFee method={selection} />
        </Flex>
    );
});

export default PayoutMethodSelector;
