import { StoreOrder } from "models/store";
import { useAuthenticatedRequest } from "./useRequests";
import { useQuery } from "@tanstack/react-query";

const useCustomerOrder = (orderId: string | undefined) => {
    const get = useAuthenticatedRequest<StoreOrder>({
        method: "get",
        url: `/orders/${orderId}`
    });

    return useQuery(["customer", "orders", orderId], get, { enabled: !!orderId });
};

export default useCustomerOrder;