import { Box, Flex, Text, VStack } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { CustomerRoutes } from "appRoutePaths";
import LoadingSpinnerOverlay from "components/LoadingSpinnerOverlay";
import { usePublicRequestCreator } from "hooks/useRequests";
import { FC, useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

type ConfirmEmailParams = {
    token?: string;
    email?: string;
};

const useConfirmEmail = () => {
    const requestEmailConfirmation = usePublicRequestCreator<
        void,
        ConfirmEmailParams
    >((data) => ({
        url: "/account/confirm-email",
        method: "POST",
        data,
    }));

    return useMutation(requestEmailConfirmation);
};

const ConfirmationState = (props: { isSuccess: boolean, isIdle: boolean, isLoading: boolean }) => {
    if (props.isIdle || props.isLoading) {
        return <p>Confirming your email address...</p>;
    }

    if (!props.isSuccess) {
        return <p>There was a problem confirming your email address</p>;
    }

    return <>
        <p>
            Your email has been confirmed!
        </p>
        <p>
            You will be redirected to your <Text
                as={Link}
                to={CustomerRoutes.dashboard.url}
                color="blue"
            >
                dashboard
            </Text> in 10 seconds.
        </p>
    </>;
}

const ConfirmEmailPage: FC = () => {
    // Get the token and email from the query string
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token") as string | undefined;
    const email = searchParams.get("email") as string | undefined;
    const navigate = useNavigate();

    const { mutateAsync: confirmEmail, isLoading, isSuccess, isIdle } = useConfirmEmail();

    useEffect(() => {
        if (token && email) {
            confirmEmail({ token, email });
        }
    }, [confirmEmail, email, token]);

    useEffect(() => {
        if (isSuccess) {
            setTimeout(function () {
                navigate(CustomerRoutes.dashboard.url, { replace: true });
            }, 10000);
        }
    }, [navigate, isSuccess]);

    return <>
        <LoadingSpinnerOverlay isLoading={isLoading} />
        <Flex
            minHeight="50vh"
            height="100%"
            w="100%"
            color="darkGray"
            m={0}
            p={0}
        >
            <Flex flex={1} justifyContent="center">
                <VStack
                    align="start"
                    minWidth="30vw"
                    pt="7vh"
                    px="2rem"
                    maxWidth="30rem"
                    spacing={4}
                >
                    <Flex flexDir="column" pb="3rem">
                        <VStack spacing="2">
                            <Box fontSize="3xl">Email Confirmation</Box>
                            <ConfirmationState
                                isLoading={isLoading}
                                isIdle={isIdle}
                                isSuccess={isSuccess}
                            />
                        </VStack>
                    </Flex>
                </VStack>
            </Flex>
        </Flex>
    </>;
}

export default ConfirmEmailPage;