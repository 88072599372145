import { Modal } from "antd";
import { useState } from "react";
import { useCustomerImpersonation } from "hooks/useLogin";
import { ApplicationUser } from "models/applicationUsers";
import "styles/impersonate_modal.css";
import CustomerSelector from "./CustomerSelector";

export const ImpersonateModal = (props: {
    isVisible: boolean;
    onClose: () => void;
}) => {
    const [selectedImpersonationUser, selectImpersonationUser] = useState<
        ApplicationUser | undefined
    >();
    const { impersonate } = useCustomerImpersonation();

    const onImpersonationUserChange = async (
        customer: ApplicationUser | undefined
    ) => {
        if (customer) {
            selectImpersonationUser(customer);
        }
    };

    const clearImpersonationUser = () => {
        selectImpersonationUser(undefined);
    };

    const cancelImpersonationModal = () => {
        clearImpersonationUser();
        props.onClose();
    };

    const confirmImpersonation = () => {
        if (selectedImpersonationUser) {
            impersonate(selectedImpersonationUser.email);
        }
    };

    return (
        <Modal
            className="impersonate-modal"
            title="Impersonate"
            open={props.isVisible}
            onCancel={cancelImpersonationModal}
            onOk={confirmImpersonation}
            okText="Impersonate"
            destroyOnClose
        >
            <p>Select user to impersonate:</p>
            <CustomerSelector
                autoFocus={true}
                onSelected={onImpersonationUserChange}
                onClear={clearImpersonationUser}
            />
        </Modal>
    );
};
