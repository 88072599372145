import FilterTag from "components/FilterTag";
import { ApplicationUser } from "models/applicationUsers";
import { ClearTableFilterRequest } from "models/common";
import { PackageAmount, PackageStatus, PackageType } from "models/packages";
import { toDisplayName } from "util/helpers";

export interface StaffPackagesFilterDetail {
    status?: PackageStatus | undefined;
    customer?: ApplicationUser | undefined;
    type?: PackageType | undefined;
    amount?: PackageAmount | undefined;
    friendlyId?: string | undefined;
}

const StaffPackagesFilter = (props: { detail: StaffPackagesFilterDetail }) => {
    const { status, customer, type, amount, friendlyId } = props.detail;

    const displayName = toDisplayName(customer);

    return (
        <div id="staff-card-active-filters">
            {status && (
                <FilterTag
                    filter={ClearTableFilterRequest.Status}
                    text={`Status: ${PackageStatus[status]}`}
                />
            )}
            {friendlyId && (
                <FilterTag
                    filter={ClearTableFilterRequest.FriendlyId}
                    text={`Package ID: ${friendlyId}`}
                />
            )}
            {customer && (
                <FilterTag
                    filter={ClearTableFilterRequest.Customer}
                    text={`Customer: ${displayName}`}
                />
            )}
            {type && (
                <FilterTag
                    filter={ClearTableFilterRequest.Type}
                    text={`Type: ${type.internalName}`}
                />
            )}
            {amount && (
                <FilterTag
                    filter={ClearTableFilterRequest.Amount}
                    text={`Amount: ${PackageAmount[amount]}`}
                />
            )}
        </div>
    );
};

export default StaffPackagesFilter;
