import { Box, chakra, Flex, Stack } from "@chakra-ui/react";
import useWaitTimes from "hooks/useWaitTimes";
import { PackageLeadTime } from "models/common";
import moment from "moment-timezone";

export const WaitTimes = () => {
    const { isLoading, data } = useWaitTimes();

    if (isLoading) {
        return <></>;
    }

    const withText = (value: number, businessDays: boolean) => {
        return businessDays
            ? `${value} business day${value === 1 ? '' : 's'}`
            : `${value} day${value === 1 ? '' : 's'}`;
    }

    const displayWaitTime = (waitTime: PackageLeadTime) => {
        const { displayName, waitTimeInDays, businessDays, recurring } = waitTime;

        return recurring !== null
            ? `${displayName} every ${moment().day(recurring!).format('dddd')}`
            : `${displayName}: ${withText(waitTimeInDays, businessDays)}`
    }

    return (
        <Stack
            fontSize="24px"
            direction={{ base: "column", md: "row" }}
            spacing={{
                base: "0",
                md: "2",
            }}
            align={{
                base: "baseline",
                md: "center",
            }}
        >
            <Box fontWeight="bold">Current Wait Times:</Box>
            {data?.map((wt, idx) => (
                <Box as="div" key={idx} display='flex'>
                    <Box>{displayWaitTime(wt)}</Box>
                    {idx !== (data.length - 1) && <Box px={3}
                        display={{
                            base: "none",
                            md: "block",
                        }}
                    >
                        |
                    </Box>}
                </Box>
            ))}
        </Stack>
    );
};

export const WaitTimesBanner = chakra(() => (
    <Flex
        w="100%"
        justify="center"
        align="center"
        color="white"
        py="1"
        minHeight="3rem"
    >
        <WaitTimes />
    </Flex>
));
