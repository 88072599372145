import { useMemo } from "react";
import { useLocation } from "react-router";

const useQueryParams = () => {
    const loc = useLocation();

    return useMemo(() => {
        const search = loc.search;
        const urlSearchParams = new URLSearchParams(search);
        const params = Object.fromEntries(urlSearchParams.entries());
        return params
    }, [loc]);
}

export default useQueryParams;