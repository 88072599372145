import { Box, Flex, HStack, VStack } from "@chakra-ui/react";
import { Button, Form, Input, Select } from "antd";
import { StaffRoutes } from "appRoutePaths";
import CustomerSelector from "components/CustomerSelector";
import { ApplicationUser } from "models/applicationUsers";
import { PackageAmount, PackageType } from "models/packages";
import { useState } from "react";
import { Link } from "react-router-dom";
import packageAmountOptions from "./packageAmountOptions";
import "styles/staff/new_package.css";
import useAvailablePackageTypes from "hooks/useAvailablePackageTypes";
import { PackageTypeOption } from "models/common";

const { Option } = Select;

export interface NewPackageFormData {
    customer: ApplicationUser;
    amount: PackageAmount;
    packageType: PackageType;
}

const NewPackageForm = (props: {
    disabled: boolean;
    onSubmit: (form: NewPackageFormData) => void;
}) => {
    const [form] = Form.useForm<NewPackageFormData>();
    const [currentPkgType, setCurrentPkgType] = useState<
        PackageType | undefined
    >();
    const { data: types } = useAvailablePackageTypes();

    const onPackageTypeChange = (_: string, option: PackageTypeOption | PackageTypeOption[]) => {
        const opt = option as PackageTypeOption;
        setCurrentPkgType(opt.packageType);
        form.setFieldsValue({
            packageType: opt.packageType,
            amount: opt.packageType.usesAmounts
                ? undefined
                : PackageAmount.NotApplicable,
        });
    };

    const filteredPackageAmounts = currentPkgType?.usesAmounts
        ? packageAmountOptions.filter(o => o.value !== PackageAmount.NotApplicable)
        : packageAmountOptions.filter(o => o.value === PackageAmount.NotApplicable);

    const filteredAmountOptions = filteredPackageAmounts.map((o) => (
        <Option key={o.value} value={o.value}>
            {o.label}
        </Option>
    ));

    return (
        <Form
            id="new-pkg"
            form={form}
            disabled={props.disabled}
            onFinish={props.onSubmit}
            layout="vertical"
        >
            <VStack align="start" minW="30rem" maxW="35rem" w="100%">
                <Box
                    as={Form.Item}
                    minWidth="30rem"
                    label="Customer Name"
                    name="customer"
                    rules={[
                        {
                            required: true,
                            message: "You must select a customer!",
                        },
                    ]}
                >
                    <CustomerSelector
                        onSelected={(customer) => {
                            form.setFieldsValue({ customer });
                        }}
                        onClear={() => {
                            form.setFieldsValue({ customer: undefined });
                        }}
                    />
                </Box>
                <Flex w="100%" flexDirection={{ base: "column", lg: "row" }}>
                    <Box
                        flex={1}
                        pr={6}
                        as={Form.Item}
                        label="Package Type"
                        rules={[
                            {
                                required: true,
                                message: "Package type is required!",
                            },
                        ]}
                        name="type"
                    >
                        <Select
                            disabled={types?.length === 0}
                            onChange={onPackageTypeChange}
                            options={types?.map(t =>
                                ({ label: t.internalName, value: t.id, packageType: t }))
                            } />
                    </Box>
                    <Box hidden as={Form.Item} name="packageType"><Input hidden /></Box>
                    <Box
                        flex={1}
                        as={Form.Item}
                        label="Package Amount"
                        name="amount"
                        rules={[
                            {
                                required: true,
                                message: "Package amount is required!",
                            },
                        ]}
                    >
                        <Select>{filteredAmountOptions}</Select>
                    </Box>
                </Flex>
                <HStack as={Form.Item} spacing={10} alignSelf="flex-end">
                    <Button size="large" type="link">
                        <Link to={StaffRoutes.packages.url}>Cancel</Link>
                    </Button>
                    <Button size="large" type="primary" htmlType="submit">
                        Create
                    </Button>
                </HStack>
            </VStack>
        </Form>
    );
};

export default NewPackageForm;
