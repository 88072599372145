import { LoadingOutlined } from "@ant-design/icons";
import { Flex, Text } from "@chakra-ui/react";
import { PublicRoutes } from "appRoutePaths";
import { Navigate } from "components/Navigate";
import { useIdentity } from "context/auth";
import { CssVariables } from "models/common";
import { useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { ZENDESK_BASE_URL } from "util/config";

const ExternalSupport = () => {
    const [params,] = useSearchParams();
    const { isLoggedIn } = useIdentity();
    const formRef = useRef<HTMLFormElement>(null);

    const jwt = params.get('jwt');
    const returnTo = params.get('return_to');

    useEffect(() => {
        formRef.current!.submit();
    }, []);

    if (!isLoggedIn || !jwt) {
        return <Navigate to={PublicRoutes.rootPath} replace />
    }

    return <Flex alignItems="center" justifyContent="center" m={70}>
        <Flex direction='column'>
            <Text fontSize="4xl" mb={5}>Redirecting you to Zendesk...</Text>
            <LoadingOutlined spin style={{ color: CssVariables.blue, fontSize: '40px' }} />
        </Flex>

        <form ref={formRef} action={`${ZENDESK_BASE_URL}/access/jwt`} method="post">
            <input type="hidden" name="jwt" value={jwt} />
            {returnTo && <input type="hidden" name="return_to" value={returnTo} />}
        </form>
    </Flex>;
};

export default ExternalSupport;