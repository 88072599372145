import KeyValueTableFilter from "components/KeyValueTableFilter";
import { ClearTableFilterRequest } from "models/common";
import { PackageAmount } from "models/packages";
import { ExtendedFilterDropdownProps } from "util/table";

const AmountFilter = ({
    prefixCls,
    visible,
    confirm,
    selectedKeys,
    setSelectedKeys,
    clearFilters,
    onFilter,
}: ExtendedFilterDropdownProps) => {

    const kvp: Record<string, number> = {}
    for (var i = 1; i <= 20; i++) {
        kvp[i.toString()] = (i + 2);
    }

    return <KeyValueTableFilter
        prefixCls={prefixCls}
        visible={visible}
        type='select'
        confirm={confirm}
        selectedKeys={selectedKeys}
        setSelectedKeys={setSelectedKeys}
        clearFilters={clearFilters}
        onFilter={onFilter}
        clearTableFilterRequest={ClearTableFilterRequest.Amount}
        keyValuePairs={{
            '1/4': PackageAmount.QuarterRow,
            '1/2': PackageAmount.HalfRow,
            ...kvp,
            '20+': PackageAmount.OverTwenty,
        }}
    />;
};

export default AmountFilter;