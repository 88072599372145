import { Button, Input, Skeleton, Typography } from "antd";
import SettingsHeader from "./SettingsHeader";
import { Box, Flex, HStack, Text, VStack } from "@chakra-ui/react";
import CustomerPayoutTable from "components/CustomerPayoutTable";
import TextArea from "antd/lib/input/TextArea";
import { CheckOutlined, DeleteFilled, PlusOutlined } from "@ant-design/icons";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { PayoutTable, PayoutTableRow } from "models/websiteSettings";
import useCustomerPayoutTable from "hooks/useCustomerPayoutTable";
import useWebsiteSettings from "hooks/useWebsiteSettings";
import { CssVariables } from "models/common";

const { Paragraph } = Typography;

const PayoutRowControl: FC<{
    row: PayoutTableRow,
    onChange: (r: PayoutTableRow) => void,
    onDelete: (r: PayoutTableRow) => void,
}> = ({ row, onChange, onDelete }) => {
    const onValueChange = (e: ChangeEvent<HTMLInputElement>) => {
        onChange({
            ...row,
            [e.target.name]: e.target.value
        });
    }

    const deleteRow = () => {
        onDelete(row);
    }

    return <HStack>
        <Input placeholder="Sale Price Range" style={{ width: '160px' }} value={row.salePrice} onChange={onValueChange} name='salePrice' />
        <Input placeholder="Payout Description" style={{ width: '300px' }} value={row.description} onChange={onValueChange} name='description' />
        <Button icon={<DeleteFilled />} danger onClick={deleteRow} />
    </HStack>
}

const CustomerPayoutSection = () => {
    const { data, isLoading, refetch } = useCustomerPayoutTable();
    const { updateCustomerPayoutTable } = useWebsiteSettings();
    const [updatedData, setUpdatedData] = useState<PayoutTable | undefined>();
    const [dirty, setDirty] = useState<boolean>(false);
    const [result, setResult] = useState<boolean>(false);

    useEffect(() => {
        if (data && !updatedData) {
            setUpdatedData({ ...data, rows: [...data.rows] });
        }
    }, [data, updatedData]);

    if (isLoading || !updatedData) {
        return <Skeleton />;
    }

    const save = async () => {
        await updateCustomerPayoutTable(updatedData);
        await refetch();
        setDirty(false);
        setResult(true);
    };

    const updateRows = (rows: PayoutTableRow[]) => {
        setUpdatedData({
            ...updatedData,
            rows: [...rows]
        });
        setDirty(true);
    }

    const onRowChange = (row: PayoutTableRow) => {
        const rows = updatedData.rows;
        const idx = updatedData.rows.findIndex(x => x.id === row.id);
        rows[idx] = row;

        updateRows(rows);
    }

    const addRow = () => {
        const rows = updatedData.rows;
        rows.push({
            id: crypto.randomUUID(),
            ordinal: Math.max(...rows.map(r => r.ordinal)) + 1,
            salePrice: '',
            description: ''
        });

        updateRows(rows);
    }

    const removeRow = (row: PayoutTableRow) => {
        const rows = updatedData.rows.filter(x => x.id !== row.id);

        updateRows(rows);
    }

    const onFooterChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setUpdatedData({
            ...updatedData,
            footer: e.target.value
        });
        setDirty(true);
    }

    const reset = () => {
        setUpdatedData(undefined);
        setDirty(false);
        setResult(false);
    }

    return <>
        <SettingsHeader title="Customer Payout Table" />
        <Paragraph>
            The payout table shown to customers in the 'How much will I make?' section of the 'How it works' page.
        </Paragraph>
        <HStack display='flex' w='100%' alignItems='start'>
            <Box w='40%' display='flex'>
                <VStack>
                    <HStack>
                        <Text style={{ width: '160px' }} fontWeight={600}>Sale Price</Text>
                        <Text style={{ width: '300px' }} fontWeight={600}>Payout Description</Text>
                    </HStack>

                    {updatedData.rows.map(r => <PayoutRowControl
                        key={r.id}
                        row={r}
                        onChange={onRowChange}
                        onDelete={removeRow} />)}

                    <TextArea placeholder="Footer" defaultValue={updatedData.footer} onChange={onFooterChange} />

                    <Flex justifyContent='space-between' w='100%'>
                        <Button icon={<PlusOutlined />} type="primary" onClick={addRow}>Add Row</Button>
                        <HStack>
                            {dirty && <Button onClick={reset}>Undo Changes</Button>}
                            {result && <CheckOutlined style={{ color: CssVariables.darkGreen }} />}
                            <Button type="primary" onClick={save} disabled={!dirty}>Save</Button>
                        </HStack>
                    </Flex>
                </VStack>
            </Box>
            <Box w='48%'>
                <CustomerPayoutTable tableData={updatedData} />
            </Box>
        </HStack>
    </>;
}

export default CustomerPayoutSection;