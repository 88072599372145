import { Ref, forwardRef } from "react";
import EntitySelector, { EntitySelectorProps, EntitySelectorRef } from "./EntitySelector";
import { PackageType } from "models/packages";
import { useAuthenticatedRequestCreator } from "hooks/useRequests";

const usePackageTypeSearch = () => {
    const search = useAuthenticatedRequestCreator<PackageType[], string>(
        term => ({
            method: "get",
            url: `/package-types/search?internalName=${term}`
        })
    );

    return { search };
}

const PackageTypeSelector = (
    props: EntitySelectorProps<PackageType>,
    ref: Ref<EntitySelectorRef>
) => {

    const { search } = usePackageTypeSearch();

    return (
        <EntitySelector
            ref={ref}
            {...props}
            placeholder="Package Type Internal Name"
            search={search}
            resultDisplay={pt => pt.internalName}
        />
    );
};

export default forwardRef<EntitySelectorRef, EntitySelectorProps<PackageType>>(
    PackageTypeSelector
);