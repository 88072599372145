import { Box, BoxProps, forwardRef } from "@chakra-ui/react";
import { Form, FormItemProps } from "antd";

export type FormInputProps = FormItemProps & BoxProps;

const FormInput = forwardRef<FormInputProps, typeof Box>(
    ({ children, rules, required = true, ...props }, ref) => (
        <Box
            as={Form.Item}
            ref={ref}
            rules={[
                ...(required
                    ? [
                          {
                              required: true,
                              message: `${
                                  props.label ?? props.name
                              } is required`,
                          },
                      ]
                    : []),
                ...(rules || []),
            ]}
            {...props}
        >
            {children}
        </Box>
    )
);

export default FormInput;
