import { useState } from "react";
import { RangeValue } from "rc-picker/lib/interface";
import useEbayJobFunctions from "hooks/useEbayJobFunctions";
import { Alert, Typography, DatePicker, Radio, Button } from "antd";
import SettingsHeader from "./SettingsHeader";
import { Box, VStack } from "@chakra-ui/react";
import { DisplayDateFormat } from "models/websiteSettings";
import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment";

const { Paragraph } = Typography;
const { RangePicker } = DatePicker;

const NoSelectedType = 0;
const NewAuctionType = 1;
const EndedAuctionType = 2;
const ModifiedOrdersType = 3;
const ActiveBidsType = 4;
const MaxDateRange = 86400000;

const EbayFunctionsSection = () => {
    const [range, setRange] = useState<RangeValue<moment.Moment>>();
    const [type, setType] = useState<number>(0);
    const [success, setSuccess] = useState<boolean>(false);
    const { mutateAsync, isLoading: working } = useEbayJobFunctions();

    const validRange = range
        ? (range[1]!.diff(range[0]!) < MaxDateRange)
        : true;

    const invalid = !range || !validRange || type === 0;

    const submit = async () => {
        setSuccess(false);
        const [from, to] = range!;

        await mutateAsync({
            from: from!.toISOString(),
            to: to!.toISOString(),
            type
        });
        setRange(undefined);
        setType(0);

        setSuccess(true);
    }

    let banner = null;
    if (!validRange) {
        banner = <Alert type="error" message="Cannot request a window of more than 24 hours" />;
    } else if (working) {
        banner = <Alert type="info" message="Submitting job process request..." icon={<LoadingOutlined />} showIcon />;
    } else if (success) {
        banner = <Alert type="success" message="Job processing successfully enqueued." />;
    }

    return <>
        <SettingsHeader title="eBay Functions" />
        <Paragraph>
            Query eBay and update customer data for auctions that have been created, ended or had orders for completed auctions
            <br />created or updated within a given date range. This will kick off a long-running process behind the scenes, but you
            <br />can only request a window of 24 hours or less.
        </Paragraph>
        <Box display='flex' alignItems='start'>
            <VStack>
                <RangePicker
                    style={{ width: '100%' }}
                    showTime={{ format: 'h:mm a' }}
                    format={`${DisplayDateFormat} LT`}
                    onChange={(r) => { setRange(r); setSuccess(false); }}
                    value={range}
                    disabled={working}
                />
                <Radio.Group
                    defaultValue={NoSelectedType}
                    value={type}
                    onChange={e => { setType(e.target.value); setSuccess(false); }}
                    disabled={working}>
                    <VStack display='flex' alignItems='start'>
                        <Radio value={NewAuctionType}>
                            New Auctions
                            <Box color='cyan.700'>Cards that are on eBay, but are not in the site</Box>
                        </Radio>
                        <Radio value={ActiveBidsType}>
                            Auction Bid Updates
                            <Box color='cyan.700'>Updates the active bids for cards placed during the given timeframe</Box>
                        </Radio>
                        <Radio value={EndedAuctionType}>
                            Ended Auctions
                            <Box color='cyan.700'>Cards that have completed eBay auctions, but are still marked as Active</Box>
                        </Radio>
                        <Radio value={ModifiedOrdersType}>
                            Item Status Updates
                            <Box color='cyan.700'>Cards that are Awaiting Payment, but have been paid for</Box>
                        </Radio>
                    </VStack>
                </Radio.Group>
                {banner}
                <Button type="primary"
                    style={{ width: '100%', marginTop: '20px' }}
                    disabled={invalid || working}
                    onClick={submit}>
                    Enqueue Processing Job
                </Button>
            </VStack>
        </Box>
    </>;
}

export default EbayFunctionsSection;