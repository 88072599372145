import { Box } from "@chakra-ui/react";
import { FC } from "react";

const MoreInfoContact: FC = () => {
    return (
        <Box fontSize="sm" opacity={0.5}>
            *If you need to provide us new account information for this payout,
            please contact us by text or voicemail at (804) 885-5001.
        </Box>
    );
};

export default MoreInfoContact;
