import { useQuery } from "@tanstack/react-query";
import { SupportTicket, SupportTicketComment } from "models/support";
import { useAuthenticatedRequest } from "./useRequests";

const useTicketComments = (ticket: SupportTicket) => {
    const { id } = ticket;

    const getComments = useAuthenticatedRequest<SupportTicketComment[]>({
        url: `/support/tickets/${id}/comments`,
        method: "GET",
    });

    return useQuery(["comments", id], getComments, {
        // NOTE: Because we use suspense, any use of this hook MUST be wrapped
        //  in a Suspense component that won't "resolve" until the query does
        suspense: true,

        // NOTE: Without this option, the query will be refetched every time a ticket is re-expanded
        staleTime: 60 * 1000,
    });
};

export default useTicketComments;
