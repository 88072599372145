import { Box, Stack, VStack } from "@chakra-ui/react";
import { CustomerRoutes } from "appRoutePaths";
import CallToActionBubble from "components/CallToActionBubble";
import { FC } from "react";
import { Link } from "react-router-dom";

import CardListingsTable from "./CardListingsTable";
import CurrentlyBeingProcessed from "./CurrentlyBeingProcess";
import LastThreePackages from "./LastThreePackages";

const CustomerDashboard: FC = () => {
    return (
        <VStack spacing={4} fontFamily="body" pb={50}>
            <Stack
                direction={{ base: "column", md: "row" }}
                spacing={{ base: 4, md: 10 }}
                w="100%"
                minHeight="13rem"
                bg="lightGray"
                rounded={5}
                p={4}
            >
                <CurrentlyBeingProcessed />
                <LastThreePackages />
            </Stack>
            <VStack
                w="100%"
                minHeight="5rem"
                bg="lightGray"
                rounded={5}
                p={4}
                align="baseline"
                spacing={4}
            >
                <CardListingsTable />
                <Box
                    as={Link}
                    display="block"
                    fontSize={14}
                    color="blue"
                    fontWeight="semibold"
                    textDecor="underline"
                    to={CustomerRoutes.cards.url}
                    pl={2}
                >
                    See All Cards
                </Box>
            </VStack>
            <CallToActionBubble
                message="Got Questions? We got Answers! Reach out any time."
                buttonText="Contact Us"
                buttonLink={CustomerRoutes.support.url}
            />
        </VStack>
    );
};

export default CustomerDashboard;
