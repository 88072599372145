import { StaffPackage } from "models/packages";
import { useAuthenticatedRequestCreator } from "./useRequests";

const usePackagesForCustomer = () => {
    const getPackagesForCustomer = useAuthenticatedRequestCreator<StaffPackage[], string>(customerId => ({
        method: "get",
        url: `/packages/customer/${customerId}`
    }));

    return { getPackagesForCustomer };
};

export default usePackagesForCustomer;