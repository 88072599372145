import { useMutation } from "@tanstack/react-query";
import { useAuthenticatedRequestCreator } from "hooks/useRequests";
import { AuctionCategory, CardTitle, GraderRegEx } from "models/cardIngestion";
import { toTitleCase } from "util/helpers";

interface AssignedCardTitle {
    cardId: string;
    title: string;
    category: string;
    subCategory: string;
    conditionId?: string;
    conditionName?: string;
    gradeId?: string;
    gradeName?: string;
    gradingCompanyId?: string;
    gradingCompanyName?: string;
    statusId?: string;
    statusName?: string;
    notes?: string | null;
    isLot: boolean,
    packageId: string | null;
}

interface DoNotUploadCard {
    cardId: string;
    reason: string;
}

export const generateAssignedCardTitle = (data: CardTitle, category: AuctionCategory): AssignedCardTitle => {
    const condition = category.ungradedConditions.find(c => c.id === data.conditionId);
    const grader = category.graders.find(g => g.id === data.gradingCompanyId);
    const grade = grader?.grades.find(g => g.id === data.gradeId);
    const status = category.itemConditions.find(c => c.id === data.statusId);

    const normalizedCardTitle = toTitleCase(data.title).replace(GraderRegEx, x => x.toUpperCase());

    return {
        cardId: data.id,
        title: normalizedCardTitle,
        isLot: data.isLot,
        category: data.category,
        subCategory: data.subCategory,
        conditionId: condition?.id,
        conditionName: condition?.name,
        gradingCompanyId: grader?.id,
        gradingCompanyName: grader?.name,
        gradeId: grade?.id,
        gradeName: grade?.name,
        statusId: status?.id,
        statusName: status?.name,
        notes: data.notes,
        packageId: data.packageId
    };
}

const useAssignedCardTitle = () => {
    const assign = useAuthenticatedRequestCreator<CardTitle | null, AssignedCardTitle>(
        (payload) => ({
            method: "post",
            url: "/card-titling/assign-title",
            data: payload
        })
    );

    const dnu = useAuthenticatedRequestCreator<CardTitle | null, DoNotUploadCard>(
        (payload) => ({
            method: "post",
            url: "/card-titling/do-not-upload",
            data: payload
        })
    )

    const acu = useAuthenticatedRequestCreator<CardTitle, string>(
        (cardId) => ({
            method: "post",
            url: "/card-titling/allow-card-upload",
            data: { cardId }
        })
    )

    const { mutateAsync: assignCardTitle, isLoading: isAssigning } = useMutation(assign);
    const { mutateAsync: doNotUpload, isLoading: isFlagging } = useMutation(dnu);
    const { mutateAsync: allowUpload, isLoading: isAllowing } = useMutation(acu);

    return {
        assignCardTitle,
        doNotUpload,
        allowUpload,
        isSaving: isAssigning || isFlagging || isAllowing
    };
};

export default useAssignedCardTitle;