import {
    AuditOutlined,
    FormOutlined,
    InfoCircleOutlined,
} from "@ant-design/icons";
import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
} from "@chakra-ui/react";
import { Steps } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useSteps } from "chakra-ui-steps";
import { CreatePayoutRequestPayload } from "models/payoutRequests";
import { FC, useState } from "react";

import PayoutDetailsStep1 from "./Steps/PayoutStep1";
import PayoutConfirmStep2 from "./Steps/PayoutStep2";
import PayoutResultsStep3 from "./Steps/PayoutStep3";

const { Step } = Steps;

export type RequestPayoutModalProps = {
    visible: boolean;
    onClose: () => void;
};

const RequestPayoutModal: FC<RequestPayoutModalProps> = (props) => {
    // We create a form instance to pass down to step 1 to control its form
    //  AND to ensure that the form instance lives for the entire lifetime of the modal
    const [form] = useForm();

    const { nextStep, prevStep, reset, activeStep } = useSteps({
        initialStep: 0,
    });

    const resetForm = () => {
        setPayoutRequest(undefined);
        form.resetFields();
        reset();
    };

    const onClose = () => {
        props.onClose();
        resetForm();
    };

    // The payout request payload we build in step 1 and pass around to other steps
    const [payoutRequest, setPayoutRequest] = useState<
        CreatePayoutRequestPayload | undefined
    >(undefined);
    const finishStep1 = async (payoutRequest: CreatePayoutRequestPayload) => {
        setPayoutRequest(payoutRequest);
        nextStep();
    };

    return (
        <Modal isOpen={props.visible} onClose={onClose}>
            <ModalOverlay />
            <ModalContent
                minWidth={{ base: "unset", md: "700px" }}
                minH="400px"
            >
                <ModalHeader fontSize={30} fontWeight="bold">
                    Request Payout
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Steps
                        size="small"
                        current={activeStep}
                        direction="horizontal"
                        responsive={false}
                    >
                        <Step icon={<FormOutlined />} />
                        <Step icon={<AuditOutlined />} />
                        <Step icon={<InfoCircleOutlined />} />
                    </Steps>
                </ModalBody>
                <PayoutDetailsStep1
                    active={activeStep === 0}
                    form={form}
                    onClose={onClose}
                    onNextStep={finishStep1}
                />
                <PayoutConfirmStep2
                    active={activeStep === 1}
                    payoutRequest={payoutRequest}
                    onBack={prevStep}
                    onNext={nextStep}
                />
                <PayoutResultsStep3 active={activeStep === 2} />
            </ModalContent>
        </Modal>
    );
};

export default RequestPayoutModal;
