import { CloseOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { Box, Flex, HStack, Text } from "@chakra-ui/react";
import { Badge, Button, Divider, InputNumber, Modal } from 'antd';
import { valueType } from 'antd/lib/statistic/utils';
import { CustomerRoutes } from 'appRoutePaths';
import useShoppingCart from 'hooks/useShoppingCart';
import { CssVariables } from 'models/common';
import { CartItem, CartStockCheck } from 'models/store';
import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { centsToDollars } from 'util/helpers';

type CartItemUpdater = (item: CartItem, adjustment: number) => void;

interface CartModalProps {
    cart: CartItem[];
    loading: boolean;
    updateItem: CartItemUpdater;
}

const StockMessage: FC<{ stockCheck?: CartStockCheck }> = ({ stockCheck }) => {
    if (!stockCheck) {
        return null;
    }

    const { ok, actualQuantity } = stockCheck;

    if (ok) {
        return null;
    }

    return <Text color={CssVariables.errorRed} fontStyle="italic" fontWeight="600">
        {actualQuantity === 0
            ? "Sorry, this item is no longer in stock!"
            : `Only ${actualQuantity} left in stock!`}
    </Text>;
}

const CartLineItem: FC<{
    item: CartItem,
    updater: CartItemUpdater,
    stockCheck?: CartStockCheck
}> = ({ item, updater, stockCheck }) => {
    const stepQuantity = (_: number, info: {
        offset: valueType;
        type: 'up' | 'down';
    }) => {
        const { offset, type } = info;
        const value = Number(offset);

        if (type === 'up') {
            updater(item, value);
        } else if (type === 'down') {
            updater(item, -value);
        }
    };

    const adjustQuantity = (value: number | null) => {
        if (value) {
            const diff = value - item.quantity;
            updater(item, diff);
        }
    }

    const removeFromCart = () => {
        updater(item, -item.quantity);
    }

    return <Flex alignItems='center' mb='16px'>
        <Box>
            <Text fontWeight='bold'>{item.itemName}</Text>
            <Text as="p">{item.variationName} <Divider type='vertical' />{centsToDollars(item.priceInCents)}</Text>
            <StockMessage stockCheck={stockCheck} />
        </Box>
        <Box ml="auto">
            <HStack>
                <InputNumber size="large" value={item.quantity} min={1} onStep={stepQuantity} onChange={adjustQuantity} />
                <Button danger shape="circle" icon={<CloseOutlined />} size="small" onClick={removeFromCart} />
            </HStack>
            <Text as="p" m='0 8px' fontSize='16px' fontWeight='600' align='right' mr='30px'>
                {centsToDollars(item.totalInCents)}
            </Text>
        </Box>
    </Flex>;
}

const CartModal: React.FC<CartModalProps> = ({ cart, loading, updateItem }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const { stockCheck } = useShoppingCart();
    const [working, setWorking] = useState<boolean>(false);
    const [stockChecks, setStockChecks] = useState<CartStockCheck[] | undefined>();
    const navigate = useNavigate();

    const sortedCart = [...cart].sort((a, b) => {
        if (a.itemName < b.itemName) return -1;
        if (a.itemName > b.itemName) return 1;
        if (a.variationName < b.variationName) return -1;
        if (a.variationName > b.variationName) return 1;
        return 0;
    });

    const close = () => {
        setIsOpen(false);
        setStockChecks(undefined);
    }

    const getTotal = (): string => {
        const total = sortedCart.reduce(
            (accumulator, item) => accumulator + item.totalInCents, 0
        );

        return centsToDollars(total);
    };

    const handleCheckout = async () => {
        try {
            setWorking(true);
            const { data } = await stockCheck();

            if (data?.every(x => x.ok)) {
                navigate(CustomerRoutes.checkout.url);
            } else {
                setStockChecks(data);
            }
        } finally {
            setWorking(false);
        }
    }

    return <>
        <Button type="link" onClick={() => setIsOpen(true)}>
            <Badge count={cart.length} color='blue'>
                <ShoppingCartOutlined style={{ fontSize: '30px', marginRight: '8px', filter: 'invert(1)' }} />
            </Badge>
        </Button>
        <Modal
            title="Cart"
            open={isOpen}
            onCancel={close}
            footer={null}
            closable
            keyboard
            maskClosable
            destroyOnClose>
            <Box _disabled={{ pointerEvents: 'none', opacity: 0.5 }}>
                {sortedCart.length > 0 ? (
                    <>
                        {sortedCart.map((item) => <CartLineItem
                            key={item.id}
                            item={item}
                            updater={updateItem}
                            stockCheck={stockChecks?.find(c => c.catalogId === item.catalogId)} />)}
                    </>
                ) : (
                    <Text fontSize={18} fontWeight='600'>Your cart is empty.</Text>
                )}
                <Divider />
                {sortedCart.length > 0 && <Flex justifyContent='space-between' alignItems='center' mt='16px'>
                    <Text fontFamily="body" fontWeight="bold" fontSize={18}>Total: {getTotal()}</Text>
                    <Button
                        type="primary"
                        size="large"
                        loading={loading || working}
                        onClick={handleCheckout}
                    >
                        Checkout
                    </Button>
                </Flex>}
            </Box>
        </Modal>
    </>;
};

export default CartModal;
