import { Button, Modal } from "antd";
import { StoreOrder } from "models/store";
import { FC, useState } from "react";
import useOrderFulfillment from "hooks/useOrderFulfillment";
import OrderFulfillmentDetail from "./OrderFulfillmentDetail";

const OrderFulfillmentModal: FC<{ order: StoreOrder, onDataChanged: () => void }> = ({ order, onDataChanged }) => {
    const { fulfill, isLoading, errors } = useOrderFulfillment();
    const [visible, setVisible] = useState<boolean>(false);
    const [requireRefresh, setRequireRefresh] = useState<boolean>(false);

    const touch = () => setRequireRefresh(true);

    const close = () => {
        if (requireRefresh) {
            // for some reason, passing through onDataChanged will
            // auto-close the modal, probably something to do with
            // the actual function refreshing the table. Instead, invoke
            // the data changed function when the modal is closed manually
            onDataChanged();
        }
        setVisible(false);
    }

    return <>
        <Button type="link" onClick={() => setVisible(true)}>
            #{order.friendlyId}
        </Button>
        <Modal
            title={`Order #${order.friendlyId}`}
            width={600}
            style={{ top: 30 }}
            bodyStyle={{ marginTop: 0, paddingTop: 0 }}
            closable={!isLoading}
            keyboard={!isLoading}
            maskClosable={false}
            destroyOnClose
            open={visible}
            onCancel={close}
            footer={null}>
            <OrderFulfillmentDetail
                order={order}
                fulfill={fulfill}
                isLoading={isLoading}
                errors={errors}
                onFulfillSuccess={touch}
                onAddressUpdated={touch}
            />
        </Modal>
    </>;
}

export default OrderFulfillmentModal;