import { Button } from "@chakra-ui/react";
import useZendeskSSO from "hooks/useZendeskSSO";
import { FC, useState } from "react";
import { ZendeskAPI } from "react-zendesk";
import { ZENDESK_BASE_URL } from "util/config";

const OpenSupportChatButton: FC = () => {
    const makeZendeskLink = useZendeskSSO();

    const openZendeskLink = async () => {
        const zendeskLink = await makeZendeskLink(
            `${ZENDESK_BASE_URL}/hc/en-us/requests/new`
        );
        window.open(zendeskLink, "_blank");
    };

    // If the user is clicking the button over and over then either:
    //  - The chat widget isn't open
    //  - The chat widget is open but the user doesn't realize or doesn't want to use it
    // In either case, open the Zendesk New Ticket link in a new tab
    const [rageClickCount, setRageClickCount] = useState(0);
    const handleClick = () => {
        if (rageClickCount === 2) {
            openZendeskLink();
            setRageClickCount(0);
        }
        setRageClickCount((old) => old + 1);

        ZendeskAPI("webWidget", "open");
    };

    return (
        <Button variant="primary" onClick={handleClick}>
            Contact Support
        </Button>
    );
};

export default OpenSupportChatButton;
