import { ShipmentAddress } from "models/shipping"

const ShippingAddress = (props: { address: ShipmentAddress }) => {
    const {
        addressLine1,
        addressLine2,
        city,
        state,
        zipCode,
        country
    } = props.address;

    if (!addressLine2) {
        return <address>
            {addressLine1}<br />
            {city}, {state} {zipCode}, {country}
        </address>;
    }

    return <address>
        {addressLine1}<br />
        {addressLine2}<br />
        {city}, {state} {zipCode}, {country}
    </address>;
}

export default ShippingAddress;