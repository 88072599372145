import { useMutation } from "@tanstack/react-query";
import { useAuthenticatedRequestCreator } from "hooks/useRequests";
import { ZendeskTicketRequest, SupportTicket } from "models/support";

const useZendeskTicket = () => {
    const sendTicket = useAuthenticatedRequestCreator<
        SupportTicket,
        ZendeskTicketRequest
    >((data) => ({
        url: "/support/ticket",
        method: "POST",
        data,
    }));

    return useMutation(sendTicket);
};

export default useZendeskTicket;
