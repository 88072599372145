import { Box } from "@chakra-ui/react";
import { Alert, Button, Modal, Skeleton } from "antd";
import useUnsoldCardBatchCreation from "hooks/useUnsoldCardBatchCreation";
import { UnsoldCardBatchCreationResult } from "models/unsoldCards";
import { useEffect, useState } from "react";

const UnsoldBatchCreationModal = () => {
    const [visible, setVisible] = useState<boolean>(false);
    const [working, setWorking] = useState<boolean>(false);
    const [result, setResult] = useState<UnsoldCardBatchCreationResult | undefined>();
    const [pending, setPending] = useState<UnsoldCardBatchCreationResult | undefined>();
    const { createBatches } = useUnsoldCardBatchCreation();

    useEffect(() => {
        if (!pending) {
            setWorking(true);

            createBatches(true).then(result => {
                setPending(result);
            }).finally(() => {
                setWorking(false);
            });
        }
    }, [setWorking, createBatches, pending]);

    const onOpen = () => {
        setVisible(true);
    }

    const close = () => setVisible(false);

    const enqueue = async () => {
        setWorking(true);
        const result = await createBatches(false);
        setWorking(false);
        setResult(result);
    }

    const { unsoldCardCount } = pending || {};

    return <>
        <Button type="link" size="large" onClick={onOpen}>Create Unsold Card Batches</Button>
        <Modal
            title="Create Unsold Card Batches"
            closable={false}
            keyboard={true}
            maskClosable={true}
            destroyOnClose={true}
            open={visible}
            footer={result !== undefined ? (
                <Button key='cancel' type="default" onClick={close} disabled={working}>Close</Button>
            ) : ([
                <Button key='ok' type="primary" onClick={enqueue} disabled={working || unsoldCardCount === 0}>Create</Button>,
                <Button key='cancel' type="default" onClick={close} disabled={working}>Cancel</Button>
            ])}
        >
            {(working && !pending) ? (
                <Skeleton />
            ) : (
                <>
                    <Box mb={5}>
                        This will create unsold card batches, and may take a second or two.
                    </Box>
                    <Box mb={5}>
                        There are currently {unsoldCardCount === 0 ? "no cards" : `${unsoldCardCount} card(s)`} ready to be batched up.
                    </Box>
                </>)
            }
            {working && pending && <Alert
                className="bold-font"
                message="Creating unsold card batches..."
                type="info"
                showIcon
            />}
            {result && <Alert
                className="bold-font"
                message={`Placed ${result.unsoldCardCount} unsold cards into ${result.unsoldCardBatchCount} batches`}
                type="success"
                showIcon
            />}
        </Modal>
    </>
}

export default UnsoldBatchCreationModal;