import {
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
} from "@chakra-ui/react";
import BasicLoadingSpinner from "components/BasicLoadingSpinner";
import { SupportTicket } from "models/support";
import { FC, Suspense } from "react";

import TicketComments from "./TicketComments";
import TicketHeader from "./TicketHeader";

const SupportTicketRow: FC<{ ticket: SupportTicket, unread: boolean }> = ({ ticket, unread }) => {
    return (
        <AccordionItem>
            {({ isExpanded }) => (
                <>
                    <AccordionButton
                        display="flex"
                        flex="1"
                        textAlign="left"
                        alignItems="center"
                        justifyContent="space-between"
                        p={3}
                    >
                        <TicketHeader ticket={ticket} isExpanded={isExpanded} unread={unread} />
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                        <Suspense fallback={<BasicLoadingSpinner />}>
                            {isExpanded ? ( // Only load the comments if the ticket is expanded, to lazily load the comments
                                <TicketComments ticket={ticket} unread={unread} />
                            ) : null}
                        </Suspense>
                    </AccordionPanel>
                </>
            )}
        </AccordionItem>
    );
};

export default SupportTicketRow;
