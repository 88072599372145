import { Box } from "@chakra-ui/react";
import BasicLoadingSpinner from "components/BasicLoadingSpinner";
import SupportTickets from "components/customer/Support/SupportTickets";
import { FC, Suspense } from "react";

const SupportTicketsPage: FC = () => {
    return (
        <Box
            p={25}
            w="100%"
            minHeight="75vh"
            maxW={{ base: "100%", lg: "80rem" }}
            color="var(--dcs-black)"
        >
            <Suspense fallback={<BasicLoadingSpinner />}>
                <SupportTickets />
            </Suspense>
        </Box>
    );
};

export default SupportTicketsPage;
