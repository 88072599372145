import { Box } from "@chakra-ui/react";
import { ChaseACHPayoutMethodDetails } from "models/payoutRequests";
import { FC } from "react";

import { Alert } from "antd";
import { useGetPayoutFees } from "hooks/usePayoutFees";
import useTreasuryAccounts from "hooks/useTreasuryAccounts";
import { ConfirmationDetailsProps } from ".";
import SummaryDetail from "./SummaryDetail";

const ACHConfirmation: FC<ConfirmationDetailsProps> = ({ payoutRequest }) => {
    const details = payoutRequest?.details as ChaseACHPayoutMethodDetails;
    const { data: accounts } = useTreasuryAccounts(payoutRequest?.customerId);
    const { data: fees } = useGetPayoutFees();

    const account = (accounts || []).find(a => a.accountId === details.externalAccountId);
    const subjectToApproval = fees && (payoutRequest?.amountInCents ?? 0) >= fees.achApprovalThresholdInCents;

    return (
        <Box fontSize={18}>
            <Box fontSize={26} pb={3} fontWeight="bold">
                ACH Confirmation
            </Box>
            <SummaryDetail style={{ paddingBottom: '10px' }}>
                {account?.organization}
            </SummaryDetail>
            <SummaryDetail label="Account Number">
                {account?.accountNumber}
            </SummaryDetail>
            <SummaryDetail label="Routing Number">
                {account?.routingNumber}
            </SummaryDetail>

            {subjectToApproval && <Alert
                showIcon
                type="info"
                description="Based on the amount, this request will be subject to admin review during processing."
                style={{ marginTop: '20px' }}
            />}
        </Box>
    );
};

export default ACHConfirmation;
