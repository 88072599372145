import { PrinterFilled } from "@ant-design/icons";
import { Text } from "@chakra-ui/react";
import { CssVariables } from "models/common";

const PrintButton = () => {
    return <Text as="div" pr={5} fontSize={30} cursor='pointer' onClick={window.print}>
        <PrinterFilled style={{ color: CssVariables.blue }} />
    </Text>;
}

export default PrintButton;