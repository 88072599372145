import { useQuery } from "@tanstack/react-query";
import { PagedResult, PageRequest } from "models/common";
import { ShipperCard } from "models/shipping";
import { pageRequestToUrlSearchParams } from "util/helpers";
import { useAuthenticatedRequest } from "./useRequests";

const makeAxiosRequest = (shipmentId: string, pageRequest: PageRequest) => {
    const options = pageRequestToUrlSearchParams(pageRequest);

    return {
        method: "get",
        url: `/shipping/shipper/cards/${shipmentId}?${options}`
    };
}

const useShipmentCards = (
    shipmentId: string,
    pageRequest: PageRequest
) => {
    const getCards = useAuthenticatedRequest<PagedResult<ShipperCard>>(
        makeAxiosRequest(shipmentId, pageRequest)
    );

    return useQuery(["shipper-cards", shipmentId, pageRequest], getCards);
}

export default useShipmentCards;