import { Button } from "@chakra-ui/button";
import { Text, VStack } from "@chakra-ui/react";
import { Col, Row } from "antd";
import { PublicRoutes } from "appRoutePaths";
import CustomerPayoutTable from "components/CustomerPayoutTable";
import { useNavigate } from "react-router-dom";

const HowMuchWillIMakeText = () => {
    const navigate = useNavigate();

    return (
        <VStack
            align="baseline"
            mr={{ base: 0, md: "40px" }}
            spacing={4}
            maxW={{
                base: "full",
                md: "40vw",
            }}
        >
            <Text as="h1" fontSize="40" color="var(--dcs-white)" minW="40rem">
                How much will I make?
            </Text>
            <Text
                fontSize="18px"
                color="var(--dcs-white)"
                maxW="30rem"
                align="start"
            >
                This chart shows a breakdown for each individual sale. Please
                note that each individual card is subject to the advertised
                rates and we do not offer rates per package or shipment.
            </Text>
            <Button
                variant="primary"
                onClick={() => navigate(PublicRoutes.signUp.url)}
            >
                Get Started Now
            </Button>
        </VStack>
    );
};

export const HowMuchWillMake = () => {
    return (
        <Row style={{ padding: '30px' }} gutter={30}>
            <Col>
                <HowMuchWillIMakeText />
            </Col>
            <Col>
                <CustomerPayoutTable />
            </Col>
        </Row>
    );
};
