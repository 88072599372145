import { PackageType } from "models/packages";
import { useAuthenticatedRequest } from "./useRequests";
import { useQuery } from "@tanstack/react-query";
import { PageRequest, PagedResult } from "models/common";
import { pageRequestToUrlSearchParams } from "util/helpers";

const makeAxiosRequest = (pageRequest: PageRequest) => {
    const options = pageRequestToUrlSearchParams(pageRequest);

    return {
        url: `/package-types/all?${options}`,
        method: "GET",
    };
};

const useAllPackageTypes = (pageRequest: PageRequest) => {
    const getTypes = useAuthenticatedRequest<PagedResult<PackageType>>(
        makeAxiosRequest(pageRequest)
    );

    return useQuery(["package-types", "all", pageRequest], getTypes);
};

export default useAllPackageTypes;