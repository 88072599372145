import { Text, VStack } from "@chakra-ui/react";
import { ShipperShipment, ShipmentAddress } from "models/shipping";
import { FC } from "react";
import ShippingAddress from "./ShippingAddress";

const Destination: FC<{ shipment: ShipperShipment }> = ({ shipment }) => {
    const shipmentAddress: ShipmentAddress = shipment;

    return <VStack alignItems='start'>
        <Text fontWeight='bold'>Ship To</Text>
        <Text>{shipmentAddress.recipientName}</Text>
        <ShippingAddress address={shipmentAddress} />
    </VStack>;
}

export default Destination;