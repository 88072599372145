import { Button, Stack, Text } from "@chakra-ui/react";
import { PublicRoutes } from "appRoutePaths";
import { useNavigate } from "react-router-dom";

export const MoreQuestions = () => {
    const navigate = useNavigate();

    return (
        <Stack
            direction={{
                base: "column",
                md: "row",
            }}
            bg="var(--dcs-bright-blue)"
            py="1rem"
            pl={{
                base: "4rem",
                md: "8rem",
            }}
            pr={{
                base: "4rem",
                md: "unset",
            }}
            spacing={{
                base: "0.5rem",
                md: "3rem",
            }}
            align="center"
            textAlign="center"
            width="100%"
        >
            <Text as="h1" fontSize="2rem" color="var(--dcs-white)">
                Have more questions? We have answers!
            </Text>
            <Button
                variant="primary"
                fontSize="md"
                backgroundColor="var(--dcs-white)"
                color="var(--dcs-black)"
                fontWeight="semibold"
                maxWidth="12rem"
                onClick={() => navigate(PublicRoutes.faq.url)}
            >
                Visit FAQ Page
            </Button>
        </Stack>
    );
};
