import { Box, VStack } from "@chakra-ui/react";
import useTicketComments from "hooks/useTicketComments";
import { SupportTicket } from "models/support";
import { FC } from "react";

import TicketComment from "./TicketComment";
import useMarkTicketAsRead from "hooks/useMarkTicketAsRead";

const TicketComments: FC<{ ticket: SupportTicket, unread: boolean }> = ({ ticket, unread }) => {
    const { data: comments, isError, isLoading } = useTicketComments(ticket);
    const { markAsRead } = useMarkTicketAsRead();

    if (!isLoading && !isError && unread) {
        markAsRead(ticket.id);
    }

    return isError ? (
        <Box fontSize={24} color="error">
            Failed to load comments
        </Box>
    ) : (
        <VStack px={4} spacing={0} w="100%" h="100%" minH="10rem" align="start">
            {comments?.map((comment, i) => (
                <TicketComment
                    key={comment.id}
                    comment={comment}
                    isTop={i === 0}
                />
            ))}
        </VStack>
    );
};

export default TicketComments;
