import { PlusOutlined } from "@ant-design/icons";
import { Box, HStack } from "@chakra-ui/react";
import { Button, DatePicker, Form, Input, Modal, Select, Switch } from "antd";
import { DisplayDateFormat } from "models/websiteSettings";
import moment from "moment";
import { FC, useState } from "react";
import { dollarsToCents } from "util/helpers";
import { Kind, bannerOrdinalValidator, feeValidator, toNullableNumber } from "./helpers";
import usePackageTypeUpdate from "hooks/usePackageTypeUpdate";
import { DayOfWeek } from "models/common";

interface NewPackageTypeForm {
    displayName: string;
    internalName: string;
    usesAmounts: boolean;
    leadTimeInDays: number | null;
    estUploadDate: moment.Moment | null;
    eventDayOfWeek: DayOfWeek | null;
    processingFee: string | null;
    processingFeeInCents: number;
    bannerOrdinal: number | null;
}

const NewModal: FC<{
    onSuccess: () => Promise<any>
}> = ({ onSuccess }) => {
    const { createPackageType } = usePackageTypeUpdate();
    const [visible, setVisible] = useState<boolean>(false);
    const [kind, setKind] = useState<Kind>(Kind.OneTimeEvent);
    const [working, setWorking] = useState<boolean>(false);
    const [form] = Form.useForm<NewPackageTypeForm>();

    const reset = () => {
        form.resetFields();
        setKind(1);
        setVisible(false);
    }

    const submit = () => {
        form.validateFields().then(async (data) => {
            setWorking(true);

            try {
                await createPackageType({
                    ...data,
                    estimatedUploadDate: data.estUploadDate
                        ? data.estUploadDate.toISOString()
                        : null,
                    eventDayOfWeek: (!data.leadTimeInDays && !data.estUploadDate)
                        ? data.eventDayOfWeek
                        : null
                });
                await onSuccess();
                setVisible(false);
            } finally {
                setWorking(false);
            }
        });
    }

    return <>
        <Button icon={<PlusOutlined />} type="primary" title="Add New Package Type" onClick={() => setVisible(true)} />
        <Modal
            open={visible}
            destroyOnClose
            closable={false}
            keyboard={false}
            maskClosable={false}
            onCancel={reset}
            onOk={submit}
            confirmLoading={working}
            cancelButtonProps={{ disabled: working }}
            okText={working ? "Saving..." : "Create"}
            title="Create New Package Type"
        >
            <Form
                form={form}
                preserve={false}
                layout="vertical"
                initialValues={{ isEvent: true, usesAmounts: true, kind: Kind.OneTimeEvent, eventDayOfWeek: DayOfWeek.Sunday }}
            >
                <HStack display='flex'>
                    <Form.Item
                        label="Internal Name"
                        name="internalName"
                        rules={[{ required: true, message: "Internal Name is required!" }]}
                        style={{ marginBottom: 0, width: '50%' }}>
                        <Input placeholder="Shown to dcsports87 staff" />
                    </Form.Item>

                    <Form.Item
                        label="Display Name"
                        name="displayName"
                        rules={[{ required: true, message: "Display Name is required!" }]}
                        style={{ width: '50%' }}>
                        <Input placeholder="Shown to customers" />
                    </Form.Item>
                </HStack>

                <HStack display='flex' mt={5} mb={5}>
                    <Box display='flex' w='50%' justifyContent='start' alignItems='center' m='15px 0'>
                        <label style={{ marginRight: '10px' }}>Uses Package Amounts?</label>
                        <Form.Item
                            name="usesAmounts"
                            style={{ marginBottom: 0 }}
                            valuePropName="checked">
                            <Switch
                                checkedChildren="Yes"
                                unCheckedChildren="No" />
                        </Form.Item>
                    </Box>
                    <Box display='flex' w='50%'>
                        <Form.Item
                            label="What kind of package type is this?"
                            name="kind"
                            style={{ width: '100%' }}
                            rules={[{ required: true, message: "This is required!" }]}>
                            <Select
                                onChange={setKind}
                                options={[
                                    { label: 'It\'s based on a lead time', value: Kind.LeadTime },
                                    { label: 'It\'s for a one-time event', value: Kind.OneTimeEvent },
                                    { label: 'It\'s a recurring event', value: Kind.WeeklyEvent },
                                ]}
                            />
                        </Form.Item>
                    </Box>
                </HStack>

                <HStack display='flex'>
                    <Form.Item
                        label="Banner Order #"
                        name="bannerOrdinal"
                        style={{ marginBottom: 0, width: '33%' }}
                        rules={[{ transform: toNullableNumber, validator: (r, v) => bannerOrdinalValidator(form, r, v) }]}>
                        <Input placeholder="Not Shown" type="number" />
                    </Form.Item>

                    <Form.Item
                        label="Processing Fee"
                        name="processingFee"
                        style={{ width: '33%' }}
                        rules={[{ transform: dollarsToCents, validator: (r, v) => feeValidator(form, r, v) }]}>
                        <Input prefix="$" placeholder="No Fee" />
                    </Form.Item>

                    {kind === Kind.LeadTime && <Form.Item
                        label="Lead Time in Days"
                        name="leadTimeInDays"
                        style={{ width: '33%' }}
                        rules={[{ required: true, message: "Lead Time is required!" }]}>
                        <Input type="number" />
                    </Form.Item>}

                    {kind === Kind.OneTimeEvent && <Form.Item
                        label="Est. Upload Date"
                        name="estUploadDate"
                        style={{ width: '33%' }}
                        rules={[{ required: true, message: "Date is required!" }]}>
                        <DatePicker
                            format={DisplayDateFormat}
                            disabledDate={(date: moment.Moment) => date.isBefore(moment())} />
                    </Form.Item>}

                    {kind === Kind.WeeklyEvent && <Form.Item
                        label="Event Starts On"
                        name="eventDayOfWeek"
                        style={{ width: '33%' }}
                        rules={[{ required: true, message: "Day is required!" }]}>
                        <Select
                            options={[
                                { label: 'Sundays', value: DayOfWeek.Sunday },
                                { label: 'Mondays', value: DayOfWeek.Monday },
                                { label: 'Tuesdays', value: DayOfWeek.Tuesday },
                                { label: 'Wednesdays', value: DayOfWeek.Wednesday },
                                { label: 'Thursdays', value: DayOfWeek.Thursday },
                                { label: 'Fridays', value: DayOfWeek.Friday },
                                { label: 'Saturdays', value: DayOfWeek.Saturday },
                            ]}
                        />
                    </Form.Item>}
                </HStack>
                <Form.Item
                    hidden
                    name="processingFeeInCents">
                    <Input />
                </Form.Item>
            </Form>
        </Modal >
    </>;
};

export default NewModal;