import { Tag } from "antd";
import { publishMessage } from "messages/messageBus";
import { ClearTableFilterRequest } from "models/common";

const sendClearFilterRequest = (filter: ClearTableFilterRequest) => {
    publishMessage(ClearTableFilterRequest.MessageType, filter);
};

const FilterTag = (props: {
    filter: ClearTableFilterRequest;
    text: string;
}) => {
    return (
        <Tag
            closable
            color="blue"
            onClose={() => sendClearFilterRequest(props.filter)}
        >
            {props.text}
        </Tag>
    );
};

export default FilterTag;
