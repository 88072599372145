import { useQuery } from "@tanstack/react-query";
import { PagedResult, PageRequest } from "models/common";
import { Transaction } from "models/transactions";
import { pageRequestToUrlSearchParams } from "util/helpers";

import { useAuthenticatedRequest } from "./useRequests";

const makeAxiosRequest = (accountId: string, pageRequest: PageRequest) => {
    const options = pageRequestToUrlSearchParams(pageRequest);

    return {
        method: "get",
        url: `/transactions/${accountId}?${options}`,
    };
};

export const useAccountTransactionsCSV = (
    accountId: string,
    pageRequest: Omit<PageRequest, "page">
) => {
    // `as const` makes the exact type we have here, instead of the more general inferred type
    // (specifically, the 'csv' stays in the type, instead of being generalized to 'string')
    const pageRequestWithCSV = {
        ...pageRequest,
        page: 1, // ignored
        format: "csv",
    } as const;
    const axiosRequest = makeAxiosRequest(accountId, pageRequestWithCSV);

    return useAuthenticatedRequest<string>(axiosRequest);
};

const useAccountTransactions = (
    accountId: string,
    pageRequest: PageRequest
) => {
    const axiosRequest = makeAxiosRequest(accountId, pageRequest);

    const accountRequest =
        useAuthenticatedRequest<PagedResult<Transaction>>(axiosRequest);

    return useQuery<PagedResult<Transaction>>(
        [accountId, "transactions", pageRequest],
        accountRequest
    );
};

export default useAccountTransactions;
