import { Box, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import useCustomerDashboard from "hooks/useCustomerDashboard";
import { FC } from "react";

const CardListingsTable: FC = () => {
    const { data } = useCustomerDashboard();
    const latestCards = data?.latestCards ?? [];

    return (
        <>
            <Box fontSize={22} pl={2} fontWeight="semibold" color="var(--dcs-black)">
                Current Card Listings
            </Box>
            <Table
                bg="white"
                colorScheme="var(--dcs-bright-blue)"
                color="black"
                fontFamily="body"
            >
                <Thead bg="var(--dcs-bright-blue)">
                    <Tr>
                        <Th fontWeight="normal" textTransform="capitalize" color="var(--dcs-white)">
                            Name
                        </Th>
                        <Th fontWeight="normal" textTransform="capitalize" color="var(--dcs-white)">
                            Listing Date
                        </Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {latestCards.map((listing) => (
                        <Tr key={listing.cardId}>
                            <Td>{listing.title}</Td>
                            <Td>
                                {new Date(
                                    listing.listingDate
                                ).toLocaleDateString()}
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </>
    );
};

export default CardListingsTable;
