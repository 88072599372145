import { useQuery } from "@tanstack/react-query";
import { Testimonial } from "models/testimonials";
import { testimonialsCacheKey } from "./useStaffTestimonials";
import { usePublicRequest } from './useRequests';

const TESTIMONIAL_ROOT = "/testimonials";

const useLiveTestimonials = () => {
    const queryKey = [testimonialsCacheKey];
    const getLiveTestimonials = usePublicRequest<Testimonial[] | undefined>({
        method: "get",
        url: `${TESTIMONIAL_ROOT}/live`,
    });

    const { isLoading, data: testimonials } = useQuery(
        queryKey,
        getLiveTestimonials,
    );

    return { isLoading, testimonials: testimonials ?? [] };
};

export default useLiveTestimonials;
