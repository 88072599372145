import {
    useQuery,
} from "@tanstack/react-query";
import { ShipmentCardWindow } from "models/shipping";
import { useAuthenticatedRequest } from "./useRequests";

const makeAxiosRequest = (shipmentBatchId: string, cardId: string | undefined | null) => {
    return {
        method: "get",
        url: cardId
            ? `/shipping/puller/navigate/${shipmentBatchId}/${cardId}`
            : `/shipping/puller/navigate/${shipmentBatchId}`
    };
};

const useShipmentCardNavigation = (shipmentBatchId: string, cardId: string | undefined | null) => {
    const navigateToShipmentCard = useAuthenticatedRequest<ShipmentCardWindow>(
        makeAxiosRequest(shipmentBatchId, cardId)
    );

    return useQuery(["shipments", shipmentBatchId, cardId], navigateToShipmentCard);
};

export default useShipmentCardNavigation;