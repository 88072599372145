import { usePublicRequest } from "./useRequests";
import { useQuery } from "@tanstack/react-query";
import { PackageLeadTime } from "models/common";

const useWaitTimes = () => {
    const get = usePublicRequest<PackageLeadTime[]>({
        url: '/website-settings/wait-times',
        method: "GET",
    });

    return useQuery(["website-settings", "wait-times"], get);
};

export default useWaitTimes;