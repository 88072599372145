import { SimpleGrid } from "@chakra-ui/react";
import { StaffRoutes } from "appRoutePaths";
import useAdminDashboard from "hooks/useAdminDashboard";
import { centsToDollars } from "util/helpers";
import AdminDashboardCard from "./AdminDashboardCard";

const useAdminDashboardData = () => {
    const data = useAdminDashboard();

    const {
        pendingPackages,
        pendingPayouts,
        activeCardsAuctions,
        newUserCount,
        openSupportTickets,
        totalCustomerBalanceInCents,
        pendingDropboxUploads,
    } = data;

    const pendingPackagesValue = `${pendingPackages.toLocaleString()} Packages`;
    const pendingPayoutsValue = `${pendingPayouts?.toLocaleString()} Payout requests`;
    const activeCardsAuctionsValue = `${activeCardsAuctions.toLocaleString()}`;
    const newUserCountValue = `${newUserCount.toLocaleString()} New users this week`;
    const openSupportTicketsValue = `${openSupportTickets?.toLocaleString()} Open tickets`;
    const pendingDropboxUploadsValue = `${pendingDropboxUploads.toLocaleString()} Images`;
    const totalCustomerBalanceValue = centsToDollars(
        totalCustomerBalanceInCents
    );

    return {
        pendingPackagesValue,
        pendingPayoutsValue,
        activeCardsAuctionsValue,
        newUserCountValue,
        openSupportTicketsValue,
        totalCustomerBalanceValue,
        pendingDropboxUploadsValue
    };
};

const AdminDashboardData = (props: { isAdmin: boolean }) => {
    const {
        pendingPackagesValue,
        pendingPayoutsValue,
        activeCardsAuctionsValue,
        newUserCountValue,
        openSupportTicketsValue,
        totalCustomerBalanceValue,
        pendingDropboxUploadsValue
    } = useAdminDashboardData();

    return (
        <SimpleGrid columns={2} spacing={6} pb={6}>
            <AdminDashboardCard
                title="Packages to Upload"
                subtitle="Number of packages being processed"
                value={pendingPackagesValue}
                linkText="Go to Packages Page"
                linkTo={StaffRoutes.packages.url}
            />
            <AdminDashboardCard
                title="Pending Payouts"
                subtitle="Number of currently requested payouts"
                value={pendingPayoutsValue}
                linkText="Go to Payouts Page"
                linkTo={StaffRoutes.payouts.url}
                showLink={props.isAdmin}
            />
            <AdminDashboardCard
                title="Cards"
                subtitle="Active number of cards auctions on eBay"
                value={activeCardsAuctionsValue}
                linkText="Go to Cards Page"
                linkTo={StaffRoutes.cards.url}
                showLink={props.isAdmin}
            />
            <AdminDashboardCard
                title="Users"
                subtitle="Latest user information"
                value={newUserCountValue}
                linkText="Go to Users Page"
                linkTo={StaffRoutes.users.url}
                showLink={props.isAdmin}
            />
            <AdminDashboardCard
                title="Support Tickets"
                subtitle="Tickets that are unresolved on ZenDesk"
                value={openSupportTicketsValue}
                linkText="Visit ZenDesk"
                linkTo="https://dcsports87.zendesk.com/access/normal"
                external={true}
                showLink={props.isAdmin}
            />
            <AdminDashboardCard
                title="Total Sum of All Customer Balances"
                subtitle="Amount owed if everyone cashed out today"
                value={totalCustomerBalanceValue}
                linkText="Visit Financial Summary Page"
                linkTo={StaffRoutes.financialSummary.url}
                showLink={props.isAdmin}
            />
            <AdminDashboardCard
                title="Failed Dropbox Uploads"
                subtitle="Retry attempts will continue until they succeed"
                value={pendingDropboxUploadsValue}
                linkTo=""
                linkText=""
            />
        </SimpleGrid>
    );
};

export default AdminDashboardData;
