import { ShipmentBatchLabel } from "models/shipping";
import { useAuthenticatedRequestCreator } from "./useRequests";

const useSeenShipmentLabel = () => {
    const seenRequest = useAuthenticatedRequestCreator<boolean, string>(
        (id) => ({
            method: "post",
            url: `/shipping/labels/${id}/seen`,
        })
    );

    const seenLabel = async (label: ShipmentBatchLabel): Promise<boolean> => {
        await seenRequest(label.id);
        return true;
    }

    return { seenLabel };
}

export default useSeenShipmentLabel;