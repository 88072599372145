import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Box, Flex, VStack } from "@chakra-ui/react";
import { useIdentity } from "context/auth";
import useZendeskSSO from "hooks/useZendeskSSO";
import parseHTML from "html-react-parser";
import { SupportTicketComment } from "models/support";
import { FC } from "react";
import { ZENDESK_BASE_URL } from "util/config";

const CommentDate: FC<{ comment: SupportTicketComment }> = ({ comment }) => {
    const createdAt = new Date(comment.createdAt);
    const createdAtDate = createdAt.toLocaleDateString();
    const createdAtTime = createdAt.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
    });

    return (
        <Box color="gray" fontSize="1em" fontWeight="semibold">
            <Box as="span" pr="0.5em">
                {createdAtTime}
            </Box>
            <Box as="span" opacity={0.75}>
                {createdAtDate}
            </Box>
        </Box>
    );
};

const TicketComment: FC<{ comment: SupportTicketComment; isTop: boolean }> = ({
    comment,
    isTop,
}) => {
    const { zendeskUserId } = useIdentity();
    const { authorName, htmlBody } = comment;

    const isAuthor = zendeskUserId === "" + comment.authorId;

    const makeZendeskLink = useZendeskSSO();
    const openZendeskComment = async () => {
        const commentUrl = `${ZENDESK_BASE_URL}/hc/en-us/requests/${comment.requestId}`;
        const zendeskLink = await makeZendeskLink(commentUrl);
        window.open(zendeskLink, "_blank");
    };

    return (
        <VStack
            px={2}
            py={2}
            w="100%"
            align="start"
            spacing={3}
            fontSize="14"
            borderBottom="1px solid lightGray"
        >
            <Flex alignItems="start" justifyContent="space-between" w="100%">
                <VStack align="start" spacing={0}>
                    <CommentDate comment={comment} />
                    <Box
                        fontWeight="semibold"
                        fontSize="1.5em"
                        lineHeight="1em"
                    >
                        {authorName}
                        {isAuthor && (
                            <Box
                                fontSize={16}
                                pl=".5em"
                                as="span"
                                fontWeight="normal"
                            >
                                (you)
                            </Box>
                        )}
                    </Box>
                </VStack>
                {isTop && (
                    <Box pl={2}>
                        <ExternalLinkIcon
                            fontSize={22}
                            color="lightBlue"
                            onClick={openZendeskComment}
                            cursor="pointer"
                            _hover={{ filter: "brightness(1.2)" }}
                        />
                    </Box>
                )}
            </Flex>
            <Box>{parseHTML(htmlBody)}</Box>
        </VStack>
    );
};

export default TicketComment;
