import { useQuery } from "@tanstack/react-query";
import { useAuthenticatedRequest } from "hooks/useRequests";
import { CardTitle } from "models/cardIngestion";

const useNextPendingCard = (cardFolderId: string) => {
    const get = useAuthenticatedRequest<CardTitle>({
        method: "get",
        url: `/card-titling/folders/${cardFolderId}/next-title`
    });

    return useQuery(["card-titling", "folders", cardFolderId, "nextTitle"], get, {
        suspense: true
    });
};

export default useNextPendingCard;