import { Stack, StackProps } from "@chakra-ui/react";
import { forwardRef } from "@chakra-ui/system";

export type DisclosureStackProps = StackProps & {
    isOpen: boolean;
};

export const DisclosureStack = forwardRef<DisclosureStackProps, typeof Stack>(
    ({ isOpen, children, maxHeight, maxH, ...props }, ref) => {
        const maxHeightProp = isOpen
            ? maxH ?? maxHeight ?? "min(80vh, 40rem)"
            : "0";

        return (
            <Stack
                pos="absolute"
                spacing={0}
                top={"100%"}
                right={0}
                height="auto"
                maxHeight={maxHeightProp}
                overflowY={isOpen ? "auto" : "hidden"}
                boxShadow="-1px 3px 6px rgba(0, 0, 0, 0.3)"
                transition="max-height 0.2s ease-in-out"
                zIndex={1000}
                bg="darkGray"
                width={{
                    base: "100vw",
                    md: "unset",
                }}
                color="white"
                ref={ref}
                {...props}
            >
                {children}
            </Stack>
        );
    }
);

export default DisclosureStack;
