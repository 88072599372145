import { useQuery } from "@tanstack/react-query";
import { PagedResult, PageRequest } from "models/common";
import { CustomerPackage } from "models/packages";
import { pageRequestToUrlSearchParams } from "util/helpers";

import { useAuthenticatedRequest } from "./useRequests";

const makeAxiosRequest = (accountId: string, pageRequest: PageRequest) => {
    const options = pageRequestToUrlSearchParams(pageRequest);

    return {
        method: "get",
        url: `/packages/${accountId}?${options}`,
    };
};

export const usePackagesCSV = (
    accountId: string,
    pageRequest: Omit<PageRequest, "page">
) => {
    const pageRequestWithCSV = {
        ...pageRequest,
        page: 1, // ignored
        format: "csv",
    } as const;
    const axiosRequest = makeAxiosRequest(accountId, pageRequestWithCSV);

    return useAuthenticatedRequest<string>(axiosRequest);
};

const usePackages = (accountId: string, pageRequest: PageRequest) => {
    const getPackages = useAuthenticatedRequest<PagedResult<CustomerPackage>>(
        makeAxiosRequest(accountId, pageRequest)
    );

    return useQuery([accountId, "packages", pageRequest], getPackages);
};

export default usePackages;
