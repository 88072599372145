import { Box, Text } from "@chakra-ui/react";
import { Divider, List } from "antd";
import { StoreOrder } from "models/store";
import { FC } from "react";
import { centsToDollars } from "util/helpers";

const OrderPreview: FC<StoreOrder> = ({
    lineItems,
    subTotalInCents,
    shippingInCents,
    taxesInCents,
    totalInCents
}) => {
    const charges = [
        { label: "Subtotal", value: subTotalInCents },
        { label: "Shipping", value: shippingInCents },
        { label: "Taxes", value: taxesInCents }
    ];
    const total = [{ label: "Total", value: totalInCents }];
    return <Box bg="whitesmoke" borderRadius={20}>
        <List
            style={{ paddingTop: '20px' }}
            size="small"
            split={false}
            dataSource={lineItems}
            renderItem={item => (
                <List.Item>
                    <List.Item.Meta
                        title={<Text fontFamily='body' fontWeight={500}>{item.itemName}</Text>}
                        description={`${item.variationName} (${item.quantity} x ${centsToDollars(item.priceInCents)})`}
                    />
                    <Text fontWeight={500} fontSize='lg'>{centsToDollars(item.totalInCents)}</Text>
                </List.Item>
            )}
        />
        <Divider />
        <List
            size="small"
            split={false}
            dataSource={charges}
            renderItem={item => (
                <List.Item>
                    <List.Item.Meta
                        title={<Text fontFamily='body' fontWeight={800}>{item.label}</Text>}
                    />
                    <Text fontWeight={500} fontSize='lg'>{centsToDollars(item.value)}</Text>
                </List.Item>
            )}
        />
        <Divider />
        <List
            size="small"
            split={false}
            dataSource={total}
            renderItem={item => (
                <List.Item>
                    <List.Item.Meta
                        title={<Text fontFamily='body' fontWeight={800} fontSize='xl'>{item.label}</Text>}
                    />
                    <Text fontWeight={500} fontSize='lg'>{centsToDollars(item.value)}</Text>
                </List.Item>
            )}
        />
    </Box>;
};

export default OrderPreview;