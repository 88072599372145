import { BellOutlined } from "@ant-design/icons";
import { Box, HStack, Image, VStack } from "@chakra-ui/react";
import { Badge } from "antd";
import { FC, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { SideMenuEntry, SubMenuEntry } from "./sideMenuEntries";

const TopLevelMenuItem: FC<{ item: SideMenuEntry; isActive: boolean }> = ({
    item,
    isActive,
}) => {
    const { name, url, icon, external, count } = item;

    return (
        <HStack
            as={external ? "a" : Link}
            width="100%"
            spacing={4}
            bg={isActive ? "blue" : "var(--dcs-dark-blue)"}
            lineHeight="64px"
            fontSize="14px"
            {...(external ? { href: url, target: "_blank" } : { to: url })}
        >
            <Box w="5px" height="100%" bg={isActive ? "blue" : "transparent"} />
            <Box
                h="1rem"
                as={Image}
                src={icon?.default}
                style={{ filter: 'invert(1)' }}
            />
            <Box
                w="100%"
                _hover={{ color: "var(--dcs-light-blue)" }}
                fontFamily="Archivo"
                color={{ base: "var(--dcs-white)" }}
                fontWeight={isActive ? "bold" : "normal"}
            >
                {name}
            </Box>
            {count && count > 0 && (
                <Badge
                    count={count}
                    overflowCount={99}
                    style={{ marginRight: "15px" }}
                >
                    <BellOutlined
                        style={{
                            fontSize: "24px",
                            marginRight: "15px",
                            color: "gray",
                        }}
                    />
                </Badge>
            )}
        </HStack>
    );
};

// TODO: Spruce up the styling of the sub menu items
// They need to feel more distinct from the top level menu items, and be clearer that they're sub menu items
// Maybe some kind of indenting, or a different background color? An icon that shows they're sub menu items?
const SubMenuItem: FC<{ item: SubMenuEntry }> = ({ item }) => {
    const { name, url } = item;

    const location = useLocation();
    const isActive = useMemo(
        () => location.pathname.startsWith(url),
        [location, url]
    );

    return (
        <HStack
            as={Link}
            width="100%"
            to={url}
            bg={isActive ? "white" : "lightGray"}
            lineHeight="64px"
            fontSize="14px"
        >
            <Box
                w="20px"
                height="100%"
                bg={isActive ? "blue" : "transparent"}
            />
            <Box
                pl="4rem"
                _hover={{ color: "blue" }}
                fontFamily="Archivo"
                color={isActive ? "blue" : "black"}
                fontWeight={isActive ? "bold" : "normal"}
            >
                {name}
            </Box>
        </HStack>
    );
};

const SubMenu: FC<{ item: SideMenuEntry; isActive: boolean }> = ({
    item,
    isActive,
}) => {
    const numChildren = item.children?.length ?? 0;
    return (
        <VStack
            spacing={0}
            w="100%"
            maxH={isActive ? `${5 * numChildren}rem` : 0}
            overflow="hidden"
            transition="max-height 0.2s ease-in-out"
        >
            {item.children?.map((child) => (
                <SubMenuItem key={child.name} item={child} />
            ))}
        </VStack>
    );
};

const SideMenuItem: FC<{ item: SideMenuEntry }> = ({ item }) => {
    const isActive = item.isActive ?? false;

    return (
        <VStack spacing={0} w="100%">
            <TopLevelMenuItem item={item} isActive={isActive} />
            <SubMenu item={item} isActive={isActive} />
        </VStack>
    );
};

export default SideMenuItem;
