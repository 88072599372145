import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { Modal } from 'antd';
import CatalogItemDetails from 'components/Shop/CatalogItemDetails';
import useShopCatalog from "hooks/useShopCatalog";
import useShoppingCart, { AddToCartRequest } from 'hooks/useShoppingCart';
import { CartItem, CatalogItem } from 'models/store';
import React, { useEffect, useState } from 'react';
import { centsToDollars } from 'util/helpers';
import CartModal from "../../components/Shop/Cart/CartModal";

const ShopPage: React.FC = () => {
    const {
        fetchCart: {
            data: cartItemsData,
            refetch: cartItemsRefetch
        },
        addToCart,
        updateCart
    } = useShoppingCart();
    const [cart, setCart] = useState<CartItem[]>(cartItemsData ?? []);
    const [isCardDetailsModalOpen, setIsCardDetailsModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState<CatalogItem | undefined>();
    const { data: shopCatalogData } = useShopCatalog();
    const items = shopCatalogData ?? [];
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setCart(cartItemsData ?? []);
    }, [cartItemsData]);

    const addItemToCart = async (req: AddToCartRequest) => {
        await addToCart(req);

        await cartItemsRefetch();
    }

    const handleCardClick = async (item: CatalogItem) => {
        setSelectedItem(item);
        setIsCardDetailsModalOpen(true);
    };

    const updateItem = (item: CartItem, adjustment: number) => {
        const updatedQuantity = item.quantity + adjustment;

        handleQuantityUpdate(item, updatedQuantity);
    };


    const handleQuantityUpdate = async (item: CartItem, updatedQuantity: number) => {
        try {
            setLoading(true);

            const requestBody = {
                cartItemId: item.id,
                quantity: updatedQuantity,
            };

            await updateCart(requestBody);

            await cartItemsRefetch();
        } finally {
            setLoading(false);
        }
    };

    return (
        <Flex direction='column' minH='100vh' p='24px'>
            <Flex justifyContent='space-between' alignItems='center' mb='24px'>
                <Box h="100%" fontSize={36} textAlign="center">
                    Shop
                </Box>
                <CartModal
                    cart={cart}
                    loading={loading}
                    updateItem={updateItem} />
            </Flex>
            <Box display='grid' gridTemplateColumns='repeat(auto-fill, minmax(300px, 1fr))' gap='24px'>
                {items.map((item) => (
                    <Flex
                        key={item.id}
                        onClick={() => handleCardClick(item)}
                        flexDirection='column'
                        alignItems='center'
                        boxShadow='0 0 4px rgba(0, 0, 0, 0.1)'
                        p='16px'
                        borderRadius='4px'
                        cursor='pointer'
                        transition='background-color 0.3s ease'
                        _hover={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
                    >
                        <Box maxW={300} maxH={300}>
                            <Image
                                src={item.images?.[0] ?? 'https://via.placeholder.com/300'}
                                alt={item.name}
                                boxSize="100%"
                                objectFit="cover"
                            />
                        </Box>
                        <Text fontSize={20} fontWeight='600'>{item.name}</Text>
                        <Text as="p" whiteSpace='nowrap' fontWeight='600'>
                            {item.hasPriceRange
                                ? `${centsToDollars(item.minPriceInCents)} - ${centsToDollars(item.maxPriceInCents)}`
                                : `${centsToDollars(item.maxPriceInCents)}`}
                        </Text>
                    </Flex>
                ))
                }
            </Box >

            <Modal
                title={selectedItem?.name}
                open={isCardDetailsModalOpen}
                onCancel={() => setIsCardDetailsModalOpen(false)}
                footer={null}
                closable
                keyboard
                maskClosable
                destroyOnClose>
                {selectedItem && <CatalogItemDetails itemId={selectedItem.id} addToCart={addItemToCart} onClose={() => setIsCardDetailsModalOpen(false)} />}
            </Modal>
        </Flex >
    );
};

export default ShopPage;
