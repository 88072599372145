import {
    Box,
    Button,
    HStack,
    VStack,
} from "@chakra-ui/react";
import useSupportTickets from "hooks/useSupportTickets";
import { FC } from "react";
import OpenSupportChatButton from "./OpenSupportChatButton";

import OpenExternalSupport from "./OpenExternalSupportLink";
import SupportTicketsList from "./SupportTicketsList";
import useCustomerUnreadTicketIds from "hooks/useCustomerUnreadTickets";
import { BellFilled } from "@ant-design/icons";
import useMarkTicketAsRead from "hooks/useMarkTicketAsRead";

// This component would be particularly messy to decompose the buttons out of, unfortunately.
// It's not that gross of a file, so I'm going to leave it as-is for now.
const SupportTickets: FC = () => {
    const { data: tickets, isError } = useSupportTickets();
    const { data: unread } = useCustomerUnreadTicketIds();
    const { markAllAsRead } = useMarkTicketAsRead();

    return isError || !tickets ? (
        <Box fontSize={24} color="error" fontWeight="semibold">
            Failed to load tickets
        </Box>
    ) : (
        <VStack align="start" w="100%" spacing={6}>
            <HStack w="100%" justifyContent="space-between">
                <OpenExternalSupport />
                <OpenSupportChatButton />
            </HStack>
            <HStack w="100%" justifyContent="space-between">
                {unread && unread.ticketIds.length > 0 && <Button onClick={markAllAsRead} bg='darkred'>
                    <BellFilled /> Mark All As Read
                </Button>}
            </HStack>
            <SupportTicketsList tickets={tickets} unreadIds={unread?.ticketIds} />
        </VStack>
    );
};

export default SupportTickets;
