import { Text } from "@chakra-ui/react";
import { Table, Tbody, Td, Tfoot, Th, Thead, Tr } from "@chakra-ui/table";
import { Skeleton } from "antd";
import useCustomerPayoutTable from "hooks/useCustomerPayoutTable";
import { PayoutTable } from "models/websiteSettings";
import { FC } from "react";

const CustomerPayoutTable: FC<{ tableData?: PayoutTable }> = ({
    tableData,
}) => {
    const { data, isLoading } = useCustomerPayoutTable(tableData);

    if (isLoading) {
        return <Skeleton />;
    }

    return (
        <Table
            bg="lightGray"
            maxW={{
                base: "full",
                md: "25vw",
            }}
            color="black"
            whiteSpace='nowrap'
        >
            {
                // TODO: Consider making a th variant
                //  (or a whole table variant with automatic descendents like th)
            }
            <Thead color="blue">
                <Tr>
                    <Th
                        fontFamily="body"
                        fontWeight="bold"
                        fontSize="16"
                        textTransform="none"
                    >
                        Sale Price
                    </Th>
                    <Th
                        fontFamily="body"
                        fontWeight="bold"
                        fontSize="16"
                        textTransform="none"
                    >
                        Payout
                    </Th>
                </Tr>
            </Thead>
            <Tbody>
                {data?.rows.map((r) => (
                    <Tr key={r.id}>
                        <Td>{r.salePrice}</Td>
                        <Td>{r.description}</Td>
                    </Tr>
                ))}
            </Tbody>
            {data?.footer && data.footer.trim() !== "" && (
                <Tfoot>
                    <Tr>
                        <Td colSpan={2}>
                            <Text
                                fontWeight={500}
                                fontStyle="italic"
                                whiteSpace="pre-wrap"
                            >
                                {data.footer}
                            </Text>
                        </Td>
                    </Tr>
                </Tfoot>
            )}
        </Table>
    );
};

export default CustomerPayoutTable;
