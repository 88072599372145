import { Text } from "@chakra-ui/react";
import { Alert, Form, Input, Modal } from "antd";
import TextArea from "antd/lib/input/TextArea";
import useSendPackageUpdate, { SendPackageUpdateRequest } from "hooks/useSendPackageUpdate";
import { StaffPackage } from "models/packages";
import { useEffect, useState } from "react";

const DISPLAY_INPUT_STYLE = { color: 'rgba(0, 0, 0, .85)', backgroundColor: '#fff' };

const SendUpdateModal = (props: {
    open: boolean;
    package: StaffPackage;
    onClose: (edited?: boolean) => void;
}) => {
    const [form] = Form.useForm<SendPackageUpdateRequest>();
    const { sendPackageUpdate, isLoading } = useSendPackageUpdate();
    const [ sent , setSent ] = useState<boolean>(false);

    useEffect(() => {
        if (props.open) {
            form.setFieldsValue({
                packageId: props.package.id,
                genericMessage: ''
            });
        }
    }, [props.package, form, props.open]);

    const onClose = () => {
        props.onClose();
        setSent(false);
        form.resetFields();
    }

    const submit = async (form: SendPackageUpdateRequest) => {
        if (!sent) {
            await sendPackageUpdate(form);
            setSent(true);
        } else {
            await props.onClose(true);
        }
    }

    return (
        <Modal
            title={`Send Package Update for ${props.package.friendlyId}`}
            closable={false}
            keyboard={false}
            maskClosable={false}
            destroyOnClose={true}
            open={props.open}
            onOk={form.submit}
            onCancel={onClose}
            okButtonProps={{ disabled: isLoading, hidden: !!sent }}
            okText={isLoading ? "Sending update..." : "OK"}
            cancelButtonProps={{ disabled: isLoading }}
            cancelText={sent ? "Done" : "Cancel"}
        >
            {sent && <Alert
                message="Update Sent"
                showIcon
                type="success"
                description={<>
                    <Text>Your package update has been sent!</Text>
                </>}
            />}
            {!sent && <Form
                disabled={isLoading}
                form={form}
                layout="vertical"
                onFinish={submit}>
                <Form.Item name="packageId" hidden>
                    <Input />
                </Form.Item>
                <Form.Item label="Customer">
                    <Input disabled value={`${props.package.customerFirstName} ${props.package.customerLastName} (${props.package.customerEmail})`} style={DISPLAY_INPUT_STYLE} />
                </Form.Item>
                <Form.Item label="Message" name="genericMessage" rules={[{ required: true, message: "A message is required"}]}>
                    <TextArea rows={4} />
                </Form.Item>
            </Form>}
        </Modal>
    );
}

export default SendUpdateModal;