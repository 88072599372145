import { FormOutlined } from "@ant-design/icons";
import { Text } from "@chakra-ui/react";
import { CssVariables } from "models/common";
import { ShipperShipment, ShipmentTemplates, ShipmentServiceLevelNames } from "models/shipping";
import { FC } from "react";

const Method: FC<{ shipment: ShipperShipment }> = ({ shipment }) => {
    const { serviceLevel, dimensionString, requireSignature } = shipment;
    const packageString = ShipmentTemplates[dimensionString] || dimensionString;

    return <>
        <Text fontWeight='bold'>METHOD:</Text>
        <Text>
            {ShipmentServiceLevelNames[serviceLevel]} <Text as='span' color={CssVariables.darkGrayTransparent}>&#47;&#47;</Text> {packageString}
        </Text>
        {requireSignature && <FormOutlined style={{ color: CssVariables.blue }} title="Signature Required" />}
    </>;
}

export default Method;