import { CheckCircleFilled, ExclamationCircleFilled } from "@ant-design/icons";
import { CssVariables } from "models/common";
import { UnsoldCardStatus } from "models/unsoldCards";
import { FC } from "react";

const StatusIcon: FC<{ status: UnsoldCardStatus }> = ({ status }) => {
    switch (status) {
        case UnsoldCardStatus.Pulled:
            return <CheckCircleFilled style={{ color: CssVariables.darkGreen, padding: "5px" }} />
        case UnsoldCardStatus.NotPulled:
            return <ExclamationCircleFilled style={{ color: CssVariables.warning, padding: "5px" }} />
        default:
            return null;
    }
};

export default StatusIcon;