import { PagedResult, PageRequest } from "models/common";
import { StaffTransaction } from "models/transactions";
import {
    useQuery,
    useQueryClient,
    UseQueryResult
} from "@tanstack/react-query";
import { pageRequestToUrlSearchParams } from "util/helpers";
import { useAuthenticatedRequest } from "./useRequests";

const makeAxiosRequest = (pageRequest: PageRequest) => {
    const options = pageRequestToUrlSearchParams(pageRequest);

    return {
        method: "get",
        url: `/transactions/all?${options}`,
    };
};

export const useStaffTransactionsCSV = (
    pageRequest: Omit<PageRequest, "page">
) => {
    const pageRequestWithCSV = {
        ...pageRequest,
        page: 1, // ignored
        format: "csv",
    } as const;

    const axiosRequest = makeAxiosRequest(pageRequestWithCSV);

    return useAuthenticatedRequest<string>(axiosRequest);
};

const useStaffTransactions = (
    pageRequest: PageRequest
): [UseQueryResult<PagedResult<StaffTransaction>, unknown>, () => void] => {
    const key = ["staff-transactions", pageRequest];
    const queryClient = useQueryClient();


    const staffTransactionRequest = useAuthenticatedRequest<
        PagedResult<StaffTransaction>
    >(makeAxiosRequest(pageRequest));

    const forceRefresh = () => {
        queryClient.invalidateQueries(key);
    };

    return [useQuery(key, staffTransactionRequest), forceRefresh];
};

export default useStaffTransactions;
