import { CodeSandboxOutlined } from "@ant-design/icons";
import { Text } from "@chakra-ui/react";
import { CssVariables } from "models/common";
import { FC } from "react";

const LabelButton: FC<{
    shipmentId: string,
    enabled: boolean
}> = ({ shipmentId, enabled }) => {
    const color = enabled
        ? CssVariables.blue
        : CssVariables.gray

    const pointerEvents = enabled
        ? 'auto'
        : 'none';

    return <Text as="a"
        pr={5}
        fontSize={30}
        cursor='pointer'
        href={`/api/shipping/shipments/${shipmentId}/label`}
        style={{ pointerEvents }}
        target='_blank'
        rel='noopener, noreferrer'>
        <CodeSandboxOutlined style={{ color }} />
    </Text>;
}

export default LabelButton;