import { Box, Flex, Stack, VStack } from "@chakra-ui/react";
import { CustomerInfoBanner } from "components/CustomerInfoBanner";
import { ImpersonatingBanner } from "components/ImpersonatingBanner";
import { Navigate } from "components/Navigate";
import { Footer } from "components/Navigation/Footer";
import { NavHeader } from "components/Navigation/NavHeader";
import { SideMenu } from "components/Navigation/SideMenu/SideMenu";
import { Roles } from "context/auth";
import { useContactDetailsRedirect } from "hooks/useContactDetails";
import useCustomerMenuItems from "hooks/useCustomerMenuItems";
import { Outlet } from "react-router-dom";
import useIdentityRedirection from "./useIdentityRedirection";

const CustomerLayout = ({ children }: any) => {
    useContactDetailsRedirect();

    const menuItems = useCustomerMenuItems();
    const { shouldRedirect, destination } = useIdentityRedirection([Roles.User, Roles.UserPartner]);

    if (shouldRedirect) {
        return <Navigate to={destination!} replace />;
    }

    // HACK: Not so bad, but much better ways exist to hide the <CustomerInfo /> on some pages (support)
    const isOnSupportPage = window.location.pathname.includes("support");

    return (
        <Flex
            flexDir="column"
            w="100%"
            justifyContent="space-between"
            minH="100vh"
            backgroundColor="black"
            color="var(--dcs-white)"
        >
            <NavHeader />
            <ImpersonatingBanner />
            <Stack
                direction={{ base: "column", md: "row" }}
                align="start"
                spacing={0}
                width="100vw"
                flex={1}
            >
                <SideMenu entries={menuItems} />
                <VStack alignItems="baseline" w="100%" h="100%">
                    {!isOnSupportPage && <CustomerInfoBanner />}
                    <Box
                        px={{ base: 5, md: 15 }}
                        py={3}
                        width="100%"
                    >
                        {children ? children : <Outlet />}
                    </Box>
                </VStack>
            </Stack>
            <Footer />
        </Flex>
    );
};

export default CustomerLayout;
