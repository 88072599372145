import "styles/multi_value.css";

const MultiValueDisplay = (props: { title: string; subTitle: string, opacity?: number }) => {
    return (
        <div className="multi-value-display" style={{ opacity: props.opacity || '1' }}>
            <span className="bold-font">{props.title}</span>
            <span>{props.subTitle}</span>
        </div>
    );
};

export default MultiValueDisplay;
