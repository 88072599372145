import {
    Box,
    Flex,
    HStack,
    useBreakpointValue,
    VStack,
} from "@chakra-ui/react";
import { FAQCategory } from "hooks/useFAQ";
import { FC } from "react";

const CategoryButton: FC<{
    category?: FAQCategory;
    selected: boolean;
    onClick: () => void;
}> = ({ category, selected, onClick }) => {
    const isMobileLayout = useBreakpointValue({ base: true, md: false });
    return (
        <Flex
            alignSelf="stretch"
            padding={2}
            px={2}
            py={!category && selected ? 10 : 2}
            cursor="pointer"
            w="100%"
            h="100%"
            minHeight={category && !isMobileLayout ? "10rem" : "5rem"}
            textAlign="center"
            alignItems="center"
            justifyContent="center"
            rounded={5}
            fontWeight={selected ? "800" : "medium"}
            fontSize={selected ? { base: "36px" } : { base: 16, md: 20 }}
            color={selected ? "white" : "black"}
            bg={selected ? "var(--dcs-bright-blue)" : "lightGray"}
            boxShadow="-3px 3px 6px rgba(0, 0, 0, 0.05)"
            _hover={{
                filter: "brightness(1.02)",
            }}
            transition="padding 0.2s ease-in-out, bg 0.2s ease-in-out"
            onClick={onClick}
        >
            {category?.name ?? "All Categories"}
        </Flex>
    );
};

const CategoryButtons: FC<{
    categories: FAQCategory[];
    selectedId: number | undefined;
    onSelect: (catId: number | undefined) => void;
}> = ({ categories, selectedId, onSelect }) => {
    // Setup max column counts for each breakpoint and group categories into rows
    const maxColCount =
        useBreakpointValue({ base: 2, sm: 2, md: 3, lg: 4, xl: 6 }) ?? 6;
    const rowCount = Math.ceil(categories.length / maxColCount);
    const categoriesRows = categories.reduce((rows, cat, i) => {
        rows[i % rowCount].push(cat);
        return rows;
    }, Array.from(Array(rowCount)).map(() => []) as FAQCategory[][]);

    const allSelected = selectedId === undefined;
    return (
        <VStack py={10}>
            <CategoryButton
                selected={allSelected}
                onClick={() => onSelect(undefined)}
            />
            {categoriesRows.map((categoriesRow, i) => (
                <HStack key={i} w="100%" pt="3">
                    {categoriesRow.map((cat) => {
                        const catSelected = cat.id === selectedId;
                        return (
                            <Box
                                flex={catSelected ? 1.1 : 1}
                                transition="flex 0.2s ease-in-out, bg 0.2s ease-in-out"
                                alignSelf="stretch"
                            >
                                <CategoryButton
                                    key={cat.id}
                                    category={cat}
                                    selected={catSelected}
                                    onClick={() => onSelect(cat.id)}
                                />
                            </Box>
                        );
                    })}
                </HStack>
            ))}
        </VStack>
    );
};

export default CategoryButtons;
