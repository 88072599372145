import { Box, HStack, VStack } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { Button, Radio, RadioChangeEvent } from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import { FilterValue } from "antd/lib/table/interface";
import { TyperRoutes } from "appRoutePaths";
import { useIdentity } from "context/auth";
import { useAuthenticatedRequest } from "hooks/useRequests";
import { CardFolder, CardFolderExtra, FolderFilter } from "models/cardIngestion";
import { InitialPageRequest, PageRequest, PageSizeOptions, PagedResultWithExtra } from "models/common";
import { useState } from "react";
import { localizedDate, pageRequestToUrlSearchParams } from "util/helpers";
import CardTyperCountModal from "./CardTyperCountModal";

const useCardFolders = (pageRequest: PageRequest) => {
    const { id } = useIdentity();
    const options = pageRequestToUrlSearchParams(pageRequest);

    const get = useAuthenticatedRequest<PagedResultWithExtra<CardFolder, CardFolderExtra>>(({
        method: "get",
        url: `/card-titling/assigned-folders?${options}`
    }));

    return useQuery(["assigned-folders", id, pageRequest], get);
}

const getFilterBy = (filter: FolderFilter): Record<string, string[]> | undefined => {
    switch (filter) {
        case FolderFilter.InProgress:
            return { InProgress: [''] };
        case FolderFilter.Completed:
            return { ShowCompleted: [''] };
        default:
            return undefined;
    }
}

const completePercentage = (folder: CardFolder) => (folder.completedCount / folder.cardCount) * 100;

const columns: ColumnsType<CardFolder> = [
    {
        title: "Package ID",
        dataIndex: "friendlyPackageId",
        sorter: false,
    },
    {
        title: "Name",
        dataIndex: "name",
        sorter: false,
        render: (name, rec) => <Button
            type="link"
            href={TyperRoutes.cardTitling.url(rec.id)}
            disabled={completePercentage(rec) === 100}>
            {name}
        </Button>
    },
    {
        title: "Count",
        dataIndex: "cardCount",
        sorter: false,
    },
    {
        title: "Progress",
        key: "progress",
        render: (_, rec) => `${completePercentage(rec).toFixed(2)}%`
    },
    {
        title: "Rec. Date",
        dataIndex: "packageReceivedDate",
        sorter: false,
        render: localizedDate
    },
    {
        title: "Est. Upload Date",
        dataIndex: "packageEstUploadDate",
        sorter: false,
        render: localizedDate
    },
];

export const TyperDashboard = () => {
    const [{ page, filterBy, withinDays, sort, pageSize }, setPageRequest] =
        useState<PageRequest>({
            ...InitialPageRequest,
            filterBy: getFilterBy(FolderFilter.InProgress)
        });
    const { isLoading, data } = useCardFolders({ page, filterBy, withinDays, sort, pageSize });
    const { data: folders, totalRecords, extra } = data ?? {};

    const tableChanged = (
        // pagination
        { current: newPage, pageSize: newPageSize }: any,
        // filtering
        filters: Record<string, FilterValue | null>,
        // sorting
        { column, field, order }: any
    ) => {
        setPageRequest({
            page: newPageSize !== pageSize ? 1 : newPage,
            pageSize: newPageSize,
            filterBy: filters,
            withinDays,
            sort: column ? [field, order === "ascend"] : null,
        });
    };

    const applyFilter = (e: RadioChangeEvent) => {
        const filterBy = getFilterBy(e.target.value);
        setPageRequest({ page: 1, filterBy, sort, pageSize, withinDays });
    }

    return <Box p={50} display='flex'>
        <Box w='100%'>
            <Box fontSize={36} pb={10}>Assigned Card Folders</Box>
            <VStack alignItems='end'>
                <HStack>
                    <CardTyperCountModal />
                    <Radio.Group
                        defaultValue={FolderFilter.InProgress}
                        size='large'
                        buttonStyle='solid'
                        style={{ alignSelf: 'end' }}
                        onChange={applyFilter}
                    >
                        <Radio.Button value={FolderFilter.All}>All</Radio.Button>
                        <Radio.Button value={FolderFilter.InProgress}>Incomplete</Radio.Button>
                        <Radio.Button value={FolderFilter.Completed}>Completed</Radio.Button>
                    </Radio.Group>
                </HStack>
                <Box w='100%'>
                    <Table
                        loading={isLoading}
                        columns={columns}
                        dataSource={folders}
                        rowKey={p => p.id}
                        pagination={{
                            total: totalRecords,
                            current: page,
                            pageSize,
                            pageSizeOptions: PageSizeOptions,
                            showSizeChanger: true,
                            showQuickJumper: true,
                        }}
                        onChange={tableChanged}
                        footer={() => <Box>
                            Total Cards: {extra?.totalCards}
                        </Box>}
                    />
                </Box>
            </VStack>
        </Box>
    </Box>;
}