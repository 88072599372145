import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { PayoutTable, WebsiteSettings } from "models/websiteSettings";
import {
    useAuthenticatedRequestCreator,
    usePublicRequest
} from "./useRequests";

const WEBSITE_SETTINGS_ROOT_URI = "/website-settings";
const QUERY_KEY = ["website-settings"];

const parseWebsiteSettings = (settings: any) => {
    const getValue = <T>(key: string) =>
        settings.find((x: { key: string; value: any }) => x.key === key)
            .value as T;

    return {
        announcement: getValue<string>("Announcement"),
        announcementEnabled: getValue<boolean>("AnnouncementEnabled"),
        promoStartDate: getValue<string>("PromoStartDate"),
        promoEndDate: getValue<string>("PromoEndDate"),
        promoImageKey: getValue<string>("PromoImageKey"),
        featuredVideoId: getValue<string>("FeaturedVideoId"),
        featuredVideoTitle: getValue<string>("FeaturedVideoTitle"),
        outOfOfficeDates: getValue<string[]>("OutOfOfficeDates")
    };
};

interface AnnouncementArgs {
    announcement: string;
    enabled: boolean;
}

const useUpdateAnnouncement = () => {
    const queryClient = useQueryClient();
    const setAnnouncement = useAuthenticatedRequestCreator<
        void,
        AnnouncementArgs
    >(({ announcement, enabled }) => ({
        method: "post",
        url: `${WEBSITE_SETTINGS_ROOT_URI}/announcement`,
        data: {
            announcement,
            enabled
        }
    }));

    const { mutate: updateAnnouncement } = useMutation(
        (announcementArgs: AnnouncementArgs) =>
            setAnnouncement(announcementArgs),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(QUERY_KEY);
            }
        }
    );

    // TODO: This weird-ish wrapper exists to avoid refactoring WebsiteUpdates and the way "results" are handled
    const wrapper = async (args: AnnouncementArgs) => {
        await updateAnnouncement(args);

        return true;
    };

    return wrapper;
};

interface PromotionDetails {
    startDate: string;
    endDate: string;
    image: File;
}

const useUpdatePromotionDetails = () => {
    const queryClient = useQueryClient();

    const setPromotionDetails = useAuthenticatedRequestCreator<void, FormData>(
        formData => ({
            method: "post",
            url: `${WEBSITE_SETTINGS_ROOT_URI}/promotion`,
            data: formData
        })
    );

    const { mutate: update } = useMutation(
        (formData: FormData) => setPromotionDetails(formData),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(QUERY_KEY);
            }
        }
    );

    const wrapper = async (post: PromotionDetails) => {
        var formData = new FormData();
        formData.append('StartDate', post.startDate);
        formData.append('EndDate', post.endDate);
        if (post.image) {
            formData.append('Image', post.image);
        }
        await update(formData);

        return true;
    };

    return wrapper;
}

interface FeaturedVideoArgs {
    videoId: string;
    title: string;
}

const useUpdateFeaturedVideo = () => {
    const queryClient = useQueryClient();
    const setFeaturedVideo = useAuthenticatedRequestCreator<
        void,
        FeaturedVideoArgs
    >(({ videoId, title }) => ({
        method: "post",
        url: `${WEBSITE_SETTINGS_ROOT_URI}/featured-video`,
        data: { videoId, title }
    }));

    const { mutate: updateFeaturedVideo } = useMutation(
        (args: FeaturedVideoArgs) => setFeaturedVideo(args),
        {
            onSuccess: () => queryClient.invalidateQueries(QUERY_KEY)
        }
    );

    const wrapper = async (args: FeaturedVideoArgs) => {
        await updateFeaturedVideo(args);

        return true;
    }

    return wrapper;
};

const useOutOfOfficeDates = () => {
    const queryClient = useQueryClient();
    const invalidateCache = () => queryClient.invalidateQueries(QUERY_KEY);

    const addDate = useAuthenticatedRequestCreator<void, string>(
        date => ({
            method: "post",
            url: `${WEBSITE_SETTINGS_ROOT_URI}/out-of-office/add`,
            data: { date }
        })
    );

    const removeDate = useAuthenticatedRequestCreator<void, string>(
        date => ({
            method: "post",
            url: `${WEBSITE_SETTINGS_ROOT_URI}/out-of-office/remove`,
            data: { date }
        })
    );

    const { mutate: add } = useMutation(addDate, { onSuccess: invalidateCache });
    const { mutate: remove } = useMutation(removeDate, { onSuccess: invalidateCache });

    return { add, remove };
}

const useUpdateCustomerPayoutTable = () => {
    const queryClient = useQueryClient();
    const invalidateCache = () => queryClient.invalidateQueries(QUERY_KEY);

    const update = useAuthenticatedRequestCreator<void, PayoutTable>(
        info => ({
            method: "post",
            url: `${WEBSITE_SETTINGS_ROOT_URI}/customer-payout-info`,
            data: info
        })
    );

    const { mutate: updateTable } = useMutation(update, { onSuccess: invalidateCache });


    const wrapper = async (info: PayoutTable) => {
        await updateTable(info);

        return true;
    };

    return wrapper;
}

const useWebsiteSettings = () => {
    // TODO: Better typing, maybe just a totally different DTO?
    //  This one is kind of hard to work with generically
    const getWebsiteSettings = usePublicRequest<any>({
        method: "get",
        url: WEBSITE_SETTINGS_ROOT_URI,
    });

    const { isLoading, data: settings } = useQuery<WebsiteSettings>(
        QUERY_KEY,
        async () => {
            return parseWebsiteSettings(await getWebsiteSettings());
        },
        { cacheTime: Infinity }
    );

    const updateAnnouncement = useUpdateAnnouncement();
    const updatePromotion = useUpdatePromotionDetails();
    const updateFeaturedVideo = useUpdateFeaturedVideo();
    const outOfOffice = useOutOfOfficeDates();
    const updateCustomerPayoutTable = useUpdateCustomerPayoutTable();

    return {
        isLoading,
        settings,
        updateAnnouncement,
        updatePromotion,
        updateFeaturedVideo,
        outOfOffice,
        updateCustomerPayoutTable
    };
};

export default useWebsiteSettings;
