import { UploadOutlined } from '@ant-design/icons';
import { Box, HStack, VStack } from '@chakra-ui/react';
import { Button, Checkbox, Col, Divider, Form, Input, Modal, Radio, Row, Upload } from "antd";
import { Roles } from 'context/auth';
import { StaffUsersProfile } from "hooks/useUsers";
import { FC, useState } from "react";
import { roleNameToDisplayName } from "util/helpers";
import useUserUpdate from "./useUserUpdate";

interface UpdateUserForm {
    userId: string;
    emailAddress: string | undefined;
    sendPasswordResetEmail: boolean;
    image: File | string | null; // File / null = edit, string = no change
    roles: string[] | undefined;
    active: boolean | undefined;
    initials: string | null;
}

const RoleDivider: FC<{ caption: string }> = ({ caption }) =>
    <Divider orientation='left' style={{ fontWeight: 'normal', fontSize: '10pt' }}>{caption}</Divider>;

const only = (items: string[], source: string[]) => {
    return source.every(x => items.includes(x));
}

const UpdateUserModal = (props: {
    user: StaffUsersProfile;
    onSuccess: () => Promise<void>
}) => {
    const { updateUser, removeUserTwoFactor } = useUserUpdate();
    const [visible, setVisible] = useState<boolean>(false);
    const [working, setWorking] = useState<boolean>(false);
    const [isEmailUpdated, setEmailUpdated] = useState<boolean>(false);
    const [mfaRemoved, setMfaRemoved] = useState<boolean>(false);
    const [form] = Form.useForm<UpdateUserForm>();
    const initialValues: UpdateUserForm = {
        userId: props.user.id,
        emailAddress: props.user.email,
        sendPasswordResetEmail: true,
        image: props.user.imageUri,
        roles: props.user.roles,
        active: props.user.active,
        initials: props.user.initials
    };

    const image = Form.useWatch('image', form);
    const roles = Form.useWatch('roles', form) ?? []

    const isNotStaff = only([Roles.User, Roles.UserPartner], roles);
    const isAdmin = only([Roles.Admin], roles);

    const closeAndResetModal = () => {
        setVisible(false);
        form.resetFields();
    };

    const submitForm = () => {
        form.validateFields().then(async (data) => {
            setWorking(true);

            try {
                await updateUser({
                    userId: data.userId,
                    emailAddress: data.emailAddress!,
                    sendPasswordResetEmail: data.sendPasswordResetEmail,
                    image: typeof data.image === 'string' ? undefined : data.image,
                    roles: data.roles,
                    // only update the account active flag if it changed
                    active: data.active !== props.user.active
                        ? data.active
                        : undefined,
                    initials: data.initials,
                    updatedInitials: form.isFieldTouched('initials')
                });
                setVisible(false);
                await props.onSuccess();
            } finally {
                setWorking(false);
            }
        });
    };

    const removeTwoFactor = async () => {
        try {
            setWorking(true);
            await removeUserTwoFactor(props.user.id);
            setMfaRemoved(true);
        } finally {
            setWorking(false);
        }
    }

    return (
        <>
            <Button type="link" onClick={() => setVisible(true)}>
                Edit User
            </Button>
            <Modal
                title={`Edit User - ${props.user.userName}`}
                closable={false}
                keyboard={false}
                maskClosable={false}
                destroyOnClose={true}
                open={visible}
                confirmLoading={working}
                cancelButtonProps={{ disabled: working }}
                okText={working ? "Saving..." : "Update"}
                onOk={submitForm}
                onCancel={closeAndResetModal}
            >
                <Form
                    form={form}
                    disabled={working}
                    initialValues={initialValues}
                    layout="vertical"
                >
                    <Form.Item
                        label="Email Address"
                        name="emailAddress"
                        rules={[{ type: "email", required: true }]}
                    >
                        <Input
                            onChange={(e) =>
                                setEmailUpdated(
                                    e.target.value !==
                                    initialValues.emailAddress
                                )
                            }
                        />
                    </Form.Item>
                    <HStack alignItems='baseline' spacing={20}>
                        <Form.Item
                            name="sendPasswordResetEmail"
                            valuePropName="checked"
                        >
                            <Checkbox disabled={!isEmailUpdated}>
                                Send Password Reset Email
                            </Checkbox>
                        </Form.Item>
                        {!props.user.twoFactorEnabled && <div>User has not enabled MFA</div>}
                        {props.user.twoFactorEnabled && !mfaRemoved && <Button
                            danger
                            type="primary"
                            disabled={isAdmin}
                            onClick={removeTwoFactor}
                            loading={working}>
                            Remove MFA
                        </Button>}
                        {mfaRemoved && <div>MFA has been removed</div>}
                    </HStack>
                    <Form.Item label="Roles" name="roles">
                        <Checkbox.Group style={{ width: '100%' }}>
                            <Row>
                                <Col span={24}><RoleDivider caption="Site Administration" /></Col>
                                <Col span={24}><Checkbox value='admin'>{roleNameToDisplayName("admin")}</Checkbox></Col>

                                <Col span={24}><RoleDivider caption="Processing" /></Col>
                                <Col span={6}><Checkbox value='staff'>{roleNameToDisplayName("staff")}</Checkbox></Col>
                                <Col span={8}><Checkbox value='typer'>{roleNameToDisplayName("typer")}</Checkbox></Col>

                                <Col span={24}><RoleDivider caption="Customers" /></Col>
                                <Col span={6}><Checkbox value='user'>{roleNameToDisplayName("user")}</Checkbox></Col>
                                <Col span={8}><Checkbox value='user_partner'>{roleNameToDisplayName("user_partner")}</Checkbox></Col>
                            </Row>
                        </Checkbox.Group>
                    </Form.Item>
                    {roles.includes(Roles.UserPartner) && (
                        <Form.Item label="Profile Image" name="image">
                            <Upload
                                multiple={false}
                                accept="image/*"
                                maxCount={1}
                                listType="picture-card"
                                showUploadList={{
                                    showPreviewIcon: false,
                                    showDownloadIcon: false,
                                    showRemoveIcon: true,
                                }}
                                fileList={typeof image === 'string' || image instanceof File
                                    ? [{
                                        uid: '0',
                                        name: 'image',
                                        status: 'done',
                                        url: typeof image === 'string'
                                            ? `${image}?t=${Date.now()}` // Bust browser cache
                                            : URL.createObjectURL(image)
                                    }]
                                    : []
                                }
                                customRequest={({ file, onSuccess }) => {
                                    onSuccess && onSuccess(undefined);
                                    form.setFieldValue('image', file);
                                }}
                            >
                                <VStack>
                                    <UploadOutlined />
                                    <Box>Upload Image</Box>
                                </VStack>
                            </Upload>
                        </Form.Item>
                    )}
                    <HStack alignItems='baseline' spacing={50}>
                        <Form.Item label="Account Status" name="active">
                            <Radio.Group>
                                <Radio value={true}>Active</Radio>
                                <Radio value={false}>Inactive</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item label="Initials Override" name="initials" rules={[{ required: false }]}>
                            <Input disabled={isNotStaff} placeholder='For card folder names' className='initials-override' />
                        </Form.Item>
                    </HStack>
                    <Form.Item hidden name="userId">
                        <Input type="hidden" />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default UpdateUserModal;
