import { SearchOutlined } from "@ant-design/icons";
import { Alert, Button, Divider, InputRef, Modal } from "antd";
import Search from "antd/lib/input/Search";
import useOrderSearch from "hooks/useOrderSearch";
import { StoreOrder } from "models/store";
import { FC, useRef, useState } from "react";
import OrderFulfillmentDetail from "./OrderFulfillmentDetail";
import useOrderFulfillment from "hooks/useOrderFulfillment";

const OrderSearch: FC<{ onDataChanged: () => void }> = ({ onDataChanged }) => {
    const { fulfill, isLoading: isFulfilling, errors, reset } = useOrderFulfillment();
    const { search, isLoading: isSearching } = useOrderSearch();
    const [visible, setVisible] = useState<boolean>(false);
    const [searchError, setSearchError] = useState<string | undefined>();
    const [requireRefresh, setRequireRefresh] = useState<boolean>(false);
    const [order, setOrder] = useState<StoreOrder | undefined>();
    const input = useRef<InputRef>(null);

    const isLoading = isFulfilling || isSearching;

    const searchForOrder = async (term: string) => {
        if (term.trim().length === 0) {
            return;
        }

        reset();

        try {
            const order = await search(Number(term));
            setOrder(order);
            setSearchError(undefined);
        } catch {
            setOrder(undefined);
            setSearchError(`No orders found when searching for ${term}.`);
        }
    }

    // workaround for setting the search to auto-focus
    // when opening the modal
    setTimeout(() => input.current?.focus(), 10);

    const touch = () => setRequireRefresh(true);

    const close = () => {
        if (requireRefresh) {
            onDataChanged();
        }
        setVisible(false);
        setOrder(undefined);
        setSearchError(undefined);
        reset();
    }

    return <>
        <Button type="dashed" shape="circle" icon={<SearchOutlined />} size="large" onClick={() => setVisible(true)} style={{ marginRight: '15px' }} />
        <Modal
            title="Order Search"
            width={600}
            closable={false}
            keyboard
            maskClosable
            destroyOnClose
            open={visible}
            footer={<Button key='cancel' type="default" onClick={close} disabled={isLoading}>Cancel</Button>}
        >
            <Search
                ref={input}
                enterButton
                placeholder="Order #"
                size="large"
                disabled={isLoading}
                style={{ marginBottom: '10px' }}
                onSearch={searchForOrder}
            />
            {searchError && <Alert
                className="bold-font"
                message={searchError}
                type="error"
                showIcon
            />}
            {order && <>
                <Divider>Order #{order.friendlyId}</Divider>
                <OrderFulfillmentDetail key={order.id}
                    order={order}
                    fulfill={fulfill}
                    isLoading={isLoading}
                    errors={errors}
                    onFulfillSuccess={touch}
                    onAddressUpdated={touch}
                />
            </>}
        </Modal>
    </>;
};

export default OrderSearch;