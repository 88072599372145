import { CheckOutlined, CloseOutlined, QuestionOutlined } from "@ant-design/icons";
import { VStack } from "@chakra-ui/react";
import { Table, Image, Radio, RadioChangeEvent } from "antd";
import { ColumnsType } from "antd/lib/table";
import useShipmentCards from "hooks/useShipmentCards";
import { CssVariables, InitialPageRequest, PageRequest, PageSizeOptions } from "models/common";
import { ShipmentType, ShipperCard } from "models/shipping";
import { FC, useState } from "react";
import { centsToDollars } from "util/helpers";

// This CSS targets ant design classes to get the image 
// previews in the card table to open in the center of
// the viewport. without it, they open at the bottom-left.
import "styles/staff/shipment_card_table.css";

const NotPulledFilter = { NotPulled: [''] };
const BookendFilter = { Bookend: [''] };

const AllCards = 1;
const MissingCards = 2;
const BookendCards = 3;

const StatusIcon: FC<{ card: ShipperCard }> = ({ card }) => {
    if (card.pulled) {
        return <CheckOutlined style={{ color: CssVariables.darkGreen }} />;
    }

    if (card.waiting) {
        return <QuestionOutlined style={{ color: CssVariables.blue }} title="Waiting on card puller" />;
    }

    return <CloseOutlined style={{ color: CssVariables.errorRed }} title="Missing" />;
}

const columns: ColumnsType<ShipperCard> = [
    {
        title: "",
        dataIndex: "frontImage",
        render: (img, rec) => img && <Image alt={rec.title} src={img} width={50} />
    },
    {
        title: "Title",
        dataIndex: "title"
    },
    {
        title: "SKU",
        dataIndex: "sku"
    },
    {
        title: "Pulled?",
        dataIndex: "pulled",
        render: (_, rec) => <StatusIcon card={rec} />
    },
    {
        title: "Price",
        dataIndex: "salePriceInCents",
        align: 'right',
        render: (val) => centsToDollars(val)
    }
];

const initialValuesForShipmentType = (type: ShipmentType) => {
    const isNotBulkType =
        type === ShipmentType.RegularSingle ||
        type === ShipmentType.PrioritySingle ||
        type === ShipmentType.Wax;

    const defaultSelection = isNotBulkType ? AllCards : BookendCards;

    return {
        isNotBulkType,
        initialFilter: filterForSelection(defaultSelection),
        defaultSelection
    };
}

const filterForSelection = (selection: number) => {
    switch (selection) {
        case MissingCards:
            return NotPulledFilter;
        case BookendCards:
            return BookendFilter;
        default:
            return undefined;
    }
}

const ShipmentCardTable = (props: { shipmentId: string, type: ShipmentType }) => {
    const { isNotBulkType, initialFilter, defaultSelection } = initialValuesForShipmentType(props.type);
    const [pageRequest, setPageRequest] = useState<PageRequest>({
        ...InitialPageRequest,
        filterBy: initialFilter
    });
    const { isLoading, data } = useShipmentCards(props.shipmentId, pageRequest);
    const { data: records, totalRecords } = data || {};

    const tableChanged = (
        // pagination
        { current: newPage, pageSize: newPageSize }: any,
        // filtering
        _: any,
        // sorting
        __: any
    ) => {
        setPageRequest({ ...pageRequest, page: newPageSize !== pageRequest.pageSize ? 1 : newPage, pageSize: newPageSize });
    };

    const applyFilter = (e: RadioChangeEvent) => {
        const filterBy = filterForSelection(e.target.value);
        setPageRequest({ ...pageRequest, page: 1, filterBy });
    }

    return <VStack>
        <Radio.Group defaultValue={defaultSelection}
            size='large'
            buttonStyle='solid'
            onChange={applyFilter}
            style={{ alignSelf: 'end' }}>
            <Radio.Button value={AllCards}>All</Radio.Button>
            <Radio.Button value={MissingCards}>Missing Cards</Radio.Button>
            <Radio.Button value={BookendCards} disabled={isNotBulkType}>First &amp; Last</Radio.Button>
        </Radio.Group>
        <Table
            size="small"
            style={{ width: '100%' }}
            id="shipper-cards-table"
            columns={columns}
            dataSource={records}
            rowKey={(r) => r.cardId}
            onChange={tableChanged}
            pagination={{
                total: totalRecords,
                current: pageRequest.page,
                pageSize: pageRequest.pageSize,
                pageSizeOptions: PageSizeOptions,
                showSizeChanger: true,
                showQuickJumper: true
            }}
            showSorterTooltip={false}
            loading={isLoading}
        />
    </VStack>;
}

export default ShipmentCardTable;