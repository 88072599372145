import { Image } from "@chakra-ui/image";
import { Stack, Text, VStack } from "@chakra-ui/react";
import { Col, Row, Skeleton } from "antd";
import { FeaturedAuction } from "models/featuredAuction";

// TODO: Refactor the FeaturedSkeleton away from antd, or at least away from the css file styles
export const FeaturedSkeleton = () => {
    return (
        <Col span={12} className="featured-auction">
            <div>
                <Row>
                    <Col span={12} className="auction-info">
                        <Skeleton.Image className="auction-image" />
                    </Col>
                    <Col span={12}>
                        <Skeleton active paragraph={{ rows: 7 }} />
                    </Col>
                </Row>
            </div>
        </Col>
    );
};

export const FeaturedCard = ({
    title,
    currentBid,
    remaining,
    imageUrl,
    auctionUrl
}: FeaturedAuction) => {
    return (
        <Stack
            direction={{
                base: "column",
                md: "row"
            }}
            spacing={3}
            rounded="md"
            bg="lightGray"
            boxShadow="0px 3px 6px #00000029"
            width="100%"
            align={{
                base: "center",
                md: "flex-start"
            }}
            justifyContent="space-between"
            p={6}
        >
            <Image
                src={imageUrl}
                alt={title}
                rounded="lg"
                maxWidth="15rem"
                flexBasis="50%"
            />
            <VStack
                spacing={3}
                flexBasis="50%"
                align={{
                    base: "center",
                    md: "baseline"
                }}
                minWidth="8rem"
                maxWidth={{
                    base: "16rem",
                    md: "24rem"
                }}
            >
                <Text as="h1" fontSize="1.5em">
                    {title}
                </Text>
                <Text
                    as="a"
                    color="blue"
                    fontWeight="bold"
                    fontSize="1.2em"
                    href={auctionUrl}
                    className="link"
                    target="_blank"
                    rel="noreferrer"
                >
                    {currentBid} on eBay
                </Text>
                <Text>{remaining}</Text>
            </VStack>
        </Stack>
    );
};
