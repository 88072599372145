import Papa from "papaparse";
import { centsToDollars } from "./helpers";

const guidRegex =
    /^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/;

// Several general fixes to the data to make it more readable
const fixData = (data: any, guidFieldsToKeep: string[]) => {
    const csvData = Papa.parse(data, {
        header: true,
        skipEmptyLines: true,
    });

    const fields = csvData.meta.fields;
    let excludedFields: string[] = [];

    const rows = csvData.data.map((row: any) => {
        // If any of the strings are GUIDs, then remove the fields, unless explicitly requested
        const rowWithGuidsRemoved = fields?.reduce((acc, field) => {
            if (!guidFieldsToKeep.includes(field) && guidRegex.test(row[field])) {
                if (!excludedFields.includes(field)) {
                    excludedFields.push(field);
                }
            } else {
                acc[field] = row[field];
            }
            return acc;
        }, {} as any);

        // If any of the strings end with "InCents" (case-insensitive) then convert them to dollars
        //  then transform the field name to remove the "InCents" suffix
        const rowWithDollars = Object.keys(rowWithGuidsRemoved).reduce(
            (acc, field) => {
                if (field.toLowerCase().endsWith("incents")) {
                    const fieldName = field.replace(/InCents$/i, "");
                    acc[fieldName] = centsToDollars(rowWithGuidsRemoved[field]);
                } else {
                    acc[field] = rowWithGuidsRemoved[field];
                }
                return acc;
            },
            {} as any
        );

        return rowWithDollars;
    });

    const newFields = fields
        ?.filter((field) => !excludedFields.includes(field))
        .map((field) => {
            if (field.toLowerCase().endsWith("incents")) {
                return field.replace(/InCents$/i, "");
            }
            return field;
        });

    // Re-parse the data into a CSV for download
    const csv = Papa.unparse(rows, {
        columns: newFields,
        header: true,
    });

    return csv;
};

const downloadAsCSV = (data: string, filename: string = "data.csv", guidFieldsToKeep: string[] = []) => {
    const csv = fixData(data, guidFieldsToKeep);

    const blob = new Blob([csv], { type: "text/csv" });

    // This URL can then be used to initiate a file download in the browser
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url ?? "#";
    a.download = filename;
    a.click();

    a.remove();
};

export default downloadAsCSV;
