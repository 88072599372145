import {
    useQuery,
} from "@tanstack/react-query";
import { Shipment } from "models/shipping";
import { useAuthenticatedRequest } from "./useRequests";

const makeAxiosRequest = (id: string) => {
    return {
        method: "get",
        url: `/shipping/batches/${id}`,
    };
};

const useShipments = (id: string | undefined) => {
    const getShipments = useAuthenticatedRequest<Shipment[]>(
        makeAxiosRequest(id!)
    );

    return useQuery(["shipments", id], getShipments);
};

export default useShipments;