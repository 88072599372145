import { useMutation } from "@tanstack/react-query";

import { useAuthenticatedRequest } from "./useRequests";

const usePayoutRequestInfoNeeded = (id: string) => {
    const infoNeeded = useAuthenticatedRequest({
        url: `/payout-requests/info-needed`,
        method: "POST",
        data: {
            payoutRequestId: id
        }
    });

    const { mutateAsync, ...rest } = useMutation(
        ["payoutRequestInfoNeeded", id],
        infoNeeded
    );

    return {
        payoutRequestInfoNeeded: mutateAsync,
        ...rest,
    };
};

export default usePayoutRequestInfoNeeded;