import { useMutation } from "@tanstack/react-query";
import { useAuthenticatedRequestCreator } from "./useRequests";

export type AddressFieldName = "recipientName"
    | "addressLine1"
    | "addressLine2"
    | "city"
    | "state"
    | "zipCode"
    | "country";

export type AddressFieldChange = Partial<Record<AddressFieldName, string>>;

const useUpdateShippingAddress = (id: string) => {
    const patch = useAuthenticatedRequestCreator<boolean, AddressFieldChange>(
        (req: AddressFieldChange) => ({
            method: "patch",
            url: `/orders/shipping-address`,
            data: { id, ...req }
        }), [400]
    );

    const { mutateAsync: update, isLoading } = useMutation(patch);

    return { update, isLoading };
};

export default useUpdateShippingAddress;