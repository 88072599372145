import { FeaturedCard, FeaturedSkeleton } from "components/FeaturedCard";
import useFeaturedAuctions from "hooks/useFeaturedAuctions";

import { Box, Flex, SimpleGrid, Text, VStack } from "@chakra-ui/react";
import { forwardRef } from "@chakra-ui/system";
import { Button, Image } from "antd";
import { FC } from "react";

const StoreButton = forwardRef(({ children, ...props }, ref) => (
    <Button
        size="large"
        style={{ fontWeight: 'bold', width: '270px', textAlign: 'start', height: '50px', alignContent: 'end', borderRadius: '6px', transformOrigin: 'top left', scale: '120%' }}
        href="https://www.ebay.com/str/uscgzachssportscards"
        target="_blank"
    >
        <div style={{ paddingTop: '5px' }}>
            <Image
                preview={false}
                src="/img/ebay.svg"
                alt="ebay"
                style={{
                    height: 25,
                    marginBottom: "-6px",
                }}
            />
            <span
                style={{
                    textDecoration: "underline",
                    fontSize: "18px",
                }}
            >
                Visit our eBay Store
            </span>
        </div>
    </Button>
));

const FeaturedActionsHero: FC = () => (
    <Box maxWidth="75rem">
        <Text
            as="h1"
            fontSize="52"
            fontWeight={800}
            lineHeight="58px"
            color="var(--dcs-white)"
            textTransform="uppercase"
        >
            eBay top rated seller... And striving to keep climbing the ranks.
        </Text>
    </Box>
);

const SkeletonCards: FC = () => (
    <>
        {Array.from(Array(6)).map((_, index) => (
            <FeaturedSkeleton key={index} />
        ))}
    </>
);

const CardsGrid: FC = () => {
    const { isLoading, data } = useFeaturedAuctions();

    return (
        <SimpleGrid minChildWidth="25rem" spacing={10}>
            {isLoading ? (
                <SkeletonCards />
            ) : (
                data?.map((c, i) => <FeaturedCard key={i} {...c} />)
            )}
        </SimpleGrid>
    );
};

const FeaturedCards: FC = () => {
    return (
        <VStack spacing={3} align="left" p="20">
            <Text as="h1" fontSize="40" color="var(--dcs-white)">
                See our Featured Live Actions
            </Text>
            <Text fontSize="18px" lineHeight="26px" color="var(--dcs-white)">
                Below are some of the hottest cards available NOW on auction.
                Check out these high end offerings and more in our eBay store.
                We love seeing our customers max out the value they get on their
                best cards by choosing to sell through us!
            </Text>
            <CardsGrid />
            <Box pt="10">
                <StoreButton
                    width={{
                        base: "100%",
                        md: "auto",
                    }}
                >
                    Get Started Now
                </StoreButton>
            </Box>
        </VStack>
    );
};

const featuredAuctionsBGImage = require("images/featured-cards.jpg");

const FeaturedAuctions = () => {
    return (
        <VStack
            bgImage={featuredAuctionsBGImage}
            overflow="cover"
            bgRepeat="no-repeat"
            bgColor="var(--dcs-black)"
            width="100vw"
        >
            <Flex width="100vw">
                <VStack
                    align="left"
                    spacing={6}
                    py={{
                        base: "6rem",
                        md: "10rem",
                    }}
                    px={24}
                >
                    <FeaturedActionsHero />
                    <StoreButton />
                </VStack>
            </Flex>
            <FeaturedCards />
        </VStack>
    );
};

export default FeaturedAuctions;
