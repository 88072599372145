import { useIdentity } from "context/auth";
import useProfile from "hooks/useProfile";
import { useZendeskChatToken } from "hooks/useZendeskSSO";
import { FC, useEffect } from "react";
import Zendesk, { ZendeskAPI } from "react-zendesk";

const ZENDESK_KEY = process.env["REACT_APP_ZENDESK_WIDGET_TOKEN"] ?? "FAILURE";

const ZendeskChatWidget: FC = () => {
    const { isLoggedIn } = useIdentity();
    const getChatToken = useZendeskChatToken();

    // NOTE: The authentication doesn't seem to give us anything useful
    //  Prefilling the name/email is done with the command(s) in the useEffect
    const authenticateCallback = async (callback: (token: string) => void) => {
        if (!isLoggedIn) {
            return;
        }

        const token = await getChatToken();

        // Fetch your jwt token and then call the supplied callback below.
        callback(token.accessToken);
    };

    const settings = {
        authenticate: {
            // Dumb zendesk wrapper lib requires a dumb callback
            jwtFn: authenticateCallback,
        },
    };

    const remountKey = isLoggedIn ? "logged-in" : "logged-out";

    const { data: profile } = useProfile();

    useEffect(() => {
        // Identify the user to zendesk chat
        if (isLoggedIn && profile?.firstName && profile?.lastName) {
            const identity = {
                name: {
                    value: `${profile?.firstName} ${profile?.lastName}`,
                    readOnly: true, // optional
                },
                email: {
                    value: profile?.email,
                    readOnly: true, // optional
                },
            };
            // NOTE: I do both because .. why not, but their overlap should be
            //  the only fields we care about prefilling
            ZendeskAPI("webWidget", "identify", identity);
            ZendeskAPI("webWidget", "prefill", identity);
        }
    }, [isLoggedIn, profile]);

    return (
        <Zendesk
            key={remountKey}
            defer
            zendeskKey={ZENDESK_KEY}
            {...settings}
        />
    );
};

export default ZendeskChatWidget;
