import { useQuery } from "@tanstack/react-query";
import { useAuthenticatedRequest } from "./useRequests";
import { CatalogItem } from "models/store";

const useItemDetails = (itemId: string) => {
    const getData = useAuthenticatedRequest<CatalogItem>({
        url: `/store/catalog/${itemId}`, // Append the itemId to the API URL
        method: "GET",
    });

    return useQuery(["itemDetails", itemId], getData);
};

export default useItemDetails;
