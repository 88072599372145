import { Box, BoxProps, forwardRef, Spinner } from "@chakra-ui/react";

const BasicLoadingSpinner = forwardRef<BoxProps, typeof Box>((props, ref) => {
    return (
        <Box ref={ref} pos="relative" w="100%" h="100%" minH="15rem" {...props}>
            <Box
                pos="absolute"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
            >
                <Spinner color="darkGray" size="xl" />
            </Box>
        </Box>
    );
});

export default BasicLoadingSpinner;
