import { StoreOrder } from "models/store";
import { useAuthenticatedRequest } from "./useRequests";
import { useQuery } from "@tanstack/react-query";
import { PageRequest, PagedResult } from "models/common";
import { pageRequestToUrlSearchParams } from "util/helpers";

const useStaffOrders = (pageRequest: PageRequest) => {
    const options = pageRequestToUrlSearchParams(pageRequest);

    const get = useAuthenticatedRequest<PagedResult<StoreOrder>>({
        method: "get",
        url: `/orders/all?${options}`
    });

    return useQuery(["staff", "orders", "all", pageRequest], get);
};

export default useStaffOrders;