import { useQuery } from "@tanstack/react-query";
import { DeadMessagePage, DeadMessageType } from "models/deadMessages";
import { useAuthenticatedRequest } from "./useRequests";

const makeAxiosRequest = (ep: string, fromId: number) => {
    return {
        method: "get",
        url: `/dead-messages/${ep}?fromId=${fromId}`
    };
}

const getEndpoint = (type: DeadMessageType): string => {
    switch (type) {
        case DeadMessageType.BatchCreated:
            return 'batch-created';
        case DeadMessageType.BatchPurchased:
            return 'batch-purchased';
        case DeadMessageType.Fulfillment:
            return 'fulfillment';
        default:
            throw new Error('invalid dead message type');
    }
}

const useDeadMessages = (type: DeadMessageType, startingFromId: number) => {
    const getDeadMessages = useAuthenticatedRequest<DeadMessagePage>(
        makeAxiosRequest(getEndpoint(type), startingFromId)
    );

    return useQuery(["dead-messages", type, startingFromId], getDeadMessages);
};

export default useDeadMessages;