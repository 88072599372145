import { useMutation } from "@tanstack/react-query";
import { useAuthenticatedRequestCreator } from "./useRequests";

export type ReturnCardsRequest = {
    packageId: string;
    recipientName: string;
    address1: string;
    address2: string | null;
    city: string;
    state: string;
    zipCode: string;
    country: string;
    parcelTemplate: string;
};

export type ReturnCardsResult = {
    id: string;
    accountId: string;
    packageId: string;
    labelTransactionId: string;
    labelUrl: string;
    trackingNumber: string;
    shippingCostInCents: number;
    timestamp: string;
};

const useReturnCardsToCustomer = () => {
    const post = useAuthenticatedRequestCreator<ReturnCardsResult, ReturnCardsRequest>(
        (req: ReturnCardsRequest) => ({
            method: "post",
            url: "/packages/return-cards",
            data: req
        })
    );

    const { mutateAsync: returnCards, isLoading } = useMutation(post);

    return { returnCards, isLoading };
}

export default useReturnCardsToCustomer;