import {
    ArrowLeftOutlined,
    ArrowRightOutlined,
    AuditOutlined,
    CheckCircleOutlined,
    FormOutlined,
    InfoCircleOutlined,
    WarningOutlined
} from "@ant-design/icons";
import {
    Button,
    Col,
    Divider,
    Form,
    Input,
    Modal,
    Radio,
    Row,
    Steps
} from "antd";
import { FormInstance, RuleObject } from "antd/lib/form";
import TextArea from "antd/lib/input/TextArea";
import Currency from "components/Currency";
import CustomerSelector from "components/CustomerSelector";
import useCreateTransaction from "hooks/useCreateTransaction";
import { ApplicationUser } from "models/applicationUsers";
import { useState } from "react";
import { dollarsToCents, toDisplayName } from "util/helpers";

const { Step } = Steps;

enum AdjustmentType {
    Credit = 1,
    Debit = 2
}

enum ModalSteps {
    Details = 0,
    Confirm = 1,
    Result = 2
}

interface ManualAdjustmentForm {
    amount: string;
    amountInCents: number;
    type: AdjustmentType;
    customer: ApplicationUser | null;
    description: string;
}

interface ManualAdjustmentTransaction {
    amountInCents: number;
    customer: ApplicationUser;
    customerDisplayName: string;
    description: string;
}

const convertFormToTransaction = (
    form: ManualAdjustmentForm
): ManualAdjustmentTransaction => {
    const { customer, description, amountInCents, type } = form;
    const realizedAmtInCents =
        type === AdjustmentType.Credit ? amountInCents : -amountInCents;

    return {
        customer: customer!,
        customerDisplayName: toDisplayName(customer),
        amountInCents: realizedAmtInCents,
        description
    };
};

const DetailsStep = (props: {
    form: FormInstance<ManualAdjustmentForm>;
    active: boolean;
}) => {
    const { form, active } = props;

    if (!active) {
        return <></>;
    }

    const amountValidator = (_: RuleObject, price: number) => {
        if (isNaN(price)) {
            return Promise.reject(new Error("Not a valid amount!"));
        } else if (price <= 0) {
            return Promise.reject(
                new Error("Amount must be greater than zero!")
            );
        }
        form.setFieldsValue({ amountInCents: price });
        return Promise.resolve();
    };

    return (
        <Form form={form} initialValues={{ amount: "" }} layout="vertical">
            <Form.Item
                label="Customer"
                name="customer"
                rules={[
                    {
                        required: true,
                        message: "You must select a customer!"
                    }
                ]}
            >
                <CustomerSelector
                    onSelected={customer => {
                        form.setFieldsValue({ customer });
                    }}
                    onClear={() => {
                        form.setFieldsValue({ customer: null });
                    }}
                    initialEntity={form.getFieldValue("customer")}
                />
            </Form.Item>
            <Row gutter={[16, 0]}>
                <Col span={12}>
                    <Form.Item
                        label="Amount"
                        name="amount"
                        rules={[
                            {
                                required: true,
                                validator: amountValidator,
                                transform: dollarsToCents
                            }
                        ]}
                    >
                        <Input prefix="$" suffix="USD" />
                    </Form.Item>
                    <Form.Item hidden name="amountInCents">
                        <Input type="hidden" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label="Type"
                        name="type"
                        rules={[
                            {
                                required: true,
                                message: "You must select credit or debit!"
                            }
                        ]}
                    >
                        <Radio.Group id="adjustment-type">
                            <Radio.Button
                                value={AdjustmentType.Credit}
                                className="adj-credit"
                            >
                                Credit
                            </Radio.Button>
                            <Radio.Button
                                value={AdjustmentType.Debit}
                                className="adj-debit"
                            >
                                Debit
                            </Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item
                label="Description"
                name="description"
                rules={[
                    {
                        required: true,
                        message:
                            "You must specify a reason for the transaction!"
                    }
                ]}
            >
                <TextArea
                    rows={2}
                    placeholder="A brief description of the reason for this transaction"
                />
            </Form.Item>
        </Form>
    );
};

const ConfirmationStep = (props: {
    data: ManualAdjustmentTransaction | undefined;
    active: boolean;
}) => {
    const { active, data } = props;
    if (!active || data === undefined) {
        return <></>;
    }
    const { amountInCents, customerDisplayName, description } = data;

    return (
        <>
            <div id="confirm-banner">Confirm Information</div>
            <div id="confirm-message">
                <div className="content">
                    <span className="bold-font">{customerDisplayName}</span>{" "}
                    will recieve an adjustment of{" "}
                    <Currency cents={amountInCents} /> to their account.
                </div>
                <div className="content">
                    <span className="bold-font">Reason:</span> {description}
                </div>
            </div>
        </>
    );
};

const ResultsStep = (props: {
    data: ManualAdjustmentTransaction | undefined;
    active: boolean;
    success: boolean;
}) => {
    const { active, data, success } = props;

    if (!active || data === undefined) {
        return <></>;
    }

    const { amountInCents, customerDisplayName } = data;

    return (
        <div id="adjustment-result">
            {success && (
                <>
                    <div className="result success">
                        <CheckCircleOutlined />
                    </div>
                    <div className="content">
                        An adjustment of <Currency cents={amountInCents} /> has
                        been applied to {customerDisplayName}.
                    </div>
                </>
            )}

            {!success && (
                <>
                    <div className="result error">
                        <WarningOutlined />
                    </div>
                    <div className="content">
                        An error occurred when attempting to apply an adjustment
                        of <Currency cents={amountInCents} /> to{" "}
                        {customerDisplayName}
                    </div>
                </>
            )}
        </div>
    );
};

const ManualAdjustmentModal = (props: { onSuccess: () => void }) => {
    const [visible, setVisible] = useState<boolean>(false);
    const [step, setStep] = useState<ModalSteps>(ModalSteps.Details);
    const [form] = Form.useForm<ManualAdjustmentForm>();
    const [transaction, setTransaction] = useState<
        ManualAdjustmentTransaction | undefined
    >();
    const [working, setWorking] = useState<boolean>(false);
    const [result, setResult] = useState<boolean>(false);
    const { createManualAdjustment } = useCreateTransaction();

    const closeAndResetModal = () => {
        setVisible(false);
        setStep(ModalSteps.Details);
        setTransaction(undefined);
        setResult(false);
        form.resetFields();
    };

    const submit = async () => {
        const { customer, description, amountInCents } = transaction!;

        setWorking(true);
        try {
            await createManualAdjustment({
                customer,
                description,
                amountInCents
            });
            setResult(true);
            props.onSuccess();
        } catch {
            setResult(false);
        } finally {
            setWorking(false);
        }
    };

    const nextStep = async () => {
        switch (step) {
            case ModalSteps.Details:
                await form.validateFields().then(async form => {
                    const transaction = convertFormToTransaction(form);
                    setTransaction(transaction);
                    setStep(step + 1);
                });
                break;
            case ModalSteps.Confirm:
                await submit();
                setStep(step + 1);
                break;
            case ModalSteps.Result:
                closeAndResetModal();
                break;
        }
    };

    const previousStep = () => {
        switch (step) {
            case ModalSteps.Details:
                closeAndResetModal();
                break;
            default:
                setStep(step - 1);
        }
    };

    const okText = working ? (
        "Submitting..."
    ) : step === ModalSteps.Result ? (
        "Done"
    ) : (
        <>
            Next <ArrowRightOutlined />
        </>
    );

    const cancelText =
        step === ModalSteps.Details ? (
            "Cancel"
        ) : (
            <>
                <ArrowLeftOutlined /> Prev
            </>
        );

    return (
        <>
            <Button
                id="manual-adjustment"
                type="primary"
                onClick={() => setVisible(true)}
            >
                Initiate Credit / Debit
            </Button>
            <Modal
                className="manual-adjustment-modal"
                title="Initiate Credit / Debit"
                closable={false}
                keyboard={false}
                maskClosable={false}
                destroyOnClose={true}
                visible={visible}
                onOk={nextStep}
                confirmLoading={working}
                okText={okText}
                cancelText={cancelText}
                onCancel={previousStep}
                cancelButtonProps={{
                    disabled: working || step === ModalSteps.Result
                }}
            >
                <Steps size="small" current={step}>
                    <Step title="Details" icon={<FormOutlined />} />
                    <Step title="Confirm" icon={<AuditOutlined />} />
                    <Step title="Result" icon={<InfoCircleOutlined />} />
                </Steps>
                <Divider />
                <DetailsStep form={form} active={step === ModalSteps.Details} />
                <ConfirmationStep
                    data={transaction}
                    active={step === ModalSteps.Confirm}
                />
                <ResultsStep
                    data={transaction}
                    success={result}
                    active={step === ModalSteps.Result}
                />
            </Modal>
        </>
    );
};

export default ManualAdjustmentModal;
