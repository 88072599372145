export type UnsoldCardBatch = {
    id: string;
    name: string;
    generated: string;
    items: number;
    pulled: number;
};

export type UnsoldCardWindow = {
    hasPreviousCard: boolean;
    hasNextCard: boolean;
    unsoldCardBatchName: string;
    itemCount: number;
    itemNumber: number;
    previousCard: UnsoldCard | null;
    currentCard: UnsoldCard;
    nextCard: UnsoldCard | null;
    nextUntouchedCardId: string | null;
};

export type UnsoldCard = {
    cardId: string;
    unsoldCardBatchId: string;
    title: string;
    frontImage: string | null;
    backImage: string | null;
    location: string;
    status: UnsoldCardStatus;
    originalUploadDate: string;
    validSKU: boolean;
};

export enum UnsoldCardStatus {
    Unknown = 0,
    Waiting = 1,
    Pulled = 2,
    NotPulled = 3
}

export type UnsoldCardBatchCreationResult = {
    unsoldCardCount: number;
    unsoldCardBatchCount: number;
};