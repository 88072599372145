import { PackageType } from "models/packages";
import { useAuthenticatedRequest } from "./useRequests";
import { useQuery } from "@tanstack/react-query";

/**
 * Based on the role of the authenticated user, returns all enabled package types (staff/admin), 
 * or just package types that apply to the customer (user)
 */
const useAvailablePackageTypes = (withFees: boolean = false) => {
    const getTypes = useAuthenticatedRequest<PackageType[]>({
        url: `/package-types/available?withFees=${withFees}`,
        method: "GET",
    });

    return useQuery(["package-types", "available", withFees], getTypes);
};

export default useAvailablePackageTypes;