import { Box, HStack, Text, VStack } from "@chakra-ui/react";
import { Alert, Button, Card, Checkbox, Col, Divider, Form, InputNumber, List, Radio, Row, Select } from "antd";
import { FormInstance, Rule } from "antd/lib/form";
import Search from "antd/lib/input/Search";
import { StaffRoutes } from "appRoutePaths";
import ShippingAddress from "components/staff/Shipping/ShippingAddress";
import {
    DefaultShipmentSelectOption,
    NewShipmentSelectOption,
    NewShipmentSelectOptions,
    useCustomShipmentRates,
    useNewShipmentLabel,
    useShipmentByFriendlyId
} from "hooks/useShipmentLabelReplacement";
import { Shipment, ShipmentCarrierNames, ShipmentTemplates, ShippingRate } from "models/shipping";
import moment from "moment-timezone";
import { FC, useEffect, useState } from "react";
import { centsToDollars } from "util/helpers";

const DateFormat = 'MM/DD/YYYY LT';

const dimensionRules: Rule[] = [{ required: true, type: 'number', min: 1 }];

export interface Dimensions {
    length: number;
    width: number;
    height: number;
    weight?: number;
}

type LabelOptions = Dimensions & {
    rateId?: string;
    requireSignature: boolean;
    weightLbs: number;
    weightOz: number;
}

const ShippingRateCard: FC<{ rate: ShippingRate }> = ({ rate }) => {
    const { id, amountInCents, carrier, isTemplate, serviceLevel, providerLogoUrl } = rate;
    const title = isTemplate
        ? `${centsToDollars(amountInCents)} - ${ShipmentTemplates[id]}`
        : centsToDollars(amountInCents);

    return <Card title={title} style={{ width: 300, backgroundColor: 'rgba(from var(--dcs-dark-blue) r g b / 0.03)' }} className="rate-card">
        <Row style={{ alignItems: 'center' }} gutter={[16, 16]}>
            <Col><img src={providerLogoUrl} alt='logo' /></Col>
            <Col>{ShipmentCarrierNames[carrier]} {serviceLevel}</Col>
        </Row>
    </Card>;
}

export const ShipmentLabelForm: FC<{
    shipment: Shipment | undefined,
    onLabelRequest: (opt: NewShipmentSelectOption, dims?: Dimensions) => Promise<void>,
    working: boolean
}> = ({ shipment, onLabelRequest, working }) => {
    const [selectedOption, setSelectedOption] = useState<NewShipmentSelectOption | undefined>();
    const [form] = Form.useForm<LabelOptions>();

    useEffect(() => {
        setSelectedOption(DefaultShipmentSelectOption);
    }, [shipment]);

    if (!shipment) {
        return null;
    }

    const submit = async () => {
        if (!selectedOption!.custom) {
            await onLabelRequest(selectedOption!);
        } else {
            const dims = await form.validateFields();
            await onLabelRequest(selectedOption!, dims);

            form.resetFields();
        }
    }

    return <VStack alignItems='start' width='100%'>
        <HStack alignItems='start'>
            <Box fontSize={20} mr={100}>
                <Text mb={5}>Shipment #{shipment.friendlyId}</Text>
                <Text>{shipment.destination.recipientName}</Text>
                <ShippingAddress address={shipment.destination} />
                <Text>Weight: {shipment.options.dimensions.weightInOz}oz</Text>
            </Box>
            {shipment.labels.length > 0 && <List
                header={<div>Label History</div>}
                dataSource={shipment.labels}
                renderItem={label => <List.Item>
                    {moment(label.generated).format(DateFormat)} <Button type="link" href={label.labelUrl} target="_blank">Label PDF</Button>
                </List.Item>}
            />}
        </HStack>
        <Select
            value={selectedOption}
            disabled={working}
            options={NewShipmentSelectOptions}
            size="large"
            style={{ width: '100%' }}
            placeholder="Select New Shipment Method"
            onChange={(_, o) => {
                setSelectedOption(o as NewShipmentSelectOption);
                form.resetFields();
            }}
        />
        <LabelCustomization
            form={form}
            shipmentFriendlyId={shipment.friendlyId}
            allowCustom={selectedOption?.custom}
            disabled={!selectedOption || working}
            onSubmit={submit}
        />
    </VStack>;
}

const LabelCustomization: FC<{
    form: FormInstance<LabelOptions>,
    shipmentFriendlyId: number,
    allowCustom?: boolean,
    disabled: boolean,
    onSubmit: () => Promise<void>,
}> = ({ form, shipmentFriendlyId, allowCustom, disabled, onSubmit }) => {
    const [rates, setRates] = useState<ShippingRate[]>([]);
    const [rateLabel, setRateLabel] = useState<string | undefined>();
    const [valuesChanged, setValuesChanged] = useState<boolean>(false);
    const { rateId, weightLbs, weightOz } = Form.useWatch([], form) ?? {};
    const { requestRates, isLoading } = useCustomShipmentRates();
    const [weightError, setWeightError] = useState<string | null>(null);

    useEffect(() => {
        const totalWeightInOz = ((weightLbs ?? 0) * 16) + (weightOz ?? 0);

        form.setFieldValue('weight', totalWeightInOz);
    }, [form, weightLbs, weightOz]);

    const validateWeight = async () => {
        try {
            const dims = await form.validateFields();
            setWeightError(null);

            return dims;
        } catch (error) {
            setWeightError(form.getFieldError('weight')[0]);

            return null;
        }
    }

    const getRates = async () => {
        const dims = await validateWeight();
        if (!dims) {
            return;
        }

        setRates([]);
        form.setFieldValue('rateId', null);
        const result = await requestRates({
            shipmentFriendlyId,
            ...dims,
        });
        setRates(result);

        const { length, width, height, weightLbs, weightOz, requireSignature } = dims;
        setRateLabel(`Rates for ${length}" x ${width}" x ${height}" — ${weightLbs ?? 0} lbs, ${weightOz ?? 0} oz — ${requireSignature ? 'Signature Required' : 'No Signature'}`);
        setValuesChanged(false);
    }

    const handleChanges = (data: any) => {
        const { rateId: updatedRateId } = data;
        if (!updatedRateId) {
            setValuesChanged(true);
        }
    }

    return <>
        {allowCustom && <Form form={form}
            initialValues={{ weight: 0 }}
            onValuesChange={handleChanges}
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 12 }}
            style={{ marginTop: '20px' }}
            disabled={disabled}>
            <VStack>
                <HStack w='100%' alignItems='baseline'>
                    <Form.Item name="length" label="Length" rules={dimensionRules}>
                        <InputNumber placeholder="Inches" />
                    </Form.Item>
                    <Form.Item name="width" label="Width" rules={dimensionRules}>
                        <InputNumber placeholder="Inches" />
                    </Form.Item>
                    <Form.Item name="height" label="Height" rules={dimensionRules}>
                        <InputNumber placeholder="Inches" />
                    </Form.Item>
                </HStack>
                <HStack w='100%' alignItems='baseline'>
                    <Form.Item name="weightLbs" label="Lbs." rules={[{ type: 'number', min: 0 }]}>
                        <InputNumber placeholder="0" step="1" />
                    </Form.Item>
                    <Form.Item name="weightOz" label="Oz." rules={[{ type: 'number', min: 0, max: 15 }]}>
                        <InputNumber placeholder="0" step="1" />
                    </Form.Item>
                    <Form.Item name="requireSignature" valuePropName="checked">
                        <Checkbox style={{ marginLeft: '30px', width: '250px' }}>Require Signature for Delivery?</Checkbox>
                    </Form.Item>
                </HStack>
                {weightError && <HStack w='100%'>
                    <div className="ant-form-item-explain-error">{weightError}</div>
                </HStack>}
                {rates.length > 0 && <>
                    <Form.Item name="rateId">
                        <Radio.Group>
                            <Divider>{rateLabel}</Divider>
                            <Row className="no-radio" gutter={[8, 8]} style={{ width: '660px' }}>
                                {rates.map(r => (<Col span={12}>
                                    <Radio key={r.id} value={r.id} style={{ fontSize: '20px' }}>
                                        <ShippingRateCard rate={r} />
                                    </Radio>
                                </Col>))}
                            </Row>
                        </Radio.Group>
                    </Form.Item>
                </>}
            </VStack>
            <Form.Item name="weight" hidden rules={[{ ...dimensionRules[0], message: "Weight in lbs and/or oz must be provided" }]}>
                <InputNumber />
            </Form.Item>
        </Form>}
        <HStack pt={'10px'}>
            {allowCustom && <Button
                size="large"
                type="primary"
                disabled={disabled || !valuesChanged}
                loading={isLoading}
                onClick={getRates}>
                Get Rates
            </Button>}
            <Button
                size="large"
                type="primary"
                disabled={disabled || (allowCustom && !rateId)}
                loading={isLoading}
                onClick={onSubmit}>
                Request New Label
            </Button>
        </HStack>
    </>;
};

const ShipmentLabelReplacement = () => {
    const [id, setId] = useState<number | undefined>();
    const [success, setSuccess] = useState<boolean>(false);
    const { isLoading, data, isError, refetch } = useShipmentByFriendlyId(id);
    const { requestNewLabel, requesting } = useNewShipmentLabel();

    const working = (isLoading && !!id) || requesting;

    const search = (term: string) => {
        setSuccess(false);

        var id = Number(term);
        if (id === 0 || isNaN(id)) {
            setId(undefined);
        }

        setId(id);
    }

    const submitRequest = async (opt: NewShipmentSelectOption, dims?: Dimensions) => {
        setSuccess(false);
        const label = await requestNewLabel({ id: id!, option: opt, ...dims });
        window.open(label.labelUrl, "_blank");
        await refetch();
        setSuccess(true);
    }

    return <Box p={50} display='flex'>
        <VStack w='100%'>
            <Box w='100%'>
                <HStack justifyContent='space-between'>
                    <VStack alignItems='start'>
                        <Box fontSize={36}>Replace Shipment Label</Box>
                        <Button style={{ padding: 0 }} type="link" href={StaffRoutes.shipmentBatches.url}>&#8592;Back to Shipment Batches</Button>
                    </VStack>
                </HStack>
            </Box>
            <Divider />
            <Box
                minW={375}
                alignItems='center'
                justifyContent='center'
                p={25}
                border='1px solid gainsboro'
                borderRadius={10}
                bgColor='#f0f4fb7f'>
                <Search
                    autoFocus
                    enterButton
                    placeholder="Shipment #"
                    size="large"
                    onSearch={search}
                    type="number"
                    disabled={working}
                    style={{ marginBottom: '10px' }} />
                {isError && <Alert
                    style={{ marginBottom: '5px' }}
                    type="error"
                    message="Not Found"
                    description={`No shipment with an id of ${id} could be found.`}
                    showIcon />}
                {success && <Alert
                    style={{ marginBottom: '5px' }}
                    type="success"
                    message="Label Generated"
                    description={<>
                        <Text>A new label has been created for this shipment, and any previous label has been voided.</Text>
                        <Button type="link" href={data!.labels[0].labelUrl} style={{ padding: 0 }} target="_blank">Open Label PDF</Button>
                    </>}
                    showIcon />}
                <ShipmentLabelForm key={data?.id} shipment={data} onLabelRequest={submitRequest} working={working} />
            </Box>
        </VStack>
    </Box>;
}

export default ShipmentLabelReplacement;