import { Button, Flex } from "@chakra-ui/react";
import { FC } from "react";
import { Link } from "react-router-dom";

const CallToActionBubble: FC<{
    message: string;
    buttonText: string;
    buttonLink: string;
}> = ({ message, buttonText, buttonLink }) => (
    <Flex
        justifyContent="space-between"
        bg="var(--dcs-blue)"
        color="var(--dcs-white)"
        rounded={7}
        w="100%"
        px={3}
        py={2}
        alignItems="center"
        my={1}
    >
        <Flex fontSize={20} fontFamily="body">
            {message}
        </Flex>
        <Button as={Link} minWidth="7rem" variant="primary" to={buttonLink}>
            {buttonText}
        </Button>
    </Flex>
);

export default CallToActionBubble;
