import { useToast } from "@chakra-ui/react";
import { PublicRoutes } from "appRoutePaths";
import { registerMessageHandler } from "messages/messageBus";
import { ErrorOccurred, messageTypes } from "messages/messages";
import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const useGlobalMessageHandlers = () => {
    const navigate = useNavigate();

    const toast = useToast({
        styleConfig: {
            baseStyle: {
                bg: "red",
                container: {
                    bg: "red",
                },
            },
        },
    });

    const handleError = useCallback(
        (message: ErrorOccurred) => {
            const showErrorNotification = (message: ErrorOccurred) => {
                const toastID = `error-toast-${message.id}`;
                if (!toast.isActive(toastID)) {
                    toast({
                        id: toastID,
                        title: "Error Occurred",
                        description: message.errorMessage,
                        status: "error",
                        duration: 9000,
                        isClosable: true,
                        position: "top-right",
                        variant: "solid",
                        containerStyle: {
                            backgroundColor: "darkGray",
                            textTransform: "capitalize",
                        },
                    });
                }
            };
            if (message.status === 401) {
                if (navigate) {
                    navigate(PublicRoutes.login.routePath);
                }
            } else {
                showErrorNotification(message);
            }
        },
        [navigate, toast]
    );

    useEffect(() => {
        registerMessageHandler<ErrorOccurred>(messageTypes.error, handleError);
    }, [handleError]);
};

export default useGlobalMessageHandlers;
