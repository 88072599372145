import { LoadingOutlined, SearchOutlined, StopOutlined } from "@ant-design/icons";
import { Text } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { Col, Form, Input, Rate, Row } from "antd";
import { useAuthenticatedRequestCreator } from "hooks/useRequests";
import { TyperForm } from "models/cardIngestion";
import { CssVariables } from "models/common";
import { StaffPackage } from "models/packages";
import React, { FC, useCallback, useEffect, useState } from "react";

interface PackageSelectorProps {
    packageFriendlyId: number | null;
    visible: boolean;
    setPackageId: (packageId: string | null, packageFriendlyId: number | null) => void;
    completedTitle: boolean;
    previousPackageId?: string | null;
}

const usePackageByFriendlyId = () => {
    const get = useAuthenticatedRequestCreator<StaffPackage, number>(
        (id: number) => ({
            method: "get",
            url: `/packages/${id}`
        }), [400, 404]
    );

    const { mutateAsync: findPackage, isLoading } = useMutation(get);

    return { findPackage, isLoading };
}

const PackageSelector: FC<PackageSelectorProps> = ({ packageFriendlyId, visible, setPackageId, completedTitle, previousPackageId }) => {
    const form = Form.useFormInstance<TyperForm>();
    const [selectedPackage, setSelectedPackage] = useState<StaffPackage | null>(null);
    const { findPackage, isLoading } = usePackageByFriendlyId();
    const [requirePackageId, setRequirePackageId] = useState<boolean>(() => {
        // if we're loading a completed card title w/o a package id
        if ((completedTitle && !packageFriendlyId) ||
            // or it's an incomplete card title and the previous card saved didnt have a package id,
            (!completedTitle && previousPackageId === null)) {
            // start off by making the package id not required
            return false;
        }

        return true;
    });

    const onSearch = useCallback(async (value: string | number) => {
        const friendlyId = Number(value);

        try {
            const pkg = await findPackage(friendlyId);
            setPackageId(pkg.id, pkg.friendlyId);
            setSelectedPackage(pkg);
            setRequirePackageId(true);
        } catch {
            // the friendly id propegates through the props from the context, so even though
            // it's invalid, we set it here to avoid the textbox clearing itself
            setPackageId(null, friendlyId);
            setSelectedPackage(null);
        }
    }, [findPackage, setPackageId, setSelectedPackage]);

    // if we're loading a typed card title, fetch the package data.
    // the endpoint is explicitly cached on an id basis (for 1 hour)
    // so loading the same package over and over won't spam the server
    useEffect(() => {
        if (packageFriendlyId) {
            form.setFieldsValue({ packageFriendlyId });

            if (!selectedPackage) {
                onSearch(packageFriendlyId);
            }

            form.validateFields(['packageFriendlyId']);
        }
    }, [form, packageFriendlyId, selectedPackage, onSearch]);

    const ensureSelectedPackage = () => {
        if (requirePackageId && !selectedPackage) {
            return Promise.reject(new Error('Invalid Package ID'));
        }
        return Promise.resolve();
    };

    if (!visible) {
        return null;
    }

    return <Row gutter={32}>
        <Col span={4}>
            <Form.Item name="packageId" hidden>
                <Input />
            </Form.Item>

            <Form.Item
                label="Package ID"
                name="packageFriendlyId"
                validateFirst={true}
                validateTrigger={false}
                rules={[{
                    required: requirePackageId,
                    message: 'Package ID is required'
                }, {
                    validator: ensureSelectedPackage
                }]}
            >
                <Input
                    onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                        if (e.code === "Enter") {
                            onSearch(e.currentTarget.value)
                        }
                    }}
                    onBlur={e => onSearch(e.target.value)}
                    type="number"
                    prefix={(isLoading ? <LoadingOutlined /> : <SearchOutlined />)}
                    addonAfter={<Rate
                        count={1}
                        tabIndex={-1}
                        tooltips={["Toggle package ID requirement"]}
                        character={[<StopOutlined key={packageFriendlyId} />]}
                        style={{ color: CssVariables.errorRed, fontSize: '14px' }}
                        onChange={(n) => setRequirePackageId(n === 0)}
                        value={requirePackageId ? 0 : 1}
                    />}
                />
            </Form.Item>
        </Col>
        <Col span={20} style={{ alignSelf: 'center' }}>
            {selectedPackage && <Text fontSize={30}>
                {selectedPackage.customerFirstName} {selectedPackage.customerLastName} ({selectedPackage.customerEmail})
            </Text>}
        </Col>
    </Row>;
};

export default PackageSelector;