import { Box, Spinner } from "@chakra-ui/react";
import { FC } from "react";

const LoadingSpinnerOverlay: FC<{ isLoading: boolean }> = ({ isLoading }) => (
    <Box
        pos="fixed"
        w="100vw"
        h="100vh"
        bg="darkGray"
        opacity={isLoading ? 0.3 : 0}
        top={0}
        left={0}
        zIndex={10}
        pointerEvents={isLoading ? "all" : "none"}
        transition="opacity 0.3s"
    >
        <Box pos="fixed" top="50%" left="50%" transform="translate(-50%, -50%)">
            <Spinner color="white" size="xl" />
        </Box>
    </Box>
);

export default LoadingSpinnerOverlay;
