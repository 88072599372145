import KeyValueTableFilter, { KeyValueRecord } from "components/KeyValueTableFilter";
import useAvailablePackageTypes from "hooks/useAvailablePackageTypes";
import { ClearTableFilterRequest } from "models/common";
import { ExtendedFilterDropdownProps } from "util/table";

const TypeFilter = ({
    prefixCls,
    visible,
    confirm,
    selectedKeys,
    setSelectedKeys,
    clearFilters,
    onFilter,
}: ExtendedFilterDropdownProps) => {
    const { isLoading, data } = useAvailablePackageTypes();

    const kvps: KeyValueRecord = isLoading
        ? []
        : Object.assign({}, ...data!.map(t => ({ [t.displayName]: t.id })));

    return <KeyValueTableFilter
        prefixCls={prefixCls}
        visible={visible}
        confirm={confirm}
        selectedKeys={selectedKeys}
        setSelectedKeys={setSelectedKeys}
        clearFilters={clearFilters}
        onFilter={onFilter}
        clearTableFilterRequest={ClearTableFilterRequest.Type}
        keyValuePairs={kvps}
    />;
};

export default TypeFilter;