import { Box, HStack, Text, VStack } from "@chakra-ui/react";
import { Divider, Form, Input, Select, Skeleton } from "antd";
import useCountryOptions from "hooks/useCountryOptions";
import useProfile from "hooks/useProfile";
import { UserShippingAddress } from "models/store";
import { FC, useEffect } from "react";

const ShippingAddress: FC<{
    onAddressChanged: (addr: UserShippingAddress | undefined) => void
}> = ({ onAddressChanged }) => {
    const { isLoading, data } = useProfile();
    const [form] = Form.useForm();
    const countries = useCountryOptions();

    useEffect(() => {
        if (!isLoading && data) {
            form.validateFields();
        }
    }, [form, data, isLoading]);

    const onFormChanged = () => {
        const isValid = form.getFieldsError().every((item) => item.errors.length === 0);

        if (isValid) {
            const data = form.getFieldsValue();
            onAddressChanged({ ...data });
        } else {
            onAddressChanged(undefined);
        }
    };

    if (isLoading) {
        return <Skeleton />;
    }

    return <Box p={5} display='flex'>
        <VStack align='start'>
            <Text fontSize={20} color="var(--dcs-black)">
                Shipping Address
            </Text>
            <Divider />
            <Form
                style={{ width: '100%' }}
                layout="vertical"
                form={form}
                onFieldsChange={onFormChanged}
                initialValues={data}>
                <HStack mb="24px">
                    <Form.Item
                        style={{ marginBottom: 0 }}
                        label="First Name"
                        name="firstName"
                        rules={[{ required: true }]}
                    >
                        <Input size="large" />
                    </Form.Item>
                    <Form.Item
                        label="Last Name"
                        name="lastName"
                        rules={[{ required: true }]}
                    >
                        <Input size="large" />
                    </Form.Item>
                </HStack>
                <Form.Item
                    label="Address Line 1"
                    name="addressLine1"
                    rules={[{ required: true }]}
                >
                    <Input size="large" />
                </Form.Item>

                <Form.Item
                    label="Address Line 2"
                    name="addressLine2"
                >
                    <Input size="large" />
                </Form.Item>

                <HStack mb="24px">
                    <Form.Item
                        style={{ marginBottom: 0 }}
                        label="City"
                        name="city"
                        rules={[{ required: true }]}
                    >
                        <Input size="large" />
                    </Form.Item>
                    <Form.Item
                        label="State"
                        name="state"
                        rules={[{ required: true }]}
                    >
                        <Input size="large" />
                    </Form.Item>
                    <Form.Item
                        label="Zip Code"
                        name="zipCode"
                        rules={[{ required: true }]}
                    >
                        <Input size="large" />
                    </Form.Item>
                </HStack>
                <Form.Item
                    label="Country"
                    name="country"
                    rules={[{ required: true }]}>
                    <Select size="large" options={countries} />
                </Form.Item>
            </Form>
        </VStack>
    </Box>;
};

export default ShippingAddress;