import FilterTag from "components/FilterTag";
import { ApplicationUser } from "models/applicationUsers";
import { ClearTableFilterRequest } from "models/common";
import { toDisplayName } from "util/helpers";

export interface StaffTransactionFilterDetail {
    description?: string | undefined;
    type?: string | undefined;
    customer?: ApplicationUser | undefined;
}

const StaffTransactionFilter = (props: {
    detail: StaffTransactionFilterDetail;
}) => {
    const { description, type, customer } = props.detail;

    const displayName = toDisplayName(customer);

    return (
        <div id="staff-card-active-filters">
            {description && (
                <FilterTag
                    filter={ClearTableFilterRequest.Name}
                    text={`Desc. contains '${description}'`}
                />
            )}
            {type && (
                <FilterTag
                    filter={ClearTableFilterRequest.Type}
                    text={`Type: ${type}`}
                />
            )}
            {customer && (
                <FilterTag
                    filter={ClearTableFilterRequest.Customer}
                    text={`Customer: ${displayName}`}
                />
            )}
        </div>
    );
};

export default StaffTransactionFilter;
