import { useMutation, useQuery } from "@tanstack/react-query";
import { useAuthenticatedRequest, useAuthenticatedRequestCreator } from "./useRequests";
import { AuthenticatorBulkBatchToFulfill } from "models/shipping";

const useAuthenticatorBulkMissingLabel = (enabled: boolean) => {
    const query = useAuthenticatedRequest<AuthenticatorBulkBatchToFulfill[]>({
        method: "get",
        url: "/shipping/search/ab-missing-label",
    });

    return useQuery(['shipping', 'authenticator-bulk', 'missing-label'], query, { enabled });
}

const useAuthenticatorBulkFulfill = () => {
    const post = useAuthenticatedRequestCreator<void, { batchId: string, trackingNumber: string }>(
        (data) => ({
            method: "post",
            url: "/shipping/batches/ab-fulfill",
            data: data
        })
    );

    const { mutateAsync: fulfill } = useMutation(post);

    return { fulfill };
};

export { useAuthenticatorBulkMissingLabel, useAuthenticatorBulkFulfill };