import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { usePublicRequestCreator } from "./useRequests";

export interface SignUpData {
    firstName: string;
    lastName: string;
    username: string;
    email: string;
    password: string | undefined;
}

export const useRegistration = () => {
    const signUpRequestCreator = usePublicRequestCreator(
        (signUpData: SignUpData) => ({
            method: "post",
            url: "/account/register",
            data: {
                ...signUpData,
                // TODO: Remove when API is updated
                confirmPassword: signUpData.password,
            },
        }),
        [400]
    );

    const { mutateAsync: registerUser, error } =
        useMutation(signUpRequestCreator);

    const typedError = error as AxiosError<{ errors: string[] }>;

    return { registerUser, error: typedError };
};
