import { UnsoldCard, UnsoldCardStatus } from "models/unsoldCards";
import { useAuthenticatedRequestCreator } from "./useRequests";

type TouchCardRequest = {
    unsoldCardBatchId: string;
    cardId: string;
    status: UnsoldCardStatus;
}

const useUnsoldCardPulling = () => {
    const touchCardRequest = useAuthenticatedRequestCreator<boolean, TouchCardRequest>(
        (payload) => ({
            method: "post",
            url: "/unsold-cards/puller/touch-card",
            data: payload
        })
    );

    const touchCard = async (card: UnsoldCard, status: UnsoldCardStatus): Promise<boolean> => {
        const { unsoldCardBatchId, cardId } = card;
        await touchCardRequest({ unsoldCardBatchId, cardId, status });
        return true;
    };

    const pullCard = async (card: UnsoldCard): Promise<boolean> =>
        await touchCard(card, UnsoldCardStatus.Pulled);

    const skipCard = async (card: UnsoldCard): Promise<boolean> =>
        await touchCard(card, UnsoldCardStatus.NotPulled);

    return { pullCard, skipCard };
};

export default useUnsoldCardPulling;