import { Box, Text, VStack } from "@chakra-ui/react";
import { Divider, Result, Select, Space, Timeline } from "antd";
import Search from "antd/lib/input/Search";
import { DefaultOptionType } from "antd/lib/select";
import useAuditLog, { AuditLogType, AuditLogTypeDisplayName } from "hooks/useAuditLog";
import { AuditEntry, PropertyChangedDetail } from "models/auditLog";
import { CssVariables } from "models/common";
import { DisplayDateFormat } from "models/websiteSettings";
import moment from "moment-timezone";
import { FC, useCallback, useEffect, useRef, useState } from "react";

// https://stackoverflow.com/questions/68821299/how-to-align-antdesign-timeline-to-the-left
import { FileExclamationOutlined } from "@ant-design/icons";
import useQueryParams from "hooks/useQueryString";
import '../../styles/staff/timeline.css';

type AuditLogOptionType = DefaultOptionType & { value: AuditLogType, placeholder: string };

const EntityTypes: AuditLogOptionType[] = [
    { label: "Cards", value: "Card", placeholder: "Enter card SKU or ID" },
    { label: "Packages", value: "Package", placeholder: "Enter package ID" },
    { label: "Package Types", value: "PackageType", placeholder: "Enter package type ID" },
    { label: "Shipments", value: "Shipment", placeholder: "Enter shipment ID" },
]

const formatProperty = (prop: PropertyChangedDetail) => {
    let { old: oldValue, new: newValue } = prop.values;

    // this is in no way sustainable and the server should
    // probably return datatype hints
    if (prop.property.endsWith("Date")) {
        oldValue = moment(oldValue).format(DisplayDateFormat);
        newValue = moment(newValue).format(DisplayDateFormat);
    }

    return <>
        <Box as='span' color='red'>{oldValue || 'null'}</Box> ➡ <Box as='span' color='darkGreen'>{newValue || 'null'}</Box>
    </>;
}

const EventDetails: FC<{ entry: AuditEntry }> = ({ entry }) => {
    const { id, isSystemEvent, description, details, displayName } = entry;

    const summary = <>
        <Box as='span' color={isSystemEvent ? CssVariables.errorRed : CssVariables.blue}>{displayName}
        </Box> {description}
    </>;

    if (details) {
        return <Box key={id}>
            {summary}
            {details.map(d => (<Text key={`${id}-${d.property}`}>{d.property}: {formatProperty(d)}</Text>))}
        </Box>
    }

    return summary;
}

const AuditLog = () => {
    const { t, id } = useQueryParams();
    const initialType = EntityTypes.find(et => et.value === t);
    const [type, setType] = useState<AuditLogOptionType | undefined>(initialType);
    const [result, setResult] = useState<{
        type: AuditLogType,
        referenceId: String,
        entries: AuditEntry[]
    } | undefined>();
    const [working, setWorking] = useState<boolean>(false);
    const { query } = useAuditLog();
    // working around infinite render loop because I can't seem
    // to properly memoize query
    const queryAuditLog = useRef(query).current;

    const search = useCallback(async (value: string) => {
        if (!value && value.trim() === '') {
            return;
        }

        setWorking(true);
        setResult(undefined);

        const request = {
            type: type!.value,
            referenceId: value!
        };
        var result = await queryAuditLog(request);

        setResult({ ...request, entries: result });
        setWorking(false);
    }, [queryAuditLog, type]);

    const typeChanged = (_: string, option: AuditLogOptionType | AuditLogOptionType[]) => {
        setType(option as AuditLogOptionType);
    };

    useEffect(() => {
        // if there was request in the query string, execute it on load
        if (type && id) {
            search(id);
        }
    }, [type, id, search]);

    return <Box p={50}>
        <Box fontSize={36} mb={5}>Audit Log</Box>
        <VStack align='start' spacing={5}>
            <Space direction="vertical">
                <Search
                    addonBefore={<Select
                        defaultValue={type?.value}
                        placeholder="Entry Type"
                        style={{ width: '140px' }}
                        onChange={typeChanged}
                        options={EntityTypes}
                    />}
                    defaultValue={id}
                    placeholder={type?.placeholder || "⬅ Select an entry type"} size="large"
                    onSearch={search}
                    disabled={working || !type}
                    enterButton
                    allowClear
                />
            </Space>
            {result && result.entries.length === 0 && <Result
                status='warning'
                icon={<FileExclamationOutlined />}
                title={`No entries for a ${AuditLogTypeDisplayName[result.type]} matching ${result.referenceId}!`}
            />}
            {result && result.entries.length > 0 && <Box className="timeline_container" w='100%'>
                <Box fontSize={20} mb={5} ml={'9px'}>Log entries for {AuditLogTypeDisplayName[result.type]} {result?.referenceId}</Box>
                <Divider />
                <Timeline mode='left'>
                    {result?.entries.map(e => <Timeline.Item key={`item-${e.id}`} label={moment(e.timestamp).format('MM/DD/YYYY LT')} color="gray">
                        <EventDetails key={`detail-${e.id}`} entry={e} />
                    </Timeline.Item>)}
                </Timeline>
            </Box>}
        </VStack>
    </Box>;
}

export default AuditLog;