import { LoadingOutlined } from "@ant-design/icons";
import { Box, HStack, Image, VStack } from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Alert, Button, Col, Input, Modal, Row, Tag } from "antd";
import { useAuthenticatedRequest, useAuthenticatedRequestCreator } from "hooks/useRequests";
import { FC, ReactNode, useState } from "react";
import QRCode from "react-qr-code";

const AppLinks = [
    {
        name: "Authy",
        android: "https://play.google.com/store/apps/details?id=com.authy.authy",
        apple: "https://apps.apple.com/us/app/twilio-authy/id494168017"
    },
    {
        name: "Google Authenticator",
        android: "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2",
        apple: "https://apps.apple.com/us/app/google-authenticator/id388497605"
    },
    {
        name: "Microsoft Authenticator",
        android: "https://play.google.com/store/apps/details?id=com.azure.authenticator",
        apple: "https://apps.apple.com/us/app/microsoft-authenticator/id983156458"
    }
];

const RecoveryCodeFileHeader =
    `**********************************************************
* Each code can only be used once! If you've lost access *
* to your authenticator, you should remove two-factor    *
* from your account until you have a replacement.        *
**********************************************************

`;

interface AuthenticatorUrl {
    url: string;
}

const useTwoFactorSetup = () => {
    const getUrl = useAuthenticatedRequest<AuthenticatorUrl>(({
        method: "get",
        url: "/profile/mfa/authenticator-url"
    }));

    const complete = useAuthenticatedRequestCreator<
        { recoveryCodes: string[] },
        { token: string }
    >(
        (payload) => ({
            method: 'post',
            url: "/profile/mfa/complete-setup",
            data: payload
        }),
        [400]
    );

    const { mutateAsync: completeSetup } = useMutation(complete);

    return {
        authenticatorUrl: useQuery(["profile", "mfa", "authenticator-url"], getUrl, { enabled: false }),
        completeSetup
    };
}

const recoveryCodeObjectUrl = (codes: string[]) => {
    const file = new Blob([
        RecoveryCodeFileHeader,
        codes.join('\r\n')
    ], { type: 'text/plain' });

    return URL.createObjectURL(file);
}

const EnableMFA: FC<{
    onEnabled?: () => void,
    emailConfirmed: boolean,
    trigger?: ReactNode
}> = ({ onEnabled, emailConfirmed, trigger }) => {
    const [open, setOpen] = useState<boolean>(false);
    const [token, setToken] = useState<string>("");
    const [completed, setCompleted] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [working, setWorking] = useState<boolean>(false);
    const [codes, setCodes] = useState<string[]>([]);

    const {
        authenticatorUrl: { isLoading, data, refetch },
        completeSetup
    } = useTwoFactorSetup();

    const openModal = async () => {
        setOpen(true);
        await refetch();
    };

    const closeModal = () => {
        if (completed && onEnabled) {
            onEnabled();
        }

        setOpen(false);
        setToken("");
        setCompleted(false);
        setError(false);
        setCodes([]);
    }

    const turnOnTwoFactor = async () => {
        try {
            setWorking(true);
            setError(false);

            var result = await completeSetup({ token });
            setCodes(result.recoveryCodes);

            setCompleted(true);
        } catch {
            setError(true);
        } finally {
            setWorking(false);
        }
    }

    const busy = isLoading || working;
    trigger ??= <Button type="link">
        Enable Two-Factor Authentication
    </Button>;

    if (!emailConfirmed) {
        return <Button type="link" disabled>Confirm your Email for Two-Factor options</Button>
    }

    return <>
        {<div onClick={openModal}>{trigger}</div>}
        <Modal
            open={open}
            closable={false}
            keyboard={false}
            maskClosable={false}
            destroyOnClose={true}
            confirmLoading={busy}
            title="Enable Two-Factor Authentication"
            width='600px'
            footer={<Button
                onClick={closeModal}
                type={completed ? "primary" : "default"}>
                {completed ? "Close" : "Cancel"}
            </Button>}
        >

            {isLoading && <Box display='flex' justifyContent='center'>
                <LoadingOutlined spin style={{ color: "var(--dcs-blue)", fontSize: '100px' }} />
            </Box>}

            {!isLoading && <VStack>
                <HStack spacing={10} alignItems='start'>
                    <QRCode value={data!.url} />
                    <VStack style={{ marginTop: '-8px' }}>
                        {AppLinks.map(app => (<Box key={app.name} pb={3}>
                            <Box borderBottom='2px solid' w='100%' textAlign='center' fontWeight='bold' mb={2}>{app.name}</Box>
                            <HStack>
                                <a href={app.apple} target="_blank" rel="noreferrer">
                                    <Image src="/img/app_store_badge.svg" alt="Apple App Store Link"></Image>
                                </a>

                                <a href={app.android} target="_blank" rel="noreferrer">
                                    <Image src="/img/play_store_badge.png" alt="Google Play Store Link" width='140px'></Image>
                                </a>
                            </HStack>
                        </Box>))}
                    </VStack>
                </HStack>

                {!completed && <>
                    <Box>
                        Scan the QR code above with your authenticator app of choice, or if you don't have one yet,
                        install one on your mobile device from the recommended list on the right.
                    </Box>
                    <Box>
                        Enter the two-factor code your authenticator app gives you to verify & finalize the setup.
                    </Box>

                    <Box justifyContent='center' display='flex' p={5}>
                        <Input.Group compact>
                            <Input
                                onChange={(e) => setToken(e.target.value)}
                                value={token}
                                style={{ width: 'calc(100% - 70px)' }}
                                autoFocus
                                disabled={busy}
                                onKeyDown={async (e) => {
                                    if (e.key === "Enter") {
                                        await turnOnTwoFactor();
                                    }
                                }} />
                            <Button type="primary" onClick={turnOnTwoFactor} disabled={busy}>Verify</Button>
                        </Input.Group>
                    </Box>
                </>}

                {completed && <Alert
                    showIcon
                    type="success"
                    message="Two-Factor Authentication Enabled!"
                    description={<>
                        <Box>One-Time Recovery Codes - Store These Securely!</Box>
                        <Box my={5}>
                            These codes cannot be retrieved after the window is closed. Each code has
                            a one-time use in place of an authenticator code in the event you lose access to your device.
                            <Button
                                download="dcsports87.com Recovery Codes.txt"
                                type="link"
                                target="_blank"
                                rel="noreferrer"
                                href={recoveryCodeObjectUrl(codes)}
                            >
                                Download Recovery Codes
                            </Button>
                        </Box>
                        <Row gutter={[16, 16]}>
                            {codes.map(code =>
                            (
                                <Col span={8}>
                                    <Tag style={{
                                        fontSize: '20px',
                                        fontFamily: 'monospace',
                                        padding: '6px',
                                    }}>{code}</Tag>
                                </Col>
                            ))}
                        </Row>
                    </>}
                />}

                {error && <Alert
                    showIcon
                    type="error"
                    message="Invalid Code"
                />}
            </VStack>}

        </Modal>
    </>;
};

export default EnableMFA;