import { useMutation } from "@tanstack/react-query";
import { useAuthenticatedRequestCreator } from "hooks/useRequests";
import { ChaseAccount } from "models/chase";
import { TreasurySession } from "models/treasury";

const useTreasuryManagement = (onBehalfOfUserId?: string) => {
    const sessionCreator = useAuthenticatedRequestCreator<TreasurySession, void>(
        () => ({
            method: 'post',
            url: "/treasury/session",
            data: { onBehalfOfUserId }
        }),
        [500]
    );

    const accountStore = useAuthenticatedRequestCreator<void, ChaseAccount>(
        (account) => ({
            method: 'post',
            url: '/treasury/created-account',
            data: { ...account, onBehalfOfUserId }
        }),
        [400]
    );

    const accountDelete = useAuthenticatedRequestCreator<void, string>(
        (externalAccountId) => ({
            method: 'post',
            url: '/treasury/accounts/deactivate',
            data: { externalAccountId }
        }),
        [400, 404]
    );

    const { mutateAsync: createSession, isLoading, isError } = useMutation(sessionCreator);

    const { mutate: storeAccount } = useMutation(accountStore);

    const { mutateAsync: deleteAccount } = useMutation(accountDelete);

    return {
        createSession,
        storeAccount,
        deleteAccount,
        isLoading,
        sessionError: isError
    };
};

export default useTreasuryManagement;