import { Button, Radio, RadioChangeEvent, Select, Space } from "antd";
import { ClearTableFilterRequest } from "models/common";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
    ExtendedFilterDropdownProps,
    handleClearFilterRequest,
} from "util/table";

type DisplayType = 'radio' | 'select' | null | undefined

export interface KeyValueRecord {
    [key: string]: React.Key
}

interface KeyValueTableFilterProps extends ExtendedFilterDropdownProps {
    clearTableFilterRequest: ClearTableFilterRequest,
    keyValuePairs: KeyValueRecord,
    type?: DisplayType
}

const { Option } = Select;

const KeyValueTableFilter = ({
    confirm,
    selectedKeys,
    setSelectedKeys,
    clearFilters,
    onFilter,
    clearTableFilterRequest,
    keyValuePairs,
    type
}: KeyValueTableFilterProps) => {
    const [selected, setSelected] = useState<{ key: string, value: React.Key } | undefined>();

    const options = useMemo(() => {
        return Object.entries(keyValuePairs)
            .map(a => { return { key: a[0], value: a[1] } })
    }, [keyValuePairs]);

    const setFilter = () => {
        confirm({ closeDropdown: true });
        onFilter(selected?.value);
    };

    const reset = useCallback(() => {
        setSelectedKeys([]);
        setSelected(undefined);
    }, [setSelectedKeys, setSelected]);

    const onClear = useCallback(() => {
        clearFilters && clearFilters();
        reset();
        confirm({ closeDropdown: false });
        onFilter(undefined);
    }, [clearFilters, confirm, onFilter, reset]);

    const onChange = (e: RadioChangeEvent | React.Key) => {
        const value = typeof e === 'object'
            ? e.target.value
            : e;
        const item = options.find(opt => opt.value === value);
        setSelectedKeys(item ? [item.value] : []);
        setSelected(item);
    };

    useEffect(() => {
        handleClearFilterRequest(clearTableFilterRequest, onClear);
    }, [onClear, clearTableFilterRequest]);

    return (
        <div className="custom-filter">
            <div className="selector-wrapper">
                {!type || type === 'radio'
                    ? (
                        <Radio.Group onChange={onChange} value={selectedKeys[0]}>
                            <Space direction="vertical">
                                {options.map(kvp =>
                                    <Radio key={kvp.value} value={kvp.value}>{kvp.key}</Radio>
                                )}
                            </Space>
                        </Radio.Group>
                    ) : (
                        <Select
                            onChange={onChange}
                            value={selected?.value}
                            placeholder='Select value...'
                            allowClear
                            onClear={reset}
                            showSearch
                            showArrow={false}
                            filterSort={(a, b) => {
                                if (a.value! > b.value!) return 1;
                                if (a.value! < b.value!) return -1;
                                return 0;
                            }}
                            filterOption={false}>
                            {options.map(kvp =>
                                <Option key={kvp.value} value={kvp.value}>{kvp.key}</Option>
                            )}
                        </Select>
                    )}
            </div>
            <div className="table-filter-actions-multiple ant-table-filter-dropdown-btns">
                <Button
                    size="small"
                    type="link"
                    onClick={reset}
                    disabled={selectedKeys[0] === undefined}
                >
                    Reset
                </Button>
                <Button size="small" type="primary" onClick={setFilter}>
                    OK
                </Button>
            </div>
        </div>
    );
};

export default KeyValueTableFilter;
