import ClockCircleOutlined from "@ant-design/icons/lib/icons/ClockCircleOutlined";
import { Box } from "@chakra-ui/react";
import { Col, Row, Tag } from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import Currency from "components/Currency";
import {
    useFinancialSummary,
    useFinancialSummaryJobStatus,
} from "hooks/useFinancialSummary";
import { InitialPageRequest, PageRequest, PageSizeOptions } from "models/common";
import { FinancialSummaryData } from "models/financialSummary";
import { useState } from "react";
import { localizedDate } from "util/helpers";

const columns: ColumnsType<FinancialSummaryData> = [
    {
        title: "Date",
        dataIndex: "date",
        key: "date",
        render: localizedDate,
    },
    {
        title: "GMV Total",
        dataIndex: "gmvTotalInCents",
        key: "gmvTotal",
        align: "right",
        render: (a) => <Currency cents={a} color="black" fontWeight="normal" />,
    },
    {
        title: "Customer Payout Total",
        dataIndex: "customerPayoutInCents",
        key: "customerPayout",
        align: "right",
        render: (a) => <Currency cents={a} color="black" fontWeight="normal" />,
    },
    {
        title: "Marketplace Fee Total",
        dataIndex: "marketplaceFeesInCents",
        key: "marketplaceFees",
        align: "right",
        render: (a) => <Currency cents={a} color="black" fontWeight="normal" />,
    },
    {
        title: "eBay Profit Total",
        dataIndex: "ebayProfitInCents",
        key: "ebayProfit",
        align: "right",
        render: (a) => <Currency cents={a} color="black" fontWeight="normal" />,
    },
];

const FinancialSummary = () => {
    const [{ page, withinDays, pageSize }, setPageRequest] =
        useState<PageRequest>(InitialPageRequest);

    const { isLoading, data } = useFinancialSummary({
        page,
        withinDays,
        sort: null,
        pageSize
    });
    const { data: records, totalRecords } = data || {};

    const { data: job } = useFinancialSummaryJobStatus();
    const { status } = job || {};

    const setTablePage = (pageRequest: PageRequest) => {
        setPageRequest(pageRequest);
    };

    const tableChanged = (
        // pagination
        { current: newPage, pageSize: newPageSize }: any,
        _: any, // filters, don't care
        __: any // sorting, don't care
    ) => {
        setTablePage({
            page: newPageSize !== pageSize ? 1 : newPage,
            pageSize: newPageSize,
            withinDays,
            sort: null,
        });
    };

    return (
        <Box p={50}>
            <Row align="bottom">
                <Col span={8}>
                    <Box fontSize={36}>Financial Summary</Box>
                </Col>
                <Col
                    span={16}
                    className="actions-container"
                    style={{ textAlign: "right" }}
                >
                    <Tag
                        icon={<ClockCircleOutlined />}
                        color="processing"
                        title="Job Status"
                    >
                        Job Status: {status}
                    </Tag>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Table
                        id="financial-summary-table"
                        columns={columns}
                        dataSource={records}
                        rowKey={(r) => r.id}
                        onChange={tableChanged}
                        pagination={{
                            total: totalRecords,
                            current: page,
                            pageSize,
                            pageSizeOptions: PageSizeOptions,
                            showSizeChanger: true,
                            showQuickJumper: true,
                        }}
                        showSorterTooltip={false}
                        loading={isLoading}
                    />
                </Col>
            </Row>
        </Box>
    );
};

export default FinancialSummary;
