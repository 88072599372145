import { Box, HStack, Text } from "@chakra-ui/react";
import { Alert, Button, Divider, Input, Modal, Radio, Spin } from "antd";
import LoadingSpinnerOverlay from "components/LoadingSpinnerOverlay";
import useApprovePayoutRequest from "hooks/useApprovePayoutRequest";
import { CheckPayoutMethodDetails, PayoutRequest, PayoutRequestMethod } from "models/payoutRequests";
import { ShipmentCarrier } from "models/shipping";
import { FC, useState } from "react";

interface ApprovalDetails extends CheckPayoutMethodDetails {
    requestedLabel: boolean;
}

const Working = () => <Box w='100%' alignItems='center' display='flex'>
    <Spin size="large" tip="Generating label & approving check payout..." style={{ width: '100%', alignSelf: 'center' }} />
</Box>;

const CheckApprovalResult: FC<{
    details: ApprovalDetails | undefined
}> = ({ details }) => {
    if (!details) {
        return null;
    }

    if (details.requestedLabel) {
        return <Alert
            type="success"
            showIcon
            message="Check Shipping Label Created"
            description={<>
                <Text>The tracking number for this shipment label is {details.trackingNumber}, and the request has been approved.</Text>
                <Button type="link" href={details.labelUrl!} style={{ padding: 0 }} target="_blank">Open Label PDF</Button>
            </>}
        />;
    }

    return <Alert
        type="success"
        showIcon
        message="Marked as Shipped"
        description={<Text>
            You've set the tracking number for this request as {details.trackingNumber}, and it has been successfully approved.
        </Text>}
    />;
}

const PayoutApproval: FC<{
    request: PayoutRequest,
    onApproved: () => void,
    onCancelled: (reason: string) => void,
}> = ({ request, onApproved, onCancelled }) => {
    const { approvePayoutRequest, isLoading } = useApprovePayoutRequest(request.id);
    const [visible, setVisible] = useState<boolean>(false);
    const [result, setResult] = useState<ApprovalDetails | undefined>();
    const [trackingNumber, setTrackingNumber] = useState<string>("");
    const [carrier, setCarrier] = useState<number>(0);

    const state = {
        ready: !result && !isLoading,
        working: isLoading,
        finished: result && !isLoading,
        validInput: trackingNumber.length > 0 && carrier !== 0
    };

    const approveNonCheck = async () => {
        const updatedRequest = await approvePayoutRequest({});
        if (updatedRequest.cancelledBySystem) {
            onCancelled(updatedRequest.cancelledReason!);
        } else {
            onApproved();
        }
    }

    const approveCheckWithLabel = async () => {
        const result = await approvePayoutRequest({});
        setResult({
            ...(result.details as CheckPayoutMethodDetails),
            requestedLabel: true
        });
    }

    const approveCheckWithoutLabel = async () => {
        const result = await approvePayoutRequest({
            trackingNumber,
            carrier
        });
        setResult({
            ...(result.details as CheckPayoutMethodDetails),
            requestedLabel: false
        });
    }

    const close = () => {
        if (state.finished) {
            onApproved();
        }

        setVisible(false);
        setTrackingNumber("");
        setCarrier(0);
        setResult(undefined);
    }

    if (request.method !== PayoutRequestMethod.Check) {
        const label = request.method === PayoutRequestMethod.ACH
            ? "Submit to Chase"
            : "Mark as Sent";
        return <>
            <LoadingSpinnerOverlay isLoading={state.working} />
            <Box color="blue" as="a" onClick={approveNonCheck}>{label}</Box>
        </>
    }

    return <>
        <LoadingSpinnerOverlay isLoading={state.working} />
        <Box color="blue" as="a" onClick={() => setVisible(true)}>Ship Check</Box>
        <Modal
            open={visible}
            footer={[
                <Button key='cancel' onClick={close} disabled={state.working} loading={state.working}>
                    {state.finished ? "Close" : "Cancel"}
                </Button>
            ]}
            title={`${request.requestingUserFirstName} ${request.requestingUserLastName} - Ship Check`}
            closable={false}
            keyboard={true}
            maskClosable={true}
            destroyOnClose={true}>

            {state.ready && <>
                <Button size="large" type="primary" style={{ width: '100%' }} onClick={approveCheckWithLabel}>
                    Generate Shipping Label &amp; Mark as Sent
                </Button>
                <Divider>Or</Divider>
                <label htmlFor="trackingNo">I already have a label, the tracking number and carrier are:</label>
                <HStack>
                    <Input
                        name="trackingNo"
                        size="large"
                        placeholder="Tracking Number"
                        value={trackingNumber}
                        onChange={(e) => setTrackingNumber(e.target.value)} />
                    <Radio.Group
                        options={[
                            { label: 'USPS', value: ShipmentCarrier.USPS },
                            { label: 'UPS', value: ShipmentCarrier.UPS }
                        ]}
                        onChange={(e) => setCarrier(e.target.value)}
                    />
                    <Button
                        size="large"
                        type="primary"
                        onClick={approveCheckWithoutLabel}
                        disabled={!state.validInput}>Mark as Sent</Button>
                </HStack>
            </>}

            {state.working && <Working />}

            {state.finished && <CheckApprovalResult details={result} />}
        </Modal>
    </>
};

export default PayoutApproval;
