import axios from "axios";
import { useAuthenticatedRequestCreator } from "./useRequests";

export type CreationResult = {
    successful: boolean;
    message: string;
}

const useShipmentCreation = () => {
    const enqueueShipmentCreation = useAuthenticatedRequestCreator<CreationResult, void>(
        () => ({
            method: "post",
            url: "/shipping/create-shipments",
        }), [409, 403]
    );

    const createShipments = async (): Promise<CreationResult> => {
        try {
            await enqueueShipmentCreation();
            return { successful: true, message: "Shipment creation in progress!" };
        } catch (e: any) {
            const resp = { successful: false };

            if (axios.isAxiosError(e)) {
                if (e.response?.status === 409) {
                    return { ...resp, message: "Shipment creation is already in progress" }
                } else if (e.response?.status === 403) {
                    return { ...resp, message: "Shipment creation has been disabled" };
                }
            }

            return { ...resp, message: "Shipment creation failed!" }
        }
    }

    return { createShipments };
}

export default useShipmentCreation;