import { Box, ListItem, UnorderedList } from "@chakra-ui/react";
import { FC, PropsWithChildren } from "react";

const ContentHeader: FC<PropsWithChildren> = ({ children }) => {
    return <Box fontSize={24}>
        {children}
    </Box>
}
const ContentBox: FC<PropsWithChildren> = ({ children }) => {
    return <Box py="1rem">
        {children}
    </Box>
}

const PrivacyPolicy: FC = () => {
    return (<>
        <Box fontSize={36} textAlign="center" p="2rem">Terms and Conditions</Box>
        <Box px="10vw" pb="3vw">
            <ContentHeader>Agreement between User and www.dcsports87.com</ContentHeader>
            <ContentBox>
                Welcome to www.dcsports87.com. The www.dcsports87.com website (the "Site") is comprised of various web pages operated by
                dcsports87 ("dcsports87"). www.dcsports87.com is offered to you conditioned on your acceptance without modification of
                the terms, conditions, and notices contained herein (the "Terms"). Your use of www.dcsports87.com constitutes your agreement
                to all such Terms. Please read these terms carefully, and keep a copy of them for your reference.
            </ContentBox>
            <ContentBox>
                www.dcsports87.com is an E-Commerce Site.
            </ContentBox>
            <ContentBox>
                dcsports87 is a sports card consignment company. This website is provided to allow you to communicate with our team and
                track the progress of your submissions that are being processed by our eBay team. Please review the below terms which are
                accepted upon creation of an account, and/or accessing and utilizing our website for cards submitted to us.
            </ContentBox>
            <UnorderedList p="1rem">
                <ListItem>All cards that are received at our office are received with the understanding that we will list these items for sale via eBay auction, and the details of the format and scheduling of these auctions is at the sole discretion of dcsports87. </ListItem>
                <ListItem>All payouts resulting from sales of your items will be done so in accordance with the payout rates and methods posted on this website, which can be found both on our FAQ page as well as the support chat interface.</ListItem>
                <ListItem>Once items are received, we cannot guarantee any specific sale price, and once processed we may not be able to end listings prior to a completed sale.</ListItem>
                <ListItem>If we receive shipments from you that include items we deem not eligible to be listed per our policies, these will be returned at your expense. </ListItem>
            </UnorderedList>

            <ContentHeader>Electronic Communications</ContentHeader>
            <ContentBox>
                Visiting www.dcsports87.com or sending emails to dcsports87 constitutes electronic communications. You consent to receive electronic
                communications and you agree that all agreements, notices, disclosures and other communications that we provide to you electronically,
                via email and on the Site, satisfy any legal requirement that such communications be in writing.
            </ContentBox>

            <ContentHeader>Your Account</ContentHeader>
            <ContentBox>
                If you use this site, you are responsible for maintaining the confidentiality of your account and password and for restricting access to
                your computer, and you agree to accept responsibility for all activities that occur under your account or password. You may not assign or
                otherwise transfer your account to any other person or entity. You acknowledge that dcsports87 is not responsible for third party access
                to your account that results from theft or misappropriation of your account. dcsports87 and its associates reserve the right to refuse
                or cancel service, terminate accounts, or remove or edit content in our sole discretion.
            </ContentBox>

            <ContentHeader>Children Under Thirteen</ContentHeader>
            <ContentBox>
                dcsports87 does not knowingly collect, either online or offline, personal information from persons under the age of thirteen. If you are under 18,
                you may use www.dcsports87.com only with permission of a parent or guardian.
            </ContentBox>

            <ContentHeader>Links to Third Party Sites/Third Party Services</ContentHeader>
            <ContentBox>
                www.dcsports87.com may contain links to other websites ("Linked Sites"). The Linked Sites are not under the control of dcsports87
                and dcsports87 is not responsible for the contents of any Linked Site, including without limitation any link contained in a Linked Site,
                or any changes or updates to a Linked Site. dcsports87 is providing these links to you only as a convenience, and the inclusion of
                any link does not imply endorsement by dcsports87 of the site or any association with its operators.
            </ContentBox>
            <ContentBox>
                Certain services made available via www.dcsports87.com are delivered by third party sites and organizations. By using any product,
                service or functionality originating from the www.dcsports87.com domain, you hereby acknowledge and consent that dcsports87 may share
                such information and data with any third party with whom dcsports87 has a contractual relationship to provide the requested product,
                service or functionality on behalf of www.dcsports87.com users and customers.
            </ContentBox>

            <ContentHeader>No Unlawful or Prohibited Use/Intellectual Property</ContentHeader>
            <ContentBox>
                You are granted a non-exclusive, non-transferable, revocable license to access and use www.dcsports87.com strictly in accordance with
                these terms of use. As a condition of your use of the Site, you warrant to dcsports87 that you will not use the Site for any purpose
                that is unlawful or prohibited by these Terms. You may not use the Site in any manner which could damage, disable, overburden, or impair
                the Site or interfere with any other party's use and enjoyment of the Site. You may not obtain or attempt to obtain any materials or
                information through any means not intentionally made available or provided for through the Site.
            </ContentBox>
            <ContentBox>
                All content included as part of the Service, such as text, graphics, logos, images, as well as the compilation thereof, and any software
                used on the Site, is the property of dcsports87 or its suppliers and protected by copyright and other laws that protect intellectual
                property and proprietary rights. You agree to observe and abide by all copyright and other proprietary notices, legends or other restrictions
                contained in any such content and will not make any changes thereto.
            </ContentBox>
            <ContentBox>
                You will not modify, publish, transmit, reverse engineer, participate in the transfer or sale, create derivative works, or in any way exploit
                any of the content, in whole or in part, found on the Site. dcsports87 content is not for resale. Your use of the Site does not entitle you
                to make any unauthorized use of any protected content, and in particular you will not delete or alter any proprietary rights or attribution
                notices in any content. You will use protected content solely for your personal use, and will make no other use of the content without the
                express written permission of dcsports87 and the copyright owner. You agree that you do not acquire any ownership rights in any protected
                content. We do not grant you any licenses, express or implied, to the intellectual property of dcsports87 or our licensors except as expressly
                authorized by these Terms.
            </ContentBox>

            <ContentHeader>Third Party Accounts</ContentHeader>
            <ContentBox>
                You will be able to connect your dcsports87 account to third party accounts. By connecting your dcsports87 account to your third party account,
                you acknowledge and agree that you are consenting to the continuous release of information about you to others (in accordance with your privacy
                settings on those third party sites). If you do not want information about you to be shared in this manner, do not use this feature.
            </ContentBox>

            <ContentHeader>International Users</ContentHeader>
            <ContentBox>
                The Service is controlled, operated and administered by dcsports87 from our offices within the USA. If you access the Service from a location
                outside the USA, you are responsible for compliance with all local laws. You agree that you will not use the dcsports87 Content accessed through
                www.dcsports87.com in any country or in any manner prohibited by any applicable laws, restrictions or regulations.
            </ContentBox>

            <ContentHeader>Indemnification</ContentHeader>
            <ContentBox>
                You agree to indemnify, defend and hold harmless dcsports87, its officers, directors, employees, agents and third parties, for any losses, costs,
                liabilities and expenses (including reasonable attorney's fees) relating to or arising out of your use of or inability to use the Site or services,
                any user postings made by you, your violation of any terms of this Agreement or your violation of any rights of a third party, or your violation
                of any applicable laws, rules or regulations. dcsports87 reserves the right, at its own cost, to assume the exclusive defense and control of any
                matter otherwise subject to indemnification by you, in which event you will fully cooperate with dcsports87 in asserting any available defenses.
            </ContentBox>

            <ContentHeader>Liability Disclaimer</ContentHeader>
            <ContentBox>
                THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR AVAILABLE THROUGH THE SITE MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL ERRORS. CHANGES
                ARE PERIODICALLY ADDED TO THE INFORMATION HEREIN. DCSPORTS87 AND/OR ITS SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR CHANGES IN THE SITE AT ANY TIME.
            </ContentBox>
            <ContentBox>
                DCSPORTS87 AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY OF THE INFORMATION,
                SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS CONTAINED ON THE SITE FOR ANY PURPOSE. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH
                INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS ARE PROVIDED "AS IS" WITHOUT WARRANTY OR CONDITION OF ANY KIND. DCSPORTS87 AND/OR ITS
                SUPPLIERS HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH REGARD TO THIS INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS, INCLUDING
                ALL IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.
            </ContentBox>
            <ContentBox>
                TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL DCSPORTS87 AND/OR ITS SUPPLIERS BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE,
                INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR PROFITS,
                ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF THE SITE, WITH THE DELAY OR INABILITY TO USE THE SITE OR RELATED SERVICES,
                THE PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS OBTAINED THROUGH THE SITE,
                OR OTHERWISE ARISING OUT OF THE USE OF THE SITE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF DCSPORTS87 OR
                ANY OF ITS SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
                LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SITE,
                OR WITH ANY OF THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SITE.
            </ContentBox>

            <ContentHeader>Termination/Access Restriction</ContentHeader>
            <ContentBox>
                dcsports87 reserves the right, in its sole discretion, to terminate your access to the Site and the related services or any portion thereof at any
                time, without notice. To the maximum extent permitted by law, this agreement is governed by the laws of the Commonwealth of Virginia and you hereby
                consent to the exclusive jurisdiction and venue of courts in Virginia in all disputes arising out of or relating to the use of the Site. Use of
                the Site is unauthorized in any jurisdiction that does not give effect to all provisions of these Terms, including, without limitation, this section.
            </ContentBox>
            <ContentBox>
                You agree that no joint venture, partnership, employment, or agency relationship exists between you and dcsports87 as a result of this agreement or
                use of the Site. dcsports87's performance of this agreement is subject to existing laws and legal process, and nothing contained in this agreement
                is in derogation of dcsports87's right to comply with governmental, court and law enforcement requests or requirements relating to your use of the
                Site or information provided to or gathered by dcsports87 with respect to such use. If any part of this agreement is determined to be invalid or
                unenforceable pursuant to applicable law including, but not limited to, the warranty disclaimers and liability limitations set forth above, then the
                invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original
                provision and the remainder of the agreement shall continue in effect.
            </ContentBox>
            <ContentBox>
                Unless otherwise specified herein, this agreement constitutes the entire agreement between the user and dcsports87 with respect to the Site and it
                supersedes all prior or contemporaneous communications and proposals, whether electronic, oral or written, between the user and dcsports87 with
                respect to the Site. A printed version of this agreement and of any notice given in electronic form shall be admissible in judicial or
                administrative proceedings based upon or relating to this agreement to the same extent and subject to the same conditions as other business documents
                and records originally generated and maintained in printed form. It is the express wish to the parties that this agreement and all related documents
                be written in English.
            </ContentBox>

            <ContentHeader>Changes to Terms</ContentHeader>
            <ContentBox>
                dcsports87 reserves the right, in its sole discretion, to change the Terms under which www.dcsports87.com is offered. The most current version of
                the Terms will supersede all previous versions. dcsports87 encourages you to periodically review the Terms to stay informed of our updates.
            </ContentBox>

            <ContentHeader>Contact Us</ContentHeader>
            <ContentBox>
                dcsports87 welcomes your questions or comments regarding the Terms via our Support messaging found on your dashboard or at the base of the FAQ page
                of our website.
            </ContentBox>
            <ContentBox>
                <address>
                    dcsports87<br />
                    3957 Westerre Parkway<br />
                    Richmond, Virginia 23233<br />
                </address>
                Email Address:
                dcsports87@gmail.com
            </ContentBox>
            <ContentBox>
                <span>Effective as of November 01, 2022</span>
            </ContentBox>
        </Box>
    </>)
}

export default PrivacyPolicy;