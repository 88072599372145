import React, { useState, useEffect, useCallback, FC } from 'react';
import { Image, Text, Flex } from "@chakra-ui/react";
import useItemDetails from 'hooks/useItemDetails';
import { Spinner } from "@chakra-ui/react";
import { CatalogItemVariation } from 'models/store';
import useShoppingCart, { AddToCartRequest } from 'hooks/useShoppingCart';
import { Button, Divider, Select } from 'antd';
import { centsToDollars } from 'util/helpers';
import { StopOutlined } from '@ant-design/icons';

interface CatalogItemDetailsProps {
    itemId: string;
    onClose: () => void,
    addToCart: (requestBody: AddToCartRequest) => Promise<void>;
}

const VariationAction: FC<{
    visible: boolean,
    loading: boolean,
    inStock: boolean | undefined,
    inCart: boolean,
    onClick: () => void
}> = ({ visible, loading, inStock, inCart, onClick }) => {
    if (!visible) {
        return null;
    } else if (inStock) {
        return <Button
            type="primary"
            disabled={inCart}
            loading={loading}
            onClick={onClick}>
            {inCart ? 'Already in Cart' : 'Add to Cart'}
        </Button>;
    } else {
        return <Button
            danger
            disabled
            icon={<StopOutlined />}
        > Out Of Stock
        </Button>;
    }
};

const CatalogItemDetails: React.FC<CatalogItemDetailsProps> = ({ itemId, onClose, addToCart }) => {
    const { data, isLoading } = useItemDetails(itemId);
    const [selectedVariation, setSelectedVariation] = useState<CatalogItemVariation | undefined>();
    const [variationIsInCart, setVariationIsInCart] = useState<boolean>(false);
    const [working, setWorking] = useState<boolean>(false);
    const { fetchCart: { data: cartItemsData } } = useShoppingCart();

    const handleVariationChange = useCallback((id: string) => {
        const variation = (data?.variations || []).find(v => v.id === id);
        const inCart = variation !== undefined && (cartItemsData || []).some((item) => item.catalogId === variation.id);

        setSelectedVariation(variation);
        setVariationIsInCart(inCart);
    }, [cartItemsData, data]);

    useEffect(() => {
        if (data && data.variations && data.variations.length === 1) {
            handleVariationChange(data.variations[0].id);
        }
    }, [data, handleVariationChange]);

    if (isLoading) {
        return <Flex justifyContent='center' alignItems='center' h='300px'>
            <Spinner />
        </Flex>;
    }

    if (!data) {
        return null;
    }

    const updateCart = async () => {
        setWorking(true);
        await addToCart({
            productId: data.id,
            variationId: selectedVariation!.id,
            quantity: 1,
        });

        onClose();
        setWorking(false);
    }

    const variationIsInStock = selectedVariation && selectedVariation.quantity > 0;

    return (
        <Flex flexDirection='column'>
            <Flex justifyContent='center'>
                <Image src={data.images?.[0] ?? 'https://via.placeholder.com/300'} alt={data.name} boxSize="300px" objectFit="cover" />
            </Flex>
            <Text mt='16px'>
                {data.description}
            </Text>
            <Divider />
            <Flex justifyContent='space-between' alignItems='center' mb='16px'>
                <Flex flexDirection='column'>
                    <Text mb='8px'>
                        {selectedVariation && `Price: ${centsToDollars(selectedVariation.priceInCents)}`}
                    </Text>
                    {data.variations && data.variations.length > 1 ? (
                        <Select
                            style={{ minWidth: '200px' }}
                            placeholder="Select an Option"
                            options={data.variations.map(v => ({ label: v.name, value: v.id }))}
                            onChange={handleVariationChange}
                        />
                    ) : (
                        <Text>Option: {selectedVariation?.name}</Text>
                    )}
                </Flex>

                <VariationAction
                    visible={selectedVariation !== undefined}
                    loading={working}
                    inStock={variationIsInStock}
                    inCart={variationIsInCart}
                    onClick={updateCart}
                />
            </Flex>
        </Flex>
    );
};

export default CatalogItemDetails;