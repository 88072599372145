import { useQuery } from "@tanstack/react-query";
import { SupportTicket } from "models/support";
import { useAuthenticatedRequest } from "./useRequests";

export const UnreadTicketsKeyPrefix = ["tickets", "unread"];

const useSupportTickets = () => {
    const getTickets = useAuthenticatedRequest<SupportTicket[]>({
        url: "/support/tickets",
        method: "GET",
    });

    return useQuery(["tickets"], getTickets, {
        suspense: true,
    });
};

export default useSupportTickets;
