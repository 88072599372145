import { Box, ModalBody, VStack } from "@chakra-ui/react";
import { FC } from "react";

const PayoutResultsStep3: FC<{
    active: boolean;
}> = ({ active }) => {
    return active ? (
        <>
            <ModalBody>
                <VStack>
                    <Box fontSize={20} fontWeight="semibold">
                        Payout Request Submitted
                    </Box>
                    <Box maxWidth="20rem" as="p">
                        Your payment request is now pending and will be
                        processed within one business day.
                    </Box>
                </VStack>
            </ModalBody>
        </>
    ) : null;
};

export default PayoutResultsStep3;
