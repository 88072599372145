import { useMutation } from "@tanstack/react-query";

import { useAuthenticatedRequestCreator } from "./useRequests";

const useCancelPayoutRequest = (id: string) => {
    const cancelPayoutRequest = useAuthenticatedRequestCreator<boolean, string>(
        (reason) => ({
            url: `/payout-requests/cancel`,
            method: "POST",
            data: {
                payoutRequestId: id,
                reason,
            },
        })
    );

    const { mutateAsync, ...rest } = useMutation(
        ["cancelPayoutRequest", id],
        cancelPayoutRequest
    );

    return {
        cancelPayoutRequest: mutateAsync,
        ...rest,
    };
};

export default useCancelPayoutRequest;
