import { Box, BoxProps, forwardRef } from "@chakra-ui/react";
import Currency from "components/Currency";
import { useIdentity } from "context/auth";
import useAccountSummary from "hooks/useAccountSummary";
import SummaryDetail from "./ConfirmationDetails/SummaryDetail";

const AvailableBalance = forwardRef<BoxProps, typeof Box>((props, ref) => {
    // Get the user's available balance
    const { accounts } = useIdentity();

    // Suspends this component until we have the account summary, caught by our <Suspense>
    const { data } = useAccountSummary(accounts[0], { suspense: true });

    // All data values in cents the whole time
    const availableBalance = data?.availableBalanceInCents ?? 0;

    return (
        <SummaryDetail ref={ref} label="Available Balance" {...props}>
            <Currency cents={availableBalance} />
        </SummaryDetail>
    );
});

export default AvailableBalance;
