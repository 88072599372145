import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAuthenticatedRequestCreator } from "./useRequests";
import { StaffCardsQueryKey } from "./useStaffCards";

const useShipCardToCustomer = () => {
    const queryClient = useQueryClient();

    const post = useAuthenticatedRequestCreator<void, string>(
        (cardId: string) => ({
            method: "put",
            url: "/cards/shipped-to-customer",
            data: { cardId }
        })
    );

    const { mutateAsync: shipCard } = useMutation(post, {
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [StaffCardsQueryKey] });
        }
    });

    return { shipCard };
};

export default useShipCardToCustomer;