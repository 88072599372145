import { Box, VStack } from "@chakra-ui/react";
import { Divider } from "antd";
import Currency from "components/Currency";
import { useIdentity } from "context/auth";
import useAccountSummary from "hooks/useAccountSummary";
import { PayoutFees, useGetPayoutFees } from "hooks/usePayoutFees";
import {
    CheckPayoutMethodDetails,
    CheckShippingMethods,
    CreatePayoutRequestPayload,
    PayoutFeeLabels,
    PayoutRequestMethod,
} from "models/payoutRequests";
import { FC } from "react";

import { ConfirmationDetailsProps } from ".";
import SummaryDetail from "./SummaryDetail";

const deriveFees = (
    { paypalDisplay, check, achInCents, wireInCents, internationalWireInCents }: PayoutFees,
    payoutRequest: CreatePayoutRequestPayload
) => {
    const { method, details, amountInCents } = payoutRequest;
    if (method === PayoutRequestMethod.Paypal) {
        return (paypalDisplay * amountInCents) / 100;
    }
    if (method === PayoutRequestMethod.Check) {
        const { country, shippingMethod } = details as CheckPayoutMethodDetails;
        if (country === "United States") {
            return shippingMethod === CheckShippingMethods.Standard
                ? check.upsStandardDomesticInCents
                : check.upsOvernightDomesticInCents;
        } else {
            return shippingMethod === CheckShippingMethods.Standard
                ? check.upsStandardInternationalInCents
                : check.upsOvernightInternationalInCents;
        }
    }
    if (method === PayoutRequestMethod.ACH) {
        return achInCents;
    }
    if (method === PayoutRequestMethod.Wire) {
        return wireInCents;
    }
    if (method === PayoutRequestMethod.InternationalWire) {
        return internationalWireInCents;
    }
    return undefined;
};

export const usePayoutRequestSummary = (
    payoutRequest: CreatePayoutRequestPayload | undefined
) => {
    // Get the user's available balance
    const { accounts, isAdmin } = useIdentity();

    // Suspends this component until we have the account summary, caught by our <Suspense>
    const { data } = useAccountSummary(accounts[0], { suspense: true });

    // All data values in cents the whole time
    const availableBalance = data?.availableBalanceInCents ?? 0;
    const requestedPayout = payoutRequest?.amountInCents ?? 0;

    const { data: allFees } = useGetPayoutFees();
    // We suspend, so these have data
    const fees = (payoutRequest && deriveFees(allFees!, payoutRequest)) ?? 0;

    const feeLabel = payoutRequest?.method
        ? PayoutFeeLabels[payoutRequest.method]
        : "Transfer Fee";

    const finalPayoutAmount = requestedPayout - fees;

    const newBalance = availableBalance - requestedPayout;

    // If they're an admin, skip these checks
    const isBalanceSufficient = isAdmin || newBalance >= 0;
    const isPayoutAmountSufficient = isAdmin || finalPayoutAmount > 0;

    const wireTransferNotEnough =
        payoutRequest?.method === PayoutRequestMethod.Wire &&
        payoutRequest.amountInCents < 10_000_00;

    return {
        availableBalance,
        requestedPayout,
        feeLabel,
        fees,
        finalPayoutAmount,
        newBalance,
        isBalanceSufficient,
        isPayoutAmountSufficient,
        wireTransferNotEnough,
    };
};

const AmountsSummary: FC<ConfirmationDetailsProps> = ({ payoutRequest }) => {
    const {
        availableBalance,
        requestedPayout,
        feeLabel,
        fees,
        finalPayoutAmount,
        newBalance,
        isBalanceSufficient,
        isPayoutAmountSufficient,
        wireTransferNotEnough,
    } = usePayoutRequestSummary(payoutRequest);

    const { isAdmin } = useIdentity();

    return (
        <VStack minWidth="16rem" align="start" fontSize="1.2em" spacing={1}>
            {!isAdmin && (
                <SummaryDetail label="Available Balance" pb={2}>
                    <Currency cents={availableBalance} />
                </SummaryDetail>
            )}
            <SummaryDetail label="Requested Payout">
                <Currency cents={-requestedPayout} />
            </SummaryDetail>
            <VStack w="100%" align="start" spacing={0.5}>
                {fees > 0 ? (
                    <>
                        <SummaryDetail label={feeLabel}>
                            <Currency cents={-fees} />
                        </SummaryDetail>
                        <Divider />
                        <SummaryDetail label="Amount You'll Receive">
                            <Currency cents={finalPayoutAmount} />
                        </SummaryDetail>
                    </>
                ) : null}
            </VStack>
            {!isAdmin && (
                <SummaryDetail label="New Balance">
                    <Currency cents={newBalance} showZero />
                </SummaryDetail>
            )}
            {!isBalanceSufficient && (
                <SummaryDetail label="Error" color="red">
                    <Box fontWeight="semibold">Insufficient balance</Box>
                </SummaryDetail>
            )}
            {!isPayoutAmountSufficient && (
                <SummaryDetail label="Error" color="red">
                    <Box fontWeight="semibold">Payout must exceed fees</Box>
                </SummaryDetail>
            )}
            {wireTransferNotEnough && (
                <Box fontWeight="semibold" color="red">
                    Wire transfers must be at least $10,000
                </Box>
            )}
        </VStack>
    );
};

export default AmountsSummary;
