import { FileTextOutlined } from "@ant-design/icons";
import { Box } from "@chakra-ui/react";
import { Col, Row, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useIdentity } from "context/auth";
import useAccountTransactions, {
    useAccountTransactionsCSV,
} from "hooks/useAccountTransactions";
import { InitialPageRequest, PageRequest } from "models/common";
import { Transaction, TransactionType, TransactionTypeNames } from "models/transactions";
import Papa from "papaparse";
import { useState } from "react";
import { Link } from "react-router-dom";
import downloadAsCSV from "util/downloadAsCSV";
import { localizedDate } from "util/helpers";

import Currency from "./Currency";
import TableActions from "./TableActions";

const columns: ColumnsType<Transaction> = [
    {
        title: "Date",
        dataIndex: "transactionDate",
        key: "date",
        sorter: true,
        render: localizedDate,
    },
    {
        title: "Description",
        dataIndex: "description",
        key: "desc",
        sorter: true,
    },
    {
        title: "Type",
        dataIndex: "transactionType",
        key: "type",
        render: (t: TransactionType) => TransactionTypeNames[t],
        responsive: ['xl']
    },
    {
        title: "Amount",
        dataIndex: "grossAmountInCents",
        key: "gross",
        sorter: true,
        align: "right",
        render: (a) => <Currency cents={a} />,
        responsive: ['md']
    },
    {
        title: "Fees",
        dataIndex: "feesInCents",
        key: "fees",
        sorter: true,
        align: "right",
        render: (a) => <Currency cents={-a} />,
        responsive: ['md']
    },
    {
        title: "Net",
        dataIndex: "netAmountInCents",
        key: "net",
        sorter: true,
        align: "right",
        render: (a) => <Currency cents={a} />,
    },
];

export const Transactions = () => {
    const { accounts } = useIdentity();

    const [{ page, filter, withinDays, sort }, setPageRequest] =
        useState<PageRequest>(InitialPageRequest);

    const { isLoading, data } = useAccountTransactions(accounts[0], {
        page,
        filter,
        withinDays,
        sort,
    });
    const { data: records, totalRecords, pageSize } = data || {};

    const filterChanged = (value: string) => {
        setPageRequest({ page: 1, filter: value, withinDays, sort });
    };

    const daysChanged = (value: number) => {
        setPageRequest({ page: 1, filter, withinDays: value, sort });
    };

    const tableChanged = (
        { current: newPage }: any, // pagination
        // eslint-disable-next-line no-empty-pattern
        { } = {}, // filters, don't care
        { column, field, order }: any
    ) => {
        setPageRequest({
            page: newPage,
            filter,
            withinDays,
            sort: column ? [field, order === "ascend"] : null,
        });
    };

    const fetchTransactionsCSV = useAccountTransactionsCSV(accounts[0], {
        filter,
        withinDays,
        sort,
    });

    const handleCSVExport = async () => {
        const csvData = await fetchTransactionsCSV();
        const date = new Date().toISOString().split("T")[0];
        const filename = `transactions-${date}.csv`;
        downloadAsCSV(csvData, filename);
    };

    const fetchLedgerCSV = useAccountTransactionsCSV(accounts[0], {
        sort: ["transactionDate", false],
        withinDays: 0,
    });

    const handleLedgerExport = async () => {
        const csvData = await fetchLedgerCSV();
        const date = new Date().toISOString().split("T")[0];
        const filename = `ledger-${date}.csv`;

        // Take the CSV data and add a new column for the running balance
        const parsedCsvObj = Papa.parse<any>(csvData, {
            header: true,
            skipEmptyLines: true,
        });

        const RUNNING_BALANCE_COL = "RunningBalanceInCents";

        // Add a new column for the running balance
        const newCsvData = parsedCsvObj.data.reduceRight(
            (acc, row, index, arr) => {
                // Reduce from the right to get the running balance from the oldest to the newest
                // If we're the oldest, use our net amount
                // If we're not, add our net amount to the previous row's running balance
                const netAmountForRow = Number.parseInt(row.NetAmountInCents);
                const prevRunningBalance = Number.parseInt(
                    // Get the previous row's running balance if it exists
                    acc?.[index + 1]?.[RUNNING_BALANCE_COL] ?? 0
                );
                const RunningBalanceInCents =
                    netAmountForRow + prevRunningBalance;

                // Since our acc is always the correct size, slot us in to our correct index
                acc[index] = {
                    ...row,
                    [RUNNING_BALANCE_COL]: RunningBalanceInCents.toString(),
                };

                // The acc for each reduction is always the return from the last iteration, so we return acc

                return acc;
            },
            // The initial array (acc) which is the same length as the original
            Array.from({
                length: parsedCsvObj.data.length,
            })
        );

        const columns = parsedCsvObj.meta.fields ?? [];

        // Convert the new CSV data to a string
        const newCsvString = Papa.unparse(newCsvData, {
            header: true,
            columns: [...columns, RUNNING_BALANCE_COL],
        });
        downloadAsCSV(newCsvString, filename);
    };

    return (
        <Box p={30}>
            <Row align="middle">
                <Col span={6}>
                    <Box fontSize={36}>My Balance Activity</Box>
                </Col>
                <Col span={10} offset={8} className="actions-container">
                    <TableActions
                        onDaysChanged={daysChanged}
                        onFilterChanged={filterChanged}
                        isLoading={isLoading}
                        filterPlaceholder="Search Transactions"
                        onExportCSV={handleCSVExport}

                    >
                        <Link to="#" onClick={handleLedgerExport}>
                            <FileTextOutlined /> Export Ledger - All
                            Transactions
                        </Link>
                    </TableActions>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Table
                        columns={columns}
                        dataSource={records}
                        rowKey={(r) => r.transactionId}
                        onChange={tableChanged}
                        pagination={{
                            total: totalRecords,
                            current: page,
                            pageSize,
                            showSizeChanger: false,
                            showQuickJumper: true,
                        }}
                        loading={isLoading}
                    />
                </Col>
            </Row>
        </Box>
    );
};
