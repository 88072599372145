import { Box, BoxProps, forwardRef } from "@chakra-ui/react";
import { centsToDollars } from "util/helpers";

const Currency = forwardRef<
    { cents: number; showZero?: boolean, flat?: boolean } & BoxProps,
    typeof Box
>(({ cents, showZero = false, flat = false, ...props }, ref) => {
    if (cents === 0 && !showZero) {
        return <></>;
    }

    const cls = flat
        ? "flat"
        : cents > 0 ? "positive" : "negative";

    const value = flat
        ? Math.abs(cents)
        : cents

    return (
        <Box ref={ref} className={cls} {...props}>
            {centsToDollars(value)}
        </Box>
    );
});

export default Currency;
