import {
    Box,
    Button,
    Flex,
    HStack,
    Image,
    Text,
    VStack,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { Form } from "antd";
import { PublicRoutes } from "appRoutePaths";
import LoadingSpinnerOverlay from "components/LoadingSpinnerOverlay";
import PasswordInput from "components/SignUp/PasswordInput";
import { useLogin } from "hooks/useLogin";
import { usePublicRequestCreator } from "hooks/useRequests";
import { FC } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

const SignUpImage = require("images/sign-up-page.png");

type ResetPasswordParams = {
    token?: string;
    email?: string;
    newPassword: string;
    confirmPassword: string;
};

const useResetPassword = () => {
    const requestPasswordReset = usePublicRequestCreator<
        void,
        ResetPasswordParams
    >((data) => ({
        url: "/account/reset-password",
        method: "POST",
        data,
    }));

    return useMutation(requestPasswordReset);
};

const ResetPasswordPage: FC = () => {
    // Get the token and email from the query string
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token") as string | undefined;
    const email = searchParams.get("email") as string | undefined;

    const { mutateAsync: resetPassword, isLoading } = useResetPassword();

    const { authenticate } = useLogin();
    const navigate = useNavigate();

    const onFinish = async (data: ResetPasswordParams) => {
        const { newPassword } = data;

        const payload = {
            token,
            email,
            newPassword,
            confirmPassword: newPassword,
        };
        await resetPassword(payload);

        // Log the user in with their new password
        await authenticate({ username: email!, password: newPassword });
        navigate(PublicRoutes.login.url);
    };

    return (
        <>
            <LoadingSpinnerOverlay isLoading={isLoading} />
            <Flex
                minHeight="50vh"
                height="100%"
                w="100%"
                color="darkGray"
                m={0}
                p={0}
            >
                <Flex flex={1} justifyContent="center">
                    <VStack
                        as={Form}
                        name="authenticate"
                        onFinish={onFinish as any}
                        autoComplete="off"
                        layout="vertical"
                        align="start"
                        minWidth="30vw"
                        pt="7vh"
                        px="2rem"
                        maxWidth="30rem"
                        spacing={4}
                    >
                        <Flex flexDir="column" pb="3rem">
                            <Box fontSize="3xl">Update password</Box>
                            <HStack spacing="2">
                                <Text
                                    as={Link}
                                    to={PublicRoutes.login.url}
                                    color="blue"
                                >
                                    Back to Login
                                </Text>
                            </HStack>
                        </Flex>
                        <Box fontSize="xl">
                            Create a new password for{" "}
                            <Box as="span" fontWeight="semibold" color="gray">
                                {email}
                            </Box>
                        </Box>
                        <PasswordInput
                            title="New Password"
                            name="newPassword"
                            w="100%"
                        />
                        <Box alignSelf="end">
                            <Button variant="primary" type="submit">
                                Set New Password
                            </Button>
                        </Box>
                    </VStack>
                </Flex>
                <Image
                    src={SignUpImage}
                    maxWidth={{ base: 0, md: "100%" }}
                    flexBasis="30%"
                    h="auto"
                    objectFit="cover"
                />
            </Flex>
        </>
    );
};

export default ResetPasswordPage;
