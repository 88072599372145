import { useQuery } from "@tanstack/react-query";
import { ShipmentCreationProgress } from "models/shipping";
import { useAuthenticatedRequest } from "./useRequests";

const makeAxiosRequest = () => {
    return {
        method: "get",
        url: "/shipping/create-shipments/progress"
    }
}

const useShipmentCreationState = (stream: boolean) => {
    const getState = useAuthenticatedRequest<ShipmentCreationProgress>(
        makeAxiosRequest()
    );

    return useQuery(["shipments", "creation", "state"], getState, {
        refetchInterval: stream ? 1000 : false
    });
}

export default useShipmentCreationState;