import { DeleteFilled, ExclamationCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { Box } from "@chakra-ui/react";
import { Alert, Button, Card, Modal, Skeleton } from "antd";
import { AxiosError } from "axios";
import {
    accountSaved,
    ChaseAccount,
    chaseFrameHasError,
    chaseFrameIsReady,
    ChaseFrameStatusMessage,
    isFromChase,
    userCancelledSession
} from "models/chase";
import { TreasuryAccount, TreasurySession } from "models/treasury";
import { FC, useCallback, useEffect, useState } from "react";
import useTreasuryManagement from "./useTreasuryManagement";

const { confirm } = Modal;

interface ACHAccountsProps {
    onAccountCreated: (account: ChaseAccount) => void;
    onAccountDeleted?: (accountId: string) => void;
    accounts: TreasuryAccount[];
    isLoadingAccounts: boolean;
    onBehalfOfUserId?: string;
    modalTitle?: string;
}

interface AccountCardProps {
    account: TreasuryAccount,
    onDelete?: (account: TreasuryAccount) => Promise<void>
    allowDelete?: boolean
}

const AccountCard: FC<AccountCardProps> = ({
    account,
    onDelete,
    allowDelete
}) => {
    const { organization, category, accountNumber } = account;

    const invokeDelete = () => {
        if (onDelete) {
            onDelete(account);
        }
    }

    return <Card
        title={organization}
        style={{ width: '100%' }}
        size='small'
        extra={allowDelete && <Button
            danger
            type="link"
            icon={<DeleteFilled />}
            onClick={invokeDelete}
        />}
    >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <p>{category}</p>
            <p>{accountNumber}</p>
        </div>
    </Card>;
}

const ACHAccounts: FC<ACHAccountsProps> = ({
    onAccountCreated,
    onAccountDeleted,
    accounts,
    isLoadingAccounts,
    onBehalfOfUserId,
    modalTitle
}) => {
    const [open, setOpen] = useState(false);
    const [session, setSession] = useState<TreasurySession | null>(null);
    const [error, setError] = useState<{ message: string }>();
    const [frameReady, setFrameReady] = useState(false);
    const {
        createSession,
        storeAccount,
        deleteAccount,
        isLoading,
        sessionError
    } = useTreasuryManagement(onBehalfOfUserId);

    const reset = useCallback(() => {
        setOpen(false);
        setSession(null);
        setError(undefined);
        setFrameReady(false);
    }, []);

    const onFrameReady = useCallback(() => {
        setFrameReady(true);
    }, []);

    const onFrameError = useCallback((msg: ChaseFrameStatusMessage) => {
        setError({
            message: `Unable to create secure connection: ${msg.frameErrorCode ?? "N/A"} - ${msg.errorDescription ?? "Unknown Error"}`
        });
    }, []);

    const onSuccess = useCallback((account: ChaseAccount) => {
        storeAccount(account);
        onAccountCreated(account);
        reset();
    }, [storeAccount, onAccountCreated, reset]);

    const onConfirmDelete = async (account: TreasuryAccount) => {
        try {
            await deleteAccount(account.accountId);

            if (onAccountDeleted) {
                onAccountDeleted(account.accountId);
            }
        } catch (error) {
            let content = 'Unfortunately, Chase did not specify a reason why.';

            if (error instanceof AxiosError) {
                const resp = error.response!;

                if (resp.status === 400) {
                    content = resp.data?.title ?? content;
                } else if (resp.status === 404) {
                    content = 'The specified account does not exist'
                }
            }

            Modal.error({
                title: "Unable to delete account",
                content
            });
        }
    }

    const promptForDelete = async (account: TreasuryAccount) => {
        const modal = confirm({
            title: 'Are you sure you want to delete this account?',
            icon: <ExclamationCircleOutlined />,
            content: <AccountCard account={account} />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                modal.update(prev => ({
                    ...prev,
                    cancelButtonProps: { disabled: true }
                }));
                await onConfirmDelete(account);
            }
        });
    }

    useEffect(() => {
        const dispatcher = (evt: MessageEvent) => {
            if (!isFromChase(evt)) {
                return;
            }

            const { data } = evt;

            if (chaseFrameIsReady(data)) {
                onFrameReady();
            } else if (chaseFrameHasError(data)) {
                onFrameError(data);
            } else if (userCancelledSession(data)) {
                reset();
            } else if (accountSaved(data)) {
                onSuccess(data.account);
            }
        }

        window.addEventListener('message', dispatcher, false);

        return () => window.removeEventListener('message', dispatcher);
    }, [reset, onFrameError, onFrameReady, onSuccess]);

    useEffect(() => {
        if (sessionError) {
            setError({ message: "Failed to create secure connection to Chase" });
        }
    }, [sessionError]);

    useEffect(() => {
        if (open && !session) {
            createSession()
                .then(data => setSession(data))
                .catch(e => setError(e?.response?.data));
        }
    }, [open, session, createSession]);

    if (isLoadingAccounts) {
        return <>
            <Skeleton.Button active block />
            <Card loading style={{ width: '100%' }} size='small'></Card>
        </>;
    }

    return <>
        <Button type="primary" block onClick={() => setOpen(true)}>
            Add Bank Account
        </Button>

        {accounts.map(acnt => (
            <AccountCard
                key={acnt.accountId}
                account={acnt}
                onDelete={promptForDelete}
                allowDelete={!onBehalfOfUserId}
            />
        ))}

        <Modal
            open={open}
            closable={false}
            keyboard={false}
            maskClosable={false}
            destroyOnClose={true}
            title={modalTitle ?? "Add Bank Account via J.P. Morgan Chase"}
            width='495px'
            footer={error ? (<Button
                disabled={isLoading}
                onClick={reset}
                type="primary">
                Close
            </Button>) : (null)}
        >
            {!frameReady && !error &&
                <Box display='flex' flexDirection='column' justifyContent='center' textAlign='center'>
                    <LoadingOutlined spin style={{ color: "var(--dcs-blue)", fontSize: '80px' }} />
                    <Box fontWeight='bold' m={2} fontSize='18px'>
                        {session
                            ? "Loading Chase UI..."
                            : "Creating secure session with Chase. This may take a moment..."}
                    </Box>
                </Box>
            }

            {session && <>
                {frameReady && <Box>You are securely connected to J.P. Morgan Chase Bank. dcsports87 will only receive and store sanitized account information.</Box>}
                <iframe
                    src={session!.sessionUrl}
                    width='100%'
                    height='580px'
                    title="Chase Treasury Session">
                </iframe>
            </>}

            {error &&
                <Alert
                    showIcon
                    type="error"
                    message="Unable to Communicate with J.P. Morgan Chase"
                    description={error!.message}
                />
            }
        </Modal>
    </>
};

export default ACHAccounts;
