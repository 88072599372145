import { useMutation } from "@tanstack/react-query";
import { useAuthenticatedRequestCreator } from "./useRequests";
import { StoreOrder } from "models/store";
import { AxiosError } from "axios";

export interface OrderCheckout {
    useBalance: boolean;
    paymentToken: string | null;
    verificationToken: string | null;
    recipientName: string;
    addressLine1: string;
    addressLine2?: string | null;
    city: string;
    state?: string | null;
    zipCode: string;
    country: string;
}

const useOrderCheckout = () => {
    const post = useAuthenticatedRequestCreator<StoreOrder, OrderCheckout>(model => ({
        method: "post",
        url: "/cart/checkout",
        data: model
    }), [400]);

    const { mutate: checkout, isLoading: isProcessing, error } = useMutation(
        (model: OrderCheckout) => post(model)
    );

    const errData = (error as AxiosError<{
        status: number,
        title: string,
        errors: { workflow: string[] }
    }>)?.response?.data;
    const finalErrors = errData?.errors?.workflow || errData?.title.split('\r\n');

    return { checkout, isProcessing, checkoutErrors: finalErrors || [] };
};

export default useOrderCheckout;