import { SearchOutlined } from "@ant-design/icons";
import { Alert, Button, InputRef, Modal, Tooltip } from "antd";
import Search from "antd/lib/input/Search";
import { StaffRoutes } from "appRoutePaths";
import useShipmentSearch from "hooks/useShipmentSearch";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

const ShipmentSearch = () => {
    const [visible, setVisible] = useState<boolean>(false);
    const { search, isLoading } = useShipmentSearch();
    const [error, setError] = useState<string | undefined>();
    const navigate = useNavigate();
    const input = useRef<InputRef>(null);

    const searchForShipment = async (term: string) => {
        if (term.trim().length === 0) {
            return;
        }

        var result = await search(term);

        if (!result.found) {
            setError(result.message || `No shipment found when searching for ${term}.`)
        } else {
            const shipmentUrl = StaffRoutes.shipmentBatchShipments.url(result.shipmentBatchId!, result.shipmentId!)
            navigate(shipmentUrl);
        }
    }

    // workaround for setting the search to auto-focus
    // when opening the modal
    setTimeout(() => input.current?.focus(), 10);

    const close = () => {
        setError(undefined);
        setVisible(false);
    }

    return <>
        <Tooltip placement="bottom" title="Shipment Search">
            <Button type="dashed" shape="circle" icon={<SearchOutlined />} size="large" onClick={() => setVisible(true)} style={{ marginRight: '15px' }} />
        </Tooltip>
        <Modal
            title="Shipment Search"
            closable={false}
            keyboard
            maskClosable
            destroyOnClose
            open={visible}
            footer={<Button key='cancel' type="default" onClick={close} disabled={isLoading}>Cancel</Button>}
        >
            <Search
                ref={input}
                enterButton
                placeholder="ID, SKU or Tracking Number"
                size="large"
                disabled={isLoading}
                style={{ marginBottom: '10px' }}
                onSearch={searchForShipment}
            />
            {error && <Alert
                className="bold-font"
                message={error}
                type="error"
                showIcon
            />}
        </Modal>
    </>;
}

export default ShipmentSearch;