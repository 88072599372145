export type DeadMessagePage = {
    pageCount: number;
    messageCount: number;
    startingFromId: number;
    messages: DeadMessage[]
};

export type DeadMessage = {
    id: number;
    reason: string;
    details: string;
    timestamp: string;
    message: any;
};

export enum DeadMessageType {
    Unknown = 0,
    BatchCreated,
    BatchPurchased,
    Fulfillment
}