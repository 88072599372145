import { ThemeConfig } from "@chakra-ui/react";

export enum ColorMode {
    Dark = "dark",
    Light = "light"
}

export const config: ThemeConfig = {
    initialColorMode: ColorMode.Light, // Only one theme for now
    useSystemColorMode: false,
};

export default config;
