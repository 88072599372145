import { StoreOrder } from "models/store";
import { useAuthenticatedRequestCreator } from "./useRequests";
import { useMutation } from "@tanstack/react-query";

const useOrderSearch = () => {
    const get = useAuthenticatedRequestCreator<StoreOrder, number>(
        (num: number) => ({
            method: "get",
            url: `/orders/${num}`
        }), [404]
    );

    const { mutateAsync: search, isLoading } = useMutation(get);

    return { search, isLoading };
}

export default useOrderSearch;