import "./styles/app.css";

import { ChakraProvider } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import AppRoutes from "AppRoutes";
import ZendeskChatWidget from "components/ZendeskChatWidget";
import { AuthContext } from "context/auth";
import { useAuthCookiePersistence } from "hooks/useAuthCookiePersistence";
import useGlobalMessageHandlers from "hooks/useGlobalMessageHandlers";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import theme from "theme";

const queryClient = new QueryClient();

// always scroll to the top of the page when
// the current app location changes
const useScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
};

// NOTE: This can really be removed at any point in the future.
//  Only users that ever had the problem will keep having it
const useRemoveChakraDarkMode = () => {
    useEffect(() => {
        localStorage.removeItem("chakra-ui-color-mode");
    }, []);
};

const App = () => {
    const authContext = useAuthCookiePersistence();

    useGlobalMessageHandlers();
    useScrollToTop();

    useRemoveChakraDarkMode();

    return (
        <>
            <ChakraProvider theme={theme}>
                <AuthContext.Provider value={authContext}>
                    <QueryClientProvider client={queryClient}>
                        <ZendeskChatWidget />

                        <AppRoutes />
                    </QueryClientProvider>
                </AuthContext.Provider>
            </ChakraProvider>
        </>
    );
};

export default App;
