import { Box, forwardRef, HStack, StackProps, VStack } from "@chakra-ui/react";
import { Radio, RadioChangeEvent } from "antd";
import { useGetPayoutFees } from "hooks/usePayoutFees";
import { CheckShippingMethods } from "models/payoutRequests";
import { FC, PropsWithChildren, useState } from "react";
import { centsToDollars } from "util/helpers";

import FormInput from "./FormInput";

const FeeSkeleton = forwardRef<
    { label: string } & StackProps & PropsWithChildren,
    typeof HStack
>(({ label, children, ...props }, ref) => {
    return (
        <HStack w="100%" justifyContent="space-between" ref={ref} {...props}>
            <Box>{label}: </Box>
            <Box fontWeight="semibold">{children}</Box>
        </HStack>
    );
});

const Fee = forwardRef<
    { value: number | undefined; label: string } & StackProps,
    typeof HStack
>(({ value, label, ...props }, ref) => {
    return (
        <FeeSkeleton label={label} ref={ref} {...props}>
            {value ? centsToDollars(value) : "--"}
        </FeeSkeleton>
    );
});

const ShippingMethodRadio: FC = () => {
    const { data: fees } = useGetPayoutFees();
    const [shippingMethod, setShippingMethod] = useState<CheckShippingMethods>(
        CheckShippingMethods.Standard
    );
    const handleShippingMethodChange = (e: RadioChangeEvent) => {
        setShippingMethod(e.target.value);
    };

    const { check } = fees || {};

    return (
        <>
            <FormInput
                flex={1}
                name="shippingMethod"
                label="Shipping Method"
                initialValue={CheckShippingMethods.Standard}
            >
                <Radio.Group onChange={handleShippingMethodChange}>
                    <Radio value={CheckShippingMethods.Standard}>
                        Standard
                    </Radio>
                    <Radio value={CheckShippingMethods.Overnight}>
                        Overnight
                    </Radio>
                </Radio.Group>
            </FormInput>
            <VStack flex={1}>
                <Box fontSize={16} fontWeight="semibold">
                    Fees
                </Box>
                <Box alignSelf="start">
                    {shippingMethod === CheckShippingMethods.Standard ? (
                        <Box>
                            <Box>
                                <Fee
                                    label="Domestic"
                                    value={check?.upsStandardDomesticInCents}
                                />
                            </Box>
                            <Box>
                                <Fee
                                    label="International"
                                    value={check?.upsStandardInternationalInCents}
                                />
                            </Box>
                        </Box>
                    ) : (
                        <Box>
                            <Box>
                                <Fee
                                    label="Domestic"
                                    value={check?.upsOvernightDomesticInCents}
                                />
                            </Box>
                            <Box>
                                <Fee
                                    label="International"
                                    value={check?.upsOvernightInternationalInCents}
                                />
                            </Box>
                        </Box>
                    )}
                </Box>
            </VStack>
        </>
    );
};

export default ShippingMethodRadio;
