import { Box } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { Button, Modal } from "antd";
import { useAuthenticatedRequestCreator } from "hooks/useRequests";
import { FC } from "react";

const useEmailConfirmation = () => {
    const confirm = useAuthenticatedRequestCreator<void, void>(
        () => ({
            method: 'post',
            url: "/profile/mfa/confirm-email",
        })
    );

    const { mutateAsync: confirmEmail } = useMutation(confirm);

    return {
        confirmEmail
    };
}

const ConfirmEmailAddress: FC<{ email: string }> = ({ email }) => {
    const { confirmEmail } = useEmailConfirmation();

    const informUser = async () => {
        await confirmEmail();

        Modal.info({
            title: "Email Confirmation Sent",
            content: (<>
                <Box>
                    An email has been sent to you with a confirmation link.
                </Box>
                <Box pt={20}>
                    Once you confirm your email address, return to this page to configure two-factor authentication options.
                </Box>
            </>
            )
        });
    }

    return <>
        <Button type="link" onClick={informUser}>Confirm Email Address ({email})</Button>
    </>;
}

export default ConfirmEmailAddress;