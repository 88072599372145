import { ExceptionOutlined } from "@ant-design/icons";
import { VStack, Text } from "@chakra-ui/react";
import { Alert, Button, Form, Input, Modal, Select, Tooltip } from "antd";
import { useAuthenticatorBulkFulfill, useAuthenticatorBulkMissingLabel } from "hooks/useAuthenticatorBulkMissingLabel";
import { useState } from "react";

interface FulfillForm {
    batch: string;
    trackingNumber: string;
}

const AuthenticatorBulkFulfillment = () => {
    const [visible, setVisible] = useState<boolean>(false);
    const { data } = useAuthenticatorBulkMissingLabel(visible);
    const { fulfill } = useAuthenticatorBulkFulfill();
    const [working, setWorking] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const [form] = Form.useForm<FulfillForm>();

    const reset = () => {
        setSuccess(false);
        form.resetFields();
    }

    const close = () => {
        setVisible(false);
        reset();
    }

    const submit = async (data: FulfillForm) => {
        try {
            setWorking(true);
            await fulfill({
                batchId: data.batch,
                trackingNumber: data.trackingNumber
            });
            setSuccess(true);
        } finally {
            setWorking(false);
        }
    }

    return <>
        <Tooltip placement="bottom" title="Authenticator Bulk Manual Fulfillment">
            <Button type="dashed" shape="circle" icon={<ExceptionOutlined />} size="large" onClick={() => setVisible(true)} style={{ marginRight: '15px' }} />
        </Tooltip>
        <Modal
            title="Authenticator Bulk Manual Fulfillment"
            closable={false}
            keyboard={false}
            maskClosable={false}
            destroyOnClose
            open={visible}
            footer={<Button key='cancel' type="default" onClick={close} disabled={working}>Close</Button >}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={submit}
            >
                <Form.Item>
                    <Text>
                        This will queue up order fulfillment requests to eBay if you had to manually generate a shipping label for an Authenticator Bulk batch.
                    </Text>
                </Form.Item>
                <Form.Item name="trackingNumber" label="Tracking Number" rules={[{ required: true, message: "Tracking Number is required!" }]}>
                    <Input disabled={success} />
                </Form.Item>

                <Form.Item name="batch" label="Authenticator Bulk Batch" rules={[{ required: true, message: "You must select a batch to fulfill!" }]}>
                    <Select options={data?.map(x => ({ label: x.name, value: x.id }))} disabled={!data || success} />
                </Form.Item>

                <Form.Item>
                    {!success && <Button htmlType="submit" type="primary" block disabled={working} loading={working}>Fulfill Batch Shipments</Button>}
                    {success && <VStack>
                        <Alert
                            type="success"
                            message="Fulfillments have been queued for processing!"
                        />
                        <Button onClick={reset} type="primary">Reset for another fulfillment</Button>
                    </VStack>}
                </Form.Item>
            </Form>
        </Modal>
    </>;
}

export default AuthenticatorBulkFulfillment;