import { Text, VStack } from "@chakra-ui/react";
import { CssVariables } from "models/common";
import { FC } from "react";
import { Link } from "react-router-dom";

export const ExistingUser: FC = () => (
    <VStack spacing={1} align="baseline" fontSize="3xl" color="black">
        <Text>Have an existing dcsports87 account?</Text>
        <Text fontSize="2xl">
            <Text as={Link} to="/login" color={CssVariables.antActiveFilter}>
                Sign in here
            </Text>{" "}
            with your credentials
        </Text>
    </VStack>
);
