import { PageHeader, Typography } from "antd";
const { Text } = Typography;

const SettingsHeader = (props: { title: string; subHeader?: string }) => {
    return (
        <div className="update-section-header">
            <PageHeader title={props.title} />
            {props.subHeader && (
                <Text className="sub-header">{props.subHeader}</Text>
            )}
        </div>
    );
};

export default SettingsHeader;