import { UnsoldCardBatchCreationResult } from "models/unsoldCards";
import { useAuthenticatedRequestCreator } from "./useRequests";

const PendingBatches = {
    method: 'get',
    url: '/unsold-cards/pending-batches'
};

const CreateBatches = {
    method: 'post',
    url: '/unsold-cards/create-batches'
};

const useUnsoldCardBatchCreation = () => {
    const createBatches = useAuthenticatedRequestCreator<UnsoldCardBatchCreationResult, boolean>(
        (dryRun: boolean) => dryRun
            ? PendingBatches
            : CreateBatches
    );

    return { createBatches };
};

export default useUnsoldCardBatchCreation;