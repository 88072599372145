import { Box, Text } from "@chakra-ui/react";
import { Button, Form } from "antd";
import { PublicRoutes } from "appRoutePaths";
import { SignUpFormItem } from "components/SignUp/SignUpFormItem";
import { useLogin } from "hooks/useLogin";
import { FC } from "react";
import { Link } from "react-router-dom";

export const LoginForm: FC = () => {
    const { authenticate, isLoading, isError } = useLogin();

    const onFinish = (values: any) => {
        authenticate(values);
    };

    return (
        <Box
            as={Form}
            name="authenticate"
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
            w="100%"
        >
            <SignUpFormItem
                title="Username or Email"
                name="username"
                autoComplete="username"
            />
            <SignUpFormItem
                id="current-password"
                autoComplete="current-password"
                title="Password"
                password={true}
            />

            <Form.Item>
                {/* <Checkbox>Remember me</Checkbox> */}
                <Box
                    as={Link}
                    to={PublicRoutes.forgotPassword.url}
                    color="blue"
                    float="right"
                    pr={4}
                >
                    Forgot Password
                </Box>
            </Form.Item>

            <Form.Item>
                {isError && <Text color="red">Invalid Credentials</Text>}
                <Button
                    id="btn-login"
                    className="primary-button"
                    type="primary"
                    htmlType="submit"
                    loading={isLoading}
                >
                    {isLoading ? "Authenticating..." : "Sign In"}
                </Button>
            </Form.Item>
        </Box>
    );
};
