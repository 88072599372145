import {
    AuctionCategory, CardTitle, CardTitlePhotos, DefaultCardTitleValues,
    EmptyAuctionCategory,
    GradedStatus, Grader, GraderRegEx, LotRegEx,
    SportExcellentCondition, UngradedStatus, UsedStatus
} from "models/cardIngestion";
import { FC, PropsWithChildren, createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import useAssignedCardTitle, { generateAssignedCardTitle } from "./useAssignCardTitle";
import useAuctionCategories from "./useAuctionCategories";
import useCardTitle from "./useCardTitle";

interface ICardTitleContext {
    id: string;
    cardTitle: string;
    isLot: boolean;
    category: string;
    subCategory: string;
    statusId: string;
    conditionId: string | null;
    grader: string | null;
    grade: string | null;
    notes: string | null;
    cardFolderId: string;
    cardFolderName: string;
    folderCompleted: number;
    folderTotal: number;
    photos: CardTitlePhotos[];
    shouldNotUpload: boolean;
    shouldNotUploadReason: string | null;
    auctionCategory: AuctionCategory;
    gradingCompany: Grader | null;
    previousCardTitle: string | null;
    isGraded: boolean;
    working: boolean;
    stashedCardId: string | null;
    multiPackageFolder: boolean,
    packageId: string | null;
    packageFriendlyId: number | null;
    completed: boolean;
    previousPackageId?: string | null;

    loadCardTitle: (cardId: string) => void;
    saveCardTitle: () => Promise<string | null>;
    doNotUploadCard: (reason: string) => Promise<string | null>;
    allowCardUpload: () => Promise<void>;
    setCategory: (category: string) => void;
    setIsLot: (value: boolean) => void;
    setSubcategory: (subCategory: string) => void;
    setGradingCompany: (graderId: string | null, gradeId?: string) => void;
    setStatus: (statusId: string, conditionId?: string) => void;
    setCondition: (conditionId: string | null) => void;
    setCardTitle: (title: string) => void;
    setNotes: (notes: string | null) => void;
    setPackageId: (packageId: string | null, packageFriendlyId: number | null) => void;
    stashState: () => void;
    popState: () => void;
}

export const CardTitleContext = createContext<ICardTitleContext>({
    id: '',
    cardFolderId: '',
    cardFolderName: '',
    folderCompleted: 0,
    folderTotal: 0,
    photos: [],
    shouldNotUpload: false,
    shouldNotUploadReason: null,
    cardTitle: '',
    category: '',
    isLot: false,
    subCategory: '',
    statusId: UngradedStatus,
    conditionId: SportExcellentCondition,
    grader: null,
    grade: null,
    auctionCategory: { id: '', type: '', subTypes: [], itemConditions: [], graders: [], ungradedConditions: [], isLot: false },
    gradingCompany: null,
    previousCardTitle: null,
    isGraded: false,
    notes: null,
    working: false,
    stashedCardId: null,
    multiPackageFolder: false,
    packageId: null,
    packageFriendlyId: null,
    completed: false,
    previousPackageId: null,

    loadCardTitle: (cardId: string) => { },
    saveCardTitle: () => Promise.resolve(''),
    doNotUploadCard: (reason: string) => Promise.resolve(''),
    allowCardUpload: () => Promise.resolve(),
    setCategory: (category: string) => { },
    setIsLot: (value: boolean) => { },
    setSubcategory: (subCategory: string) => { },
    setGradingCompany: (graderId: string | null, gradeId?: string) => { },
    setStatus: (statusId: string, conditionId?: string) => { },
    setCondition: (conditionId: string | null) => { },
    setCardTitle: (title: string) => { },
    setNotes: (notes: string | null) => { },
    setPackageId: (packageId: string | null, packageFriendlyId: number | null) => { },
    stashState: () => { },
    popState: () => { }
});

export const useCardTitleContext = () => {
    const context = useContext(CardTitleContext);
    if (!context) {
        throw new Error('useCardTitleContext must be used within a CardTitleContextProvider');
    }

    return context;
}

export const CardTitleContextProvider: FC<PropsWithChildren> = ({ children }) => {
    const [cardId, setCardId] = useState<string | null>(null);
    const { isLoading: isLoadingCategories, data: categories = [] } = useAuctionCategories();
    const { isLoading: isLoadingCard, data: card } = useCardTitle(cardId, !isLoadingCategories);
    const [cardState, setCardState] = useState(card);
    const [stash, setStash] = useState<CardTitle>();
    const [previousCardTitle, setPreviousCardTitle] = useState<string | null>(null);
    const [previousPackageId, setPreviousPackageId] = useState<string | null>();
    const { assignCardTitle, doNotUpload, allowUpload, isSaving } = useAssignedCardTitle();

    useEffect(() => {
        if (!card) {
            return;
        }

        if (stash && stash.id === card.id) {
            setCardState(stash);
            return;
        }

        if (card.completed) {
            // when loading a completed card title, do not default to previous card values
            setCardState(card);
        } else {
            const { category, statusId, conditionId } = DefaultCardTitleValues;

            setCardState(prev => ({
                ...card,
                category: card?.category ?? prev?.category ?? category,
                subCategory: card?.subCategory ?? prev?.subCategory ?? '',
                statusId: card?.statusId ?? prev?.statusId ?? statusId,
                conditionId: card?.conditionId ?? prev?.conditionId ?? conditionId,
                packageId: card?.packageId ?? prev?.packageId ?? null,
                packageFriendlyId: card?.packageFriendlyId ?? prev?.packageFriendlyId ?? null,
            }));
        }
    }, [card, stash]);

    const getAuctionCategory = useCallback((category: string | undefined, isLot: boolean | undefined) => {
        return categories.find(c => c.type === category && c.isLot === isLot) ?? EmptyAuctionCategory;
    }, [categories]);

    const setNextCard = useCallback((nextCard: CardTitle) => {
        setPreviousPackageId(cardState!.packageId);
        setStash(undefined);
        setCardId(nextCard.id);
    }, [cardState, setStash, setCardId]);

    const loadCardTitle = useCallback((newCardId: string) => {
        setCardId(newCardId);
    }, [setCardId]);

    const saveCardTitle = useCallback(async () => {
        const auctionCategory = getAuctionCategory(cardState?.category, cardState?.isLot);
        const completedCardTitle = generateAssignedCardTitle(cardState!, auctionCategory);
        const nextCard = await assignCardTitle(completedCardTitle);

        if (nextCard) {
            setPreviousCardTitle(cardState!.title);
            setNextCard(nextCard);

            return nextCard.id;
        }

        return null;
    }, [cardState, getAuctionCategory, assignCardTitle, setNextCard]);

    const doNotUploadCard = useCallback(async (reason: string) => {
        const nextCard = await doNotUpload({
            cardId: cardState!.id,
            reason
        });

        if (nextCard) {
            setNextCard(nextCard);

            return nextCard.id;
        }

        return null;
    }, [cardState, doNotUpload, setNextCard]);

    const allowCardUpload = useCallback(async () => {
        await allowUpload(cardState!.id);

        setCardState(prev => ({
            ...prev!,
            doNotUpload: false,
            doNotUploadReason: null,
        }));

    }, [cardState, allowUpload]);

    const setCategory = useCallback((category: string) => {
        const ac = getAuctionCategory(category, cardState?.isLot);
        setCardState(prev => ({
            ...prev!,
            category,
            subCategory: '',
            conditionId: ac.isLot ? null : ac.ungradedConditions[1]?.id
        }));

    }, [cardState?.isLot, getAuctionCategory]);

    const setIsLot = useCallback((value: boolean) => {
        const ac = getAuctionCategory(cardState?.category, value);

        setCardState(prev => ({
            ...prev!,
            isLot: value,
            statusId: value ? UsedStatus : UngradedStatus,
            conditionId: value ? null : ac.ungradedConditions[1]?.id,
        }));

    }, [cardState?.category, getAuctionCategory]);

    const setCardTitle = useCallback((newTitle: string) => {
        const lotDetected = LotRegEx.test(newTitle);
        const graderMatch = newTitle.match(GraderRegEx);

        if (lotDetected !== cardState?.isLot) {
            setIsLot(lotDetected);
        }

        if (graderMatch) {
            const auctionCategory = getAuctionCategory(cardState?.category, cardState?.isLot);
            const [, graderAbbr, grade] = graderMatch;
            const detectedGrader = auctionCategory.graders.find(g => g.name.includes(`(${graderAbbr.toUpperCase()})`) || g.name.toUpperCase() === graderAbbr.toUpperCase())!;
            const resolvedGrade = (grade.startsWith('A') || grade.startsWith('a')) ? "Authentic" : grade;

            setCardState(prev => ({
                ...prev!,
                title: newTitle,
                gradingCompanyId: detectedGrader.id,
                gradeId: detectedGrader.grades.find(g => g.name === resolvedGrade)?.id ?? null,
                statusId: GradedStatus,
                conditionId: null
            }));
        } else {
            setCardState(prev => ({
                ...prev!,
                title: newTitle,
                gradingCompanyId: null,
                gradeId: null,
            }));
        }
    }, [cardState, setIsLot, getAuctionCategory]);

    const setNotes = useCallback((notes: string | null) => {
        setCardState(prev => ({ ...prev!, notes }));
    }, []);

    const setSubcategory = useCallback((subCategory: string) => {
        setCardState(prev => ({ ...prev!, subCategory }));
    }, []);

    const setCondition = useCallback((conditionId: string | null) => {
        setCardState(prev => ({ ...prev!, conditionId }));
    }, []);

    const setPackageId = useCallback((packageId: string | null, packageFriendlyId: number | null) => {
        setCardState(prev => ({
            ...prev!,
            packageId,
            packageFriendlyId: packageFriendlyId === 0 ? null : packageFriendlyId
        }));
    }, [])

    const setStatus = useCallback((statusId: string) => {
        const ac = getAuctionCategory(cardState!.category, cardState!.isLot);
        // when the status changes, default to the 2nd ungraded condition (Excellent) if it exists
        const conditionId = ac.ungradedConditions[1]?.id ?? null;

        setCardState(prev => ({ ...prev!, statusId, conditionId }));
    }, [cardState, getAuctionCategory]);

    const setGradingCompany = useCallback((graderId: string | null, gradeId?: string) => {
        const grade = gradeId ? gradeId ?? null : null;

        setCardState(prev => ({
            ...prev!,
            gradingCompanyId: graderId,
            gradeId: grade
        }));
    }, []);

    const stashState = useCallback(() => {
        setStash(cardState!);
    }, [cardState]);

    const popState = useCallback(() => {
        if (stash) {
            setCardId(stash.id);
        }
    }, [stash]);

    const value = useMemo(() => {
        const auctionCategory = getAuctionCategory(cardState?.category, cardState?.isLot);
        const gradingCompany = auctionCategory.graders.find(g => g.id === cardState?.gradingCompanyId) ?? null
        const subCategory = cardState?.subCategory ?? '';

        return {
            id: cardState?.id ?? '',
            cardFolderId: cardState?.cardFolderId ?? '',
            cardFolderName: cardState?.folderName ?? '',
            folderCompleted: cardState?.folderCompleted ?? 0,
            folderTotal: cardState?.folderTotal ?? 0,
            photos: cardState?.photos ?? [],
            shouldNotUpload: cardState?.doNotUpload ?? false,
            shouldNotUploadReason: cardState?.doNotUploadReason ?? null,
            cardTitle: cardState?.title ?? '',
            category: cardState?.category ?? '',
            isLot: cardState?.isLot ?? false,
            subCategory,
            statusId: cardState?.statusId ?? '',
            conditionId: cardState?.conditionId ?? null,
            grader: cardState?.gradingCompanyId ?? null,
            grade: cardState?.gradeId ?? null,
            auctionCategory,
            gradingCompany,
            previousCardTitle,
            isGraded: cardState?.statusId === GradedStatus,
            notes: cardState?.notes ?? null,
            working: isLoadingCard || isLoadingCategories || isSaving,
            stashedCardId: stash?.id ?? null,
            multiPackageFolder: cardState?.multiPackageFolder ?? false,
            packageId: cardState?.packageId ?? null,
            packageFriendlyId: cardState?.packageFriendlyId ?? null,
            completed: cardState?.completed ?? false,
            previousPackageId,

            loadCardTitle,
            saveCardTitle,
            doNotUploadCard,
            allowCardUpload,
            setCategory,
            setIsLot,
            setSubcategory,
            setGradingCompany,
            setStatus,
            setCondition,
            setCardTitle,
            setNotes,
            setPackageId,
            stashState,
            popState,
        }
    }, [
        cardState,
        isLoadingCard,
        isLoadingCategories,
        isSaving,
        previousCardTitle,
        previousPackageId,
        stash,
        getAuctionCategory,
        loadCardTitle,
        saveCardTitle,
        doNotUploadCard,
        allowCardUpload,
        setCategory,
        setIsLot,
        setSubcategory,
        setGradingCompany,
        setStatus,
        setCondition,
        setCardTitle,
        setNotes,
        setPackageId,
        stashState,
        popState
    ]);

    return <CardTitleContext.Provider value={value}>
        {children}
    </CardTitleContext.Provider>
}