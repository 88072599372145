import augmentColors from "./helpers/augmentColors";

const baseColors = {
    darkGreen: "#06954d",
    lightGreen: "#C7F4C5",
    lightGray: "#f0f4fb",
    darkgrayTransparent: "#22272c99",
    gray: "#707070",
    darkGray: "#22272c",
    red: "#d94141",
    blue: "#1573e5",
    white: "#ffffff",
    lightBlue: "#1890ff",
};

const baseColorsWithAliases = {
    ...baseColors,
    green: baseColors.lightGreen,
    // text: baseColors.darkGray,
    // background: baseColors.white,
    // error: baseColors.red,
};

// We augment the base colors with several variants of 0-100 opacity (`red.50`, etc.)
const colorsWithOpacityAlternatives = augmentColors(baseColorsWithAliases);

// Purely for Intellisense and clarity
const colors = colorsWithOpacityAlternatives;


export default colors;
