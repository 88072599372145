import { useMutation } from "@tanstack/react-query";
import { ShipmentSearchResult } from "models/shipping";
import { useAuthenticatedRequestCreator } from "./useRequests";

const useShipmentSearch = () => {
    const post = useAuthenticatedRequestCreator<ShipmentSearchResult, string>(
        (term: string) => ({
            method: "post",
            url: "/shipping/search",
            data: {
                searchTerm: term
            }
        })
    );

    const { mutateAsync: search, isLoading } = useMutation(post);

    return { search, isLoading };
};

export default useShipmentSearch;