import { useQuery } from "@tanstack/react-query";
import { useIdentity } from "context/auth";
import { TreasuryAccount } from "models/treasury";
import { useAuthenticatedRequest } from "./useRequests";

const useTreasuryAccounts = (userId?: string) => {
    const { isAdmin } = useIdentity();

    // don't attempt to fetch treasury accounts for admins,
    // they'll only fetch on behalf of another user
    const enabled = !isAdmin || (isAdmin && !!userId);

    const url = userId
        ? `/treasury/accounts/${userId}`
        : `/treasury/accounts`;

    const getAccounts = useAuthenticatedRequest<TreasuryAccount[]>({
        method: "get",
        url
    });

    return useQuery(["treasury", "accounts", userId], getAccounts, {
        enabled
    });
};

export default useTreasuryAccounts;