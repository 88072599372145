import { ZendeskSSOResponse } from "models/support";
import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { useAuthenticatedRequest } from "./useRequests";

export const useZendeskChatToken = () => {
    const getToken = useAuthenticatedRequest<ZendeskSSOResponse>({
        url: "/account/zendesk/chat",
        method: "GET",
    });

    return getToken;
};


export const useZendeskSSOToken = () => {
    const getSSOToken = useAuthenticatedRequest<ZendeskSSOResponse>({
        url: "/account/zendesk/login",
        method: "GET",
    });

    return getSSOToken;
};

const useZendeskSSO = () => {
    const getSSOToken = useZendeskSSOToken();

    const [searchParams] = useSearchParams();

    const makeZendeskLink = useCallback(
        async (redirectUrl?: string) => {
            const { accessToken } = await getSSOToken();

            const returnTo = redirectUrl ?? searchParams.get("return_to");

            const zendeskLink = `/external-support?jwt=${accessToken}`;
            return zendeskLink + (returnTo ? `&return_to=${returnTo}` : "");
        },
        [getSSOToken, searchParams]
    );

    return makeZendeskLink;
};

export default useZendeskSSO;
