import { Box, Flex, HStack } from "@chakra-ui/react";
import { Badge } from "antd";
import { SupportTicket, SupportTicketStatuses } from "models/support";
import { FC } from "react";

const TICKET_TAG_NOTIFICATION = "customer_notification";

const STATUS_COLORS: Record<SupportTicketStatuses, string> = {
    open: "lightBlue",
    solved: "darkGreen",
    new: "red",
    pending: "orange",
    closed: "red",
    hold: "orange",
};

const TicketHeader: FC<{ ticket: SupportTicket; isExpanded: boolean, unread: boolean }> = ({
    ticket,
    isExpanded,
    unread
}) => {
    const {
        id,
        subject,
        description,
        status,
        tags,
        updatedAt: updatedAtStr,
    } = ticket;

    const isNotification = tags.includes(TICKET_TAG_NOTIFICATION);
    const updatedAt = new Date(updatedAtStr);
    const updatedAtDate = updatedAt.toLocaleDateString();
    const updatedAtTime = updatedAt.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
    });
    return (
        <HStack w="100%" color="text" alignItems="baseline" fontWeight="medium">
            <Box
                textTransform="capitalize"
                minWidth="5rem"
                color={STATUS_COLORS[status]}
                fontWeight="semibold"
                fontSize={14}
            >
                {unread && <Badge dot status="processing" style={{ marginRight: '5px' }} />}
                {!isNotification ? status: ""}
            </Box>
            <Box flex="1">
                <Box noOfLines={1}>{subject}</Box>
                {isExpanded && (
                    <Box color="gray" pl={1} opacity={0.6}>
                        #
                        <Box as="span" opacity={0.75}>
                            {id}
                        </Box>
                    </Box>
                )}
            </Box>
            <Box flex="1" noOfLines={1} opacity={0.5} fontSize="0.8em">
                {description}
            </Box>
            <Flex minWidth="10rem" justifyContent="end">
                <Box>
                    <Box>{updatedAtDate}</Box>
                    {isExpanded && (
                        <Box opacity={0.5} fontSize="0.8em">
                            {updatedAtTime}
                        </Box>
                    )}
                </Box>
            </Flex>
        </HStack>
    );
};

export default TicketHeader;
