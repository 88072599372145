import { Box, HStack, Text, VStack } from "@chakra-ui/react";
import { Alert, Button, Checkbox, Divider, Form, Input, Select } from "antd";
import { CustomerRoutes } from "appRoutePaths";
import LoadingSpinnerOverlay from "components/LoadingSpinnerOverlay";
import useCountryOptions, { getCountryCode } from "hooks/useCountryOptions";
import useOrderCheckout from "hooks/useOrderCheckout";
import { StoreOrder, UserShippingAddress } from "models/store";
import { FC, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CreditCard, GooglePay, PaymentForm } from 'react-square-web-payments-sdk';
import { centsToDollars } from "util/helpers";
import usePaymentCalculation, { PaymentCalculation } from "../usePaymentCalculation";

const ApplicationId = process.env["REACT_APP_SQUARE_APPLICATION_ID"] || "";
const LocationId = process.env["REACT_APP_SQUARE_LOCATION_ID"] || "";

const AccountBalance: FC<{
    calc: PaymentCalculation,
    onUseBalanceChange: (val: boolean) => void,
    onCheckout: () => void
}> = ({ calc, onUseBalanceChange, onCheckout }) => {

    if (!calc.canUseBalance) {
        return null;
    } else if (calc.balanceCanCoverOrder) {
        return <>
            <Button size="large" type="primary" block onClick={onCheckout}>Pay with my account balance</Button>
        </>
    } else {
        const remainder = calc.orderTotal - calc.balance;

        return <>
            <Checkbox
                defaultChecked
                style={{ fontWeight: 500 }}
                onChange={e => onUseBalanceChange(e.target.checked)}>
                I'll use the {centsToDollars(calc.balance)} in my account balance,
                and pay the remainer of {centsToDollars(remainder)} on a credit card or digital wallet
            </Checkbox>
        </>
    }
}

const PaymentOptions: FC<{
    accountBalanceInCents: number,
    orderTotalInCents: number,
    shippingAddress?: UserShippingAddress,
    order: StoreOrder
}> = ({ accountBalanceInCents, orderTotalInCents, shippingAddress, order }) => {
    const [validForm, setValidForm] = useState<boolean>(false);
    const [form] = Form.useForm();
    const countries = useCountryOptions();
    const [useShippingAddress, setUseShippingAddress] = useState<boolean>(true);
    const [useBalance, setUseBalance] = useState<boolean>(accountBalanceInCents > 0);
    const calc = usePaymentCalculation(orderTotalInCents, accountBalanceInCents, useBalance);
    const [paymentKey, setPaymentKey] = useState<number>(0);
    const { checkout, isProcessing, checkoutErrors } = useOrderCheckout();
    const navigate = useNavigate();

    const onFormChanged = useCallback(() => {
        const {
            firstName, lastName, addressLine1, city, state, zipCode, country
        } = form.getFieldsValue();
        const fields = [firstName, lastName, addressLine1, city, state, zipCode, country];

        setValidForm(
            fields.every(x => x !== undefined && x.trim() !== '')
        );
    }, [form]);

    useEffect(() => {
        if (useShippingAddress) {
            setValidForm(shippingAddress !== undefined);
        } else {
            onFormChanged();
        }
    }, [useShippingAddress, shippingAddress, onFormChanged]);

    const submitCheckout = async (
        useBalance: boolean,
        paymentToken: string | undefined | null,
        verificationToken: string | undefined | null
    ) => {
        await checkout({
            useBalance,
            paymentToken: paymentToken || null,
            verificationToken: verificationToken || null,
            recipientName: `${shippingAddress?.firstName} ${shippingAddress?.lastName}`,
            addressLine1: shippingAddress?.addressLine1!,
            addressLine2: shippingAddress?.addressLine2,
            city: shippingAddress?.city!,
            state: shippingAddress?.state,
            zipCode: shippingAddress?.zipCode!,
            country: shippingAddress?.country!
        }, {
            onSuccess: (newOrder) => {
                navigate(CustomerRoutes.orderConfirmation.url(newOrder.id), { replace: true });
            }
        });
    }

    const creditCardVerificationDetails = (): any => {
        const values = useShippingAddress
            ? shippingAddress!
            : form.getFieldsValue();

        const hasLine2 = values.addressLine2 && values.addressLine2!.trim() !== '';

        const billingContact = {
            firstName: values.firstName!,
            lastName: values.lastName!,
            addressLines: hasLine2
                ? [values.addressLine1!, values.addressLine2!]
                : [values.addressLine1!],
            countryCode: getCountryCode(values.country),
            city: values.city!,
            state: values.state!
        };

        return {
            amount: centsToDollars(calc.otherPaymentAmount, false, false),
            billingContact: billingContact,
            currencyCode: 'USD',
            intent: 'CHARGE',
        };
    };

    const digitalWalletPaymentRequest = (): any => ({
        countryCode: "US",
        currencyCode: "USD",
        lineItems: order.lineItems.map(li => ({
            amount: centsToDollars(li.totalInCents, false, false),
            label: `${li.itemName} (${li.variationName}) x ${li.quantity}`,
            id: li.catalogId
        })),
        taxLineItems: [{ amount: "0.00", label: "Tax" }],
        discounts: useBalance
            ? [{ label: "Account Balance Payment", amount: centsToDollars(calc.balancePaymentAmount, false, false) }]
            : undefined,
        total: {
            amount: centsToDollars(calc.otherPaymentAmount, false, false),
            label: "Total"
        }
    });

    return <Box p={5} display='flex' w="100%">
        <LoadingSpinnerOverlay isLoading={isProcessing} />
        <VStack align='start' w="100%">
            <Text fontSize={20} color="var(--dcs-black)">
                Payment Options
            </Text>
            <Divider />
            {checkoutErrors.length > 0 && <Alert
                style={{ width: "100%" }}
                type="error"
                message="Processing Error"
                description={<Box>
                    {checkoutErrors.map((e, idx) => (<Text as="p" key={idx}>{e}</Text>))}
                </Box>}
                showIcon
            />}
            {!shippingAddress && <Alert
                style={{ width: "100%" }}
                type="warning"
                message="Missing Shipping Address"
                description="Please provide a shipping address before moving on to payment options."
                showIcon />}
            {shippingAddress && <Form
                style={{ width: '100%' }}
                layout="vertical"
                form={form}
                onFieldsChange={onFormChanged}>
                <AccountBalance
                    calc={calc}
                    onUseBalanceChange={val => {
                        setUseBalance(val);
                        // digital wallets set the amount on page load & cannot
                        // be changed, so we'll force a component remount when
                        // the user toggles the 'use balance' option
                        setPaymentKey(paymentKey + 1);
                    }}
                    onCheckout={() => submitCheckout(true, null, null)}
                />
                <Divider>Credit Card</Divider>
                <Checkbox
                    defaultChecked={useShippingAddress}
                    onChange={(e) => setUseShippingAddress(e.target.checked)}>
                    Use Shipping Address as Billing Address
                </Checkbox>
                <Box display={useShippingAddress ? 'none' : 'inherit'} mt={3}>
                    <HStack mb="24px">
                        <Form.Item
                            style={{ marginBottom: 0 }}
                            label="First Name"
                            name="firstName"
                            rules={[{ required: true }]}
                        >
                            <Input size="large" />
                        </Form.Item>
                        <Form.Item
                            label="Last Name"
                            name="lastName"
                            rules={[{ required: true }]}
                        >
                            <Input size="large" />
                        </Form.Item>
                    </HStack>
                    <Form.Item
                        label="Billing Address Line 1"
                        name="addressLine1"
                        rules={[{ required: true }]}
                    >
                        <Input size="large" />
                    </Form.Item>

                    <Form.Item
                        label="Billing Address Line 2"
                        name="addressLine2"
                    >
                        <Input size="large" />
                    </Form.Item>

                    <HStack mb="24px">
                        <Form.Item
                            style={{ marginBottom: 0 }}
                            label="City"
                            name="city"
                            rules={[{ required: true }]}
                        >
                            <Input size="large" />
                        </Form.Item>
                        <Form.Item
                            label="State"
                            name="state"
                            rules={[{ required: true }]}
                        >
                            <Input size="large" />
                        </Form.Item>
                        <Form.Item
                            label="Zip Code"
                            name="zipCode"
                            rules={[{ required: true }]}
                        >
                            <Input size="large" />
                        </Form.Item>
                    </HStack>
                    <Form.Item
                        label="Country"
                        name="country"
                        rules={[{ required: true }]}>
                        <Select size="large" options={countries} />
                    </Form.Item>
                </Box>
                <Text fontSize={14}>
                    We don't store any payment details and your billing address is only used for verification purposes.
                </Text>
                <Form.Item style={{ width: '100%', marginTop: 24 }}>
                    <PaymentForm key={paymentKey}
                        applicationId={ApplicationId}
                        locationId={LocationId}
                        cardTokenizeResponseReceived={async (token, buyer) => {
                            submitCheckout(calc.willUseBalance, token.token, buyer?.token);
                        }}
                        createVerificationDetails={creditCardVerificationDetails}
                        createPaymentRequest={digitalWalletPaymentRequest}
                    >
                        {/* 
                            keeping the credit card form from stealing focus
                            on page load or a valid shipment address.

                            This has been addressed as of Oct 2022, but the
                            latest release of the library is Jun 2022.
                            https://github.com/weareseeed/react-square-web-payments-sdk/pull/74
                        */}
                        <CreditCard
                            // @ts-ignore
                            focus={""}
                            includeInputLabels
                            buttonProps={{
                                isLoading: !validForm || isProcessing,
                                css: {
                                    backgroundColor: "#2260ec",
                                    fontSize: "14px",
                                    color: "#fff",
                                    "&:hover": {
                                        backgroundColor: "#a6bff7",
                                    },
                                },
                            }}
                        />
                        <Divider>Digital Wallets</Divider>
                        <GooglePay />
                    </PaymentForm>
                </Form.Item>
            </Form>}
        </VStack>
    </Box >;
};

export default PaymentOptions;

