import KeyValueTableFilter from "components/KeyValueTableFilter";
import { ClearTableFilterRequest } from "models/common";
import { PackageStatus } from "models/packages";
import { ExtendedFilterDropdownProps } from "util/table";

const StatusFilter = ({
    prefixCls,
    visible,
    confirm,
    selectedKeys,
    setSelectedKeys,
    clearFilters,
    onFilter,
}: ExtendedFilterDropdownProps) => {
    return <KeyValueTableFilter
        prefixCls={prefixCls}
        visible={visible}
        confirm={confirm}
        selectedKeys={selectedKeys}
        setSelectedKeys={setSelectedKeys}
        clearFilters={clearFilters}
        onFilter={onFilter}
        clearTableFilterRequest={ClearTableFilterRequest.Status}
        keyValuePairs={{
            'Pending': PackageStatus.Pending,
            'Received': PackageStatus.Received,
            'Returned': PackageStatus.Returned,
            'eBay': PackageStatus.Ebay
        }}
    />;
};

export default StatusFilter;