import { PrinterOutlined } from "@ant-design/icons";
import { Text } from "@chakra-ui/react";
import { Button, Modal } from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import { ReturnedCards } from "models/packages";
import { useState } from "react";
import { centsToDollars, localizedDate } from "util/helpers";

const columns: ColumnsType<ReturnedCards> = [
    {
        title: "PDF",
        dataIndex: "labelUrl",
        render: url => <Button
            type="link"
            icon={<PrinterOutlined />}
            target="_blank"
            href={url} />
    },
    {
        title: "Tracking #",
        dataIndex: "trackingNumber"
    },
    {
        title: "Cost",
        dataIndex: "shippingCostInCents",
        render: (v: number) => centsToDollars(v)
    },
    {
        title: "Generated",
        dataIndex: "timestamp",
        render: localizedDate,
    },
]

const PackageDetailModal = (props: {
    friendlyId: number;
    returnedCards: ReturnedCards[];
}) => {
    const [visible, setVisible] = useState<boolean>(false);

    if (props.friendlyId === 0) {
        return <>N/A</>;
    } else if (props.returnedCards.length === 0) {
        return <>{props.friendlyId}</>;
    }

    return <>
        <Button type="link" style={{ padding: 0, margin: 0 }} onClick={() => setVisible(true)}>
            {props.friendlyId}
        </Button>
        <Modal
            title={`Additional Details for Package #${props.friendlyId}`}
            closable={false}
            keyboard={false}
            maskClosable={false}
            destroyOnClose={true}
            open={visible}
            cancelButtonProps={{ hidden: true }}
            onOk={() => setVisible(false)}
        >
            <Text my={2}>Shipping Labels for Returned Cards</Text>
            <Table
                id="returned-cards-table"
                size="small"
                columns={columns}
                dataSource={props.returnedCards}
                rowKey={(r) => r.id}
                pagination={{
                    pageSize: 100,
                    hideOnSinglePage: true
                }}
            />
        </Modal>
    </>
}

export default PackageDetailModal;