import { Box, Flex } from "@chakra-ui/react";
import useCustomerDashboard from "hooks/useCustomerDashboard";
import { FC } from "react";

import PackagesLineItem from "./PackagesLineItem";

const LastThreePackages: FC = () => {
    const { data } = useCustomerDashboard();
    const latestPackages = data?.latestPackages ?? [];

    return (
        <Box flex={1}>
            <Box fontSize={22} fontWeight="semibold" color="var(--dcs-black)">
                Last Three Packages
            </Box>
            <Box p={4}>
                <Flex color="darkGray" bg="white" flexDir="column" p={2}>
                    {latestPackages.length ? (
                        latestPackages.map((pkg) => (
                            <PackagesLineItem key={pkg.id} package={pkg} />
                        ))
                    ) : (
                        <Box fontSize={16}>No packages</Box>
                    )}
                </Flex>
            </Box>
        </Box>
    );
};

export default LastThreePackages;
