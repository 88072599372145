import { ShipmentCard, ShipmentCardStatus } from "models/shipping";
import { useAuthenticatedRequestCreator } from "./useRequests";

type TouchCardRequest = {
    shipmentId: string;
    cardId: string;
    status: ShipmentCardStatus;
}

const useShipmentCardPulling = () => {
    const touchCardRequest = useAuthenticatedRequestCreator<boolean, TouchCardRequest>(
        (payload) => ({
            method: "post",
            url: "/shipping/puller/touch-card",
            data: payload
        })
    );

    const touchCard = async (card: ShipmentCard, status: ShipmentCardStatus): Promise<boolean> => {
        const { shipmentId, cardId } = card;
        await touchCardRequest({ shipmentId, cardId, status });
        return true;
    };

    const pullCard = async (card: ShipmentCard): Promise<boolean> =>
        await touchCard(card, ShipmentCardStatus.Pulled);

    const skipCard = async (card: ShipmentCard): Promise<boolean> =>
        await touchCard(card, ShipmentCardStatus.NotPulled);

    return { pullCard, skipCard };
};

export default useShipmentCardPulling;