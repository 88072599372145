import { Alert, Card, List, Typography } from "antd";
import { OrderAddress, StoreOrder, StoreOrderShipment } from "models/store";
import { FC, useState } from "react";
import { Box, VStack, HStack, Text } from "@chakra-ui/react";
import { CssVariables } from "models/common";
import {
    CustomDimensions,
    FulfillRequest,
    OrderShipmentSelectOption
} from "hooks/useOrderFulfillment";
import ShippingOptions from "./ShippingOptions";
import ShipmentDetails from "./ShipmentDetails";
import { UseMutateAsyncFunction } from "@tanstack/react-query";
import useUpdateShippingAddress, { AddressFieldChange, AddressFieldName } from "hooks/useUpdateShippingAddress";
import { AxiosError } from "axios";

interface OrderFulfillmentDetailProps {
    order: StoreOrder,
    fulfill: UseMutateAsyncFunction<StoreOrderShipment, unknown, FulfillRequest, unknown>,
    isLoading: boolean,
    errors: string[],
    onFulfillSuccess: () => void,
    onAddressUpdated: () => void
}

const AddressField: FC<{
    field: AddressFieldName,
    value: string | null,
    lineBreak?: boolean,
    onChange: (value: AddressFieldChange) => void
}> = ({ field, value, lineBreak, onChange }) => {
    const [text, setText] = useState<string>(value || '');

    if (value === null) {
        return null;
    }

    const changed = (v: string) => {
        if (v.trim() === '' && field !== 'addressLine2') {
            return;
        }

        setText(v);
        onChange({ [field]: v });
    }

    return <>
        <Typography.Text
            editable={{
                triggerType: ['text'],
                onChange: changed
            }}
            style={{ cursor: 'pointer', color: CssVariables.blue }}
        >
            {text}
        </Typography.Text>{lineBreak && <br />}
    </>
}

const EditableShippingAddress: FC<{
    address: OrderAddress,
    onError: (error: string) => void,
    onUpdating: () => void,
    onSuccess: (value: AddressFieldChange) => void
}> = ({ address, onError, onUpdating, onSuccess }) => {
    const { update } = useUpdateShippingAddress(address.id);

    const onAddressEdit = async (value: AddressFieldChange) => {
        try {
            onUpdating();
            await update(value);
            onSuccess(value);
        } catch (error) {
            const msg = (error as AxiosError<{ title: string }>)?.response?.data?.title;
            onError(msg || "Failed to update shipping address");
        }
    }

    return <address>
        <AddressField field="recipientName" value={address.recipientName} lineBreak onChange={onAddressEdit} />
        <AddressField field="addressLine1" value={address.addressLine1} lineBreak onChange={onAddressEdit} />
        <AddressField field="addressLine2" value={address.addressLine2} lineBreak={!!address.addressLine2} onChange={onAddressEdit} />
        <AddressField field="city" value={address.city} onChange={onAddressEdit} />{" "}
        <AddressField field="state" value={address.state} onChange={onAddressEdit} />{", "}
        <AddressField field="zipCode" value={address.zipCode} lineBreak onChange={onAddressEdit} />
        <AddressField field="country" value={address.country} onChange={onAddressEdit} />
    </address>;
}

const StaticShippingAddress: FC<{ address: OrderAddress }> = ({ address }) => {
    return <address>
        {address.recipientName}<br />
        {address.addressLine1}<br />
        {address.addressLine2 && (<>{address.addressLine2}<br /></>)}
        {address.city} {address.state}, {address.zipCode}<br />
        {address.country}
    </address>;
}

const OrderFulfillmentDetail: FC<OrderFulfillmentDetailProps> = ({
    order, fulfill, isLoading, errors, onFulfillSuccess, onAddressUpdated
}) => {
    const [shipment, setShipment] = useState<StoreOrderShipment | undefined>(order.shipments[0]);
    const [addressUpdateError, setAddressUpdateError] = useState<string | undefined>();
    const [updating, setUpdating] = useState<boolean>(false);
    const [address, setAddress] = useState<OrderAddress>(order.addresses[0]);

    const submit = async (option: OrderShipmentSelectOption, dimensions: CustomDimensions) => {
        const result = await fulfill({
            orderId: order.id,
            option: option,
            dimensions
        });

        onFulfillSuccess();
        setShipment({ ...result });
    }

    const onAddressUpdateFailed = (error: string) => {
        setAddressUpdateError(error);
        setUpdating(false);
    }

    const onAddressUpdateSuccess = (value: AddressFieldChange) => {
        const newAddress = { ...address, ...value };
        setAddress(newAddress);
        setUpdating(false);
        onAddressUpdated();
    }

    return <Box w="100%">
        <Box alignSelf='start' display='flex' w='100%'>
            <VStack w="100%">
                <HStack align='start' w='100%'>
                    <Card title="Shipping Address" bordered={false}>
                        {!shipment && <EditableShippingAddress
                            address={address!}
                            onError={onAddressUpdateFailed}
                            onUpdating={() => setUpdating(true)}
                            onSuccess={onAddressUpdateSuccess}
                        />}

                        {shipment && <StaticShippingAddress
                            address={address}
                        />}
                    </Card>
                    <Card title="Line Items" bordered={false} style={{ width: '100%' }}>
                        <List
                            size="small"
                            split
                            dataSource={order.lineItems}
                            renderItem={item => (
                                <List.Item>
                                    <List.Item.Meta
                                        title={<Text fontFamily='body'>{item.itemName}</Text>}
                                        description={<Text color={CssVariables.gray}>{item.variationName}</Text>}
                                    />
                                    <Text fontSize='lg'>x {item.quantity}</Text>
                                </List.Item>
                            )}
                        />
                    </Card>
                </HStack>
                {addressUpdateError && <Alert
                    type="error"
                    description={addressUpdateError}
                    style={{ width: '100%' }}
                />}
                {!shipment && <ShippingOptions
                    isLoading={isLoading || updating}
                    errors={errors}
                    onSubmit={submit}
                />}
                {shipment && <ShipmentDetails
                    {...shipment}
                />}
            </VStack>
        </Box>
    </Box>
};

export default OrderFulfillmentDetail;