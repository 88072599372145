/*
  Given a label and a color, return a spreadable object with
   the original color and label, and any other color variants needed
   
   Right now this is 5 opacity levels, but it could be expanded to
    any color manipulation we want.

  Example:
    generateVersions('primary', '#F3EDD7')
  	
    returns
    {
      primary: '#F3EDD7',
      primary.0: '#F3EDD700',
      primary.25: '#F3EDD766',
      primary.50: '#F3EDD7CC',
      primary.75: '#F3EDD7FF',
      primary.100: '#F3EDD7FF',
    }

    Where each version is the percent opaque it is, 0 being transparent and 100 being
     tottally opaque (for completeness, if it's ever used procedurially)

*/
export const generateVersions = (label: string, hexColor: string) => {
    // 100 (fully opaque) is included for completeness
    const opacities = [0, 25, 50, 75, 100];

    const opacityVersions = opacities.reduce(
        (acc, opacity) => {
            // Take percentage and make it a 0-255 value, then covert to hex
            const opacityHex = Math.round(opacity / 100 * 255)
                .toString(16)
                .toUpperCase()
                .padStart(2, "0");

            acc[`${label}.${opacity}`] = `${hexColor}${opacityHex}`;

            return acc;
        },
        {} as { [key: string]: string }
    );

    return {
        ...opacityVersions,
        [label]: hexColor
    };
};

// Take in our color map and return a spreadable object with all the colors
//  and their variants
export const augmentColors = (colors: { [key: string]: string }) => {
    const colorVersions = Object.keys(colors).reduce(
        (acc, c) => {
            const color = colors[c];
            const colorVersions = generateVersions(c, color);
            return { ...acc, ...colorVersions };
        },
        {} as { [key: string]: string }
    );

    return { ...colors, ...colorVersions };
};

export default augmentColors;
