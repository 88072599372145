import { Box, Flex } from "@chakra-ui/react";
import { ImpersonatingBanner } from "components/ImpersonatingBanner";
import { Footer } from "components/Navigation/Footer";
import { NavHeader } from "components/Navigation/NavHeader";
import { Outlet } from "react-router-dom";

const PublicLayout = ({ children }: any) => {
    return (
        <Flex flexDir="column" minHeight="100vh" bg="white" color="black">
            <ImpersonatingBanner />
            <NavHeader />
            <Box flex="1" h="100%">
                {children ? children : <Outlet />}
            </Box>
            <Footer />
        </Flex>
    );
};

export default PublicLayout;
