import { useMutation } from "@tanstack/react-query";
import { PublicRoutes } from "appRoutePaths";
import { useAuthContext } from "context/auth";
import { ContactDetails } from "models/profile";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import useProfile, { useProfileState } from "./useProfile";
import { useAuthenticatedRequestCreator } from "./useRequests";

// All users must have their contact details on file before they can use an customer pages
export const useContactDetailsRedirect = () => {
    const { data: profileState, isSuccess } = useProfileState();
    const { isImpersonating } = useAuthContext();
    const navigate = useNavigate();

    useEffect(() => {
        // Don't redirect if we're impersonating a user, we don't need to fill out their info
        if (!isImpersonating() && isSuccess && (!profileState?.addressPresent || !profileState?.validEmail)) {
            navigate(PublicRoutes.contactDetails.url);
        }
    }, [isSuccess, profileState, navigate, isImpersonating]);
};

const useContactDetails = () => {
    const { data: profile } = useProfile();

    const postContactDetails = useAuthenticatedRequestCreator(
        (data: ContactDetails) => ({
            method: "post",
            url: "/profile/me",
            data: {
                ...data,
                email: data.email ?? profile?.email,
                firstName: profile?.firstName,
                lastName: profile?.lastName,
            },
        })
    );

    const { mutateAsync: setContactDetails } = useMutation(postContactDetails);

    return setContactDetails;
};

export default useContactDetails;
