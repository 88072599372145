import React from "react";
import { PageRequest } from "models/common";
import { capitalize } from "util/helpers";
import { useAuthenticatedRequestCreator } from "./useRequests";

interface BulkUpdateFields {
    newCustomerId: string;
}

export interface BulkUpdateResult {
    updateCount: number;
}

const useUpdateSelected = () => {
    const updateSelected = useAuthenticatedRequestCreator<
        BulkUpdateResult,
        BulkUpdateFields & { cardIds: React.Key[] }
    >(data => ({
        method: "post",
        url: "/cards/bulk-update/selection",
        data
    }));

    return updateSelected;
};

const useUpdateFiltered = () => {
    const updateFiltered = useAuthenticatedRequestCreator<
        BulkUpdateResult,
        BulkUpdateFields & { pageRequest: PageRequest }
    >(data => {
        const pageRequestWithCasedFilterBy = {
            ...data.pageRequest,
            filterBy: Object.fromEntries(
                Object.entries(data.pageRequest.filterBy as any)
                    // only inclide filter values that are specified
                    .filter(([, v]) => v !== null && v !== undefined)
                    // and title case them for the backend
                    .map(([k, v]) => [capitalize(k), v])
            )
        };
        const payload = { ...data, pageRequest: pageRequestWithCasedFilterBy };

        return {
            method: "post",
            url: "/cards/bulk-update/filter",
            data: payload
        };
    });

    return updateFiltered;
};

const useBulkCardUpdate = () => {
    const updateBySelection = useUpdateSelected();
    const updateByFilter = useUpdateFiltered();

    return { updateBySelection, updateByFilter };
};

export default useBulkCardUpdate;
