import { useQuery } from "@tanstack/react-query";
import { usePublicRequest } from "./useRequests";

export type FAQData = {
    categories: FAQCategory[];
    articles: FAQArticle[];
};

export type FAQCategory = {
    id: number;
    position: number;
    name: string;
};

export type FAQArticle = {
    id: number;
    categoryId: number;
    title: string;
    body: string;
};

const useFAQ = () => {
    const getFAQ = usePublicRequest<FAQData>({
        method: "get",
        url: `/faq`,
    });

    return useQuery(["faq"], getFAQ);
};

export default useFAQ;
