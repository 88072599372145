import TextTableFilter from "components/TextTableFilter";
import { ClearTableFilterRequest } from "models/common";
import { ExtendedFilterDropdownProps } from "util/table";

const FriendlyIdFilter = ({
    prefixCls,
    visible,
    confirm,
    selectedKeys,
    setSelectedKeys,
    clearFilters,
    onFilter,
}: ExtendedFilterDropdownProps) => (
    <TextTableFilter
        prefixCls={prefixCls}
        visible={visible}
        confirm={confirm}
        selectedKeys={selectedKeys}
        setSelectedKeys={setSelectedKeys}
        clearFilters={clearFilters}
        onFilter={onFilter}
        clearTableFilterRequest={ClearTableFilterRequest.FriendlyId}
        placeholder='Numeric package id'
    />
);

export default FriendlyIdFilter;