import { useMutation } from "@tanstack/react-query";
import { useAuthenticatedRequestCreator } from "./useRequests";
import { AxiosError } from "axios";
import { StoreOrderShipment } from "models/store";

export interface FulfillRequest {
    orderId: string,
    option: OrderShipmentSelectOption,
    dimensions?: CustomDimensions
}

export interface OrderShipmentSelectOption {
    value: number,
    label: string
}

export interface CustomDimensions {
    lengthInInches: number;
    widthInInches: number;
    heightInInches: number;
    weightInOz: number;
}

export const OrderFulfillmentShippingOptions: OrderShipmentSelectOption[] = [
    { value: 1, label: 'USPS Priority - Small Flat Rate Box' },
    { value: 2, label: 'USPS Priority - Medium Flat Rate Box' },
    { value: 3, label: 'USPS Priority - Large Flat Rate Box' },
    { value: 4, label: 'USPS Priority - Flat Rate Padded Envelope ' },
    { value: 5, label: 'USPS First Class (Custom)' },
];

const requestPayload = (orderId: string, opt: OrderShipmentSelectOption, dim?: CustomDimensions) => {
    const payload = { orderId, serviceLevel: "usps_priority" };

    switch (opt.value) {
        case 1:
            return { ...payload, template: "USPS_SmallFlatRateBox" };
        case 2:
            return { ...payload, template: "USPS_MediumFlatRateBox1" };
        case 3:
            return { ...payload, template: "USPS_LargeFlatRateBox" };
        case 4:
            return { ...payload, template: "USPS_FlatRatePaddedEnvelope" };
        case 5:
            return { ...payload, ...dim!, serviceLevel: "usps_first" };
        default:
            throw Error('invalid shipment option');
    }
}

const useOrderFulfillment = () => {
    const post = useAuthenticatedRequestCreator<StoreOrderShipment, FulfillRequest>(
        (req: FulfillRequest) => ({
            method: "post",
            url: "/orders/fulfill",
            data: requestPayload(req.orderId, req.option, req.dimensions)
        }), [400]
    );

    const { mutateAsync: fulfill, isLoading, error, reset } = useMutation(post);

    const errData = (error as AxiosError<{
        status: number,
        title: string,
        errors: { workflow: string[] }
    }>)?.response?.data;

    const finalErrors: string[] = errData === undefined
        ? []
        : errData.errors?.workflow || [errData.title];

    return { fulfill, isLoading, errors: finalErrors, reset };
};

export default useOrderFulfillment;