export const Button = {
    baseStyle: {
        cursor: "pointer",
        color: "white",
        bg: "transparent",
        rounded: "3",
    },
    variants: {
        // Custom Variants
        primary: {
            bg: "blue",
        },
        secondary: {
            bg: "lightGrey",
        },
        danger: {
            bg: "red",
        },

        // Default Variants
        solid: {
            colorScheme: "blue",
        },
        outline: {
            borderColor: "blue",
        },
        link: {
            color: "blue",
            bg: "transparent",
        },
    },
};

export default Button;
