import { PublicRoutes } from "appRoutePaths";
import jwt_decode from "jwt-decode";
import { createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ZendeskAPI } from "react-zendesk";

export enum Roles {
    User = 'user',
    UserPartner = 'user_partner',
    Typer = 'typer',
    Staff = 'staff',
    Admin = 'admin',
}

export interface IAuthToken {
    accessToken: string;
    tokenType: string;
    expiresIn?: number;
}

export interface IAuthContext {
    authToken: string | undefined;
    tokenType: string | undefined;
    setToken(token: IAuthToken | undefined): void;
    startImpersonating(impersonatedToken: IAuthToken): void;
    stopImpersonating(): void;
    isImpersonating(): boolean;
}

export interface Identity {
    isLoggedIn: boolean;
    accounts: string[];
    logout: () => void;
    id?: string;
    zendeskUserId?: string;
    email?: string;
    userName?: string;
    roles: Roles[];
    isAdmin?: boolean;
    isCustomer?: boolean;
    twoFactorTokenRequired: boolean;
    twoFactorSetupRequired: boolean;
}

interface IJwtToken {
    "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/sid": string;
    "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name": string;
    "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress": string;
    "http://schemas.microsoft.com/ws/2008/06/identity/claims/role":
    | string
    | string[];
    "dcs://acnt": string | string[];
    "dcs://zuid": string;
    "dcs://2fa-code/required": boolean;
    "dcs://2fa-setup/required": boolean;
    exp: number;
}

export const AuthContext = createContext<IAuthContext>({
    authToken: undefined,
    tokenType: undefined,
    setToken: () => { },
    startImpersonating: () => { },
    stopImpersonating: () => { },
    isImpersonating: () => false,
});

/**
 * Returns authentication information. Only use this in hooks that perform
 * authenticated calls to the back-end, or when setting the result of a successful
 * login. If you need information on the current user, see {@link useIdentity}.
 * @returns  An {@link IAuthContext} representing the current context
 */
export const useAuthContext = () => {
    return useContext(AuthContext);
};

const normalizeToArray = (value: string | string[]): string[] => {
    return typeof value === "string" ? [value] : value;
};

const mapRoleToEnum = (value: string | string[]): Roles[] => {
    const roles = normalizeToArray(value);
    const result: Roles[] = [];

    Object.keys(Roles).forEach(roleKey => {
        const roleName = roleKey as keyof typeof Roles;
        const roleValue = Roles[roleName];

        if (roles.includes(roleValue)) {
            result.push(roleValue);
        }
    });

    return result;
};

/**
 * Obtains information about the current user, if any. Use the
 * {@link Identity.isLoggedIn} property of the return value to verify if a user is present.
 * @returns The {@link Identity} of the current user
 */
export const useIdentity = (): Identity => {
    const { authToken, setToken } = useAuthContext();

    const navigate = useNavigate();
    const logout = () => {
        setToken(undefined);
        navigate(PublicRoutes.login.url);

        // Also log out of the zendesk chat widget
        ZendeskAPI("webWidget", "hide");
        ZendeskAPI("webWidget", "logout");
    };

    if (authToken === undefined) {
        return {
            isLoggedIn: false,
            roles: [],
            accounts: [],
            twoFactorTokenRequired: false,
            twoFactorSetupRequired: false,
            logout,
        };
    }

    const decoded = jwt_decode<IJwtToken>(authToken);

    const {
        "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/sid": id,
        "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name": userName,
        "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress":
        email,
        "http://schemas.microsoft.com/ws/2008/06/identity/claims/role": role,
        "dcs://acnt": account,
        "dcs://zuid": zendeskUserId,
        "dcs://2fa-code/required": twoFactorTokenRequired,
        "dcs://2fa-setup/required": twoFactorSetupRequired,
        exp: expirationSeconds,
    } = decoded;

    const isLoggedIn = Date.now() / 1000 < expirationSeconds;
    // roles and accounts will come back as a string if there are
    // only one, and an array if there are many. normalize this to
    // always be an array.
    const roles = mapRoleToEnum(role);
    const accounts = normalizeToArray(account);
    const isAdmin = roles.includes(Roles.Admin);
    const isCustomer = roles.includes(Roles.User) || roles.includes(Roles.UserPartner);

    return {
        isLoggedIn,
        id,
        zendeskUserId,
        email,
        userName,
        roles,
        accounts,
        isAdmin,
        isCustomer,
        twoFactorTokenRequired,
        twoFactorSetupRequired,
        logout,
    };
};