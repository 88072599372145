import { Box } from "@chakra-ui/react";
import { Button, Col, Row } from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import BatchTable from "components/staff/Packages/BatchTable";
import MultiValueDisplay from "components/MultiValueDisplay";
import TableActions from "components/TableActions";
import useStaffPackages, { useStaffPackagesCSV } from "hooks/useStaffPackages";
import { InitialPageRequest, PageRequest, PageSizeOptions } from "models/common";
import {
    StaffPackage,
    PackageAmount,
    PackageStatus,
} from "models/packages";
import { useState } from "react";
import { Link } from "react-router-dom";
import downloadAsCSV from "util/downloadAsCSV";
import { localizedDate } from "util/helpers";
import { packageTypeDisplay } from "util/table";

const columns: ColumnsType<StaffPackage> = [
    {
        title: "Status",
        dataIndex: "packageStatus",
        key: "packageStatus",
        sorter: false,
        render: (s) => PackageStatus[s],
    },
    {
        title: "ID",
        dataIndex: "friendlyId",
        key: "friendlyId",
        sorter: false,
        render: (id) => (id === 0 ? "N/A" : id),
    },
    {
        title: "Customer",
        dataIndex: "customerId",
        key: "customerId",
        sorter: false,
        render: (_, rec) => (
            <MultiValueDisplay
                title={`${rec.customerFirstName} ${rec.customerLastName}`}
                subTitle={rec.customerEmail}
            />
        ),
    },
    {
        title: "Type",
        dataIndex: "packageTypeDisplayName",
        key: "packageTypeDisplayName",
        sorter: false,
        render: (_, rec) => packageTypeDisplay(rec),
    },
    {
        title: "Amount",
        dataIndex: "packageAmount",
        key: "packageAmount",
        sorter: false,
        align: "right",
        render: (s) => PackageAmount[s],
    },
    {
        title: "Received Date",
        dataIndex: "receivedDate",
        key: "receivedDate",
        sorter: false,
        render: localizedDate,
    },
    {
        title: "Est. Upload Date",
        dataIndex: "estimatedUploadDate",
        key: "estimatedUploadDate",
        sorter: false,
        render: localizedDate,
    },
];

const InProgressPackages = () => {
    const [{ page, filterBy, withinDays, sort, pageSize }, setPageRequest] =
        useState<PageRequest>({
            ...InitialPageRequest,
            filterBy: {
                packageStatus: [PackageStatus.Pending, PackageStatus.Received],
            },
            sort: ["estimatedUploadDate", true]
        });

    const [{ isLoading, data }, forceRefresh] = useStaffPackages({
        page,
        filterBy,
        withinDays,
        sort,
        pageSize
    });

    const { data: records, totalRecords } = data || {};

    const daysChanged = (value: number) => {
        setPageRequest({ page: 1, filterBy, withinDays: value, sort, pageSize });
    };

    const tableChanged = (
        // pagination
        { current: newPage, pageSize: newPageSize }: any,
        // filtering
        _: any,
        // sorting
        __: any
    ) => {
        setPageRequest({
            page: newPageSize !== pageSize ? 1 : newPage,
            pageSize: newPageSize,
            withinDays,
            filterBy,
            sort,
        });
    };

    const fetchCSV = useStaffPackagesCSV({
        filterBy,
        withinDays,
        sort,
    });

    const handleCSVExport = async () => {
        const csvData = await fetchCSV();
        const date = new Date().toISOString().split("T")[0];
        const filename = `in-progress-packages-${date}.csv`;
        downloadAsCSV(csvData, filename);
    };

    return (
        <Box pt={10} w="90%">
            <Row align="bottom">
                <Col span={8}>
                    <Box fontSize={36}>In Progress Packages</Box>
                </Col>
                <Col span={8} offset={8} className="actions-container">
                    <TableActions
                        onDaysChanged={daysChanged}
                        isLoading={isLoading}
                        showFilter={false}
                        onExportCSV={handleCSVExport}
                    />
                </Col>
            </Row>
            <Row id="actions-and-filter">
                <Col span={4}>
                    <Button type="primary">
                        <Link to="/staff/packages/new">Add New Package</Link>
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Table
                        id="staff-packages-table"
                        columns={columns}
                        dataSource={records}
                        rowKey={(r) => r.id}
                        onChange={tableChanged}
                        pagination={{
                            total: totalRecords,
                            current: page,
                            pageSize,
                            pageSizeOptions: PageSizeOptions,
                            showSizeChanger: true,
                            showQuickJumper: true,
                        }}
                        expandable={{
                            rowExpandable: (r) => r.batches.length > 0,
                            expandedRowRender: (r) => (
                                <BatchTable
                                    batches={r.batches}
                                    onSuccess={forceRefresh}
                                />
                            ),
                        }}
                        showSorterTooltip={false}
                        loading={isLoading}
                    />
                </Col>
            </Row>
        </Box>
    );
};

export default InProgressPackages;
