import { Switch } from "antd";
import usePackageTypeUpdate from "hooks/usePackageTypeUpdate";
import { PackageType } from "models/packages";
import { FC, useState } from "react";

const TogglePackageType: FC<{
    packageType: PackageType,
    onSuccess: () => Promise<any>,
    readOnly: boolean
}> = ({ packageType, onSuccess, readOnly }) => {
    const { togglePackageType } = usePackageTypeUpdate();
    const [working, setWorking] = useState<boolean>(false);

    const toggle = async (checked: boolean) => {
        setWorking(true);
        await togglePackageType({ id: packageType.id, enabled: checked });
        setWorking(false);
        await onSuccess();
    }

    return <Switch
        defaultChecked={packageType.enabled}
        disabled={packageType.isCoreType || readOnly}
        loading={working}
        onChange={toggle}
    />;
}

export default TogglePackageType;