import { Image } from "@chakra-ui/image";
import { SimpleGrid, Text, VStack } from "@chakra-ui/react";

const photo1 = require("images/how-it-works/1.jpg");
const photo2 = require("images/how-it-works/2.jpg");
const photo3 = require("images/how-it-works/3.jpg");
const photo4 = require("images/how-it-works/4.jpg");

const bgBasketball = require("images/bg_basketball.jpg");

export const CardsOurPriority = () => {
    return (
        <VStack
            w="100%"
            bgImage={bgBasketball}
            display={{
                base: "none",
                md: "block",
            }}
            spacing={5}
            py={12}
            px={24}
        >
            <Text as="h1" color="white" fontSize="40">
                Your cards are our priority
            </Text>
            <Text color="white" fontSize="lg" maxWidth="1000px">
                With thousands of sales every day and being one of the largest
                eBay sellers in the world, we take pride in knowing we treat your
                cards like they were our own. Have confidence knowing you are
                sending to the #1 Sports Card Consignment Company in the world!
            </Text>
            <SimpleGrid columns={2} spacing={8} justifyContent="center">
                <Image src={photo1} alt="Packaged cards on a table" />
                <Image
                    src={photo2}
                    alt="A person holding a card in a protective sleeve"
                />
                <Image src={photo3} alt="A person labeling a package" />
                <Image
                    src={photo4}
                    alt="A person putting a card into a protective sleeve"
                />
            </SimpleGrid>
        </VStack>
    );
};
