import { useMutation } from "@tanstack/react-query";
import { DayOfWeek } from "models/common";
import { FeeBreakdown, PackageTypeFees } from "models/packages";
import { useAuthenticatedRequestCreator } from "./useRequests";

export interface PackageTypeUpdate {
    id: string,
    displayName: string;
    internalName: string;
    usesAmounts: boolean;
    leadTimeInDays: number | null;
    estimatedUploadDate: string | null;
    eventDayOfWeek: DayOfWeek | null;
    processingFeeInCents: number;
    bannerOrdinal: number | null
}

export type PackageTypeCreate = Omit<PackageTypeUpdate, "id">
export type PackageTypeFeesSet = Omit<PackageTypeFees, "id">

const usePackageTypeUpdate = () => {
    const toggleEnabled = useAuthenticatedRequestCreator<void, { id: string, enabled: boolean }>(
        payload => ({
            method: "post",
            url: "/package-types/toggle",
            data: payload
        })
    );

    const update = useAuthenticatedRequestCreator<void, PackageTypeUpdate>(
        payload => ({
            method: "post",
            url: "/package-types/update",
            data: payload
        })
    );

    const create = useAuthenticatedRequestCreator<void, PackageTypeCreate>(
        payload => ({
            method: "post",
            url: "/package-types/create",
            data: payload
        })
    );

    const setFee = useAuthenticatedRequestCreator<void, PackageTypeFeesSet>(
        payload => ({
            method: "post",
            url: "/package-types/fees/set",
            data: payload
        })
    );

    const deleteFee = useAuthenticatedRequestCreator<void, string>(
        packageTypeFeesId => ({
            method: "post",
            url: "/package-types/fees/delete",
            data: { packageTypeFeesId }
        })
    );

    const breakdown = useAuthenticatedRequestCreator<FeeBreakdown, { grossAmountInCents: number, packageTypeId: string, receivedDate: string | null }>(
        payload => ({
            method: "post",
            url: "/package-types/fees/dry-run",
            data: payload
        })
    );

    const { mutateAsync: togglePackageType } = useMutation(toggleEnabled);
    const { mutateAsync: updatePackageType } = useMutation(update);
    const { mutateAsync: createPackageType } = useMutation(create);
    const { mutateAsync: setPackageTypeFee } = useMutation(setFee);
    const { mutateAsync: deletePackageTypeFee } = useMutation(deleteFee);
    const { mutateAsync: dryRun } = useMutation(breakdown);

    return {
        togglePackageType,
        updatePackageType,
        createPackageType,
        setPackageTypeFee,
        deletePackageTypeFee,
        dryRun
    }
};

export default usePackageTypeUpdate;