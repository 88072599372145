import { Box } from "@chakra-ui/react";
import { CustomerRoutes } from "appRoutePaths";
import useCustomerDashboard from "hooks/useCustomerDashboard";
import { Link } from "react-router-dom";

const decidePlurality = (count: number, noun: string, suffix = "s") =>
    `${count} ${noun}${count !== 1 ? suffix : ""}`;

const CurrentlyBeingProcessed = () => {
    const { data } = useCustomerDashboard();

    const packageCount = data?.processingPackagesCount;
    const packageCountStr =
        packageCount !== undefined
            ? decidePlurality(packageCount, "Package")
            : "--";

    return (
        <Box maxW="20rem">
            <Box fontSize={22} fontWeight="semibold" color="var(--dcs-black)">
                Currently being processed
            </Box>
            <Box bg="white" color="darkGray" p={4}>
                <Box fontSize={36} fontWeight="bold">
                    {packageCountStr}
                </Box>
                <Box fontSize={24}>
                    We are currently working on uploading these for you.
                </Box>
                <Box
                    as={Link}
                    color="blue"
                    fontWeight="semibold"
                    textDecoration="underline"
                    to={CustomerRoutes.packages.url}
                >
                    See All Packages
                </Box>
            </Box>
        </Box>
    );
};

export default CurrentlyBeingProcessed;
