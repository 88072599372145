import { Button, Divider, Modal, Radio, RadioChangeEvent, Table } from "antd";
import { Box, Code, HStack, Text, VStack } from "@chakra-ui/react";
import { StaffRoutes } from "appRoutePaths";
import useDeadMessages from "hooks/useDeadMessages";
import { useEffect, useState } from "react";
import { DeadMessage, DeadMessageType } from "models/deadMessages";
import { ColumnsType } from "antd/lib/table";
import moment from "moment-timezone";

const monospaceStyle = {
    fontSize: 12,
    p: 10,
    backgroundColor: 'lightyellow',
    border: '1px solid darkkhaki',
    borderRadius: '5px'
}

const columns: ColumnsType<DeadMessage> = [
    {
        title: "Id",
        dataIndex: "id"
    },
    {
        title: "Timestamp",
        dataIndex: "timestamp",
        render: (val) => moment(val).format('llll')
    },
    {
        title: "Reason",
        dataIndex: "reason"
    },
    {
        title: "Details",
        dataIndex: "details",
        render: (_, rec) => <Button type='link' onClick={() => Modal.error({
            title: `Message Id #${rec.id} Error Details`,
            width: '1000px',
            content: (<Box style={{ whiteSpace: 'pre-wrap' }}>
                <Code children={rec.details} {...monospaceStyle} />
                <Divider />
                <Text fontSize={16} fontWeight={500} mb={5}>Original Message</Text>
                <Code children={JSON.stringify(rec.message, null, 2)} {...monospaceStyle} />
            </Box>)
        })}>
            View
        </Button>
    },
];

const DeadMessages = () => {
    const [type, setType] = useState<DeadMessageType>(DeadMessageType.BatchCreated);
    const [fromId, setFromId] = useState<number>(0);
    const [page, setPage] = useState<number>(0);
    const [pages, setPages] = useState<number[]>([]);
    const { isLoading, data } = useDeadMessages(type, fromId);

    /* 'Mock' Paging Documentation
    // To achieve mock paging, keep track of the first message id
    // per page in an array, using (page - 1) as the index to store the id.
    // The useEffect call will splice this value in at the correct index
    // when the initial data loads and as the user clicks 'Next'.
    //
    //  pages = [
    //     12,   <- page 1 starts at message id 12
    //     26,   <- page 2 starts at message id 26
    //     45,   <- page 3 starts at message id 45
    //     ...      etc
    //  ];
    //
    // When the dead message type changes, all paging values 
    // (fromId, page, pages) will be wiped as well.
    */

    useEffect(() => {
        if (!data || pages[page]) {
            // we have no data, or we already have a message id
            // for the given page
            return;
        }

        if (data.messageCount === 0) {
            setPages(_ => []);
        } else {
            // insert the message id of the first message of this page
            // into the index based on the page number
            pages.splice(page, 0, data.messages[0].id);
            setPages(_ => [...pages]);
        }
    }, [data, page, pages]);

    const changeType = (e: RadioChangeEvent) => {
        setType(e.target.value);
        setPage(0);
        setFromId(0);
        setPages([]);
    };

    const prev = () => {
        setPage(page - 1);
        setFromId(pages[page - 1]);
    };

    const next = () => {
        // we don't know future ids, but it's safe to
        // add 1 to the id of the last item, unless that item
        // happens to be long.MaxValue...
        setPage(page + 1);
        setFromId(data!.messages[data!.messages.length - 1].id + 1);
    };

    const isFirstPage = isLoading || page === 0;
    const isLastPage = isLoading || (page + 1) >= data!.pageCount;
    const noData = isLoading || data!.pageCount === 0;

    return <Box p={50} display='flex' width='100%'>
        <VStack width='100%'>

            <VStack alignItems='start' width='100%'>
                <Box fontSize={36}>Processing Errors</Box>
                <Button type="link" href={StaffRoutes.shipmentBatches.url}>&#8592;Back to Shipment Batches</Button>
            </VStack>

            <Radio.Group defaultValue={DeadMessageType.BatchCreated}
                disabled={isLoading}
                buttonStyle='solid'
                onChange={changeType}
                style={{ alignSelf: 'end' }}>
                <Radio.Button value={DeadMessageType.BatchCreated}>Batch Created</Radio.Button>
                <Radio.Button value={DeadMessageType.BatchPurchased}>Batch Purchased</Radio.Button>
                <Radio.Button value={DeadMessageType.Fulfillment}>Fulfillment</Radio.Button>
            </Radio.Group>

            <Table
                style={{ width: '100%' }}
                id="dead-messages-table"
                columns={columns}
                dataSource={data?.messages}
                rowKey={(r) => r.id}
                showSorterTooltip={false}
                pagination={false}
                loading={isLoading}
            />
            <HStack visibility={noData ? 'hidden' : 'visible'}>
                <Button
                    size="large"
                    disabled={isFirstPage}
                    onClick={prev}>
                    Previous
                </Button>
                {!isLoading && <Text>Page {page + 1} of {data!.pageCount}</Text>}
                <Button
                    size="large"
                    disabled={isLastPage}
                    onClick={next}>
                    Next
                </Button>
            </HStack>
        </VStack>
    </Box>;
}

export default DeadMessages;