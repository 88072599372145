import { Box, BoxProps, forwardRef } from "@chakra-ui/react";

const BrandLogoText = forwardRef<BoxProps, "div">((props, ref) => (
    <Box ref={ref} as="span" textTransform="none" fontStyle="italic" {...props}>
        dc
        <Box
            as="span"
            textDecor="underline"
            textDecorationColor="lightBlue"
            pos="relative"
            bottom="0.1em"
            fontSize="0.8em"
            px="0.05em"
        >
            sports
        </Box>
        87
    </Box>
));

export default BrandLogoText;
