import { Box } from "@chakra-ui/react";
import { FC } from "react";
import MoreInfoContact from "../MoreInfoContact";

import TextInput from "./Inputs/TextInput";

const WirePayoutDetails: FC = () => (
    <>
        <TextInput
            maxW="15rem"
            name="nameOnAccount"
            label="Name on Account"
            autoComplete="name"
        />
        <TextInput
            maxW="15rem"
            name="accountDigits"
            maxLength={4}
            type="number"
            label="Last 4 Digits of Bank Acct. Number"
        />
        <Box px={4} py={2} bg="lightGray" rounded="md" fontWeight="semibold">
            NOTE: Wire transfers are only available for payouts over $10,000. Customers outside of the US should use the International Wire Transfer option.
        </Box>
        <MoreInfoContact />
    </>
);

export default WirePayoutDetails;
