import { useMutation } from "@tanstack/react-query";
import { Alert, Button, Modal } from "antd";
import { useAuthenticatedRequestCreator } from "hooks/useRequests";
import { FC, ReactNode, useState } from "react";

const useTwoFactorRemoval = () => {
    const remove = useAuthenticatedRequestCreator<void, void>(
        () => ({
            method: 'post',
            url: "/profile/mfa/remove",
        })
    );

    const { mutateAsync: removeTwoFactor } = useMutation(remove);

    return {
        removeTwoFactor
    };
}


const DisableMFA: FC<{ onDisabled: () => void, trigger?: ReactNode }> = ({ onDisabled, trigger }) => {
    const { removeTwoFactor } = useTwoFactorRemoval();
    const [open, setOpen] = useState<boolean>(false);
    const [removed, setRemoved] = useState<boolean>(false);
    const [working, setWorking] = useState<boolean>(false);

    const remove = async () => {
        try {
            setWorking(true);

            await removeTwoFactor();
            setRemoved(true);
        } finally {
            setWorking(false);
        }
    }

    const closeModal = () => {
        if (removed) {
            onDisabled();
        }

        setOpen(false);
    }

    trigger ??= <Button type="link">
        Remove Two-Factor Authentication
    </Button>;

    return <>
        {<div onClick={() => setOpen(true)}>{trigger}</div>}
        <Modal
            open={open}
            closable={false}
            keyboard={false}
            maskClosable={false}
            destroyOnClose={true}
            confirmLoading={working}
            title="Remove Two-Factor Authentication"
            onCancel={closeModal}
            onOk={remove}
            footer={removed ? (
                <Button type="primary" onClick={closeModal}>Close</Button>
            ) : undefined}
        >
            {!removed && <Alert
                showIcon
                type="error"
                message="This will make your account less secure!"
                description="Are you sure you want to remove two-factor authentication from your account?"
            />}

            {removed && <Alert
                showIcon
                type="warning"
                message="Two-Factor Authentication Removed"
                description="You will only need your standard login credentials from now on"
            />}
        </Modal>
    </>
}

export default DisableMFA;