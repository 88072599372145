import {
    InstagramOutlined
} from "@ant-design/icons";
import {
    Button,
    Col,
    Image,
    Row,
    Space
} from "antd";

const LinkHero = () => {
    return (
        <Row
            justify="start"
            align="middle"
            style={{
                minHeight: "50vh",
                minWidth: "25vw",
                maxWidth: "452px",
                alignContent: "start",
                paddingTop: "10vh"
            }}
        >
            <Col span={24}>
                <Space direction="vertical" size={40} align="start">
                    <Button
                        href="/login"
                        type="primary"
                        size="large"
                        style={{ fontWeight: 'bold', width: '219px', borderRadius: '6px', transformOrigin: 'top left', scale: '150%', backgroundColor: 'var(--dcs-dark-blue)', border: 'var(--dcs-dark-blue)' }}>
                        Existing Customer Login
                    </Button>

                    <Button
                        href="/sign-up"
                        type="primary"
                        size="large"
                        style={{ fontWeight: 'bold', width: '219px', borderRadius: '6px', transformOrigin: 'top left', scale: '150%', backgroundColor: 'var(--dcs-dark-blue)', border: 'var(--dcs-dark-blue)' }}>
                        New Customer Sign Up
                    </Button>

                    <Button
                        size="large"
                        style={{ fontWeight: 'bold', width: '270px', textAlign: 'start', height: '50px', alignContent: 'end', borderRadius: '6px', transformOrigin: 'top left', scale: '120%' }}
                        href="https://www.instagram.com/dcsports87/"
                        target="_blank"
                    >
                        <div style={{ paddingTop: '7px' }}>
                            <InstagramOutlined
                                style={{
                                    color: "var(--dcs-white)",
                                    backgroundColor: "var(--dcs-bright-blue)",
                                    fontSize: "24px",
                                    marginRight: "10px",
                                    padding: "2px",
                                    borderRadius: "5px"
                                }}
                            />
                            <span
                                style={{
                                    textDecoration: "underline",
                                    fontSize: "18px",
                                }}
                            >
                                Visit us on Instagram
                            </span>
                        </div>
                    </Button>

                    <Button
                        size="large"
                        style={{ fontWeight: 'bold', width: '270px', textAlign: 'start', height: '50px', alignContent: 'end', borderRadius: '6px', marginTop: '-12px', transformOrigin: 'top left', scale: '120%' }}
                        href="https://www.ebay.com/str/uscgzachssportscards"
                        target="_blank"
                    >
                        <div style={{ paddingTop: '5px' }}>
                            <Image
                                preview={false}
                                src="/img/ebay.svg"
                                alt="ebay"
                                style={{
                                    height: 25,
                                    marginBottom: "-6px",
                                }}
                            />
                            <span
                                style={{
                                    textDecoration: "underline",
                                    fontSize: "18px",
                                }}
                            >
                                Visit our eBay Store
                            </span>
                        </div>
                    </Button>
                </Space>
            </Col>
        </Row>
    );
};

export default LinkHero;