import { Flex, Stack, Text, VStack } from "@chakra-ui/layout";
import { useQuery } from "@tanstack/react-query";
import { usePublicRequest } from "hooks/useRequests";

const NumbersToProveItCard = (props: {
    number: string | undefined;
    caption: string;
}) => {
    return (
        <VStack
            bg="var(--dcs-white)"
            color="var(--dcs-black)"
            pt={4}
            pb={2}
            px={8}
            align="baseline"
            spacing={0}
            rounded="md"
            width={{
                base: "full",
                md: "350px",
            }}
        >
            <Text as="h1" color="black" fontSize="4xl">
                {props.number ?? "--"}
            </Text>
            <Text as="h1" color="black" fontSize="xl" opacity={0.6}>
                {props.caption}
            </Text>
        </VStack>
    );
};

type NumbersToProveItData = {
    itemsSold: number;
    salesInMillions: number;
    totalUsers: number;
};

const useNumbersToProveIt = () => {
    const getNumbers = usePublicRequest<NumbersToProveItData>({
        url: "/dashboard/process-summary",
        method: "GET",
    });

    const { data } = useQuery(["numbersToProveIt"], getNumbers);

    return {
        itemsSold: data?.itemsSold,
        salesInMillions: data?.salesInMillions,
        totalUsers: data?.totalUsers,
    };
};

export const NumbersToProveIt = () => {
    const { itemsSold, salesInMillions, totalUsers } = useNumbersToProveIt();
    const itemsSoldString = itemsSold?.toLocaleString();
    const salesInMillionsString = salesInMillions
        ? `$${salesInMillions?.toLocaleString()} Million`
        : undefined;
    const totalUsersString = totalUsers?.toLocaleString();

    return (
        <Flex
            color="white"
            px={2}
            py={14}
            width="100%"
            justifyContent="center"
            zIndex={1}
        >
            <VStack align="left" spacing={7}>
                <VStack align="left">
                    <Text as="h1" fontSize={36} color="var(--dcs-white)">
                        Send. Sell. Get Paid! Check out our numbers!
                    </Text>
                    <Text fontSize={20}>
                        Check out what we've done in the last 12 months
                    </Text>
                </VStack>
                <Stack
                    spacing={6}
                    direction={{
                        base: "column",
                        md: "row",
                    }}
                >
                    <NumbersToProveItCard
                        number={itemsSoldString}
                        caption="Items sold"
                    />
                    <NumbersToProveItCard
                        number={salesInMillionsString}
                        caption="eBay Sales"
                    />
                    <NumbersToProveItCard
                        number={totalUsersString}
                        caption="Customers selling with us"
                    />
                </Stack>
            </VStack>
        </Flex>
    );
};
