import { Box, HStack, Text, VStack } from "@chakra-ui/react";
import { Card, Divider, List } from "antd";
import OrderPreview from "components/customer/Orders/OrderPreview";
import { PaymentSourceLabels, StoreOrder } from "models/store";
import moment from "moment";
import { FC } from "react";
import { centsToDollars } from "util/helpers";

const OrderDetail: FC<{ order: StoreOrder }> = ({ order }) => {
    const [address] = order.addresses || [];

    return <Box alignSelf='start' display='flex' w='100%'>
        <VStack w="100%">
            <HStack alignItems='stretch' w='100%' justifyContent="space-between">
                <Card title={`Order #${order.friendlyId}`} bordered={false} style={{ borderRadius: '10px' }}>
                    <Card bordered={false}>
                        <Text>{moment(order.createdAt).format("LLL")}</Text>
                    </Card>
                </Card>
                <Card title="Payment Methods" bordered={false} style={{ borderRadius: '10px' }}>
                    <List
                        split={false}
                        dataSource={order?.paymentMethods}
                        renderItem={item => (
                            <List.Item>
                                <List.Item.Meta
                                    title={<Text fontFamily='body'>{PaymentSourceLabels[item.source]}</Text>}
                                />
                                <Text mb={1}>{centsToDollars(item.amountInCents)}</Text>
                            </List.Item>
                        )}
                    />
                </Card>
                <Card title="Shipping Address" bordered={false} style={{ borderRadius: '10px' }}>
                    <address>
                        {address.recipientName}<br />
                        {address.addressLine1}<br />
                        {address.addressLine2 && (<>{address.addressLine2}<br /></>)}
                        {address.city} {address.state}, {address.zipCode}<br />
                        {address.country}
                    </address>
                </Card>
            </HStack>
            <Box w='100%'>
                <Divider style={{ margin: 0 }}>Line Items</Divider>
                <OrderPreview {...order} />
            </Box>
        </VStack>
    </Box>
};

export default OrderDetail;