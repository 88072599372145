import {
    useQuery,
} from "@tanstack/react-query";
import { ShipmentWindow } from "models/shipping";
import { useAuthenticatedRequest } from "./useRequests";

const makeAxiosRequest = (shipmentBatchId: string, shipmentId: string | undefined | null) => {
    return {
        method: "get",
        url: shipmentId
            ? `/shipping/shipper/navigate/${shipmentBatchId}/${shipmentId}`
            : `/shipping/shipper/navigate/${shipmentBatchId}`
    };
};

const useShipmentNavigation = (shipmentBatchId: string, shipmentId: string | undefined | null) => {
    const navigateToShipment = useAuthenticatedRequest<ShipmentWindow>(
        makeAxiosRequest(shipmentBatchId, shipmentId)
    );

    return useQuery(["shipments", shipmentBatchId, shipmentId], navigateToShipment);
};

export default useShipmentNavigation;