import { Box } from "@chakra-ui/react";
import { PaypalPayoutMethodDetails } from "models/payoutRequests";
import { FC } from "react";

import { ConfirmationDetailsProps } from ".";
import SummaryDetail from "./SummaryDetail";

const PaypalConfirmation: FC<ConfirmationDetailsProps> = ({
    payoutRequest,
}) => {
    const details = payoutRequest?.details as PaypalPayoutMethodDetails;

    return (
        <Box fontSize={18}>
            <Box fontSize={26} pb={3} fontWeight="bold">
                PayPal Confirmation
            </Box>
            <SummaryDetail label="Paypal Email">{details?.email}</SummaryDetail>
        </Box>
    );
};

export default PaypalConfirmation;
