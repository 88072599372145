import { Button, HStack, ModalBody, ModalFooter } from "@chakra-ui/react";
import LoadingSpinnerOverlay from "components/LoadingSpinnerOverlay";
import { useInvalidateAccountSummary } from "hooks/useAccountSummary";
import useCreatePayoutRequest from "hooks/useCreatePayoutRequest";
import { useInvalidatePayoutRequests } from "hooks/usePayoutRequests";
import { FC, useState } from "react";

import { Alert } from "antd";
import { AxiosError } from "axios";
import RequestConfirmationDetails, {
    ConfirmationDetailsProps,
} from "../ConfirmationDetails";
import { usePayoutRequestSummary } from "../ConfirmationDetails/AmountsSummary";

const PayoutConfirmStep2: FC<
    {
        active: boolean;
        onBack: () => void;
        onNext: () => void;
    } & ConfirmationDetailsProps
> = ({ payoutRequest, active, onBack, onNext }) => {
    const { mutateAsync: createPayoutRequest, isLoading } =
        useCreatePayoutRequest();
    const [error, setError] = useState<string>();

    const invalidatePayoutRequests = useInvalidatePayoutRequests();
    const invalidateAccountSummary = useInvalidateAccountSummary();
    const handleConfirm = async () => {
        setError(undefined);

        if (!payoutRequest) {
            console.error("No payout request to confirm, shouldn't happen");
            return;
        }
        try {
            await createPayoutRequest(payoutRequest);

            // NOTE: We'll only do these steps if the request was successful
            // To update the balance
            invalidateAccountSummary();

            // Invalidate the payout requests query so that the table updates
            invalidatePayoutRequests();

            // Go to the next step of the modal
            onNext();
        } catch (e) {
            if (e instanceof AxiosError) {
                setError(e.response?.data?.title);
            } else {
                setError('An unknown error occurred');
            }
        }
    };
    const {
        isBalanceSufficient,
        isPayoutAmountSufficient,
        wireTransferNotEnough,
    } = usePayoutRequestSummary(payoutRequest);

    // If they're an admin, these checks always come back clean
    // This is mostly because we can't fetch the user's balance as it stands
    // TODO: Properly handle getting account summary for the selected user as an admin
    const requestInvalid =
        !isBalanceSufficient ||
        !isPayoutAmountSufficient ||
        wireTransferNotEnough;

    return active ? (
        <>
            <LoadingSpinnerOverlay isLoading={isLoading} />
            <ModalBody>
                <RequestConfirmationDetails payoutRequest={payoutRequest} />
                {error && <Alert
                    showIcon
                    type="error"
                    message="Payout Request Failed"
                    description={error}
                />}
            </ModalBody>
            <ModalFooter as={HStack}>
                <Button variant="danger" onClick={onBack} size="sm">
                    Back
                </Button>
                <Button
                    variant="primary"
                    size="sm"
                    onClick={handleConfirm}
                    disabled={requestInvalid}
                >
                    Submit Request
                </Button>
            </ModalFooter>
        </>
    ) : null;
};

export default PayoutConfirmStep2;
