import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from "axios";
import { useAuthContext } from "context/auth";
import { publishMessage } from "messages/messageBus";
import { ErrorOccurred, messageTypes } from "messages/messages";

export const STAGING_BASE_API_URL =
    "https://dcsports87-stg-tmp.azurewebsites.net/api";

export const BASE_API_URL =
    process.env["REACT_APP_USE_STAGING"] === "true"
        ? STAGING_BASE_API_URL
        : process.env["REACT_APP_BASE_API_URL"] ?? "/api";

export type RequestError = AxiosError<{ errors: string[] }>;

// This is the actual base function that uses our axios instance and request definitions to make requests
// It almost exclusively exists as the step that happens to handle the error case
const sendRequest = async <T>(
    axiosInstance: AxiosInstance,
    request: AxiosRequestConfig,
    handledStatusCodes?: number[]
): Promise<T> => {
    try {
        const { data } = await axiosInstance(request);

        return data as T;
    } catch (error) {
        if (error instanceof AxiosError) {
            // If the error's HTTP status code is in the list of handled errors,
            //  then don't send the error message to the message bus
            if (!handledStatusCodes?.includes(error.response?.status ?? -1)) {
                if (
                    error.response?.status === 400 ||
                    error.response?.status === 401
                ) {
                    const errorMessageDict: Record<string, string[]> =
                        error.response?.data?.errors ?? {};
                    const status = error.response?.status ?? -1;
                    const errorMessages =
                        Object.values(errorMessageDict).flat();

                    if (errorMessages.length === 0) {
                        errorMessages.push(
                            error.response?.data?.title ??
                            "Your request generated an error"
                        );
                    }

                    errorMessages.forEach((errorMessage) => {
                        publishMessage<ErrorOccurred>(messageTypes.error, {
                            errorMessage,
                            status,
                            id: crypto.randomUUID(),
                        });
                    });
                } else {
                    console.error("Unhandled error", error);
                    publishMessage<ErrorOccurred>(messageTypes.error, {
                        errorMessage: "An unknown error occurred",
                        status: error.response?.status ?? -1,
                        id: crypto.randomUUID(),
                    });
                }
            }
        }

        // We still end up throwing the error so useQuery (or an error boundary) can handle it
        throw error;
    }
};

// Lots of verbose JSDocs to make it easier to understand the functions

/**
 * Use this one to make a request at call time, useful for Mutations
 * Takes an intended authenticated request and hands back an authenticated functor that useQuery is ready to accept
 *
 * @template ResponseT The type of the data returned by the request
 * @template ArgsT The type of the request factory arguments, if any
 *
 * @param axiosRequest A factory for the axios request to send (e.g. { method, url, headers, data, ... })
 * @param handledErrorCodes An array of (HTTP) status codes whose exceptions will be handled
 *     by the caller. Any other status codes will show a default error message in the UI
 *    and will be re-thrown.
 *
 * @returns A functor that can be used with useQuery
 */
export const useAuthenticatedRequestCreator = <ResponseT, ArgsT>(
    axiosRequest:
        | ((arg: ArgsT) => AxiosRequestConfig)
        | (() => AxiosRequestConfig),
    handledErrorCodes?: number[]
) => {
    const { authToken, tokenType } = useAuthContext();

    if (!authToken) {
        // Return a no-op if we don't have an auth token
        return () => Promise.resolve(undefined as ResponseT);
    }

    const axiosInstance = axios.create({
        baseURL: BASE_API_URL,
        headers: {
            Authorization: `${tokenType} ${authToken}`,
            Accept: "application/json",
        },
    });

    return (arg: ArgsT) =>
        sendRequest<ResponseT>(
            axiosInstance,
            axiosRequest(arg),
            handledErrorCodes
        );
};

/**
 * Use this one to make a request when the hook is called, useful for Queries or mutations that don't need arguments.
 * Takes an intended authenticated request and hands back an authenticated functor that useQuery is ready to accept
 *
 * @template ResponseT The type of the data returned by the request
 *
 * @param axiosRequest The axios request to send (e.g. { method, url, headers, data, ... })
 * @param handledErrorCodes An array of (HTTP) status codes whose exceptions will be handled
 *     by the caller. Any other status codes will show a default error message in the UI
 *    and will be re-thrown.
 *
 * @returns A functor that can be used with useQuery
 */
export const useAuthenticatedRequest = <ResponseT>(
    axiosRequest: AxiosRequestConfig,
    handledErrorCodes?: number[]
) => {
    const { authToken, tokenType } = useAuthContext();

    if (!authToken) {
        // Return a no-op if we don't have an auth token
        return () => Promise.resolve(null as ResponseT);
    }

    const axiosInstance = axios.create({
        baseURL: BASE_API_URL,
        headers: {
            Authorization: `${tokenType} ${authToken}`,
            Accept: "application/json",
        },
    });

    return () =>
        sendRequest<ResponseT>(axiosInstance, axiosRequest, handledErrorCodes);
};

// Statically create the axios instance for the public API
const publicAxiosInstance = axios.create({
    baseURL: BASE_API_URL,
    headers: { Accept: "application/json" },
});

/**
 * Use this one to make a request at call time, useful for Mutations
 * Takes an intended public request and hands back a functor that useQuery is ready to accept
 *
 * @template ResponseT The type of the data returned by the request
 * @template ArgsT The type of the request factory arguments, if any
 *
 * @param axiosRequest A factory for the axios request to send (e.g. { method, url, headers, data, ... })
 * @param handledErrorCodes An array of (HTTP) status codes whose exceptions will be handled
 *     by the caller. Any other status codes will show a default error message in the UI
 *    and will be re-thrown.
 *
 * @returns A functor that can be used with useQuery
 */
export const usePublicRequestCreator = <ResponseT, ArgsT>(
    axiosRequest: (arg: ArgsT) => AxiosRequestConfig,
    handledErrorCodes?: number[]
) => {
    return (arg: ArgsT) =>
        sendRequest<ResponseT>(
            publicAxiosInstance,
            axiosRequest(arg),
            handledErrorCodes
        );
};

/**
 * Use this one to make a request when the hook is called, useful for Queries or mutations that don't need arguments.
 * Takes an intended publci request and hands back a functor that useQuery is ready to accept
 *
 * @template ResponseT The type of the data returned by the request
 *
 * @param axiosRequest The axios request to send (e.g. { method, url, headers, data, ... })
 * @param handledErrorCodes An array of (HTTP) status codes whose exceptions will be handled
 *     by the caller. Any other status codes will show a default error message in the UI
 *    and will be re-thrown.
 *
 * @returns A functor that can be used with useQuery
 */
export const usePublicRequest = <ResponseT>(
    axiosRequest: AxiosRequestConfig,
    handledErrorCodes?: number[]
) => {
    return () =>
        sendRequest<ResponseT>(
            publicAxiosInstance,
            axiosRequest,
            handledErrorCodes
        );
};
