import { Flex, Image, VStack } from "@chakra-ui/react";
import { PublicRoutes } from "appRoutePaths";
import { TwoFactorForm } from "components/Login/TwoFactorForm";
import { OrDivider } from "components/SignUp/OrDivider";
import { useIdentity } from "context/auth";
import { getUserDashboardUrl } from "layouts/useIdentityRedirection";
import { FC } from "react";
import { Navigate } from "react-router-dom";

const SideImage = require("images/login-page.png");

const TwoFactorFormSection: FC = () => (
    <Flex w="100%" justify="space-around" maxW={{ base: "unset", md: "66%" }}>
        <Flex
            flex="1"
            flexDir="column"
            align="center"
            pt="7vh"
            px="2rem"
            maxWidth="30rem"
        >
            <VStack spacing={3} align="baseline" w="100%">
                <OrDivider>Two-Factor Verification Required</OrDivider>
                <TwoFactorForm />
            </VStack>
        </Flex>
    </Flex>
);

export const TwoFactor: FC = () => {
    const { isLoggedIn, roles, twoFactorTokenRequired } = useIdentity();

    if (isLoggedIn && !twoFactorTokenRequired) {
        const url = getUserDashboardUrl(roles);

        return <Navigate to={url} />
    } else if (!isLoggedIn) {
        return <Navigate to={PublicRoutes.login.url} />
    }

    return (
        <Flex w="100%" color="#22272c" flex={1} minH="85vh" pos="relative">
            <TwoFactorFormSection />
            <Image
                pos="absolute"
                right="0"
                top="0"
                bottom="0"
                src={SideImage}
                maxWidth={{ base: 0, md: "30%" }}
                flexBasis="30%"
                h="100%"
                objectFit="cover"
            />
        </Flex>
    );
};
