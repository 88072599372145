import { useQuery } from "@tanstack/react-query";
import { AdminDashboardDataPayload } from "models/adminDashboard";
import { useAuthenticatedRequest } from "./useRequests";

const useAdminDashboard = () => {
    const getAdminDashboardInfo =
        useAuthenticatedRequest<AdminDashboardDataPayload>({
            method: "get",
            url: `/dashboard/admin`,
        });

    const { data } = useQuery(["adminDashboard"], getAdminDashboardInfo, {
        suspense: true,
    });

    return data!;
};

export default useAdminDashboard;
