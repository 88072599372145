import { forwardRef } from "@chakra-ui/react";

import { SignUpFormItem, SignUpFormItemProps } from "./SignUpFormItem";

const PasswordInput = forwardRef<
    Partial<SignUpFormItemProps>,
    typeof SignUpFormItem
>((props, ref) => {
    return (
        <SignUpFormItem
            ref={ref}
            title="Password"
            password={true}
            autoComplete="new-password"
            id="new-password"
            rules={[
                {
                    // Password must have at least 1 uppercase letter and 1 number or symbol (~`!@#$%^&*()_-+={[}]|\:;"'<,>.?/)
                    required: true,
                    min: 6,
                    pattern:
                        /^(?=.*[A-Z])(?=.*[0-9])(?=.*[~`!@#$%^&*()_+=-{}|\\:;"'<,>.?/]).{8,}$/,
                    message:
                        "*Must be at least 6 characters and contain one symbol, number, and UPPERCASE letter",
                    validateTrigger: "onBlur",
                },
            ]}
            {...props}
        />
    );
});

export default PasswordInput;
