import { Box, Flex, Text, VStack } from "@chakra-ui/react";

export const PayoutsPaidWithin = () => {
    return (
        <Flex
            width="100%"
            bg="var(--dcs-dark-blue)"
            color="white"
            py={12}
            px={{
                base: 12,
                md: 24,
            }}
        >
            <VStack spacing={5} align="baseline">
                <Text as="h1" fontSize="40" color="white">
                    Payouts are normally processed within the same day!
                </Text>
                <Box>
                    <Text fontSize="lg">
                        We payout multiple times every business day as well as
                        Sundays.
                    </Text>
                    <Text fontSize="lg">
                        Please note that sometimes payouts can be delayed due to
                        holidays recognized by American banks.
                    </Text>
                </Box>
            </VStack>
        </Flex>
    );
};
