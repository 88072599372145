import { Tag } from "antd";
import { TransactionType as TransactionTypeEnum, TransactionTypeNames } from "models/transactions";

const TransactionType = (props: { type: TransactionTypeEnum }) => {
    let color: string;
    const name = TransactionTypeNames[props.type];

    switch (props.type) {
        case TransactionTypeEnum.CardSale:
            color = "green";
            break;
        case TransactionTypeEnum.Payout:
            color = "red";
            break;
        case TransactionTypeEnum.ManualAdjustment:
            color = "blue";
            break;
        case TransactionTypeEnum.OrderPayment:
            color = "gold";
            break;
        default:
            color = "purple";
            break;
    }

    return <Tag color={color}>{name}</Tag>;
};

export default TransactionType;
