import { CloseOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Form, Input, Modal, Row, Typography } from "antd";
import Checkbox, { CheckboxChangeEvent } from "antd/lib/checkbox";
import useStaffTestimonials from "hooks/useStaffTestimonials";
import { Testimonial } from "models/testimonials";
import SettingsHeader from "./SettingsHeader";
import TextArea from "antd/lib/input/TextArea";

const { Text, Paragraph } = Typography;

const TestimonialPreview = (props: {
    testimonial: Testimonial;
    onDelete: (id: string) => void;
    onUpdate: (t: Testimonial) => void;
    number: number;
}) => {
    const { id, copy, author, isLive } = props.testimonial;

    const remove = () =>
        Modal.confirm({
            title: `Delete Testimonial ${props.number}`,
            content: `Delete Testimonial from ${author}?`,
            icon: <ExclamationCircleOutlined />,
            onOk: async () => await props.onDelete(id),
        });

    const updateCopy = async (newCopy: string) => {
        if (newCopy !== copy) {
            await props.onUpdate({ ...props.testimonial, copy: newCopy });
        }
    };
    const updateAuthor = async (newAuthor: string) => {
        if (newAuthor !== author) {
            await props.onUpdate({ ...props.testimonial, author: newAuthor });
        }
    };
    const toggleIsLive = async (e: CheckboxChangeEvent) =>
        await props.onUpdate({
            ...props.testimonial,
            isLive: e.target.checked,
        });

    return (
        <div className="preview-testimonial">
            <Row align="middle">
                <Col span={3}>
                    <Text className="preview-number">
                        Testimonial {props.number}
                    </Text>
                </Col>
                <Col span={3} offset={15}>
                    <Checkbox checked={isLive} onChange={toggleIsLive}>
                        {" "}
                        Live on site
                    </Checkbox>
                </Col>
                <Col span={3}>
                    <Button
                        onClick={remove}
                        type="link"
                        danger
                        icon={<CloseOutlined />}
                    >
                        Delete
                    </Button>
                </Col>
            </Row>
            <Divider />
            <Paragraph
                className="preview-copy"
                editable={{ autoSize: { minRows: 4 }, onChange: updateCopy }}
            >
                {copy}
            </Paragraph>
            <Row align="middle">
                <Col span={6}>
                    <Text
                        className="preview-author"
                        editable={{ onChange: updateAuthor }}
                    >
                        {author}
                    </Text>
                </Col>
            </Row>
        </div>
    );
};

const TestimonialSection = () => {
    const [form] = Form.useForm();
    const { isLoading, testimonials, create, update, remove } =
        useStaffTestimonials();

    const submit = async (props: { copy: string; author: string }) => {
        await create(props);
        form.resetFields();
    };

    return (
        <>
            <SettingsHeader
                title="Testimonials"
                subHeader="Add New Testimonial"
            />
            <Form form={form} layout="vertical" onFinish={submit}>
                <Form.Item
                    label="Testimonial Copy"
                    name="copy"
                    rules={[
                        {
                            required: true,
                            message: "Testimonial copy is required!",
                        },
                    ]}
                >
                    <TextArea
                        rows={4}
                        placeholder="Add testimonial copy here"
                    />
                </Form.Item>

                <Row gutter={[32, 0]}>
                    <Col span={8}>
                        <Form.Item
                            label="Author"
                            name="author"
                            rules={[
                                {
                                    required: true,
                                    message: "Testimonial author is required!",
                                },
                            ]}
                        >
                            <Input placeholder="Set testimonial author here" />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={isLoading}
                    >
                        Publish to Website
                    </Button>
                </Form.Item>
            </Form>
            <div id="testimonial-previews">
                {!isLoading &&
                    testimonials?.map((t, index) => (
                        <TestimonialPreview
                            key={t.id}
                            number={index + 1}
                            testimonial={t}
                            onDelete={remove}
                            onUpdate={update}
                        />
                    ))}
            </div>
        </>
    );
};

export default TestimonialSection;