export type ShipmentBatch = {
    id: string;
    name: string;
    type: ShipmentBatchType;
    externalBatchId: string;
    orders: number;
    items: number;
    pulled: number;
    shipped: number;
    labels: ShipmentBatchLabel[];
    waitingOnLabels: boolean;
};

export type ShipmentBatchLabel = {
    id: string;
    labelUrl: string;
    seen: boolean;
}

export type ShipmentBatchSummary = {
    serviceLevel: string;
    dimensions: string;
    count: number
};

export enum ShipmentBatchType {
    Unknown = 0,
    // This batch type is obsolete & superceded by the more specific non-priority single types.
    // it's just here for legacy purposes.     
    RegularSingle = 1,
    PrioritySingle = 2,
    RegularBulk = 3,
    PriorityBulk = 4,
    HeavyBulkFirstClass = 5,
    HeavyBulkPriority = 6,
    // This batch type is obsolete & superceded by the more specific Wax types.
    // it's just here for legacy purposes.
    Wax = 7,
    ArmedForces = 8,
    AuthenticatorBulkTC1 = 9,
    // This batch type will be deprecated by eBay on Sept 9, 2024. Again, legacy purposes
    AuthenticatorBulkTC2 = 10,
    WaxPaddedFlatRate = 11,
    WaxMedium = 12,
    // This batch type is obsolete & superceded by WaxCustom.
    WaxUPS = 13,
    WaxFirstClass = 14,
    WaxLargeFlatRate = 15,
    // This batch type is obsolete & superceded by WaxCustom. 
    MixedWax = 16,
    SingleRaw = 17,
    SingleRawHighDollar = 18,
    SingleGraded = 19,
    SingleGradedHighDollar = 20,
    AuthenticatorBulkTC3 = 21,
    AuthenticatorBulkTC4 = 22,
    WaxCustom = 23
}

export enum ShipmentType {
    Unknown = 0,
    // This shipment type is obsolete & superceded by the more specific non-priority single types.
    // it's just here for legacy purposes.      
    RegularSingle = 1,
    PrioritySingle = 2,
    RegularBulk = 3,
    PriorityBulk = 4,
    HeavyBulkFirstClass = 5,
    HeavyBulkPriority = 6,
    // This shipment type is obsolete & superceded by the more specific Wax types.
    // it's just here for legacy purposes.    
    Wax = 7,
    AuthenticatorBulkTC1 = 8,
    // This batch type will be deprecated by eBay on Sept 9, 2024. Again, legacy purposes
    AuthenticatorBulkTC2 = 9,
    WaxPaddedFlatRate = 10,
    WaxMedium = 11,
    // This batch type is obsolete & superceded by WaxCustom. 
    WaxUPS = 12,
    WaxFirstClass = 13,
    WaxLargeFlatRate = 14,
    // This batch type is obsolete & superceded by WaxCustom. 
    MixedWax = 15,
    SingleRaw = 16,
    SingleRawHighDollar = 17,
    SingleGraded = 18,
    SingleGradedHighDollar = 19,
    AuthenticatorBulkTC3 = 20,
    AuthenticatorBulkTC4 = 21,
    WaxCustom = 22
}

export const ShipmentBatchTypeNames: Record<ShipmentBatchType, string> = {
    [ShipmentBatchType.Unknown]: "Unknown",
    [ShipmentBatchType.RegularSingle]: "Regular Single",
    [ShipmentBatchType.PrioritySingle]: "Priority Single",
    [ShipmentBatchType.RegularBulk]: "Regular Bulk",
    [ShipmentBatchType.PriorityBulk]: "Priority Bulk",
    [ShipmentBatchType.HeavyBulkFirstClass]: "Heavy Bulk First Class",
    [ShipmentBatchType.HeavyBulkPriority]: "Heavy Bulk Priority",
    [ShipmentBatchType.Wax]: "Wax",
    [ShipmentBatchType.ArmedForces]: "Armed Forces",
    [ShipmentBatchType.AuthenticatorBulkTC1]: "Authenticator Bulk (TC1)",
    [ShipmentBatchType.AuthenticatorBulkTC2]: "Authenticator Bulk (TC2)",
    [ShipmentBatchType.WaxPaddedFlatRate]: "Wax Padded Flat Rate",
    [ShipmentBatchType.WaxMedium]: "Wax Medium",
    [ShipmentBatchType.WaxUPS]: "Wax UPS",
    [ShipmentBatchType.WaxFirstClass]: "Wax First Class",
    [ShipmentBatchType.WaxLargeFlatRate]: "Wax Large Flat Rate",
    [ShipmentBatchType.MixedWax]: "Mixed Wax",
    [ShipmentBatchType.SingleRaw]: "Single Raw",
    [ShipmentBatchType.SingleRawHighDollar]: "Single Raw High$",
    [ShipmentBatchType.SingleGraded]: "Single Graded",
    [ShipmentBatchType.SingleGradedHighDollar]: "Single Graded High$",
    [ShipmentBatchType.AuthenticatorBulkTC3]: "Authenticator Bulk (TC3)",
    [ShipmentBatchType.AuthenticatorBulkTC4]: "Authenticator Bulk (TC4)",
    [ShipmentBatchType.WaxCustom]: "Wax Custom"
};

export const ShipmentTypeNames: Record<ShipmentType, string> = {
    [ShipmentType.Unknown]: "Unknown",
    [ShipmentType.RegularSingle]: "Regular Single",
    [ShipmentType.PrioritySingle]: "Priority Single",
    [ShipmentType.RegularBulk]: "Regular Bulk",
    [ShipmentType.PriorityBulk]: "Priority Bulk",
    [ShipmentType.HeavyBulkFirstClass]: "Heavy Bulk First Class",
    [ShipmentType.HeavyBulkPriority]: "Heavy Bulk Priority",
    [ShipmentType.Wax]: "Wax",
    [ShipmentType.AuthenticatorBulkTC1]: "Authenticator Bulk (TC1)",
    [ShipmentType.AuthenticatorBulkTC2]: "Authenticator Bulk (TC2)",
    [ShipmentType.WaxPaddedFlatRate]: "Wax Padded Flat Rate",
    [ShipmentType.WaxMedium]: "Wax Medium",
    [ShipmentType.WaxUPS]: "Wax UPS",
    [ShipmentType.WaxFirstClass]: "Wax First Class",
    [ShipmentType.WaxLargeFlatRate]: "Wax Large Flat Rate",
    [ShipmentType.MixedWax]: "Mixed Wax",
    [ShipmentType.SingleRaw]: "Single Raw",
    [ShipmentType.SingleRawHighDollar]: "Single Raw High$",
    [ShipmentType.SingleGraded]: "Single Graded",
    [ShipmentType.SingleGradedHighDollar]: "Single Graded High$",
    [ShipmentType.AuthenticatorBulkTC3]: "Authenticator Bulk (TC3)",
    [ShipmentType.AuthenticatorBulkTC4]: "Authenticator Bulk (TC4)",
    [ShipmentType.WaxCustom]: "Wax Custom"
};

export type Shipment = {
    id: string;
    friendlyId: number;
    type: ShipmentType;
    orderDate: string;
    created: string;
    shipmentBatchId: string;
    shipmentBatchName: string;
    ordinal: number;
    options: ShipmentOptions;
    destination: ShipmentAddress;
    items: number;
    pulled: number;
    shipped: boolean;
    labels: ShipmentLabel[];
};

export type ShipmentLabel = {
    id: string;
    shipmentId: string;
    transactionId: string;
    labelUrl: string;
    generated: string;
};

export enum ShipmentCarrier {
    Unknown = 0,
    USPS = 1,
    UPS = 2
}

export const ShipmentCarrierNames: Record<ShipmentCarrier, string> = {
    [ShipmentCarrier.Unknown]: "Unknown",
    [ShipmentCarrier.USPS]: "USPS",
    [ShipmentCarrier.UPS]: "UPS",
};

export type ShipmentDimensions = {
    lengthInInches: number | null;
    widthInInches: number | null;
    heightInInches: number | null;
    templateName: string | null;
    weightInOz: number;
    usingParcelTemplate: boolean;
};

export type ShipmentOptions = {
    dimensions: ShipmentDimensions;
    carrier: ShipmentCarrier;
    requireSignature: boolean;
    serviceLevel: string;
};

export type ShipmentAddress = {
    recipientName: string;
    addressLine1: string;
    addressLine2: string | null;
    city: string;
    state: string | null;
    zipCode: string;
    country: string;
};

export type ShipmentCardWindow = {
    hasPreviousCard: boolean;
    hasNextCard: boolean;
    shipmentBatchName: string;
    shipmentCount: number;
    shipmentNumber: number;
    shipmentBatchType: ShipmentBatchType;
    shipmentType: ShipmentType;
    itemCount: number;
    itemNumber: number;
    previousCard: ShipmentCard | null;
    currentCard: ShipmentCard;
    nextCard: ShipmentCard | null;
    nextUntouchedCardId: string | null;
};

export enum ShipmentCardStatus {
    Unknown = 0,
    Waiting = 1,
    Pulled = 2,
    NotPulled = 3
}

export const ShipmentCardStatusNames: Record<ShipmentCardStatus, string> = {
    [ShipmentCardStatus.Unknown]: "Unknown",
    [ShipmentCardStatus.Waiting]: "Waiting",
    [ShipmentCardStatus.Pulled]: "Pulled",
    [ShipmentCardStatus.NotPulled]: "Not Pulled"
};

export const ShipmentServiceLevelNames: Record<string, string> = {
    "usps_first": "USPS First Class",
    "usps_priority": "USPS Priority",
    "ups_express": "UPS Express"
};

export const ShipmentTemplates: Record<string, string> = {
    "USPS_SmallFlatRateBox": "Small Flat Rate Box",
    "USPS_MediumFlatRateBox1": "Medium Flat Rate Box",
    "USPS_LargeFlatRateBox": "Large Flat Rate Box",
    "USPS_FlatRatePaddedEnvelope": "Flat Rate Padded Envelope",
    "EBayAuthenticatorBulk": "eBay Authenticator Bulk"
};

export type ShipmentCard = {
    cardId: string;
    shipmentId: string;
    title: string;
    frontImage: string | null;
    backImage: string | null;
    location: string;
    status: ShipmentCardStatus;
    originalUploadDate: string;
    salePriceInCents: number;
    validSKU: boolean;
};

export type ShipmentWindow = {
    hasPreviousShipment: boolean;
    hasNextShipment: boolean;
    shipmentBatchName: string;
    shipmentCount: number;
    shipmentNumber: number;
    previousShipment: ShipperShipment | null;
    currentShipment: ShipperShipment;
    nextShipment: ShipperShipment | null;
};

export type ShipperShipment = {
    shipmentId: string;
    friendlyId: number;
    shipmentBatchName: string;
    recipientName: string;
    addressLine1: string;
    addressLine2: string | null;
    city: string;
    state: string | null;
    zipCode: string;
    country: string;
    carrier: ShipmentCarrier;
    trackingNumber: string | null;
    serviceLevel: string;
    dimensionString: string;
    shipped: boolean;
    requireSignature: boolean;
    shipmentCount: number;
    shipmentNumber: number;
    itemCount: number;
    itemsPulled: number;
    pullingIncomplete: boolean;
    totalOrderPriceInCents: number;
    errors: string[];
    shipmentBatchType: ShipmentBatchType;
    shipmentType: ShipmentType;
};

export type ShipperCard = {
    cardId: string;
    title: string;
    sku: string;
    salePriceInCents: number;
    frontImage: string | null;
    pulled: boolean;
    waiting: boolean;
};

export type ShipmentCreationProgress = {
    id: string;
    batchCount: number;
    validatedCount: number;
    purchasedCount: number;
    created: string;
    lastUpdated: string;
    started: boolean;
    complete: boolean;
    exception: string | null;
    failed: boolean;
    status: ShipmentCreationStatus;
}

export enum ShipmentCreationStatus {
    Unknown = 0,
    None = 1,
    Pending = 2,
    Creating = 3,
    Processing = 4,
    Successful = 5,
    Failed = 6
}

export type ShipmentSearchResult = {
    shipmentId: string | null;
    shipmentBatchId: string | null;
    message: string | null;
    found: boolean;
}

export type AuthenticatorBulkBatchToFulfill = {
    id: string;
    name: string;
}

export interface ShippingRate {
    id: string;
    amountInCents: number;
    carrier: ShipmentCarrier;
    serviceLevel: string;
    providerLogoUrl: string;
    isTemplate: boolean;
}