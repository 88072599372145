import { StoreOrder } from "models/store";
import { useAuthenticatedRequest } from "./useRequests";
import { useQuery } from "@tanstack/react-query";
import { useIdentity } from "context/auth";
import { PageRequest, PagedResult } from "models/common";
import { pageRequestToUrlSearchParams } from "util/helpers";

const useCustomerOrders = (pageRequest: PageRequest) => {
    const { id } = useIdentity();
    const options = pageRequestToUrlSearchParams(pageRequest);

    const get = useAuthenticatedRequest<PagedResult<StoreOrder>>({
        method: "get",
        url: `/orders?${options}`
    });

    return useQuery(["customer", id, "orders", pageRequest], get);
};

export default useCustomerOrders;