import { Text, VStack } from "@chakra-ui/react";
import { CssVariables } from "models/common";
import { ShipperShipment } from "models/shipping";
import { FC } from "react";
import { centsToDollars } from "util/helpers";

const Summary: FC<{ shipment: ShipperShipment }> = ({ shipment }) => {
    const { itemCount, itemsPulled, totalOrderPriceInCents } = shipment;

    const pullColor = itemsPulled === itemCount
        ? CssVariables.darkGreen
        : CssVariables.errorRed;

    return <VStack alignItems='start'>
        <table>
            <tbody>
                <tr>
                    <td><Text>Items Ordered:</Text></td>
                    <td><Text pl={2}>{itemCount}</Text></td>
                </tr>
                <tr>
                    <td><Text>Items Pulled:</Text></td>
                    <td><Text pl={2} as='span' color={pullColor}>{itemsPulled} OF {itemCount}</Text></td>
                </tr>
                <tr>
                    <td><Text>Total Order:</Text></td>
                    <td><Text pl={2}>{centsToDollars(totalOrderPriceInCents)}</Text></td>
                </tr>
            </tbody>
        </table>
    </VStack>;
}

export default Summary;