import { List } from "antd";
import { CardBatch } from "models/cards";
import { localizedDate } from "util/helpers";
import EditBatchModal from "./EditBatchModal";

const BatchTable = (props: { batches: CardBatch[], onSuccess: () => void }) => {
    return <List
        size="small"
        style={{ marginLeft: '2%' }}
        rowKey={b => b.id}
        itemLayout="horizontal"
        dataSource={props.batches}
        renderItem={item => (
            <List.Item>
                <List.Item.Meta
                    avatar={<EditBatchModal batch={item} onSuccess={props.onSuccess} />}
                    title={item.number}
                    description={localizedDate(item.createdDate)}
                />
            </List.Item>
        )} />
}

export default BatchTable;