import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
} from "@chakra-ui/react";
import { PayoutRequest } from "models/payoutRequests";
import { FC, useRef } from "react";

const InfoNeededConfirmationModal: FC<{
    request: PayoutRequest;
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
}> = ({ request, isOpen, onClose, onConfirm }) => {
    const cancelRef = useRef<HTMLButtonElement>(null);

    return (
        <>
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Request Info from {request.requestingUserFirstName} {request.requestingUserLastName}
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            This will put the request into "Info Needed" status and send a notification
                            via Zendesk to <b>{request.requestingUserEmail}</b> asking for additional details.
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Close
                            </Button>
                            <Button variant="solid" colorScheme="telegram" onClick={onConfirm} ml={3}>
                                Request More Information
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    );
};

export default InfoNeededConfirmationModal;
