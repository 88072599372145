import { useQuery } from "@tanstack/react-query";
import { UnsoldCardWindow } from "models/unsoldCards";
import { useAuthenticatedRequest } from "./useRequests";

const makeAxiosRequest = (unsoldCardBatchId: string, cardId?: string | null) => {
    let url = `/unsold-cards/puller/navigate/${unsoldCardBatchId}`;
    if (cardId) {
        url = `${url}/${cardId}`;
    }

    return {
        method: 'get',
        url
    };
};

const useUnsoldCardNavigation = (unsoldCardBatchId: string, cardId?: string | null) => {
    const navigateToUnsoldCard = useAuthenticatedRequest<UnsoldCardWindow>(
        makeAxiosRequest(unsoldCardBatchId, cardId)
    );

    return useQuery(["unsold-cards", unsoldCardBatchId, cardId], navigateToUnsoldCard);
};

export default useUnsoldCardNavigation;