import { Box, HStack, VStack } from "@chakra-ui/react";
import { Button, Radio, RadioChangeEvent, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { StaffRoutes } from "appRoutePaths";
import UnsoldBatchCreationModal from "components/staff/UnsoldCards/UnsoldBatchCreationModal";
import useUnsoldCardBatches from "hooks/useUnsoldCardBatches";
import { InitialPageRequest, PageRequest } from "models/common";
import { UnsoldCardBatch } from "models/unsoldCards";
import { useState } from "react";
import { localizedDate } from "util/helpers";

enum BatchFilter {
    Unknown = 0,
    All,
    ToBePulled,
    Completed
}

const getFilterBy = (filter: BatchFilter): Record<string, string[]> | undefined => {
    switch (filter) {
        case BatchFilter.Completed:
            return { ShowCompleted: [''] };
        case BatchFilter.ToBePulled:
            return { ToBePulled: [''] };
        default:
            return undefined;
    }
}

const columns: ColumnsType<UnsoldCardBatch> = [
    {
        title: "Name",
        dataIndex: "name",
        render: (name, rec) => <Button type="link" href={StaffRoutes.unsoldCards.url(rec.id)}>{name}</Button>
    },
    {
        title: "Generated",
        dataIndex: "generated",
        render: (g) => localizedDate(g)
    },
    {
        title: "Items",
        dataIndex: "items",
    },
    {
        title: "Pulled",
        dataIndex: "pulled",
        render: (_, rec) => `${((rec.pulled / rec.items) * 100).toFixed(2)}%`
    }
];

const UnsoldCardBatches = () => {
    const [pageRequest, setPageRequest] = useState<PageRequest>(InitialPageRequest);
    const { isLoading, data } = useUnsoldCardBatches(pageRequest);
    const { data: records, totalRecords, pageSize } = data || {};

    const tableChanged = (
        // pagination
        { current: newPage }: any,
        // filtering
        _: any,
        // sorting
        __: any
    ) => {
        setPageRequest({ ...pageRequest, page: newPage });
    };

    const applyFilter = (e: RadioChangeEvent) => {
        const filterBy = getFilterBy(e.target.value);
        setPageRequest({ ...pageRequest, page: 1, filterBy });
    }

    return (
        <Box p={50}>
            <VStack>
                <Box justifyContent='space-between' display='flex' style={{ width: '100%' }}>
                    <Box fontSize={36}>Unsold Card Batches</Box>
                    <VStack alignItems='end'>
                        <HStack>
                            <UnsoldBatchCreationModal />
                        </HStack>
                        <HStack>
                            <Radio.Group defaultValue={BatchFilter.All}
                                size='large'
                                buttonStyle='solid'
                                onChange={applyFilter}
                                style={{ alignSelf: 'end' }}>
                                <Radio.Button value={BatchFilter.All}>All</Radio.Button>
                                <Radio.Button value={BatchFilter.ToBePulled}>To Be Pulled</Radio.Button>
                                <Radio.Button value={BatchFilter.Completed}>Completed</Radio.Button>
                            </Radio.Group>
                        </HStack>
                    </VStack>
                </Box>
                <Box w='100%'>
                    <Table
                        id="shipment-batches-table"
                        rowKey={r => r.id}
                        columns={columns}
                        dataSource={records}
                        loading={isLoading}
                        onChange={tableChanged}
                        pagination={{
                            total: totalRecords,
                            current: pageRequest.page,
                            pageSize,
                            showSizeChanger: false,
                            showQuickJumper: true,
                            hideOnSinglePage: true
                        }}
                    />
                </Box>
            </VStack>
        </Box>
    )
}

export default UnsoldCardBatches;