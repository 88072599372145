import { Box } from "@chakra-ui/react";
import { InternationalWirePayoutMethodDetails } from "models/payoutRequests";
import { FC } from "react";

import { ConfirmationDetailsProps } from ".";
import SummaryDetail from "./SummaryDetail";

const WireConfirmation: FC<ConfirmationDetailsProps> = ({ payoutRequest }) => {
    const details = payoutRequest?.details as InternationalWirePayoutMethodDetails;

    return (
        <Box fontSize={18}>
            <Box fontSize={26} pb={3} fontWeight="bold">
                International Wire Transfer Confirmation
            </Box>
            <SummaryDetail label="Name on Account">
                {details?.nameOnAccount}
            </SummaryDetail>
        </Box>
    );
};

export default WireConfirmation;
