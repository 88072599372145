import { Text, VStack } from "@chakra-ui/react";
import { FC, ReactNode } from "react";

const CallOutItem: FC<{ label: string, value: string | ReactNode }> = ({ label, value }) => {
    return <VStack alignItems='start'>
        <Text fontSize={14}>{label}</Text>
        <Text style={{ marginTop: 0 }} as="div">{value}</Text>
    </VStack>;
};

export default CallOutItem;