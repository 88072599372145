import { Box, HStack, Stack } from "@chakra-ui/react";
import { useGetPayoutFees } from "hooks/usePayoutFees";
import { FC, Suspense } from "react";

import TextInput from "./Inputs/TextInput";

const PaypalFeesDisclosure: FC = () => {
    const { data: allFees } = useGetPayoutFees();
    const paypalPercentFee = allFees?.paypalDisplay ?? 0;

    return (
        <HStack spacing={1} color="gray">
            <Box fontWeight="bold">Note: </Box>
            <div>Paypal takes a</div>
            <Box as="span" fontWeight="bold">
                {paypalPercentFee}%
            </Box>
            <div>fee out of all payouts</div>
        </HStack>
    );
};

const PaypalPayoutDetails: FC = () => {
    return (
        <Stack direction={{ base: "column", md: "row" }}>
            <TextInput
                maxW="15rem"
                name="email"
                label="PayPal Email"
                autoComplete="email"
            />
            <Suspense>
                <PaypalFeesDisclosure />
            </Suspense>
        </Stack>
    );
};

export default PaypalPayoutDetails;
