import { useQuery } from "@tanstack/react-query";
import { PayoutTable } from "models/websiteSettings";
import { usePublicRequest } from "./useRequests";

const useCustomerPayoutTable = (tableData?: PayoutTable) => {
    const get = usePublicRequest<PayoutTable>({
        url: '/website-settings/customer-payout-info',
        method: "GET"
    });

    // if we have a table provided, no need to fetch one
    // from the backend.
    const func = tableData !== null && tableData !== undefined
        ? () => Promise.resolve(tableData)
        : get;

    return useQuery(['customer', 'payout-table', tableData], func);
};

export default useCustomerPayoutTable;