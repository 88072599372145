import { useMutation } from "@tanstack/react-query";

import { useAuthenticatedRequestCreator } from "./useRequests";
import { PayoutRequest } from "models/payoutRequests";
import { ShipmentCarrier } from "models/shipping";

interface PayoutApprovalRequest {
    trackingNumber?: string
    carrier?: ShipmentCarrier
}

const useApprovePayoutRequest = (id: string) => {
    const approvePayoutRequest = useAuthenticatedRequestCreator<PayoutRequest, PayoutApprovalRequest>(
        (req: PayoutApprovalRequest) => ({
            url: `/payout-requests/approve`,
            method: "POST",
            data: { payoutRequestId: id, ...req }
        })
    );

    const { mutateAsync, ...rest } = useMutation<PayoutRequest, unknown, PayoutApprovalRequest>(
        ["approvePayoutRequest", id],
        approvePayoutRequest
    );

    return {
        approvePayoutRequest: mutateAsync,
        ...rest,
    };
};

export default useApprovePayoutRequest;
