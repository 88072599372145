import { CalculatorOutlined } from "@ant-design/icons";
import { useQuery } from "@tanstack/react-query";
import { Button, DatePicker, Divider, Modal, Tooltip, Typography } from "antd";
import { useAuthenticatedRequest } from "hooks/useRequests";
import { CompletedCardTitlesByDateRange } from "models/cardIngestion";
import { DisplayDateFormat } from "models/websiteSettings";
import moment, { Moment } from "moment-timezone";
import { RangeValue } from "rc-picker/lib/interface";
import { useState } from "react";

const { Text } = Typography;

const { RangePicker } = DatePicker;

const useCompletedCardCount = (dateRange: RangeValue<Moment>) => {
    const [startDate, endDate] = dateRange || [];

    const getCompletedCount = useAuthenticatedRequest<CompletedCardTitlesByDateRange>({
        method: "get",
        url: `/card-titling/completed?startDate=${startDate?.toISOString()}&endDate=${endDate?.toISOString()}`
    });

    return useQuery(["card-titling", "completed", dateRange], getCompletedCount, {
        enabled: !!startDate && !!endDate
    });
}

const futureDates = (date: Moment) => date > moment().endOf('day');

const CardTyperCountModal = () => {
    const [visible, setVisible] = useState<boolean>(false);
    const [dateRange, setDateRange] = useState<RangeValue<Moment>>(null);
    const { isFetching, data } = useCompletedCardCount(dateRange);

    const close = () => {
        setVisible(false);
        setDateRange(null);
    }

    return <>
        <Tooltip placement="bottom" title="Completed Card Title Counts">
            <Button size='large' icon={<CalculatorOutlined />} onClick={() => setVisible(true)} />
        </Tooltip>
        <Modal title="Completed Card Title Count by Date Range"
            closable={false}
            keyboard
            maskClosable
            destroyOnClose
            open={visible}
            footer={<Button key='cancel' type="default" onClick={close}>Close</Button>}>
            <RangePicker
                style={{ flex: 1, width: '100%' }}
                format={DisplayDateFormat}
                value={dateRange}
                onChange={setDateRange}
                disabled={isFetching}
                disabledDate={futureDates}
            />
            <Divider />
            {data && <Text style={{ fontSize: '48px', textAlign: 'center' }}>
                {data.count} Card(s)
            </Text>}
        </Modal>
    </>;
};

export default CardTyperCountModal;