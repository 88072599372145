import { PlusOutlined, RollbackOutlined, StopOutlined, ZoomInOutlined } from "@ant-design/icons";
import { Box, Grid, GridItem, Text, VStack } from "@chakra-ui/react";
import { Alert, Image } from "antd";
import { CssVariables } from "models/common";
import { FC, PropsWithChildren, useState } from "react";

export interface CardPreviewImages {
    front: string;
    back: string;
    additional?: string[];
}

type OverlayItemProps = PropsWithChildren<{
    colSpan: number,
    onClick: () => void
}>

const OverlayItem: FC<OverlayItemProps> = ({
    children, colSpan, onClick
}) =>
    <GridItem
        borderRadius='15px'
        pt='5%'
        textAlign='center'
        colSpan={colSpan}
        sx={{
            background: 'rgba(70, 70, 70, .5)',
            transition: 'background .3s',
            ':hover': {
                background: 'rgba(0, 0, 0, .5)'
            }
        }}
        onClick={onClick}>
        {children}
    </GridItem>;

export const CardPreview: FC<CardPreviewImages> = ({ front, back, additional }) => {
    const [imgSrc, setImgSrc] = useState<string>(front);
    const [visible, setVisible] = useState<boolean>(false);
    const [extraVisible, setExtraVisible] = useState<boolean>(false);

    const oppositeCard = imgSrc === front ? back : front;
    const extraImages = additional ?? [];
    const hasExtraImages = extraImages.length > 0;

    return <VStack key={front + back}>
        <Box sx={{ position: 'relative' }}>
            <img src={imgSrc} alt="current card"></img>
            <Grid
                templateRows='repeat(2, 1fr)'
                templateColumns='repeat(4, 1fr)'
                gap={3}
                sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    color: CssVariables.white,
                    background: 'rgba(0, 0, 0, .5)',
                    cursor: 'pointer',
                    opacity: 0,
                    transition: 'opacity .3s',
                    ':hover': {
                        opacity: 1
                    }
                }}>
                <OverlayItem colSpan={4} onClick={() => setImgSrc(oppositeCard)}>
                    <Text fontSize={24}>Flip Card</Text>
                    <Text fontSize={48}>
                        <RollbackOutlined />
                    </Text>
                </OverlayItem>

                <OverlayItem colSpan={2} onClick={() => { if (hasExtraImages) setExtraVisible(true); }}>
                    <Text fontSize={24}>{extraImages.length === 0 ? 'No' : extraImages.length} Additional Images</Text>
                    <Text fontSize={48}>
                        {hasExtraImages && <PlusOutlined />}
                        {!hasExtraImages && <StopOutlined />}
                    </Text>
                    <Box display='none'>
                        <Image.PreviewGroup preview={{ visible: extraVisible, onVisibleChange: setExtraVisible }}>
                            {extraImages.map(img => <Image key={img} src={img} />)}
                        </Image.PreviewGroup>
                    </Box>
                </OverlayItem>

                <OverlayItem colSpan={2} onClick={() => setVisible(true)}>
                    <Text fontSize={24}>Zoom</Text>
                    <Text fontSize={48}>
                        <ZoomInOutlined />
                    </Text>
                    <Box display='none'>
                        <Image.PreviewGroup preview={{ visible, onVisibleChange: setVisible }}>
                            <Image src={imgSrc} />
                            <Image src={oppositeCard} />
                            {extraImages.map(img => <Image key={img} src={img} />)}
                        </Image.PreviewGroup>
                    </Box>
                </OverlayItem>
            </Grid>
        </Box>
        {hasExtraImages && <Alert
            style={{ width: '100%', marginTop: '5px' }}
            message="Heads Up!"
            description="This card has more than just a front and back image!"
            type="info"
            showIcon
        />}
    </VStack>;
}