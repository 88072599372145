import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Testimonial } from "models/testimonials";
import {
    useAuthenticatedRequest,
    useAuthenticatedRequestCreator
} from "./useRequests";

const TestimonialRoot = "/testimonials";

export const testimonialsCacheKey = "testimonials";
const queryKey = [testimonialsCacheKey];

const useGetAllTestimonials = () => {
    const getAllTestimonials = useAuthenticatedRequest<Testimonial[]>({
        method: "get",
        url: `${TestimonialRoot}/all`
    });

    return useQuery([testimonialsCacheKey], getAllTestimonials);
};

interface AddTestimonialParams {
    copy: string;
    author: string;
}

const useAddTestimonial = () => {
    const queryClient = useQueryClient();

    const addTestimonialCreator = useAuthenticatedRequestCreator<
        Testimonial,
        AddTestimonialParams
    >(({ copy, author }) => ({
        method: "post",
        url: TestimonialRoot,
        data: {
            copy,
            author,
            isLive: true
        }
    }));

    const { mutate: addTestimonial } = useMutation(
        (params: AddTestimonialParams) => addTestimonialCreator(params),
        {
            onSuccess: testimonial => {
                queryClient.invalidateQueries(queryKey);
            }
        }
    );

    return addTestimonial;
};

const useUpdateTestimonial = () => {
    const queryClient = useQueryClient();

    const updateTestimonialCreator = useAuthenticatedRequestCreator<
        Testimonial,
        Testimonial
    >(testimonial => ({
        method: "put",
        url: TestimonialRoot,
        data: testimonial
    }));

    const { mutate: updateTestimonial } = useMutation(
        (testimonial: Testimonial) => updateTestimonialCreator(testimonial),
        {
            onSuccess: testimonial => {
                queryClient.invalidateQueries(queryKey);
            }
        }
    );

    return updateTestimonial;
};

const useDeleteTestimonial = () => {
    const queryClient = useQueryClient();

    const deleteTestimonialCreator = useAuthenticatedRequestCreator<
        Testimonial,
        string
    >(id => ({
        method: "delete",
        url: TestimonialRoot,
        data: { id }
    }));

    const { mutate: deleteTestimonial } = useMutation(
        (id: string) => deleteTestimonialCreator(id),
        {
            onSuccess: testimonial => {
                queryClient.invalidateQueries(queryKey);
            }
        }
    );

    return deleteTestimonial;
};

const useStaffTestimonials = () => {
    const { isLoading, data: testimonials } = useGetAllTestimonials();
    const create = useAddTestimonial();
    const update = useUpdateTestimonial();
    const remove = useDeleteTestimonial();

    return { isLoading, testimonials, create, update, remove };
};

export default useStaffTestimonials;
