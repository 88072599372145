import { CheckOutlined } from "@ant-design/icons";
import { Box } from "@chakra-ui/react";
import { Button, Col, Row, Skeleton } from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import { StaffRoutes } from "appRoutePaths";
import ShippingAddress from "components/staff/Shipping/ShippingAddress";
import useShipments from "hooks/useShipments";
import { Shipment, ShipmentAddress, ShipmentCarrier, ShipmentCarrierNames } from "models/shipping";
import { useParams } from "react-router-dom";

const columns: ColumnsType<Shipment> = [
    {
        title: "#",
        dataIndex: "ordinal",
    },
    {
        title: "Recipient",
        dataIndex: ["destination", "recipientName"]
    },
    {
        title: "Address",
        dataIndex: "destination",
        render: (dest: ShipmentAddress) => <ShippingAddress address={dest} />
    },
    {
        title: "Carrier",
        dataIndex: ["options", "carrier"],
        render: (carrier: ShipmentCarrier) => ShipmentCarrierNames[carrier]
    },
    {
        title: "Signature?",
        dataIndex: ["options", "requireSignature"],
        render: (sig: boolean) => sig ? <CheckOutlined /> : null
    },
    {
        title: "Items",
        dataIndex: "items"
    },
    {
        title: "Pulled",
        dataIndex: "pulled",
        render: (_, rec) => `${((rec.pulled / rec.items) * 100).toFixed(2)}%`
    }
];

const Shipments = () => {
    const { shipmentBatchId } = useParams();
    const { isLoading, data } = useShipments(shipmentBatchId);

    return (
        <Box p={50}>
            <Row align="bottom">
                <Col span={24}>
                    <Box fontSize={36}>
                        {isLoading || data === undefined
                            ? <Skeleton />
                            : data[0].shipmentBatchName}
                    </Box>
                    <Button type="link" href={StaffRoutes.shipmentBatches.url}>
                        &#8592;Back to Shipment Batches
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Table
                        id="shipments-table"
                        rowKey={r => r.id}
                        columns={columns}
                        dataSource={data}
                        loading={isLoading}
                        pagination={{
                            pageSize: 100,
                            hideOnSinglePage: true
                        }}
                    />
                </Col>
            </Row>
        </Box>
    )
};

export default Shipments;