import { Button, Checkbox, Form } from "antd";
import useWebsiteSettings from "hooks/useWebsiteSettings";
import { useEffect, useState } from "react";
import SettingsHeader from "./SettingsHeader";
import TextArea from "antd/lib/input/TextArea";
import { CheckOutlined } from "@ant-design/icons";

const AnnouncementSection = () => {
    const { isLoading, settings, updateAnnouncement } = useWebsiteSettings();
    const { announcement, announcementEnabled } = settings || {};
    const [form] = Form.useForm();
    const [result, setResult] = useState<boolean>(false);

    // TODO: Refactor this and all of the other update functions to not have the weirdness
    const update = async (props: { copy: string; enabled: boolean }) => {
        setResult(
            await updateAnnouncement({
                announcement: props.copy,
                enabled: props.enabled,
            })
        );
    };

    useEffect(() => {
        if (!isLoading) {
            form.setFieldsValue({
                copy: announcement,
                enabled: announcementEnabled,
            });
        }
    }, [isLoading, announcement, announcementEnabled, form]);

    return (
        <>
            <SettingsHeader title="Would you like to send an announcement to all members?" />
            <Form
                form={form}
                className="website-update"
                layout="vertical"
                onFinish={update}
            >
                <Form.Item label="Message to users:" name="copy">
                    <TextArea
                        rows={4}
                        placeholder="Set your announcement copy here"
                    />
                </Form.Item>
                <Form.Item name="enabled" valuePropName="checked">
                    <Checkbox>
                        Select to share notification with users. Uncheck to
                        remove the notification.
                    </Checkbox>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Update Announcement
                    </Button>
                    {result && <CheckOutlined className="result-success" />}
                </Form.Item>
            </Form>
        </>
    );
};

export default AnnouncementSection;