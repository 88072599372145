import { useQuery } from "@tanstack/react-query";
import { useAuthenticatedRequest } from "./useRequests";
import { UnreadTicketsKeyPrefix } from "./useSupportTickets";

const useCustomerUnreadTickets = () => {
    const getIds = useAuthenticatedRequest<{ ticketIds: number[] }>({
        method: 'get',
        url: '/support/tickets/unread'
    });

    return useQuery(UnreadTicketsKeyPrefix, getIds);
}

export default useCustomerUnreadTickets;