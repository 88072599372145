import { Box, VStack } from "@chakra-ui/react";
import { Divider } from "antd";
import CreatedPackageDetail from "components/staff/Packages/CreatedPackageDetail";
import NewPackageForm, {
    NewPackageFormData,
} from "components/staff/Packages/NewPackageForm";
import useCreatePackage from "hooks/useCreatePackage";
import { StaffPackage } from "models/packages";
import { useState } from "react";

const NewPackage = () => {
    const [createdPackage, setCreatedPackage] = useState<
        StaffPackage | undefined
    >();
    const { createPackage, working } = useCreatePackage();

    const submitForm = async (formData: NewPackageFormData) => {
        const data = await createPackage(formData);
        setCreatedPackage(data);
    };

    const resetPage = () => {
        setCreatedPackage(undefined);
    };

    return (
        <VStack p={50} align="start" fontSize="1.6rem">
            <Box fontSize={36}>Add New Package</Box>
            <Divider />
            <Box fontSize={26}>Package Information</Box>
            {createdPackage ? (
                <CreatedPackageDetail
                    package={createdPackage}
                    onAddNext={resetPage}
                />
            ) : (
                <NewPackageForm disabled={working} onSubmit={submitForm} />
            )}
        </VStack>
    );
};

export default NewPackage;
