import { Box, Button, Flex, Image, VStack } from "@chakra-ui/react";
import { CustomerRoutes } from "appRoutePaths";
import { FC } from "react";

const backgroundImage = require("images/faq.jpg");
const logo = require("images/logo.png");

const FAQHero: FC = () => (
    <Flex pos="relative" w="100%" mt={0}>
        <Image
            pos="absolute"
            src={backgroundImage}
            alt="DC Sports 87"
            objectFit="cover"
            objectPosition="top"
            w="100%"
            h="100%"
        />
        <VStack
            color="white"
            zIndex={1}
            p={{ base: 8, md: 24, lg: 32 }}
            maxWidth={{ base: "60rem", md: "120rem" }}
        >
            <Box
                fontFamily="body"
                fontSize={{ base: "3rem", md: "4rem" }}
                textTransform="uppercase"
                lineHeight="1.1em"
            >
                <Box>
                    <Image w="416px" src={logo} pl="-5px" />
                    <Box
                        fontSize={{ base: "3rem", md: "30px" }}
                        letterSpacing="8.1px"
                    >
                        Consignment
                    </Box>
                </Box>
                <Box>NEW CUSTOMER INFORMATION & FAQ’S</Box>
            </Box>

            <Button
                alignSelf="start"
                as="a"
                href={CustomerRoutes.submissionForm.url}
                target="_blank"
                rel="noreferrer"
                variant="primary"
                width="fit-content"
                style={{ transformOrigin: 'top left', scale: '150%', backgroundColor: 'var(--dcs-dark-blue)' }}
            >
                Print Submission Form
            </Button>
        </VStack>
    </Flex>
);

export default FAQHero;
