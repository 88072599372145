import { StarIcon } from "@chakra-ui/icons";
import { HStack, Stack, Text, VStack } from "@chakra-ui/react";
import { CardsOurPriority } from "components/HowProcessWorks/CardsOurPriority";
import { HowDoesThisWork } from "components/HowProcessWorks/HowDoesThisWork";
import { HowMuchWillMake } from "components/HowProcessWorks/HowMuchWillMake";
import { HowProcessWorksHero } from "components/HowProcessWorks/HowProcessWorksHero";
import { MoreQuestions } from "components/HowProcessWorks/MoreQuestions";
import { NumbersToProveIt } from "components/HowProcessWorks/NumbersToProveIt";
import { PayoutsPaidWithin } from "components/HowProcessWorks/PayoutsPaidWithin";
import useLiveTestimonials from "hooks/useLiveTestimonials";
import { Testimonial } from "models/testimonials";
import { Carousel } from "react-responsive-carousel";

const Star = () => <StarIcon color="blue" />;
const Stars = () => (
    <HStack
        spacing={3}
        fontSize={36}
        alignSelf={{
            base: "center",
            md: "baseline",
        }}
    >
        <Star />
        <Star />
        <Star />
        <Star />
        <Star />
    </HStack>
);

const TestimonialCard = (props: { testimonial: Testimonial }) => {
    const { copy } = props.testimonial;

    const fontSize = copy.length < 100 ? "xl" : "lg";

    return (
        <VStack
            bg="lightGreen"
            rounded="md"
            minHeight="10rem"
            align="baseline"
            p={{
                base: "3rem",
                md: "5rem",
            }}
            mx={2}
            fontSize={fontSize}
        >
            <Stars />
            <Text>"{props.testimonial.copy}"</Text>
            <Text>-{props.testimonial.author}</Text>
        </VStack>
    );
};

const Testimonials = () => {
    const { isLoading, testimonials } = useLiveTestimonials();

    if (!isLoading && testimonials.length === 0) {
        return <></>;
    }
    return (
        <VStack p={6}>
            <Text as="h1" fontSize="40" width="85vw">
                Here's what everyones talking about!
            </Text>
            <Carousel
                width="85vw"
                infiniteLoop
                showStatus={false}
                showArrows={false}
                showThumbs={false}
                autoPlay
                interval={10000}
            >
                {testimonials.map((testimonial) => (
                    <TestimonialCard
                        key={testimonial.id}
                        testimonial={testimonial}
                    />
                ))}
            </Carousel>
        </VStack>
    );
};

export const HowProcessWorks = () => {
    return (
        <VStack spacing={0} width="100%" bgColor="var(--dcs-black)">
            <HowProcessWorksHero />
            <HowDoesThisWork />
            <Stack bgColor="var(--dcs-bright-blue)" minW="100vw">
                <NumbersToProveIt />
            </Stack>
            <CardsOurPriority />
            <HowMuchWillMake />
            <PayoutsPaidWithin />
            <Testimonials />
            <MoreQuestions />
        </VStack>
    );
};
