import { useMutation } from "@tanstack/react-query";
import { useAuthenticatedRequestCreator } from "./useRequests";

type ProcessRequest = {
    from: string,
    to: string,
    type: number;
};

const useEbayJobFunctions = () => {
    const enqueue = useAuthenticatedRequestCreator<boolean, ProcessRequest>(
        (data) => ({
            url: '/website-settings/job-request',
            method: 'POST',
            data
        })
    );

    return useMutation(enqueue);
};

export default useEbayJobFunctions;