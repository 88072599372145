import { Box, Flex, Text } from "@chakra-ui/react";
import { FC, PropsWithChildren } from "react";

const ColoredDividerHalf = () => (
    <Box
        mt="1px"
        borderTop="2px solid"
        borderColor="#C7F4C5"
        width="100%"
        height="2px"
        flexGrow="1"
        flexShrink="1"
        flexBasis="0"
    />
);

export const OrDivider: FC<PropsWithChildren> = ({ children }) => (
    <Flex width="100%" align="center" fontSize="md">
        <ColoredDividerHalf />
        <Text px="6" textAlign="center" whiteSpace="nowrap">
            {children ?? "Or"}
        </Text>
        <ColoredDividerHalf />
    </Flex>
);
