import { Button } from "@chakra-ui/button";
import { Box, Flex, Text, VStack } from "@chakra-ui/react";
import { PublicRoutes } from "appRoutePaths";
import BrandLogoText from "components/BrandLogoText";
import { useNavigate } from "react-router-dom";

const howItWorksBackground = require("images/how-it-works.jpg");
export const HowProcessWorksHero = () => {
    const navigate = useNavigate();

    return (
        <Flex
            bgImage={howItWorksBackground}
            p={{
                base: 10,
                md: 20,
            }}
            width="100vw"
            maxWidth="100vw"
            overflow="cover"
        >
            <VStack align="baseline" spacing={5} maxW="1280px">
                <Box
                    as="h1"
                    fontSize="40"
                    textTransform="uppercase"
                    letterSpacing="1px"
                    sx={{
                        // SX is used to override a css variable not directly exposed by Chakra's props
                        wordSpacing: "3px",
                    }}
                    maxWidth={{
                        base: "30rem",
                        md: "70rem",
                    }}
                >
                    <Text color="lightGray">
                        <BrandLogoText /> handles every aspect of selling.
                    </Text>
                    <Text color="var(--dcs-light-blue)">
                        Just ship your cards to us and get paid.
                    </Text>
                </Box>
                <VStack
                    align="start"
                    color="white"
                    fontSize="16"
                    textAlign="justify"
                    maxWidth={{
                        base: "30rem",
                        md: "70rem",
                    }}
                >
                    <Text fontSize={24} fontWeight="semibold" color="white">
                        Consignment saves you time, stress, hassle, and
                        headaches
                    </Text>
                    <Text>
                        Create an account, then ship your cards to us. It's that
                        simple! Relax and track your sales online while we
                        handle everything! Once you are ready to cash out,
                        select one of the many payout options and get paid fast!
                    </Text>
                </VStack>

                <Button
                    variant="primary"
                    onClick={() => navigate(PublicRoutes.signUp.url)}
                >
                    Start Selling
                </Button>
            </VStack>
        </Flex>
    );
};
