import { VStack } from "@chakra-ui/react";
import SettingsHeader from "./SettingsHeader";
import { Button, DatePicker, Input, List } from "antd";
import moment from "moment-timezone";
import useWebsiteSettings from "hooks/useWebsiteSettings";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { useState } from "react";
import { DisplayDateFormat } from "models/websiteSettings";
import Paragraph from "antd/lib/typography/Paragraph";

const OutOfOfficeSection = () => {
    const [newDate, setNewDate] = useState<moment.Moment | null>(null);
    const {
        isLoading,
        settings,
        outOfOffice: { add, remove }
    } = useWebsiteSettings();
    const { outOfOfficeDates } = settings || {};

    const addDate = () => {
        add(newDate!.toISOString());
        setNewDate(null);
    }

    const removeDate = (date: moment.Moment) => {
        remove(date.toISOString(true));
    }

    return <>
        <SettingsHeader title="Out of Office Dates" />
        <Paragraph>
            These dates will be taken into account when determining the estimated upload date<br />
            for a package, along with the configured wait times. Old dates will automatically fall off the list.
        </Paragraph>
        <VStack display='flex' alignItems='start'>
            <Input.Group compact>
                <DatePicker
                    format={DisplayDateFormat}
                    disabledDate={(date: moment.Moment) => date < moment().endOf('day')}
                    showToday={false}
                    onChange={setNewDate}
                    value={newDate}
                    disabled={isLoading}
                />
                <Button icon={<PlusOutlined />} type="primary" disabled={newDate === null || isLoading} onClick={addDate} />
            </Input.Group>
            <List
                style={{ width: '174px' }}
                size="small"
                bordered
                dataSource={outOfOfficeDates}
                pagination={false}
                renderItem={item => <List.Item
                    actions={[<Button icon={<DeleteOutlined />}
                        disabled={isLoading}
                        size="small"
                        danger
                        type="link"
                        onClick={() => removeDate(moment(item))} />]}
                >{moment(item).format(DisplayDateFormat)}</List.Item>}
            />
        </VStack>
    </>;
}

export default OutOfOfficeSection;