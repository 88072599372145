import { forwardRef, Ref } from "react";
import useBatchSearch from "hooks/useBatchSearch";
import { CardBatch } from "models/cards";
import { localizedDate } from "util/helpers";
import EntitySelector, {
    EntitySelectorProps,
    EntitySelectorRef
} from "./EntitySelector";
import MultiValueDisplay from "./MultiValueDisplay";

const resultDisplay = (batch: CardBatch) => {
    return (
        <MultiValueDisplay
            title={batch.number}
            subTitle={localizedDate(batch.createdDate)}
        />
    );
};

const BatchSelector = (
    props: EntitySelectorProps<CardBatch>,
    ref: Ref<EntitySelectorRef>
) => {
    const { search } = useBatchSearch();

    return (
        <EntitySelector
            ref={ref}
            {...props}
            placeholder="Batch Number (i.e. A123)"
            search={search}
            resultDisplay={resultDisplay}
        />
    );
};

export default forwardRef<EntitySelectorRef, EntitySelectorProps<CardBatch>>(
    BatchSelector
);
