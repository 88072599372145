import { useQuery, useQueryClient, UseQueryResult } from "@tanstack/react-query";
import { StaffPackage } from "models/packages";
import { PagedResult, PageRequest } from "models/common";
import { pageRequestToUrlSearchParams } from "util/helpers";
import { useAuthenticatedRequest } from './useRequests';

const makeAxiosRequest = (pageRequest: PageRequest) => {
    const options = pageRequestToUrlSearchParams(pageRequest);

    return {
        method: "get",
        url: `/packages/all?${options}`,
    };
};

export const useStaffPackagesCSV = (pageRequest: Omit<PageRequest, "page">) => {
    const pageRequestWithCSV = {
        ...pageRequest,
        page: 1, // ignored
        format: "csv",
    } as const;
    const axiosRequest = makeAxiosRequest(pageRequestWithCSV);

    return useAuthenticatedRequest<string>(axiosRequest);
};

const useStaffPackages = (
    pageRequest: PageRequest
): [UseQueryResult<PagedResult<StaffPackage>, unknown>, () => void] => {
    const key = ["staff-packages", pageRequest];
    const queryClient = useQueryClient();

    const getPackages = useAuthenticatedRequest<PagedResult<StaffPackage>>(
        makeAxiosRequest(pageRequest)
    );

    const forceRefresh = async () => {
        queryClient.invalidateQueries(key);
        await queryClient.refetchQueries();
    };

    return [useQuery(key, getPackages), forceRefresh];
};

export default useStaffPackages;
