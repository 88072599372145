import { useMutation } from "@tanstack/react-query";
import { ApplicationUser } from "models/applicationUsers";
import { useAuthenticatedRequestCreator } from "./useRequests";

export interface BatchUpdate {
    batchId: string;
    newCustomer: ApplicationUser;
    newPackageId: string;
}

const useBatchUpdate = () => {
    const update = useAuthenticatedRequestCreator<boolean, BatchUpdate>(model => ({
        method: "post",
        url: "/packages/update-batch",
        data: {
            batchId: model.batchId,
            newCustomerId: model.newCustomer.id,
            newPackageId: model.newPackageId
        }
    }));

    const { mutate: updateBatch } = useMutation(
        (model: BatchUpdate) => update(model)
    );

    return { updateBatch };
};

export default useBatchUpdate;