import { Box, BoxProps, Button, Flex, forwardRef } from "@chakra-ui/react";
import { Form, FormProps, notification } from "antd";
import { SignUpFormItem } from "components/SignUp/SignUpFormItem";
import { useIdentity } from "context/auth";
import useZendeskTicket from "hooks/useSendZendeskTicket";
import LoadingSpinnerOverlay from "./LoadingSpinnerOverlay";

type ContactUsFormData = {
    firstName?: string;
    lastName?: string;
    email?: string;
    subject: string;
    body: string;
};

const AnonymousContactUsFormSection = () => (
    <>
        <Flex width="100%">
            <SignUpFormItem title="First Name" labelColor="var(--dcs-white)" />
            <SignUpFormItem title="Last Name" labelColor="var(--dcs-white)" />
        </Flex>
        <SignUpFormItem title="Email" labelColor="var(--dcs-white)" />
    </>
);

const ContactUsForm = forwardRef<BoxProps & FormProps, typeof Box>(
    (props, ref) => {
        const { isLoggedIn } = useIdentity();

        const { mutateAsync: sendTicket, isLoading } = useZendeskTicket();
        const [form] = Form.useForm();

        const onFinish = async (values: ContactUsFormData) => {
            try {
                await sendTicket({
                    type: "question",
                    ...values,
                });

                form.resetFields();
                notification.success({
                    message: "Your message has been sent!",
                });
            } catch (err) {
                console.error(err);
            }
        };

        return (
            <Box
                as={Form}
                layout="vertical"
                onFinish={onFinish as any}
                disabled={isLoading}
                form={form}
                pos="relative"
                ref={ref}
                {...props}
            >
                {!isLoggedIn && <AnonymousContactUsFormSection />}
                <SignUpFormItem title="Subject" labelColor="var(--dcs-white)" />
                <SignUpFormItem
                    textArea={true}
                    title="How can we help?"
                    name="body"
                    labelColor="var(--dcs-white)"
                />
                <Box pt={3}>
                    <Button variant="primary" type="submit" px={2} py={1}>
                        Send Message
                    </Button>
                </Box>
                <LoadingSpinnerOverlay isLoading={isLoading} />
            </Box>
        );
    }
);

export default ContactUsForm;
