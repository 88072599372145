import { Box, HStack, Text, VStack } from "@chakra-ui/react";
import { Anchor, Button, Skeleton } from "antd";
import { CustomerRoutes } from "appRoutePaths";
import PaymentOptions from "components/Shop/Cart/PaymentOptions";
import ShippingAddress from "components/Shop/ShippingAddress";
import 'components/Shop/hide-anchor.css';
import OrderPreview from "components/customer/Orders/OrderPreview";
import { useIdentity } from "context/auth";
import useAccountSummary from "hooks/useAccountSummary";
import useOrderPreview from "hooks/useOrderPreview";
import useShoppingCart from "hooks/useShoppingCart";
import { UserShippingAddress } from "models/store";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Checkout = () => {
    const { accounts } = useIdentity();
    const [shippingAddress, setShippingAddress] = useState<UserShippingAddress | undefined>();
    const { stockCheck } = useShoppingCart();
    const navigate = useNavigate();

    const ensureValidCart = useCallback(async () => {
        const { data } = await stockCheck();
        // if the cart is empty, redirect them back to the shop page.
        if (data?.length === 0) {
            navigate(CustomerRoutes.shop.url, { replace: true });
        }

        // if any item fails a stock check, redirect them back to the shop page.
        if (!data?.every(x => x.ok)) {
            navigate(CustomerRoutes.shop.url, { replace: true });
        }
    }, [navigate, stockCheck]);

    useEffect(() => {
        ensureValidCart();
    }, [ensureValidCart]);

    const {
        isLoading: isFetchingBalance,
        data: accountSummary
    } = useAccountSummary(accounts[0]);
    const {
        isLoading: isCalculatingOrder,
        data: orderPreview
    } = useOrderPreview();

    if (isFetchingBalance || isCalculatingOrder) {
        return <Skeleton />;
    }

    return <>
        <VStack alignItems='start'>
            <Text fontSize={36}>Store Checkout</Text>
            <Button style={{ padding: 0 }} type="link" href={CustomerRoutes.shop.url}>&#8592;Back to the shop</Button>
        </VStack>
        <HStack display='flex' align='start' spacing={2}>
            <Box display='flex' w='50%' backgroundColor='whitesmoke' borderRadius={20}>
                <VStack align='start'>
                    <ShippingAddress
                        onAddressChanged={setShippingAddress}
                    />
                    <PaymentOptions
                        accountBalanceInCents={accountSummary!.availableBalanceInCents}
                        orderTotalInCents={orderPreview!.totalInCents}
                        shippingAddress={shippingAddress}
                        order={orderPreview!}
                    />
                </VStack>
            </Box>
            <Box w='40%'>
                <Anchor>
                    <OrderPreview {...orderPreview!} />
                </Anchor>
            </Box>
        </HStack >
    </>;
}

export default Checkout;