export const DisplayDateFormat = 'MM/DD/YYYY';
export const ServerDateFormat = 'YYYY/MM/DD';
// the times for promotion start/end dates
// are always treated as 12:00 AM ET
export const DefaultTimeZone = 'America/New_York';

/**
 * All admin-configurable website settings
 */
export type WebsiteSettings = {
    announcement: string;
    announcementEnabled: boolean;
    promoStartDate: string;
    promoEndDate: string;
    promoImageKey: string;
    featuredVideoId: string;
    featuredVideoTitle: string;
    outOfOfficeDates: string[];
};

export type PayoutTable = {
    rows: PayoutTableRow[];
    footer: string;
};

export type PayoutTableRow = {
    id: string;
    ordinal: number;
    salePrice: string;
    description: string;
}