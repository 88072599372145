export const styles = {
    global: {
        html: {
            padding: 0,
            margin: 0,
        },
        "h1, h2, h3, h4, h5, h6": {
            // This is how we take the string-match "heading" fontFamily variable from the theme `fonts`
            //  and make it the default font for all headings
            fontFamily: "heading",
        },
        body: {
            padding: 0,
            margin: 0,
            // This is how we take the string-match "body" fontFamily variable from the theme `fonts`
            //  and make it the default font for all text elements in the body
            fontFamily: "body",
        },
        "*": {
            boxSizing: "border-box",
        },
    },
};

export default styles;
