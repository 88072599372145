import { CheckCircleIcon } from "@chakra-ui/icons";
import { Box, Flex, HStack } from "@chakra-ui/react";
import { CustomerPackage } from "models/packages";
import { FC, PropsWithChildren } from "react";

const DataLabel: FC<{ label: string } & PropsWithChildren> = ({
    label,
    children,
}) => (
    <Box minW="20%">
        <Box as="span" fontWeight="semibold">
            {label}:{" "}
        </Box>
        {children}
    </Box>
);

const PackagesLineItem: FC<{ package: CustomerPackage }> = ({
    package: pkg,
}) => {
    const {
        receivedDate,
        friendlyId,
        uploadDate,
        packageTypeDisplayName,
        ebayLink,
    } = pkg;
    const formattedRecievedDate = receivedDate
        ? new Date(receivedDate).toLocaleDateString()
        : "--";

    return (
        <Flex
            justifyContent="space-between"
            bg="white"
            color="black"
            rounded={7}
            w="100%"
            px={3}
            py={2}
            alignItems="center"
            my={1}
            fontSize={20}
            borderBottom="2px solid"
            borderColor="var(--dcs-light-blue)"
        >
            <DataLabel label="Received">{formattedRecievedDate}</DataLabel>
            <DataLabel label="Package ID">#{friendlyId}</DataLabel>
            <Box minW="10%">{packageTypeDisplayName}</Box>
            <Flex justifyContent="flex-end" alignItems="center" minW="13%">
                {ebayLink && (
                    <Box
                        as="a"
                        href={ebayLink}
                        target="_blank"
                        rel="noreferrer"
                        color="blue"
                    >
                        eBay Listing
                    </Box>
                )}
            </Flex>
            <Flex justifyContent="flex-end" minW="20%">
                {uploadDate ? (
                    <HStack
                        // verticalAlign="middle"
                        color="darkGreen"
                        fontWeight="semibold"
                        spacing={2}
                    >
                        <CheckCircleIcon />
                        <Box>Uploaded</Box>
                    </HStack>
                ) : (
                    <Box>Received</Box>
                )}
            </Flex>
        </Flex>
    );
};

export default PackagesLineItem;
