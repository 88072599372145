import { useAuthenticatedRequestCreator } from "./useRequests";

const useShipmentCompletion = () => {
    const completeShipmentRequest = useAuthenticatedRequestCreator<boolean, string>(
        (shipmentId) => ({
            method: "post",
            url: "/shipping/shipper/complete",
            data: { shipmentId }
        })
    );

    const completeShipment = async (shipmentId: string): Promise<boolean> => {
        await completeShipmentRequest(shipmentId);
        return true;
    }

    return { completeShipment };
}

export default useShipmentCompletion;