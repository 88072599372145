const handlers: any = {};

export const registerMessageHandler = <TMessage>(
    messageType: string,
    handler: (message: TMessage) => void
): void => {
    if (!handlers[messageType]) {
        handlers[messageType] = [];
    }

    for (let i = 0; i < handlers[messageType].length; ++i) {
        if (handler === handlers[messageType][i]) {
            return;
        }
    }

    handlers[messageType].push(handler);
};

export const publishMessage = <TMessage>(
    messageType: string,
    message: TMessage
): void => {
    const messageHandlers = handlers[messageType];
    if (messageHandlers) {
        messageHandlers.forEach((handler: (message: TMessage) => void) => {
            handler(message);
        });
    }
};
