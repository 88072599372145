export type CatalogItem = {
    id: string;
    name: string;
    description: string;
    attributes: Record<string, string>;
    minPriceInCents: number;
    maxPriceInCents: number;
    hasPriceRange: boolean;
    images?: string[];
    category: string;
    variations: null | CatalogItemVariation[];
};

export type CatalogItemVariation = {
    id: string;
    name: string;
    attributes: Record<string, string>;
    quantity: number;
    state: string;
    priceInCents: number;
}

export type CartItem = {
    catalogId: string;
    id: string;
    itemName: string;
    priceInCents: number;
    quantity: number;
    totalInCents: number;
    variationName: string;
}

export type OrderLineItem = {
    id: string;
    externalId: string;
    catalogId: string;
    itemName: string;
    variationName: string;
    quantity: number;
    priceInCents: number;
    totalInCents: number;
};

export type OrderAddress = {
    id: string;
    recipientName: string;
    addressLine1: string;
    addressLine2: string | null;
    city: string;
    state: string | null;
    zipCode: string;
    country: string;
}

export enum PaymentSource {
    Unknown = 0,
    Balance = 1,
    Visa = 2,
    MasterCard = 3,
    Discover = 4,
    AmericanExpress = 5,
    DigitalWallet = 6,
    OtherCard = 7,
    UnionPay = 8,
    DiscoverDiners = 9,
    JCB = 10
}

export const PaymentSourceLabels = {
    [PaymentSource.Unknown]: "N/A",
    [PaymentSource.Balance]: "Acct. Balance",
    [PaymentSource.Visa]: "Visa",
    [PaymentSource.MasterCard]: "MasterCard",
    [PaymentSource.Discover]: "Discover",
    [PaymentSource.AmericanExpress]: "American Express",
    [PaymentSource.DigitalWallet]: "Digital Wallet",
    [PaymentSource.OtherCard]: "Other Card",
    [PaymentSource.UnionPay]: "Union Pay",
    [PaymentSource.DiscoverDiners]: "Discover Diners",
    [PaymentSource.JCB]: "JCB"
} as const;

export type OrderPaymentMethod = {
    source: PaymentSource;
    amountInCents: number;
};

export enum OrderFulfillmentStatus {
    Unknown = 0,
    InProgress = 1,
    Shipped = 2
}

export const OrderFulfillmentStatusLabels = {
    [OrderFulfillmentStatus.Unknown]: "N/A",
    [OrderFulfillmentStatus.InProgress]: "In Progress",
    [OrderFulfillmentStatus.Shipped]: "Shipped"
} as const;

export type StoreOrder = {
    id: string;
    friendlyId: number;
    createdAt: string;
    lineItems: OrderLineItem[];
    paymentMethods: OrderPaymentMethod[];
    shipments: StoreOrderShipment[];
    subTotalInCents: number;
    shippingInCents: number;
    taxesInCents: number;
    totalInCents: number;
    addresses: OrderAddress[];
    status: OrderFulfillmentStatus;
};

export type UserShippingAddress = {
    firstName: string;
    lastName: string;
    addressLine1: string;
    addressLine2: string | null;
    city: string;
    state: string | null;
    zipCode: string;
    country: string;
}

export type CartStockCheck = {
    itemName: string;
    variationName: string;
    catalogId: string;
    requestedQuantity: number;
    actualQuantity: number;
    ok: boolean;
}

export type StoreOrderShipment = {
    id: string;
    orderId: string;
    dimensions: string;
    weightInOz: number;
    trackingNumber: string | null;
    trackingUrl: string | null;
    labelUrl: string | null;
}