import { useQuery } from "@tanstack/react-query";
import { useAuthenticatedRequest } from "hooks/useRequests";
import { CompletedCardTitle } from "models/cardIngestion";
import { PageRequest, PagedResult } from "models/common";
import { pageRequestToUrlSearchParams } from "util/helpers";

const useCompletedCardTitles = (cardFolderId: string, pageRequest: PageRequest) => {
    const options = pageRequestToUrlSearchParams(pageRequest);

    const get = useAuthenticatedRequest<PagedResult<CompletedCardTitle>>({
        method: "get",
        url: `/card-titling/folders/${cardFolderId}/completed?${options}`
    });

    return useQuery(["card-titling", "folders", cardFolderId, "completed", pageRequest], get, {
        suspense: true
    });
}

export default useCompletedCardTitles;