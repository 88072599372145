import { Button, Form, Modal, Select } from "antd";
import CustomerSelector from "components/CustomerSelector";
import MultiValueDisplay from "components/MultiValueDisplay";
import useBatchUpdate from "hooks/useBatchUpdate";
import usePackagesForCustomer from "hooks/usePackagesForCustomer";
import { ApplicationUser } from "models/applicationUsers";
import { CardBatch } from "models/cards";
import { StaffPackage } from "models/packages";
import { useState } from "react";
import { localizedDate } from "util/helpers";

interface EditBatchForm {
    customer: ApplicationUser | undefined;
    packageId: string | undefined;
}

const { Option } = Select;

const EditBatchModal = (props: { batch: CardBatch; onSuccess: () => void }) => {
    const [visible, setVisible] = useState<boolean>(false);
    const [working, setWorking] = useState<boolean>(false);
    const [form] = Form.useForm<EditBatchForm>();
    const { getPackagesForCustomer } = usePackagesForCustomer();
    const [packages, setPackages] = useState<StaffPackage[] | undefined>();
    const { updateBatch } = useBatchUpdate();
    const customerSelected = packages !== undefined;

    const onCustomerSelected = async (
        customer: ApplicationUser | undefined
    ) => {
        form.setFieldsValue({ customer });
        if (customer) {
            setWorking(true);
            const pkgs = await getPackagesForCustomer(customer.id);
            setPackages(pkgs);
            setWorking(false);
        }
    };

    const onCustomerCleared = () => {
        form.setFieldsValue({ customer: undefined });
        setPackages(undefined);
    };

    const closeAndResetModal = () => {
        setVisible(false);
        setPackages(undefined);
        form.resetFields();
    };

    const submitForm = () => {
        form.validateFields().then(async (data) => {
            setWorking(true);

            try {
                await updateBatch({
                    batchId: props.batch.id,
                    newPackageId: data.packageId!,
                    newCustomer: data.customer!,
                });
                await props.onSuccess();
                closeAndResetModal();
            } finally {
                setWorking(false);
            }
        });
    };

    return (
        <>
            <Button type="link" onClick={() => setVisible(true)}>
                Edit
            </Button>
            <Modal
                title={`Edit Batch ${props.batch.number} - ${localizedDate(
                    props.batch.createdDate
                )}`}
                closable={false}
                keyboard={false}
                maskClosable={false}
                destroyOnClose={true}
                open={visible}
                confirmLoading={working}
                cancelButtonProps={{ disabled: working }}
                okText={working ? "Saving..." : "Update"}
                onOk={submitForm}
                onCancel={closeAndResetModal}
            >
                <Form form={form} disabled={working} layout="vertical">
                    <Form.Item
                        label="Change Customer To"
                        name="customer"
                        rules={[
                            {
                                required: true,
                                message: "You must select a customer!",
                            },
                        ]}
                    >
                        <CustomerSelector
                            onSelected={onCustomerSelected}
                            onClear={onCustomerCleared}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Assign To Package"
                        name="packageId"
                        rules={[
                            {
                                required: true,
                                message:
                                    "You must select a package to move the batch to!",
                            },
                        ]}
                    >
                        <Select
                            disabled={working || !customerSelected}
                            placeholder={
                                !customerSelected
                                    ? "Select a customer first"
                                    : "Select a package"
                            }
                            allowClear
                            showSearch
                            showArrow
                        >
                            {packages?.map((p) => (
                                <Option key={p.id} value={p.id}>
                                    <MultiValueDisplay
                                        title={`ID - ${p.friendlyId === 0
                                            ? "N/A"
                                            : p.friendlyId
                                            }`}
                                        subTitle={`Received on ${localizedDate(
                                            p.receivedDate
                                        )} - ${p.packageTypeDisplayName}`}
                                    />
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default EditBatchModal;
