export type ChaseMessage = ChaseFrameStatusMessage | ChaseActionMessage;

export interface ChaseFrameStatusMessage {
    frameStatus: string;
    frameErrorCode: string | null;
    errorDescription: string | null;
}

export interface ChaseActionMessage {
    action: string;
    subAction: string;
    siteId: string;
    account: ChaseAccount
}

export interface ChaseAccount {
    counterPartPayOrg: string;
    counterPartyAccountId: string;
    counterPartyAccountName: string;
    counterPartyAccountNum: string;
    counterPartyAccountType: string;
    counterPartyBankAccntCatg: string;
    counterPartyCountry: string;
    counterPartyRoutingNnum: string;
    counterPartyRoutingNumber: string;
    dateModified: string;
}

const whitelistedChaseDomains = [
    'https://cnc.jpmorgan.com',
    'https://concourse.jpmorgan.com',
    'https://concourse-test.jpmorgan.com'
];

export const isFromChase = (e: MessageEvent) => whitelistedChaseDomains.includes(e.origin);

export const chaseFrameIsReady = (msg: ChaseMessage): msg is ChaseFrameStatusMessage => {
    return (msg as ChaseFrameStatusMessage).frameStatus === "ready";
}

export const chaseFrameHasError = (msg: ChaseMessage): msg is ChaseFrameStatusMessage => {
    var state = msg as ChaseFrameStatusMessage;

    return (!!state.frameErrorCode || !!state.errorDescription);
}

export const userCancelledSession = (msg: ChaseMessage): msg is ChaseActionMessage => {
    var state = msg as ChaseActionMessage;

    return (state.action === "WALLET" && state.subAction === "CANCEL");
}

export const accountSaved = (msg: ChaseMessage): msg is ChaseActionMessage => {
    var state = msg as ChaseActionMessage;

    return (state.action === "WALLET" && state.subAction === "CREATE");
}
