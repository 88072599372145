import { Button, Input, InputRef } from "antd";
import { ClearTableFilterRequest } from "models/common";
import React, { useCallback, useEffect, useRef } from "react";
import {
    ExtendedFilterDropdownProps,
    handleClearFilterRequest,
} from "util/table";

interface TextTableFilterProps extends ExtendedFilterDropdownProps {
    clearTableFilterRequest: ClearTableFilterRequest,
    placeholder?: string
}

const TextTableFilter = ({
    confirm,
    selectedKeys,
    setSelectedKeys,
    clearFilters,
    onFilter,
    clearTableFilterRequest,
    placeholder
}: TextTableFilterProps) => {
    const searchInput = useRef<InputRef>(null);

    const setFilter = () => {
        confirm({ closeDropdown: true });
        const term = searchInput.current?.input?.value.trim();
        if (term) {
            onFilter(term);
        }
    };

    const onClear = useCallback(() => {
        clearFilters && clearFilters();
        confirm({ closeDropdown: false });
        onFilter(undefined);
    }, [clearFilters, confirm, onFilter]);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const term = e.target.value;
        setSelectedKeys(term ? [term] : []);
        if (!term) {
            onClear();
        }
    };

    useEffect(() => {
        handleClearFilterRequest(clearTableFilterRequest, onClear);
    }, [onClear, clearTableFilterRequest]);

    return (
        <div className="custom-filter">
            <div className="selector-wrapper">
                <Input
                    ref={searchInput}
                    placeholder={placeholder}
                    allowClear
                    value={selectedKeys[0]}
                    onChange={onChange}
                    onPressEnter={setFilter}
                />
            </div>
            <div className="table-filter-actions-single ant-table-filter-dropdown-btns">
                <Button size="small" onClick={setFilter} type="primary">
                    OK
                </Button>
            </div>
        </div>
    );
};

export default TextTableFilter;