import { useQuery } from "@tanstack/react-query";
import { FeaturedAuction } from "models/featuredAuction";
import { usePublicRequest } from "./useRequests";

const useFeaturedAuctions = () => {
    const featuredActionsRequest = usePublicRequest<FeaturedAuction[]>({
        method: "get",
        url: "/featured-auctions"
    });

    return useQuery<FeaturedAuction[]>(
        ["featured-auction"],
        featuredActionsRequest
    );
};

export default useFeaturedAuctions;
