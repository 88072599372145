import { Button, Flex } from "@chakra-ui/react";
import { useIdentity } from "context/auth";
import { FC, useState } from "react";
// import PartnerTransferModal from "./customer/Payouts/PartnerTransferModal/PartnerTransferModal";
import RequestPayoutModal from "./customer/Payouts/RequestPayoutModal/RequestPayoutModal";

const TransferBar: FC = () => {
    // hiding the partner transfer functionality for now -mtighe, 2024.3.11
    // const [transferVisible, setTransferVisible] = useState(false);
    const [payoutVisible, setPayoutVisible] = useState(false);

    const { isAdmin } = useIdentity();

    return (
        <>
            <Flex
                bg="var(--dcs-blue)"
                color="black"
                rounded={7}
                w="100%"
                px={5}
                py={3}
                alignItems="center"
            >
                <Flex
                    flex="1"
                    fontSize={20}
                    fontFamily="body"
                    color="var(--dcs-white)"
                >
                    Transfer funds out of {isAdmin ? "an" : "your"} account
                </Flex>
                {/*
                <Button minWidth="7rem" ml="10px" variant="primary" onClick={() => setTransferVisible(true)}>
                    Transfer to Partner
                </Button>
                */}
                <Button
                    minWidth="7rem"
                    ml="10px"
                    variant="primary"
                    onClick={() => setPayoutVisible(true)}
                    backgroundColor="var(--dcs-dark-blue)"
                >
                    {isAdmin ? "Initiate" : "Request"} Payout
                </Button>
            </Flex>
            {/*
               <PartnerTransferModal visible={transferVisible} onClose={() => setTransferVisible(false)} /> 
            */}
            <RequestPayoutModal
                visible={payoutVisible}
                onClose={() => setPayoutVisible(false)}
            />
        </>
    );
};

export default TransferBar;
