import { Box, VStack } from "@chakra-ui/react";
import { CheckPayoutMethodDetails } from "models/payoutRequests";
import { FC } from "react";

import { ConfirmationDetailsProps } from ".";
import SummaryDetail from "./SummaryDetail";

const CheckConfirmation: FC<ConfirmationDetailsProps> = ({ payoutRequest }) => {
    const details = payoutRequest?.details as CheckPayoutMethodDetails;

    return (
        <Box fontSize={14}>
            <Box fontSize={26} pb={3} fontWeight="bold">
                Check Confirmation
            </Box>
            <SummaryDetail label="Shipping Method">
                UPS{" "}
                <Box as="span" textTransform="capitalize">
                    {details?.shippingMethod}
                </Box>
            </SummaryDetail>
            <SummaryDetail label="Payee">{details?.payee}</SummaryDetail>
            <VStack align="flex-start" spacing={0} pt={3} fontSize={16}>
                {/* <Box fontWeight="bold">Address</Box> */}
                <Box>{details?.addressLine1}</Box>
                {details?.addressLine2 && <Box>{details.addressLine2}</Box>}
                <Box>
                    {details?.city}, {details?.state} {details?.zipCode}
                </Box>
                <Box>{details?.country}</Box>
            </VStack>
        </Box>
    );
};

export default CheckConfirmation;
