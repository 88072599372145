import { Button } from "antd";
import { BaseEntity, ClearTableFilterRequest } from "models/common";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
    ExtendedFilterDropdownProps,
    handleClearFilterRequest,
} from "util/table";
import BatchSelector from "./BatchSelector";
import CustomerSelector from "./CustomerSelector";
import { EntitySelectorProps, EntitySelectorRef } from "./EntitySelector";
import PackageTypeSelector from "./PackageTypeSelector";

const EntitySearch = <TEntity extends BaseEntity>(
    Selector: React.ComponentType<EntitySelectorProps<TEntity>>,
    filterRequestType: ClearTableFilterRequest,
    {
        confirm,
        setSelectedKeys,
        clearFilters,
        onFilter,
    }: ExtendedFilterDropdownProps,
    selectorProps?: any,
) => {
    const [selected, setSelected] = useState<TEntity | undefined>();
    const ref = useRef<EntitySelectorRef>(null);

    const onEntitySelected = (entity: TEntity | undefined) => {
        if (entity) {
            setSelectedKeys([entity.id]);
            setSelected(entity);
        }
    };

    const confirmFilter = () => {
        confirm({ closeDropdown: true });
        onFilter(selected);
    };

    const onClear = useCallback(() => {
        clearFilters && clearFilters();
        confirm({ closeDropdown: false });
        setSelected(undefined);
        onFilter(undefined);
    }, [clearFilters, confirm, onFilter]);

    useEffect(() => {
        handleClearFilterRequest(filterRequestType, () => {
            onClear();
            ref.current?.reset();
        });
    }, [filterRequestType, onClear]);

    return (
        <div className="custom-filter">
            <Selector
                ref={ref}
                onSelected={onEntitySelected}
                onClear={onClear}
                {...selectorProps}
            />
            <div className="table-filter-actions-single ant-table-filter-dropdown-btns">
                <Button size="small" onClick={confirmFilter} type="primary">
                    OK
                </Button>
            </div>
        </div>
    );
};

export const BatchSearch = (props: ExtendedFilterDropdownProps) =>
    EntitySearch(BatchSelector, ClearTableFilterRequest.Batch, props);

export const CustomerSearch = (props: ExtendedFilterDropdownProps) =>
    EntitySearch(CustomerSelector, ClearTableFilterRequest.Customer, props, { includeInactive: true, showStatus: true });

export const PackageTypeSearch = (props: ExtendedFilterDropdownProps) =>
    EntitySearch(PackageTypeSelector, ClearTableFilterRequest.Type, props);
