import { LoadingOutlined } from "@ant-design/icons";
import { Box, HStack, Text, VStack } from "@chakra-ui/react";
import { Alert, Progress } from "antd";
import useShipmentCreationState from "hooks/useShipmentCreationState";
import { ShipmentCreationStatus } from "models/shipping";
import moment from "moment-timezone";
import { FC, useState } from "react";

const ProgressMessage: FC<{ started: boolean, batchCount: number }> = ({ started, batchCount }) => {
    if (started && batchCount === 0) {
        return <>{"Creating, batching, and submitting shipments to Shippo..."}</>;
    } else if (started && batchCount > 0) {
        return <>{"Validating & purchasing shipment labels from Shippo..."}</>;
    }

    return <>{"Initializing shipment creation process..."}</>;
}

const CreationProgress = () => {
    const [stream, setStream] = useState<boolean>(true);
    const { isLoading, data } = useShipmentCreationState(stream);

    if (isLoading || !data ||
        data.status === ShipmentCreationStatus.None) {
        return null;
    }

    if (!stream &&
        (
            data.status === ShipmentCreationStatus.Pending ||
            data.status === ShipmentCreationStatus.Creating ||
            data.status === ShipmentCreationStatus.Processing
        )) {
        setStream(true);
    }

    if (data.complete) {
        if (stream) {
            setStream(false);
        }

        const now = moment();
        const lastUpdated = moment(data.lastUpdated);

        if (now.diff(lastUpdated, 'minutes') >= 5) {
            // not displaying anything if the latest batch was finished
            // 5 or more minutes ago
            return null;
        }

        if (data.status === ShipmentCreationStatus.Failed) {
            return <Alert
                type="error"
                closable
                showIcon
                message="Shipment Creation Failed"
                description={data.exception}
            />
        } else {
            return <Alert
                type="success"
                closable
                showIcon
                message="Complete"
                description="Shipment batches have been successfully created w/ shipping labels"
            />
        }
    }

    return <Alert
        type="info"
        showIcon
        icon={<LoadingOutlined />}
        message={<ProgressMessage {...data} />}
        description={data.batchCount > 0 && <Box display='flex' width='100%'>
            <VStack width='100%'>
                <Text>Total Batches: {data.batchCount}</Text>
                <HStack width='100%'>
                    <Text width='85px'>Validated</Text>
                    <Progress percent={Math.round((data.validatedCount / data.batchCount) * 100)} />
                </HStack>
                <HStack width='100%'>
                    <Text width='85px'>Purchased</Text>
                    <Progress percent={Math.round((data.purchasedCount / data.batchCount) * 100)} />
                </HStack>
            </VStack>
        </Box>}
    />
}

export default CreationProgress;