import { AuditEntry } from "models/auditLog";
import { useAuthenticatedRequestCreator } from "./useRequests";

export type AuditLogType = "Card" | "Package" | "Shipment" | "PackageType";

export const AuditLogTypeDisplayName: Record<AuditLogType, string> = {
    "Card": 'card',
    "Package": 'package',
    "Shipment": 'shipment',
    "PackageType": 'package type'
};

export type AuditLogRequest = {
    type: AuditLogType,
    referenceId: string;
}

const useAuditLog = () => {
    const query = useAuthenticatedRequestCreator<AuditEntry[], AuditLogRequest>(
        (req) => ({
            method: "post",
            url: "/events",
            data: req
        })
    );

    return { query };
};

export default useAuditLog;