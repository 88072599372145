import { useMutation } from "@tanstack/react-query";
import { useAuthenticatedRequestCreator } from "hooks/useRequests";

export type SendPackageUpdateRequest = {
    packageId: string;
    genericMessage: string;
};

const useSendPackageUpdate = () => {
    const post = useAuthenticatedRequestCreator<void, SendPackageUpdateRequest>(
        (data: SendPackageUpdateRequest) => ({
            url: "/support/package-update",
            method: "POST",
            data,
        })
    );

    const { mutateAsync: sendPackageUpdate, isLoading } = useMutation(post);
    return { sendPackageUpdate, isLoading };
};

export default useSendPackageUpdate;
