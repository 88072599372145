import { Box } from "@chakra-ui/react";
import { Alert, Button, Modal } from "antd";
import useShipmentCreation, { CreationResult } from "hooks/useShipmentCreation";
import { useState } from "react";

const ShipmentCreationModal = () => {
    const [visible, setVisible] = useState<boolean>(false);
    const [working, setWorking] = useState<boolean>(false);
    const [result, setResult] = useState<CreationResult | undefined>();
    const { createShipments } = useShipmentCreation();

    const onOpen = () => {
        setVisible(true);
    }

    const enqueue = async () => {
        setWorking(true);
        const result = await createShipments();
        setWorking(false);
        setResult(result);
    }

    const complete = () => {
        setVisible(false);
        if (result && result.successful) {
            window.location.reload();
        }
    }

    return <>
        <Button type="link" size="large" onClick={onOpen}>Create Shipments</Button>
        <Modal
            title="Create Shipments"
            closable={false}
            keyboard={true}
            maskClosable={true}
            destroyOnClose={true}
            open={visible}
            footer={result !== undefined ? (
                <Button key='cancel' type="default" onClick={complete} disabled={working}>Close</Button>
            ) : ([
                <Button key='ok' type="primary" onClick={enqueue} disabled={working}>Create</Button>,
                <Button key='cancel' type="default" onClick={() => setVisible(false)} disabled={working}>Cancel</Button>
            ])}
        >
            <Box mb={5}>
                This will kick off shipment creation in the background, and the process may take some time.
                Current progress will be shown & will update automatically.
            </Box>
            {working && <Alert
                className="bold-font"
                message="Enqueing shipment creation..."
                type="info"
                showIcon
            />}
            {result && <Alert
                className="bold-font"
                message={result.message}
                type={result.successful ? "success" : "error"}
                showIcon
            />}
        </Modal>
    </>
}

export default ShipmentCreationModal;