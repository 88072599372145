import { PrinterOutlined } from "@ant-design/icons";
import { Box, HStack, VStack } from "@chakra-ui/react";
import { Button, Radio, RadioChangeEvent, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import Currency from "components/Currency";
import OrderFulfillmentModal from "components/staff/Orders/OrderFulfillmentModal";
import OrderSearch from "components/staff/Orders/OrderSearch";
import useStaffOrders from "hooks/useStaffOrders";
import { InitialPageRequest, PageRequest, PageSizeOptions } from "models/common";
import { OrderFulfillmentStatus, OrderFulfillmentStatusLabels, StoreOrder } from "models/store";
import { useState } from "react";
import { localizedDate } from "util/helpers";

enum OrderFilter {
    Unknown = 0,
    All = 1,
    InProgress = 2,
    Shipped = 3
}

const getFilterBy = (filter: OrderFilter): Record<string, string[]> | undefined => {
    switch (filter) {
        case OrderFilter.Shipped:
            return { Status: ['Shipped'] };
        case OrderFilter.InProgress:
            return { Status: ['InProgress'] };
        default:
            return undefined;
    }
}

const OrderFulfillment = () => {
    const [{ page, pageSize, filter, withinDays, sort, filterBy }, setPageRequest] =
        useState<PageRequest>({ ...InitialPageRequest, filterBy: getFilterBy(OrderFilter.InProgress) });
    const { data, isLoading, refetch } = useStaffOrders({ page, pageSize, filter, withinDays, sort, filterBy });
    const { data: records, totalRecords } = data || {};

    const columns: ColumnsType<StoreOrder> = [
        {
            title: "Order #",
            dataIndex: "friendlyId",
            render: (_, rec) => <OrderFulfillmentModal order={rec} onDataChanged={refetch} />
        },
        {
            title: "Order Placed",
            dataIndex: "createdAt",
            render: localizedDate
        },
        {
            title: "Status",
            dataIndex: "status",
            render: (s: OrderFulfillmentStatus) => OrderFulfillmentStatusLabels[s]
        },
        {
            title: "Label",
            dataIndex: "labelUrl",
            render: (_, rec) => rec.shipments[0]?.labelUrl
                ? <Button
                    type="link"
                    icon={<PrinterOutlined />}
                    target="_blank"
                    href={rec.shipments[0]?.labelUrl!} />
                : <></>
        },
        {
            title: "Tracking #",
            dataIndex: "trackingNumber",
            render: (_, rec) => rec.shipments[0]?.trackingNumber
                ? <Button type="link" target="_blank" href={rec.shipments[0]?.trackingUrl!}>
                    {rec.shipments[0]?.trackingNumber}
                </Button>
                : <></>
        },
        {
            title: "Shipped To",
            key: "recipientName",
            render: (_, rec) => rec.addresses[0].recipientName
        },
        {
            title: "Order Total",
            dataIndex: "totalInCents",
            align: "right",
            render: (a) => <Currency cents={a} flat />
        }
    ];

    const tableChanged = (
        // pagination
        { current: newPage, pageSize: newPageSize }: any,
        // filtering
        _: any,
        // sorting
        { column, field, order }: any
    ) => {
        setPageRequest({
            page: newPageSize !== pageSize ? 1 : newPage,
            pageSize: newPageSize,
            withinDays,
            sort: column ? [field, order === "ascend"] : null,
            filterBy
        });
    };

    const applyFilter = (e: RadioChangeEvent) => {
        const filterBy = getFilterBy(e.target.value);
        setPageRequest({ pageSize, filter, withinDays, sort, page: 1, filterBy });
    }

    return <Box p={50}>
        <VStack w="100%" spacing={7} minHeight="800px">
            <Box justifyContent='space-between' display='flex' style={{ width: '100%' }}>
                <Box fontSize={36}>Order Fulfillment</Box>
                <VStack alignItems='end'>
                    <HStack>
                        <OrderSearch onDataChanged={refetch} />
                        <Radio.Group defaultValue={OrderFilter.InProgress}
                            size='large'
                            buttonStyle='solid'
                            onChange={applyFilter}
                            style={{ alignSelf: 'end' }}>
                            <Radio.Button value={OrderFilter.All}>All</Radio.Button>
                            <Radio.Button value={OrderFilter.InProgress}>To Be Fulfilled</Radio.Button>
                            <Radio.Button value={OrderFilter.Shipped}>Completed</Radio.Button>
                        </Radio.Group>
                    </HStack>
                </VStack>
            </Box>
            <Box w="100%" pr={10}>
                <Table
                    id="customer-orders-table"
                    columns={columns}
                    dataSource={records}
                    rowKey={(r) => r.id}
                    onChange={tableChanged}
                    pagination={{
                        total: totalRecords,
                        current: page,
                        pageSize,
                        pageSizeOptions: PageSizeOptions,
                        showSizeChanger: true,
                        showQuickJumper: true,
                    }}
                    showSorterTooltip={false}
                    loading={isLoading}
                />
            </Box>
        </VStack>
    </Box>;
};

export default OrderFulfillment;