import { Box, VStack } from "@chakra-ui/react";
import ContactUsForm from "components/ContactUsForm";

const ContactUsSection = () => {
    return (
        <VStack
            width="100vw"
            bg="var(--dcs-dark-blue)"
            color="var(--dcs-white)"
            align="left"
            py={50}
            px={75}
            spacing={3}
        >
            <VStack spacing={0}>
                <Box fontFamily="heading" fontSize={48} lineHeight="1em">
                    Got more questions? We have answers!
                </Box>
                <Box fontSize={28}>Reach out to us directly</Box>
            </VStack>
            <ContactUsForm />
        </VStack>
    );
};

export default ContactUsSection;
