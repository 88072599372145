import { useMutation } from "@tanstack/react-query";
import { IAuthToken, useAuthContext, useIdentity } from "context/auth";
import { ZendeskAPI } from "react-zendesk";
import {
    useAuthenticatedRequestCreator,
    usePublicRequestCreator,
} from "./useRequests";

const useGetImpersonationToken = () => {
    const getImpersonationToken = useAuthenticatedRequestCreator<
        IAuthToken,
        string
    >((email) => ({
        method: "post",
        url: "/account/impersonate",
        headers: { "Content-Type": "application/json" },
        data: {
            email,
        },
    }));

    return getImpersonationToken;
};

export interface LoginCreds {
    username: string;
    password: string;
}

export const useLogin = () => {
    const { setToken } = useAuthContext();
    // const [isLoading, setIsLoading] = useState(false);
    // const [error, setError] = useState<string | null>(null);
    const { isLoggedIn, roles, twoFactorTokenRequired } = useIdentity();

    const authTokenRequest = usePublicRequestCreator<IAuthToken, LoginCreds>(
        ({ username, password }) => ({
            method: "post",
            url: "/account/login",
            headers: { "Content-Type": "application/json" },
            data: {
                username,
                password,
            },
        }),
        // Tell the request we're going to handle 401s ourselves
        [401]
    );

    const { mutateAsync: authenticate, ...rest } = useMutation(
        async (creds: LoginCreds) => {
            const authToken = await authTokenRequest(creds);

            setToken(authToken);

            // Reauthenticate with Zendesk cheat widget
            // TODO: This doesn't seem to work, but the authentication doesn't do anything right now anyway
            ZendeskAPI("webWidget", "chat:reauthenticate");
        }
    );

    return { authenticate, isLoggedIn, roles, twoFactorTokenRequired, ...rest };
};

export const useTwoFactorToken = () => {
    const { setToken } = useAuthContext();

    const verify = useAuthenticatedRequestCreator<IAuthToken, string>(
        (token) => ({
            method: "post",
            url: "/account/verify-token",
            data: { token }
        }),
        [401]
    );

    const { mutateAsync: verifyToken, isLoading, isError } = useMutation(
        async (token: string) => {
            const authToken = await verify(token);

            setToken(authToken);
        }
    );

    return { verifyToken, isLoading, isError };
}

export const useCustomerImpersonation = () => {
    const { startImpersonating } = useAuthContext();
    const getImpersonationToken = useGetImpersonationToken();

    const impersonate = async (emailAddress: string) => {
        const token = await getImpersonationToken(emailAddress);
        startImpersonating(token);
    };

    return { impersonate };
};
