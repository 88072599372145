import { Box } from "@chakra-ui/react";
import { useGetPayoutFees } from "hooks/usePayoutFees";
import { PayoutRequestMethod, ValidPaymentMethodLabels, ValidPaymentMethods } from "models/payoutRequests";
import { centsToDollars } from "util/helpers";

const TransferFee = (props: { method: ValidPaymentMethods | undefined }) => {
    const { data: fees } = useGetPayoutFees();
    const { achInCents, wireInCents, internationalWireInCents } = fees || {};

    let fee;
    switch (props.method) {
        case PayoutRequestMethod.ACH:
            fee = achInCents;
            break;
        case PayoutRequestMethod.Wire:
            fee = wireInCents;
            break;
        case PayoutRequestMethod.InternationalWire:
            fee = internationalWireInCents;
            break;
        default:
            // Paypal and Checks have their fees
            // displayed on their detail forms
            return null;
    }

    if (fee === 0 || fee === undefined) {
        return null;
    }

    return <Box as="span" fontWeight={500}>
        **{ValidPaymentMethodLabels[props.method]} has a transfer fee of {centsToDollars(fee!)}**
    </Box>
};

export default TransferFee;