import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    useBreakpointValue,
    VStack,
} from "@chakra-ui/react";
import { FC } from "react";
import { useLocation } from "react-router-dom";
import { SideMenuEntry } from "./sideMenuEntries";
import SideMenuItem from "./SideMenuItem";

export const SideMenu: FC<{ entries: SideMenuEntry[] }> = ({ entries }) => {
    const location = useLocation();

    const cookedEntries = entries.map((entry) => ({
        ...entry,
        isActive: location.pathname.startsWith(entry.url),
    }));

    const currentPageEntry = {
        ...cookedEntries.find((entry) => entry.isActive)!,
        isActive: false,
    };

    const currentIndex = useBreakpointValue({ base: undefined, md: 0 });

    return (
        <Accordion
            display={"inherit"}
            allowToggle
            w={{ base: "100%", md: "auto" }}
            p={0}
            bg={{ base: "var(--dcs-dark-blue)" }}
            // By making the key the pathname, it will re-mount (and thus re-collapse) the menu when the page changes
            // This is because EVERYTHING remounts if its key changes, because React thinks it's a new component
            key={location.pathname}
            index={currentIndex}
            borderRight="2px white solid"
            alignSelf='stretch'
        >
            <AccordionItem w="100%" border="none" >
                <AccordionButton
                    w="100%"
                    p={3}
                    pos="relative"
                    display={{ base: "flex", md: "none" }}
                >
                    <Box
                        pos="absolute"
                        left="0"
                        top="0"
                        w="100%"
                        h="100%"
                        bg="var(--dcs-bright-blue)"
                    />
                    <Box fontSize={24} color="var(--dcs-white)" zIndex={1} w='100%' pl='30px'>
                        {currentPageEntry.name}
                    </Box>
                    <AccordionIcon fontSize="3xl" zIndex={1} mr={7} />
                </AccordionButton>
                <AccordionPanel p={0}>
                    <VStack spacing={0.5} minWidth="250px">
                        {cookedEntries.map((item) => (
                            <SideMenuItem key={item.name} item={item} />
                        ))}
                    </VStack>
                </AccordionPanel>
            </AccordionItem>
        </Accordion>
    );
};
