import { VStack } from "@chakra-ui/react";
import HomePageHero from "components/HomePage/HomePageHero";
import { MoreQuestions } from "components/HowProcessWorks/MoreQuestions";

const HomePage = () => {
    return (
        <>
            <HomePageHero />
            <VStack
                bg="var(--dcs-black)"
                py="4rem"
                px={{
                    base: "2rem",
                    md: "10vw",
                }}
                spacing="2rem"
            ></VStack>
            <MoreQuestions />
        </>
    );
};

export default HomePage;
