import { ApplicationUser } from "models/applicationUsers";
import { useAuthenticatedRequestCreator } from "./useRequests";

const ACCOUNT_ROOT = "/account";

export type UserSearchOption = {
    name: string;
    includeInactive?: boolean;
}

export const useApplicationUsers = () => {
    const findUsers = useAuthenticatedRequestCreator<ApplicationUser[], UserSearchOption>(
        opt => ({
            method: "get",
            url: `${ACCOUNT_ROOT}/customers/?name=${opt.name}&allUsers=${!!opt.includeInactive}`
        })
    );

    return { findUsers };
};
