import { useQuery } from "@tanstack/react-query";
import { SportsCard } from "models/cards";
import { CustomerPackage } from "models/packages";
import { useAuthenticatedRequest } from "./useRequests";

type CustomerDashboardInfo = {
    processingPackagesCount: number;
    latestPackages: CustomerPackage[];
    latestCards: SportsCard[];
};

const useCustomerDashboard = () => {
    const getData = useAuthenticatedRequest<CustomerDashboardInfo>({
        url: "/dashboard/customer",
        method: "GET",
    });

    return useQuery(["customerDashboard"], getData);
};

export default useCustomerDashboard;
