import { Text } from "@chakra-ui/react";
import { Alert, Modal } from "antd";
import MultiValueDisplay from "components/MultiValueDisplay";
import useReturnCard from "hooks/useReturnCard";
import { StaffSportsCard } from "models/cards";
import { useState } from "react";
import { CardUpdateCallback } from "./EditCardModal";

const ReturnCardModal = (props: {
    open: boolean,
    onClose: () => void,
    card: StaffSportsCard,
    onSuccess: CardUpdateCallback
}) => {
    const [working, setWorking] = useState<boolean>(false);
    const { returnCard } = useReturnCard();
    const { open, card } = props;

    const markAsReturned = async () => {
        try {
            setWorking(true);
            await returnCard(card.cardId);
            props.onSuccess(card.cardId);
            props.onClose();
        } finally {
            setWorking(false);
        }
    }

    return <Modal
        title={<MultiValueDisplay title="Card Returned by eBay" subTitle={card.title} />}
        open={open}
        closable={false}
        keyboard={false}
        maskClosable={false}
        confirmLoading={working}
        cancelButtonProps={{ disabled: working }}
        okText={working ? "Processing..." : "OK"}
        onOk={markAsReturned}
        onCancel={props.onClose}
        destroyOnClose
    >
        <Text mb={5}>
            Flags this card as having been returned by eBay, which will allow it to be re-listed &
            move through the auction process again with the same SKU.
        </Text>
        {card.sku && <Text mb={5}>
            SKU: {card.sku}
        </Text>}
        <Alert
            showIcon
            type="warning"
            message="Are you sure?"
            description="This will delete any purchase and/or shipping data against this card, remove the net amount of the previous sale from the customer's account, and cannot be undone!"
        />
    </Modal>
};

export default ReturnCardModal;