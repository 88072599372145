export const Drawer = {
    baseStyle: {},
    variants: {
        // Custom Variants
        cart: {
            // Most of these exist to undo Chakra's highly opinionated defaults
            //   and make it work in a general context, including our use-case
            dialogContainer: {
                position: "absolute",
                bottom: "0",
                height: "unset !important"
            },
            dialog: {
                position: "absolute !important"
            },
            dialogOverlay: {
                position: "absolute !important",
                height: "100%",
                width: "100%"
            },
            overlay: {
                bottom: "0",
                height: "unset !important"
            },
            body: {
                padding: ".5rem"
            }
        }
    }
};
