import { DefaultOptionType } from "antd/lib/select";
import { DefaultDays } from "components/TableActions";
import { PackageType } from "./packages";

/**
 * A page of data returned from the backend, along with paging / result set metadata
 */
export type PagedResult<T> = {
    currentPage: number;
    pageCount: number;
    pageSize: number;
    totalRecords: number;
    data: T[];
};

/**
 * A page of data returned from the backend, along with paging / result set metadata and extra relevant data
 */
export type PagedResultWithExtra<T, E> = PagedResult<T> & {
    extra: E;
}

/**
 * A request for a page of data with optional string filter, 'within days' filter and sorting
 */
export type PageRequest = {
    page: number;
    filter?: string | null;
    withinDays: number;
    sort: SortRequest;
    filterBy?: FilterBy;
    format?: "csv";
    pageSize?: number;
};

/**
 * The FilterBy object type that holds the filterBy key value pairs for filtered columns
 */
export type FilterBy = Record<
    string,
    (string | number | boolean | number[])[] | null
>;

/**
 * Represents a request to sort data. Index 0 is the sorting key, index 1 is the order (true = asc, false = desc)
 *
 * ex: ['columnName', true]
 */
export type SortRequest = [string, boolean] | null;

/**
 * Represents an entity from the backend with properties shared across all other entities
 */
export interface BaseEntity {
    id: string;
}

export enum ClearTableFilterRequest {
    MessageType = "ClearFilterRequest",
    Batch = 0,
    Status = 1,
    Title = 2,
    Customer = 3,
    Name = 4,
    Type = 5,
    Amount = 6,
    FriendlyId = 7,
    Roles = 8
}

export const CssVariables = {
    darkGreen: "var(--dcs-dark-green)",
    lightGreen: "var(--dcs-light-green)",
    lightGray: "var(--dcs-light-gray)",
    darkGrayTransparent: "var(--dcs-dark-gray-transparent)",
    gray: "var(--dcs-gray)",
    darkGray: "var(--dcs-dark-gray)",
    errorRed: "var(--dcs-error-red)",
    blue: "var(--dcs-blue)",
    white: "var(--dcs-white)",
    antActiveFilter: "var(--ant-active-filter)",
    warning: "var(--dcs-warning)",
};

export const NoPrintSx = {
    "@media print": {
        display: "none",
    },
};

export const PageSizeOptions = [10, 25, 50, 100];
export const DefaultPageSize = PageSizeOptions[0];
export const InitialPageRequest: PageRequest = {
    page: 1,
    pageSize: DefaultPageSize,
    filter: null,
    withinDays: DefaultDays,
    sort: null,
};

export interface PackageTypeOption extends DefaultOptionType {
    packageType: PackageType;
}

export interface PackageLeadTime {
    displayName: string;
    waitTimeInDays: number;
    businessDays: boolean;
    recurring: DayOfWeek | null;
}

export enum DayOfWeek {
    Sunday = 0,
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6,
}
