import { Button, Modal } from "antd";
import TextArea, { TextAreaRef } from "antd/lib/input/TextArea";
import { FC, useRef, useState } from "react";

interface DoNotUploadModalProps {
    onConfirm: (reason: string) => Promise<void>
}

const DoNotUploadModal: FC<DoNotUploadModalProps> = ({ onConfirm }) => {
    const [visible, setVisible] = useState(false);
    const [reason, setReason] = useState('');
    const ref = useRef<TextAreaRef>(null);

    // auto-focus the text area
    setTimeout(() => ref.current?.focus(), 10);

    return <>
        <Button type="primary" danger style={{ marginRight: '10px' }} onClick={() => setVisible(true)}>
            Flag as Do Not Upload
        </Button>
        <Modal
            destroyOnClose
            title="Flag as Do Not Upload"
            open={visible}
            onCancel={() => setVisible(false)}
            onOk={async () => {
                await onConfirm(reason);
                setVisible(false);
            }}
            okButtonProps={{ disabled: reason.trim().length === 0 }}
        >
            <TextArea
                ref={ref}
                placeholder="State a reason why this card should not be uploaded to eBay."
                value={reason}
                onChange={e => setReason(e.target.value)}
            />
        </Modal>
    </>;
};

export default DoNotUploadModal;