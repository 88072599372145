import { Box, HStack, SimpleGrid, Text } from "@chakra-ui/react";
import { Button, Form } from "antd";
import { PublicRoutes } from "appRoutePaths";
import { useLogin } from "hooks/useLogin";
import { useRegistration } from "hooks/useRegistration";
import { CssVariables } from "models/common";
import { FC } from "react";
import { Link, useNavigate } from "react-router-dom";
import PasswordInput from "./PasswordInput";
import { SignUpFormItem } from "./SignUpFormItem";

const CreateAccountFormFooter: FC = () => (
    <HStack spacing={4}>
        <Button type="primary" htmlType="submit">
            Continue
        </Button>
        <Box maxWidth="15rem">
            <Text>
                By continuing you agree to accept our{" "}
                <Link to={PublicRoutes.privacyPolicy.url} style={{ color: CssVariables.antActiveFilter }}>
                    Privacy Policy & Terms of Service
                </Link>
            </Text>
        </Box>
    </HStack>
);

export const CreateAccountForm: FC = () => {
    const { registerUser, error } = useRegistration();
    const errorMessages = error?.response?.data?.errors;

    const navigate = useNavigate();
    const { authenticate } = useLogin();

    const saveAccountDetails = async (values: any) => {
        await registerUser(values);

        // Login after successful registration, skipping the contact details check
        await authenticate(values);

        // The "/new" here will be picked up as a route in the account-details page :)
        navigate(PublicRoutes.contactDetails.url + "/new");
    };

    return (
        <Form
            name="sign-up"
            layout="vertical"
            onFinish={saveAccountDetails}
            style={{ width: "100%" }}
        >
            <SimpleGrid columns={{ base: 1, md: 2 }} w="100%" my="6">
                <SignUpFormItem title="First Name" autoComplete="given-name" />
                <SignUpFormItem title="Last Name" autoComplete="family-name" />
                <SignUpFormItem title="Username" autoComplete="username" />
                <SignUpFormItem
                    title="Email"
                    type="email"
                    autoComplete="email"
                    rules={[
                        {
                            required: true,
                            type: "email",
                            message: "Valid Email is required",
                            validateTrigger: "onBlur",
                        },
                    ]}
                />
                <Box>
                    <PasswordInput />
                </Box>
            </SimpleGrid>
            {errorMessages && (
                <Box color="red" fontSize="sm">
                    {errorMessages.map((message) => (
                        <Text key={message}>{message}</Text>
                    ))}
                </Box>
            )}
            <CreateAccountFormFooter />
        </Form>
    );
};
