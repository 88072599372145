import {
    useQuery,
} from "@tanstack/react-query";
import { ShipmentBatchSummary } from "models/shipping";
import { useAuthenticatedRequest } from "./useRequests";

const makeAxiosRequest = (id: string) => {
    return {
        method: "get",
        url: `/shipping/batch-summary/${id}`,
    };
};

const useShipmentBatchSummary = (id: string | undefined) => {
    const getShipmentBatchSummary = useAuthenticatedRequest<ShipmentBatchSummary[]>(
        makeAxiosRequest(id!)
    );

    return useQuery(["shipment-batch-summary", id], getShipmentBatchSummary, { enabled: !!id });
};

export default useShipmentBatchSummary;