import { TyperRoutes } from "appRoutePaths";
import BasicLoadingSpinner from "components/BasicLoadingSpinner";
import { Suspense, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useNextPendingCard from "./useNextPendingCard";

// Redirects to the CardTitleAssignment component based on the id of the next
// card that needs a title.

const OpenCardFolder = () =>
    <Suspense fallback={<BasicLoadingSpinner h="30rem" w="100%" />}>
        <RedirectToNextPendingCard />
    </Suspense>;

const RedirectToNextPendingCard = () => {
    const { cardFolderId } = useParams();
    const { data } = useNextPendingCard(cardFolderId!);
    const navigate = useNavigate();

    useEffect(() => {
        navigate(TyperRoutes.cardTitling.url(cardFolderId!, data!.id), { replace: true });
    }, [cardFolderId, data, navigate]);

    return null;
};

export default OpenCardFolder;