import { VStack } from "@chakra-ui/react";
import { Form } from "antd";
import TransferBar from "components/TransferBar";
import PayoutsTable from "components/customer/Payouts/PayoutsTable";
import { FC, Suspense } from "react";

const RequestPayoutPage: FC = () => {
    return (
        <VStack
            as={Form}
            align="start"
            spacing={7}
            p={{ base: 0, md: 10 }}
            w="100%"
            color="var(--dcs-white)"
        >
            <Suspense>
                <TransferBar />
            </Suspense>
            <PayoutsTable />
        </VStack>
    );
};

export default RequestPayoutPage;
