import { FilterFilled } from "@ant-design/icons";
import { FilterDropdownProps } from "antd/lib/table/interface";
import MultiValueDisplay from "components/MultiValueDisplay";
import { registerMessageHandler } from "messages/messageBus";
import { ClearTableFilterRequest } from "models/common";
import { CustomerPackage, StaffPackage } from "models/packages";
import React from "react";
import { centsToDollars } from "./helpers";

export type FilterUpdateCallback = (value: any) => void;

export interface ExtendedFilterDropdownProps extends FilterDropdownProps {
    onFilter: FilterUpdateCallback;
}

export const createCustomFilter = (
    SearchComponent: React.ComponentType<ExtendedFilterDropdownProps>,
    onFilter?: FilterUpdateCallback,
    currentValue?: string | undefined
) => {
    return {
        filterDropdown: (props: FilterDropdownProps) => (
            <SearchComponent {...props} onFilter={onFilter ?? (() => { })} />
        ),
        filterIcon: (filtered: boolean) => (
            <FilterFilled className={filtered ? "active" : undefined} />
        ),
        filteredValue: currentValue === undefined ? null : [currentValue],
    };
};

export const handleClearFilterRequest = (
    filterRequestType: ClearTableFilterRequest,
    callback: () => void
) => {
    registerMessageHandler<ClearTableFilterRequest>(
        ClearTableFilterRequest.MessageType,
        filter => {
            if (filter === filterRequestType) {
                callback();
            }
        }
    );
};

/**
 * Returns a ReactNode representing the package type w/ the processing fee, if applicable
 * @param {StaffPackage | CustomerPackage} pkg 
 * @returns  {string} the package type display name
 */
export const packageTypeDisplay = (pkg: StaffPackage | CustomerPackage): React.ReactNode => {
    const name = 'packageTypeInternalName' in pkg
        ? pkg.packageTypeInternalName
        : pkg.packageTypeDisplayName;

    if (pkg.processingFeeInCents === 0 || pkg.processingFeeInCents === null) {
        return name;
    }

    return <MultiValueDisplay
        title={name}
        subTitle={`${centsToDollars(pkg.processingFeeInCents)}/card`}
    />;
}