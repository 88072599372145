import { Box, Flex, VStack } from "@chakra-ui/react";
import { Button, Form, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import Currency from "components/Currency";
import OrderDetailModal from "components/customer/Orders/OrderDetailModal";
import useCustomerOrders from "hooks/useCustomerOrders";
import { InitialPageRequest, PageRequest, PageSizeOptions } from "models/common";
import { OrderFulfillmentStatus, OrderFulfillmentStatusLabels, StoreOrder } from "models/store";
import { useState } from "react";
import { localizedDate } from "util/helpers";

const columns: ColumnsType<StoreOrder> = [
    {
        title: "Order #",
        dataIndex: "friendlyId",
        render: (_, rec) => <OrderDetailModal order={rec} />
    },
    {
        title: "Order Placed",
        dataIndex: "createdAt",
        render: localizedDate
    },
    {
        title: "Status",
        dataIndex: "status",
        render: (s: OrderFulfillmentStatus) => OrderFulfillmentStatusLabels[s]
    },
    {
        title: "Tracking #",
        dataIndex: "trackingNumber",
        render: (_, rec) => rec.shipments[0]?.trackingNumber
            ? <Button type="link" target="_blank" href={rec.shipments[0]?.trackingUrl!}>
                {rec.shipments[0]?.trackingNumber}
            </Button>
            : <></>
    },
    {
        title: "Shipped To",
        key: "recipientName",
        render: (_, rec) => rec.addresses[0].recipientName
    },
    {
        title: "Order Total",
        dataIndex: "totalInCents",
        align: "right",
        render: (a) => <Currency cents={a} flat />
    }
];

const OrderHistory = () => {
    const [{ page, pageSize, filter, withinDays, sort }, setPageRequest] =
        useState<PageRequest>(InitialPageRequest);
    const { data, isLoading } = useCustomerOrders({ page, pageSize, filter, withinDays, sort });
    const { data: records, totalRecords } = data || {};

    const tableChanged = (
        // pagination
        { current: newPage, pageSize: newPageSize }: any,
        // filtering
        _: any,
        // sorting
        { column, field, order }: any
    ) => {
        setPageRequest({
            page: newPageSize !== pageSize ? 1 : newPage,
            pageSize: newPageSize,
            withinDays,
            sort: column ? [field, order === "ascend"] : null,
        });
    };

    return <VStack
        as={Form}
        align="start"
        spacing={7}
        p={{ base: 0 }}
        w="100%">
        <VStack w="100%" spacing={7} minHeight="800px">
            <Flex w="100%" justifyContent="space-between" alignItems="end">
                <Box h="100%" fontSize={36} textAlign="center">
                    Order History
                </Box>
            </Flex>
            <Box w="100%" pr={10}>
                <Table
                    id="customer-orders-table"
                    columns={columns}
                    dataSource={records}
                    rowKey={(r) => r.id}
                    onChange={tableChanged}
                    pagination={{
                        total: totalRecords,
                        current: page,
                        pageSize,
                        pageSizeOptions: PageSizeOptions,
                        showSizeChanger: true,
                        showQuickJumper: true,
                    }}
                    showSorterTooltip={false}
                    loading={isLoading}
                />
            </Box>
        </VStack>
    </VStack>;
};

export default OrderHistory;