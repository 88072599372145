import { Form, Input, Popover } from "antd";
import useWebsiteSettings from "hooks/useWebsiteSettings";
import { FC, PropsWithChildren, useEffect, useState } from "react";
import { Button, Typography } from "antd";
import { Box, HStack, VStack } from "@chakra-ui/react";
import { CheckOutlined, QuestionCircleFilled } from "@ant-design/icons";
import SettingsHeader from "./SettingsHeader";

const { Text, Paragraph } = Typography;

const FeaturedVideoSection = () => {
    const { isLoading, settings, updateFeaturedVideo } = useWebsiteSettings();
    const { featuredVideoId, featuredVideoTitle } = settings || {};
    const [form] = Form.useForm();
    const [result, setResult] = useState<boolean>(false);

    useEffect(() => {
        if (!isLoading) {
            form.setFieldsValue({
                videoId: featuredVideoId,
                title: featuredVideoTitle,
            });
        }
    }, [isLoading, featuredVideoId, featuredVideoTitle, form]);

    if (isLoading) {
        return <></>;
    }

    const hasVideo = featuredVideoId?.trim() !== '';

    const update = async (values: { videoId: string, title: string }) => {
        setResult(
            await updateFeaturedVideo(values)
        );
    }

    const Medium: FC<PropsWithChildren> = ({ children }) =>
        <Text style={{ fontWeight: '600' }}>{children}</Text>;

    const helpContent = <>
        The ID can be found within the URL of a video.
        <br />The following URLs have <Medium>abcd1234</Medium> as the ID:
        <br />
        <br />https://youtu.be/<Medium>abcd1234</Medium>
        <br />https://www.youtube.com/watch?v=<Medium>abcd1234</Medium>
        <br />https://youtube.com/v/<Medium>abcd1234</Medium>
    </>;

    const idLabel = <HStack w='100%'>
        <Text>YouTube Video Id</Text>
        <Popover content={helpContent} title="YouTube Video ID" trigger="click">
            <Button type="link" size="small" shape="circle" icon={<QuestionCircleFilled />} />
        </Popover>
    </HStack>;

    return <>
        <SettingsHeader title="Featured Video" />
        <Paragraph>
            Set the featured video section on the home page. If you do not set a title, it will default to "Featured Video".
        </Paragraph>
        <HStack align='baseline'>
            <Form
                form={form}
                onFinish={update}
                layout="vertical"
                className="website-update">
                <Form.Item name="videoId" label={idLabel} rules={[{ required: true }]}>
                    <Input />
                </Form.Item>
                <Form.Item name="title" label="Video Title">
                    <Input />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" disabled={isLoading}>
                        Update Featured Video
                    </Button>
                    {result && <CheckOutlined className="result-success" />}
                </Form.Item>
            </Form>
            {hasVideo && <VStack
                spacing={0}
                alignItems='start'>
                <Box ml={100}>
                    <Text style={{ fontWeight: 'bold' }}>{featuredVideoTitle}</Text>
                    <iframe
                        style={{ aspectRatio: '16 / 9', width: '150%' }}
                        allowFullScreen
                        seamless
                        src={`https://www.youtube.com/embed/${featuredVideoId}`}
                        title={featuredVideoTitle}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share">
                    </iframe>
                </Box>
            </VStack>}
        </HStack>
    </>;
}

export default FeaturedVideoSection;