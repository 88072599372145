import { useMutation } from "@tanstack/react-query";
import { useAuthenticatedRequestCreator } from "hooks/useRequests";

export interface UserUpdate {
    userId: string;
    emailAddress?: string;
    sendPasswordResetEmail: boolean;
    image?: File | null;
    roles?: string[];
    active?: boolean;
    initials: string | null;
    updatedInitials: boolean;
}

const useUserUpdate = () => {
    const update = useAuthenticatedRequestCreator<boolean, UserUpdate>(
        (model) => {
            const formData = new FormData();
            for (const [key, value] of Object.entries(model)) {
                if (value !== undefined) {
                    formData.append(key, value);
                }
            }

            return {
                method: "patch",
                url: "/account",
                data: formData,
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            };
        }
    );

    const removeMfa = useAuthenticatedRequestCreator<boolean, string>(
        (userId) => ({
            method: 'post',
            url: '/account/remove-mfa',
            data: { userId },
        })
    );

    const { mutateAsync: updateUser } = useMutation((model: UserUpdate) =>
        update(model)
    );

    const { mutateAsync: removeUserTwoFactor } = useMutation((userId: string) =>
        removeMfa(userId)
    );

    return { updateUser, removeUserTwoFactor };
};

export default useUserUpdate;
