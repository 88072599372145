import { Flex } from "@chakra-ui/react";
import { FC, Suspense } from "react";

import CountrySelect from "./Inputs/CountrySelect";
import ShippingMethodRadio from "./Inputs/ShippingMethodRadio";
import TextInput from "./Inputs/TextInput";

const CheckPayoutDetails: FC = () => {
    return (
        <>
            <Flex w="100%">
                <TextInput
                    flex={1}
                    name="payee"
                    label="Payee"
                    pr={3}
                    autoComplete="name"
                />
                <Suspense>
                    <ShippingMethodRadio />
                </Suspense>
            </Flex>
            <TextInput
                flex={1}
                name="addressLine1"
                label="Address Line 1"
                autoComplete="address-line1"
            />
            <TextInput
                name="addressLine2"
                label="Address Line 2"
                required={false}
                autoComplete="address-line2"
            />
            <Flex w="100%">
                <TextInput
                    flex={1}
                    name="city"
                    label="City"
                    pr={3}
                    autoComplete="address-level2"
                />
                <TextInput
                    maxWidth="8rem"
                    name="state"
                    label="State"
                    pr={3}
                    autoComplete="address-level1"
                />
                <TextInput
                    maxWidth="5rem"
                    name="zipCode"
                    label="Zip Code"
                    autoComplete="postal-code"
                />
            </Flex>
            <CountrySelect />
        </>
    );
};

export default CheckPayoutDetails;
