import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Box,
    Button,
    VStack,
} from "@chakra-ui/react";
import Currency from "components/Currency";
import { PayoutRequest } from "models/payoutRequests";
import { FC, useRef, useState } from "react";
import { METHOD_LABELS } from "../usePayoutColumns";
import SummaryDetail from "./ConfirmationDetails/SummaryDetail";
import TextArea from "antd/lib/input/TextArea";

const CancelConfirmationModal: FC<{
    isAdmin?: boolean;
    request: PayoutRequest;
    isOpen: boolean;
    onClose: () => void;
    onConfirm: (reason: string) => void;
}> = ({ isAdmin, request, isOpen, onClose, onConfirm }) => {
    const cancelRef = useRef<HTMLButtonElement>(null);
    const [reason, setReason] = useState<string>(isAdmin ? "" : "Cancelled by customer");

    const formatedDate = new Date(request.requestDate).toLocaleDateString();

    return (
        <>
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Cancel Payout Request
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            <VStack spacing={4}>
                                <Box fontSize="2xl">
                                    Are you sure you want to cancel this payout
                                    request?
                                </Box>
                                <Box fontSize="sm">
                                    <SummaryDetail
                                        label="Payout Method"
                                        spacing={5}
                                    >
                                        {METHOD_LABELS[request.method]}
                                    </SummaryDetail>
                                    <SummaryDetail label="Amount" spacing={5}>
                                        <Currency
                                            cents={request.grossAmountInCents}
                                        />
                                    </SummaryDetail>
                                    <SummaryDetail
                                        label="Requested On"
                                        spacing={5}
                                    >
                                        {formatedDate}
                                    </SummaryDetail>
                                </Box>
                                {!isAdmin && <Box
                                    px={4}
                                    py={2}
                                    bg="lightGray"
                                    rounded="md"
                                    fontWeight="semibold"
                                >
                                    NOTE: You can open a new payout request
                                    immediately
                                </Box>}
                            </VStack>
                            {isAdmin && <VStack alignItems='start'>
                                <br />
                                <label htmlFor="reason">
                                    <span style={{ padding: '0 1px', color: 'red' }}>*</span> Cancellation Reason
                                </label>
                                <TextArea name="reason" onChange={(e) => setReason(e.target.value)} style={{ resize: 'none' }} />
                            </VStack>}
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Close
                            </Button>
                            <Button variant="danger" onClick={() => onConfirm(reason)} ml={3} disabled={reason.trim().length === 0}>
                                Cancel Payout Request
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    );
};

export default CancelConfirmationModal;
