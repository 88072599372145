import { forwardRef, Ref } from "react";
import { useApplicationUsers } from "hooks/useApplicationUsers";
import { ApplicationUser } from "models/applicationUsers";
import EntitySelector, {
    EntitySelectorProps,
    EntitySelectorRef
} from "./EntitySelector";
import { Badge } from "antd";
import MultiValueDisplay from "./MultiValueDisplay";

interface CustomerSpecificProps {
    includeInactive?: boolean,
    showStatus?: boolean
}

const resultDisplay = (customer: ApplicationUser, showStatus?: boolean) => {
    if (!showStatus) {
        return <MultiValueDisplay
            title={customer.userName}
            subTitle={`${customer.firstName} ${customer.lastName} - ${customer.email}`}
        />
    }

    return (
        <div className="multi-value-display">
            <span className="bold-font">
                <Badge dot
                    color={customer.active ? "green" : "red"}
                    offset={[0, 10]}
                    style={{ marginRight: '5px' }} />
                {customer.userName}
            </span>
            <span>{`${customer.firstName} ${customer.lastName} - ${customer.email}`}</span>
        </div>
    );
};

const CustomerSelector = (
    props: EntitySelectorProps<ApplicationUser> & CustomerSpecificProps,
    ref: Ref<EntitySelectorRef>
) => {
    const { findUsers } = useApplicationUsers();
    return (
        <EntitySelector
            ref={ref}
            {...props}
            placeholder="First and/or last name"
            search={str => findUsers({ name: str, includeInactive: props.includeInactive })}
            resultDisplay={cust => resultDisplay(cust, props.showStatus)}
        />
    );
};

export default forwardRef<
    EntitySelectorRef,
    EntitySelectorProps<ApplicationUser> & CustomerSpecificProps
>(CustomerSelector);
